import React, { useState, useEffect } from 'react';

const HeadersEditor = ({ headers, onChange }) => {
  const [localHeaders, setLocalHeaders] = useState(headers || {});

  // Update local state if the headers prop changes.
  useEffect(() => {
    setLocalHeaders(headers || {});
  }, [headers]);

  const handleKeyChange = (oldKey, newKey) => {
    if (!newKey.trim()) {
      return;
    }
    if (newKey !== oldKey && localHeaders.hasOwnProperty(newKey)) {
      alert('Header key already exists.');
      return;
    }
    if (newKey === oldKey) return;
    const updatedHeaders = { ...localHeaders };
    const value = updatedHeaders[oldKey];
    delete updatedHeaders[oldKey];
    updatedHeaders[newKey] = value;
    setLocalHeaders(updatedHeaders);
    onChange(updatedHeaders);
  };

  const handleValueChange = (key, newValue) => {
    const updatedHeaders = { ...localHeaders, [key]: newValue };
    setLocalHeaders(updatedHeaders);
    onChange(updatedHeaders);
  };

  const handleRemove = (key) => {
    const updatedHeaders = { ...localHeaders };
    delete updatedHeaders[key];
    setLocalHeaders(updatedHeaders);
    onChange(updatedHeaders);
  };

  const handleAdd = () => {
    const key = prompt('Enter header key:');
    if (key && !localHeaders.hasOwnProperty(key)) {
      const updatedHeaders = { ...localHeaders, [key]: '' };
      setLocalHeaders(updatedHeaders);
      onChange(updatedHeaders);
    } else {
      alert('Header key is invalid or already exists.');
    }
  };

  return (
    <div className="headers-editor-container">
      {Object.keys(localHeaders).length === 0 && (
        <div className="headers-editor-no-headers">No headers defined.</div>
      )}
      {Object.keys(localHeaders).map((key) => (
        <div key={key} className="headers-editor-row">
          <input
            type="text"
            value={key}
            onChange={(e) => handleKeyChange(key, e.target.value)}
            className="headers-editor-key"
          />
          <input
            type="text"
            value={localHeaders[key]}
            onChange={(e) => handleValueChange(key, e.target.value)}
            className="headers-editor-value"
          />
          <button
            onClick={() => handleRemove(key)}
            className="headers-editor-remove"
          >
            Remove
          </button>
        </div>
      ))}
      <button onClick={handleAdd} className="headers-editor-add">
        Add Header
      </button>
    </div>
  );
};

export default HeadersEditor;




// import React, { useState, useEffect } from 'react';

// const HeadersEditor = ({ headers, onChange }) => {
//   // Initialize localHeaders as an object (defaulting to an empty object)
//   const [localHeaders, setLocalHeaders] = useState(headers || {});

//   // If the headers prop changes, update localHeaders accordingly.
//   useEffect(() => {
//     setLocalHeaders(headers || {});
//   }, [headers]);

//   const handleChange = (key, value) => {
//     const updatedHeaders = { ...localHeaders, [key]: value };
//     setLocalHeaders(updatedHeaders);
//     onChange(updatedHeaders);
//   };

//   const handleRemove = (key) => {
//     const updatedHeaders = { ...localHeaders };
//     delete updatedHeaders[key];
//     setLocalHeaders(updatedHeaders);
//     onChange(updatedHeaders);
//   };

//   const handleAdd = () => {
//     const key = prompt('Enter header key:');
//     if (key && !localHeaders.hasOwnProperty(key)) {
//       handleChange(key, '');
//     } else {
//       alert('Header key is invalid or already exists.');
//     }
//   };

//   return (
//     <div className="border p-2">
//       {Object.keys(localHeaders).length === 0 && (
//         <div className="text-gray-500 italic mb-2">No headers defined.</div>
//       )}
//       {Object.keys(localHeaders).map((key) => (
//         <div key={key} className="flex items-center mb-2">
//           <span className="mr-2 font-bold">{key}:</span>
//           <input
//             type="text"
//             value={localHeaders[key]}
//             onChange={(e) => handleChange(key, e.target.value)}
//             className="border p-1 flex-grow"
//           />
//           <button onClick={() => handleRemove(key)} className="text-red-500 ml-2">
//             Remove
//           </button>
//         </div>
//       ))}
//       <button onClick={handleAdd} className="text-blue-500">
//         Add Header
//       </button>
//     </div>
//   );
// };

// export default HeadersEditor;



// // import React, { useState } from 'react';

// // const HeadersEditor = ({ headers, onChange }) => {
// //   const [localHeaders, setLocalHeaders] = useState(headers || {});

// //   const handleChange = (key, value) => {
// //     const updatedHeaders = { ...localHeaders, [key]: value };
// //     setLocalHeaders(updatedHeaders);
// //     onChange(updatedHeaders);
// //   };

// //   const handleRemove = (key) => {
// //     const updatedHeaders = { ...localHeaders };
// //     delete updatedHeaders[key];
// //     setLocalHeaders(updatedHeaders);
// //     onChange(updatedHeaders);
// //   };

// //   const handleAdd = () => {
// //     const key = prompt('Enter header key:');
// //     if (key) {
// //       handleChange(key, '');
// //     }
// //   };

// //   return (
// //     <div className="border p-2">
// //       {Object.keys(localHeaders).map((key) => (
// //         <div key={key} className="flex items-center mb-2">
// //           <span className="mr-2 font-bold">{key}:</span>
// //           <input
// //             type="text"
// //             value={localHeaders[key]}
// //             onChange={(e) => handleChange(key, e.target.value)}
// //             className="border p-1 flex-grow"
// //           />
// //           <button onClick={() => handleRemove(key)} className="text-red-500 ml-2">
// //             Remove
// //           </button>
// //         </div>
// //       ))}
// //       <button onClick={handleAdd} className="text-blue-500">Add Header</button>
// //     </div>
// //   );
// // };

// // export default HeadersEditor;
