import React from 'react';
import StatusBadge from './StatusBadge';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WebhookList = ({ webhooks, onEdit, onDelete, onTest, onStatusToggle }) => {
  // Sort the webhooks alphabetically by name (case-insensitive).
  const sortedWebhooks = (webhooks || []).sort((a, b) => {
    const nameA = (a.name || a.Name || "").toLowerCase();
    const nameB = (b.name || b.Name || "").toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  return (
    <div>
      <table className="min-w-full border-collapse">
        <thead>
          <tr>
            <th className="table-cell border p-2">Name</th>
            <th className="table-cell border p-2">Description</th>
            <th className="table-cell border p-2">URL</th>
            <th className="table-cell border p-2">Method</th>
            <th className="table-cell border p-2">Status</th>
            <th className="table-cell border p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedWebhooks.map((wh) => {
            const id = wh.id || wh.ID || wh._id || '';
            const name = wh.name || wh.Name;
            const description = wh.description || wh.Description;
            const url = wh.url || wh.URL;
            const method = wh.method || wh.Method;
            const status = wh.status || wh.Status;
            return (
              <tr key={id}>
                <td className="table-cell border p-2">{name}</td>
                <td className="table-cell border p-2">{description}</td>
                <td className="table-cell table-cell-url">{url}</td>
                <td className="table-cell border p-2">{method}</td>
                <td className="table-cell border p-2">
                  <StatusBadge status={status} />
                  <button
                    onClick={() => {
                      onStatusToggle(name, status === 'active' ? 'inactive' : 'active');
                      toast.info("Status Toggled");
                    }}
                    className="btn-add-condition"
                  >
                    Toggle
                  </button>
                </td>
                <td className="table-cell table-cell-btn border p-2">
                  <button
                    onClick={() => {
                      console.log(`Edit button clicked for name: ${name}`);
                      onEdit(name);
                      toast.info("Webhook Edit");
                    }}
                    className="btn-add-condition mr-2"
                  >
                    Edit
                  </button>
                  <button onClick={() => onTest(name)} className="btn-add-condition mr-2">
                    Test
                  </button>
                  <button
                    onClick={() => {
                      onDelete(name);
                      toast.info("Webhook Delete");
                    }}
                    className="btn-add-condition"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default WebhookList;






// import React from 'react';
// import StatusBadge from './StatusBadge';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const WebhookList = ({ webhooks, onEdit, onDelete, onTest, onStatusToggle }) => {
//   return (
//     <div>
//       <table className="min-w-full border-collapse">
//         <thead>
//           <tr>
//             <th className="table-cell border p-2">Name</th>
//             <th className="table-cell border p-2">Description</th>
//             <th className="table-cell border p-2">URL</th>
//             <th className="table-cell border p-2">Method</th>
//             <th className="table-cell border p-2">Status</th>
//             <th className="table-cell border p-2">Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {(webhooks || []).map((wh) => {
//             const id = wh.id || wh.ID || wh._id || '';
//             const name = wh.name || wh.Name;
//             const description = wh.description || wh.Description;
//             const url = wh.url || wh.URL;
//             const method = wh.method || wh.Method;
//             const status = wh.status || wh.Status;
//             return (
//               <tr key={id}>
//                 <td className="table-cell border p-2">{name}</td>
//                 <td className="table-cell border p-2">{description}</td>
//                 <td className="table-cell table-cell-url">{url}</td>
//                 <td className="table-cell border p-2">{method}</td>
//                 <td className="table-cell border p-2">
//                   <StatusBadge status={status} />
//                   <button
//                     onClick={() =>{
//                       onStatusToggle(name, status === 'active' ? 'inactive' : 'active');
//                       toast.info("Status Toggled");
//                     }
//                     }
//                     className="btn-add-condition"
//                   >
//                     Toggle
//                   </button>
//                 </td>
//                 <td className="table-cell table-cell-btn border p-2">
//                 <button
//                     onClick={() => {
//                       console.log(`Edit button clicked for name: ${name}`);
//                       onEdit(name);
//                       toast.info("Webhook Edit");
//                     }}
//                     className="btn-add-condition mr-2"
//                   >
//                     Edit
//                   </button>
//                   <button onClick={() => onTest(name)} className="btn-add-condition mr-2">
//                     Test
//                   </button>
//                   <button
//                     onClick={() => {
//                       onDelete(name);
//                       toast.info("Webhook Delete");
//                     }}
//                     className="btn-add-condition"
//                   >
//                     Delete
//                   </button>
//                 </td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default WebhookList;




// // import React from 'react';
// // import StatusBadge from './StatusBadge';

// // const WebhookList = ({ webhooks, onEdit, onDelete, onTest, onStatusToggle }) => {
// //   return (
// //     <div>
// //       <table className="min-w-full border-collapse">
// //         <thead>
// //           <tr>
// //             <th className="border p-2">Name</th>
// //             <th className="border p-2">Description</th>
// //             <th className="border p-2">URL</th>
// //             <th className="border p-2">Method</th>
// //             <th className="border p-2">Status</th>
// //             <th className="border p-2">Actions</th>
// //           </tr>
// //         </thead>
// //         <tbody>
// //         {(webhooks || []).map((wh) => (
// //             <tr key={wh.id}>
// //               <td className="border p-2">{wh.name}</td>
// //               <td className="border p-2">{wh.description}</td>
// //               <td className="border p-2">{wh.url}</td>
// //               <td className="border p-2">{wh.method}</td>
// //               <td className="border p-2">
// //                 <StatusBadge status={wh.status} />
// //                 <button
// //                   onClick={() => onStatusToggle(wh.id, wh.status === 'active' ? 'inactive' : 'active')}
// //                   className="btn-add-condition"
// //                 >
// //                   Toggle
// //                 </button>
// //               </td>
// //               <td className="border p-2">
// //                 <button onClick={() => onEdit(wh.id)} className="btn-add-condition mr-2">Edit</button>
// //                 <button onClick={() => onTest(wh.id)} className="btn-add-condition mr-2">Test</button>
// //                 <button onClick={() => onDelete(wh.id)} className="btn-add-condition">Delete</button>
// //               </td>
// //             </tr>
// //           ))}
// //         </tbody>
// //       </table>
// //     </div>
// //   );
// // };

// // export default WebhookList;
