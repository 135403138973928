import React, { useState, useEffect } from 'react';

const JSONEditor = ({ value, onChange, variables, onValidate }) => {
  const [rows, setRows] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    try {
      const parsed = JSON.parse(value);
      if (typeof parsed !== 'object' || Array.isArray(parsed)) {
        throw new Error('Expected a JSON object');
      }
      const newRows = Object.keys(parsed).map(key => ({
        key,
        value: parsed[key]
      }));
      setRows(newRows);
      setError('');
    } catch (err) {
      setError('Invalid JSON');
      setRows([]);
    }
  }, [value]);

  const updateParent = (newRows) => {
    const obj = {};
    newRows.forEach(r => {
      if (r.key.trim() !== '') {
        obj[r.key] = r.value;
      }
    });
    const jsonStr = JSON.stringify(obj, null, 2);

    // Validate JSON
    if (onValidate && !onValidate(jsonStr)) {
      setError('Invalid JSON');
    } else {
      setError('');
    }
    onChange(jsonStr);
  };

  const handleKeyChange = (index, newKey) => {
    const newRows = [...rows];
    newRows[index].key = newKey;
    setRows(newRows);
    updateParent(newRows);
  };

  const handleValueChange = (index, newValue) => {
    const newRows = [...rows];
    newRows[index].value = newValue;
    setRows(newRows);
    updateParent(newRows);
  };

  const addRow = () => {
    const newRows = [...rows, { key: '', value: '' }];
    setRows(newRows);
    updateParent(newRows);
  };

  const removeRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
    updateParent(newRows);
  };

  return (
    <div className="json-editor">
      {error && <div className="json-editor-error">{error}</div>}

      <table className="json-editor-table">
        <thead>
          <tr>
            <th className="json-editor-th">Label</th>
            <th className="json-editor-th">Token Value</th>
            <th className="json-editor-th">Action</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index} className="json-editor-row">
              <td className="json-editor-td">
                <input
                  type="text"
                  value={row.key}
                  onChange={(e) => handleKeyChange(index, e.target.value)}
                  className="json-editor-input"
                />
              </td>
              <td className="json-editor-td">
                <input
                  type="text"
                  value={row.value}
                  onChange={(e) => handleValueChange(index, e.target.value)}
                  className="json-editor-input"
                />
              </td>
              <td className="json-editor-td">
                <button
                  onClick={() => removeRow(index)}
                  className="json-editor-delete-btn"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button onClick={addRow} className="json-editor-add-row">
        Add Row
      </button>
    </div>
  );
};

export default JSONEditor;



// import React, { useState, useEffect } from 'react';

// const JSONEditor = ({ value, onChange, variables, onValidate }) => {
//   const [rows, setRows] = useState([]);
//   const [error, setError] = useState('');

//   useEffect(() => {
//     try {
//       const parsed = JSON.parse(value);
//       if (typeof parsed !== 'object' || Array.isArray(parsed)) {
//         throw new Error('Expected a JSON object');
//       }
//       const newRows = Object.keys(parsed).map(key => ({
//         key,
//         value: parsed[key]
//       }));
//       setRows(newRows);
//       setError('');
//     } catch (err) {
//       setError('Invalid JSON');
//       setRows([]);
//     }
//   }, [value]);

//   const updateParent = (newRows) => {
//     const obj = {};
//     newRows.forEach(r => {
//       if (r.key.trim() !== '') {
//         obj[r.key] = r.value;
//       }
//     });
//     const jsonStr = JSON.stringify(obj, null, 2);
//     if (onValidate && !onValidate(jsonStr)) {
//       setError('Invalid JSON');
//     } else {
//       setError('');
//     }
//     onChange(jsonStr);
//   };

//   const handleKeyChange = (index, newKey) => {
//     const newRows = [...rows];
//     newRows[index].key = newKey;
//     setRows(newRows);
//     updateParent(newRows);
//   };

//   const handleValueChange = (index, newValue) => {
//     const newRows = [...rows];
//     newRows[index].value = newValue;
//     setRows(newRows);
//     updateParent(newRows);
//   };

//   const addRow = () => {
//     const newRows = [...rows, { key: '', value: '' }];
//     setRows(newRows);
//     updateParent(newRows);
//   };

//   const removeRow = (index) => {
//     const newRows = rows.filter((_, i) => i !== index);
//     setRows(newRows);
//     updateParent(newRows);
//   };

//   return (
//     <div className="json-editor">
//       {error && <div className="text-red-500 mb-2">{error}</div>}
//       <table className="json-editor-table">
//         <thead>
//           <tr>
//             <th className="json-editor-th">Label</th>
//             <th className="json-editor-th">Token Value</th>
//             <th className="json-editor-th">Action</th>
//           </tr>
//         </thead>
//         <tbody>
//           {rows.map((row, index) => (
//             <tr key={index}>
//               <td className="json-editor-td">
//                 <input
//                   type="text"
//                   value={row.key}
//                   onChange={(e) => handleKeyChange(index, e.target.value)}
//                   className="json-editor-input"
//                 />
//               </td>
//               <td className="json-editor-td">
//                 <input
//                   type="text"
//                   value={row.value}
//                   onChange={(e) => handleValueChange(index, e.target.value)}
//                   className="json-editor-input"
//                 />
//               </td>
//               <td className="json-editor-td">
//                 <button onClick={() => removeRow(index)} className="text-red-500">
//                   Delete
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <button onClick={addRow} className="button-addrow mt-2 px-2 py-1 border rounded">
//         Add Row
//       </button>
//     </div>
//   );
// };

// export default JSONEditor;


// // import React, { useState, useEffect } from 'react';

// // const JSONEditor = ({ value, onChange, variables, onValidate }) => {
// //   const [rows, setRows] = useState([]);
// //   const [error, setError] = useState('');

// //   // Parse the incoming JSON value into rows whenever it changes.
// //   useEffect(() => {
// //     try {
// //       const parsed = JSON.parse(value);
// //       if (typeof parsed !== 'object' || Array.isArray(parsed)) {
// //         throw new Error('Expected a JSON object');
// //       }
// //       const newRows = Object.keys(parsed).map(key => ({
// //         key,
// //         value: parsed[key]
// //       }));
// //       setRows(newRows);
// //       setError('');
// //     } catch (err) {
// //       setError('Invalid JSON');
// //       setRows([]);
// //     }
// //   }, [value]);

// //   // Update the parent with the new JSON string.
// //   const updateParent = (newRows) => {
// //     const obj = {};
// //     newRows.forEach(r => {
// //       // Only include non-empty keys.
// //       if (r.key.trim() !== '') {
// //         obj[r.key] = r.value;
// //       }
// //     });
// //     const jsonStr = JSON.stringify(obj, null, 2);
// //     // Validate using the provided onValidate function.
// //     if (onValidate && !onValidate(jsonStr)) {
// //       setError('Invalid JSON');
// //     } else {
// //       setError('');
// //     }
// //     onChange(jsonStr);
// //   };

// //   const handleKeyChange = (index, newKey) => {
// //     const newRows = [...rows];
// //     newRows[index].key = newKey;
// //     setRows(newRows);
// //     updateParent(newRows);
// //   };

// //   const handleValueChange = (index, newValue) => {
// //     const newRows = [...rows];
// //     newRows[index].value = newValue;
// //     setRows(newRows);
// //     updateParent(newRows);
// //   };

// //   const addRow = () => {
// //     const newRows = [...rows, { key: '', value: '' }];
// //     setRows(newRows);
// //     updateParent(newRows);
// //   };

// //   const removeRow = (index) => {
// //     const newRows = rows.filter((_, i) => i !== index);
// //     setRows(newRows);
// //     updateParent(newRows);
// //   };

// //   return (
// //     <div className="json-editor">
// //       {error && <div className="text-red-500 mb-2">{error}</div>}
// //       <table className="min-w-full border-collapse">
// //         <thead>
// //           <tr>
// //             <th className="border p-2">Label</th>
// //             <th className="border p-2">Token Value</th>
// //             <th className="border p-2">Action</th>
// //           </tr>
// //         </thead>
// //         <tbody>
// //           {rows.map((row, index) => (
// //             <tr key={index}>
// //               <td className="border p-2">
// //                 <input
// //                   type="text"
// //                   value={row.key}
// //                   onChange={(e) => handleKeyChange(index, e.target.value)}
// //                   className="w-full border p-1"
// //                 />
// //               </td>
// //               <td className="border p-2">
// //                 <input
// //                   type="text"
// //                   value={row.value}
// //                   onChange={(e) => handleValueChange(index, e.target.value)}
// //                   className="w-full border p-1"
// //                 />
// //               </td>
// //               <td className="border p-2">
// //                 <button
// //                   onClick={() => removeRow(index)}
// //                   className="text-red-500"
// //                 >
// //                   Delete
// //                 </button>
// //               </td>
// //             </tr>
// //           ))}
// //         </tbody>
// //       </table>
// //       <button
// //         onClick={addRow}
// //         className="mt-2 px-2 py-1 border rounded"
// //       >
// //         Add Row
// //       </button>
// //     </div>
// //   );
// // };

// // export default JSONEditor;

