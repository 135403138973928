import React from 'react';

function ValidationPanel({ trigger, onValidate }) {
  return (
    <div className="validation-panel-container">
      <h2 className="validation-panel-title">Validation Panel</h2>
      {/* Render validation results or status information */}
    </div>
  );
}

export default ValidationPanel;


// //ValidationPanel.jsx
// import React from 'react';

// function ValidationPanel({ pattern, onValidate }) {
//   return (
//     <div className="validation-panel-container">
//       <h2 className="validation-panel-title">Validation Panel</h2>
//       {/* 
//         Removed the "Run Validation" button.
//         You can display any relevant validation info here.
//       */}
//     </div>
//   );
// }

// export default ValidationPanel;


// // import React, { useState } from 'react';
// // import { ResponsiveBar } from '@nivo/bar';
// // import { Button, Loader } from '@mantine/core';

// // function ValidationPanel({ pattern, onValidate }) {
// //   const [validationData, setValidationData] = useState(null);
// //   const [loading, setLoading] = useState(false);

// //   const handleValidate = async () => {
// //     setLoading(true);
// //     try {
// //       const result = await onValidate(pattern);
// //       setValidationData(result);
// //     } catch (error) {
// //       console.error('Validation error:', error);
// //     }
// //     setLoading(false);
// //   };

// //   // Assume validationData.metrics is an array of objects with keys: name, value
// //   return (
// //     <div className="mt-4">
// //       <h2 className="text-xl font-bold mb-4">Validation Panel</h2>
// //       <Button onClick={handleValidate} color="gray" mb="md">
// //         Run Validation
// //       </Button>
// //       {loading && <Loader />}
// //       {validationData && validationData.metrics && (
// //         <div style={{ height: 200 }}>
// //           <ResponsiveBar
// //             data={validationData.metrics.map((m) => ({
// //               metric: m.name,
// //               value: m.value,
// //             }))}
// //             keys={['value']}
// //             indexBy="metric"
// //             margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
// //             padding={0.3}
// //             colors={{ scheme: 'nivo' }}
// //             axisBottom={{
// //               tickSize: 5,
// //               tickPadding: 5,
// //               tickRotation: 0,
// //               legend: 'Metric',
// //               legendPosition: 'middle',
// //               legendOffset: 32,
// //             }}
// //             axisLeft={{
// //               tickSize: 5,
// //               tickPadding: 5,
// //               tickRotation: 0,
// //               legend: 'Value',
// //               legendPosition: 'middle',
// //               legendOffset: -40,
// //             }}
// //           />
// //         </div>
// //       )}
// //     </div>
// //   );
// // }

// // export default ValidationPanel;
