import React, { useState, useEffect, useRef, useMemo, useCallback, useContext } from 'react';
import * as d3 from 'd3';
import { motion, AnimatePresence} from "framer-motion";
import { useDataset } from '../components/DataFetcher';
import SEO from '../components/SEO';
import LoadingSpinner from '../components/LoadingSpinner'; 
import { DimensionContext } from '../components/ResponsiveWrapper';
import InfoBox from '../components/InfoBox';

const ToggleSwitch = ({ filterBy, setFilterBy }) => {
  const handleToggle = () => {
      setFilterBy(prev => prev === 'FROM' ? 'TO' : 'FROM');
  };

  const toggleMotionVariants = {
      from: { x: '0%' },
      to: { x: '70%' }
  };

  return (
      <div className="toggle-section">
          <label className="block-label mr-2">Direction:</label>
          <div className="toggle-container">
              <div className="toggle-bar bg-gray-500 rounded-full"></div>
              <motion.div 
                  initial={filterBy === 'FROM' ? 'from' : 'to'}
                  animate={filterBy === 'FROM' ? 'from' : 'to'}
                  variants={toggleMotionVariants}
                  className={`toggle-handle bg-gray-500 cursor-pointer rounded-full`}
                  onClick={handleToggle}
              >
                  <span className="toggle-indicator bg-white rounded-full"></span>
              </motion.div>
              <span className="toggle-label-left">from</span>
              <span className="toggle-label-right">to</span>
          </div>
      </div>
  );
}

const MetricsFilters = ({ metricFilters, toggleMetricFilter }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
      <div>
          <button onClick={() => setIsOpen(!isOpen)}>+</button>

          <AnimatePresence>
              {isOpen && (
                  <motion.div 
                      className="metric-section flex flex-wrap"
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                  >
                      {Object.keys(metricFilters).map((metricName, index) => (
                          <div key={metricName} className={`metric-item ${index % 2 === 0 ? 'pr-2' : 'pl-2'} w-1/2`}>
                              <span className="metric-name">{metricName}</span>
                              {['25', '50', '75', '100'].map(quartile => (
                                  <button 
                                      key={quartile} 
                                      className={`metric-button ${metricFilters[metricName].includes(quartile) ? 'filter-active' : 'filter-inactive'}`}
                                      onClick={() => toggleMetricFilter(metricName, quartile)}
                                  >
                                      {quartile}
                                  </button>
                              ))}
                          </div>
                      ))}
                  </motion.div>
              )}
          </AnimatePresence>
      </div>
  );
}

const FeatureInsights = () => {
  const dimensions = useContext(DimensionContext);
  const { width, height } = useContext(DimensionContext);
  console.log('dimensions', dimensions);
  const svgRef = useRef(null);
  const metricDomainsRef = useRef({});
  const featureDefinitionRef = useRef(null);
  const featureStoryRefs = useRef([]);
  const [lines, setLines] = useState([]);
  const isDataFetchedRef = useRef(false);
  const [featureSelection, setFeatureSelection] = useState(null);
  const [featureStories, setFeatureStories] = useState([]);
  const [isBaseDataLoaded, setIsBaseDataLoaded] = useState(false);
  const [filterBy, setFilterBy] = useState('FROM');
  const { data: dataset, status } = useDataset();
  const [metricDomainsAndQuartiles, setMetricDomainsAndQuartiles] = useState({});
  const metricQuartilesRef = useRef({});
  const [storiesGenerated, setStoriesGenerated] = useState(false);
  const [metricDomains, setMetricDomains] = useState({});
  
  const [metricFilters, setMetricFilters] = useState(() => {
    const initialFilters = {};
    // const metricsList = ["antecedent support", "confidence", "consequent support", "conviction", "importance", "leverage", "lift", "support", "zhangs_metric", "representativity", "jaccard", "kulczynski", "certainty"];
    const metricsList = ["support", "confidence", "lift", "zhangs_metric", "leverage"];
    
    metricsList.forEach(metricName => {
      initialFilters[metricName] = ['25', '50', '75', '100']; // Use strings for quartiles
    });
  
    return initialFilters;
  });
  
  
  const { RandomForest, FPGrowth, UniqueURL, UniqueEventDescList } = useMemo(() => {
    if (!dataset || status !== 'success') {
      // Data isn't ready yet, return default values or handle as needed
      return {
        RandomForest: {},
        FPGrowth: [],
        UniqueURL: [],
        UniqueEventDescList: [],
      };
    }
    // console.log("RandomForest Data in useMemo:", dataset.RandomForest);
    // console.log("FPGrowth Data in useMemo:",  dataset.FPGrowth);

    return {
        RandomForest: dataset.RandomForest || {},
        FPGrowth: dataset.FPGrowth || [],
        UniqueURL: dataset.UniqueURL || [],
        UniqueEventDescList: dataset.UniqueEventDescList || [],
    };
    
  }, [dataset, status]);

  // Add this new useMemo to compute available URLs
const availableURLs = useMemo(() => {
  const urlsFromRandomForest = new Set();
  const urlsFromFPGrowth = new Set();

  // Extract URLs from RandomForest
  if (RandomForest && typeof RandomForest === 'string' && RandomForest !== "") {
    const parsedRandomForest = JSON.parse(RandomForest);
    Object.entries(parsedRandomForest.feature_importances)
      .forEach(([feature, importance]) => {
        if (importance !== 0) {
          urlsFromRandomForest.add(feature);
        }
      });
  }

  // Extract URLs from FPGrowth
  if (FPGrowth && typeof FPGrowth === 'string' && FPGrowth !== "") {
    const cleanedData = FPGrowth.replace(/Infinity/g, "null");
    const parsedData = JSON.parse(cleanedData);
    parsedData.data.forEach(entry => {
      const fromPage = entry[0][0];
      const toPage = entry[1][0];
      urlsFromFPGrowth.add(fromPage);
      urlsFromFPGrowth.add(toPage);
    });
  }

  // Combine URLs from both models
  const urlsWithResults = new Set([...urlsFromRandomForest, ...urlsFromFPGrowth]);

  // Filter UniqueURL based on the combined URLs
  const filteredUniqueURLs = Object.keys(UniqueURL).filter(url => urlsWithResults.has(url));

  return filteredUniqueURLs;
}, [RandomForest, FPGrowth, UniqueURL]);


const processFPGrowthData = (FPGrowth) => {
  const metricColumns = FPGrowth.columns.slice(2); 

  // Group by 'from' and 'to' fields
  const grouped = FPGrowth.data.reduce((acc, entry) => {
      const fromPage = entry[0][0];
      const toPage = entry[1][0];
      const key = `${fromPage}-${toPage}`; // Use a composite key for grouping

      if (!acc[key]) {
          acc[key] = [];
      }
      
      const metrics = entry.slice(2);
      // Map metrics to their column names
      const mappedMetrics = metricColumns.reduce((obj, col, idx) => {
          // obj[col] = metrics[idx];
          if (["support", "confidence", "lift", "zhangs_metric", "leverage"].includes(col)) {
            obj[col] = metrics[idx];
        }
        return obj;
      }, {});

      acc[key].push({
          from: fromPage,
          to: toPage,
          metrics: mappedMetrics
      });

      return acc;
  }, {});

  // Process each group to average metrics if there are duplicates
  const processed = Object.values(grouped).map(group => {
      if (group.length === 1) {
          return group[0]; // If there's only one entry, return it as-is
      }

      // If there are duplicates, average their metrics
      const averagedMetrics = metricColumns.reduce((acc, col) => {
          acc[col] = group.reduce((sum, entry) => sum + entry.metrics[col], 0) / group.length;
          return acc;
      }, {});

      return {
          from: group[0].from,
          to: group[0].to,
          metrics: averagedMetrics
      };
  });

  return processed;
}

const generateFeatureStories = useCallback((feature) => {
  let stories = [];

  // console.log("RandomForest at generateFeatureStories start:", RandomForest);
  // console.log("FPGrowth at generateFeatureStories start:", FPGrowth);

  if (RandomForest && typeof RandomForest === 'string' && RandomForest !== "") {
    console.log("RandomForest before JSON parsing:", RandomForest);
    const parsedRandomForest = JSON.parse(RandomForest);
    console.log("Parsed RandomForest Data:", parsedRandomForest);
      const featureImportancesArray = Object.entries(parsedRandomForest.feature_importances)
        .map(([key, value]) => ({
            feature: key,
            metrics: {
                importance: value
            }
        }))
        .filter(item => item.metrics.importance !== 0)
        .sort((a, b) => b.metrics.importance - a.metrics.importance); // Sorting in descending order
        console.log("Feature Importances Array:", featureImportancesArray);

      stories = [...stories, ...featureImportancesArray];
  }
 
  if (FPGrowth && typeof FPGrowth === 'string' && FPGrowth !== "") {
    const cleanedData = FPGrowth.replace(/Infinity/g, "null");
      const parsedData = JSON.parse(cleanedData);
      const structuredFPGrowthData = processFPGrowthData(parsedData);
  
      stories = [...stories, ...structuredFPGrowthData];

      
      let metricDomains = {};

      // Use `stories` instead of `completeStories`
      stories.forEach(story => {
        Object.entries(story.metrics).forEach(([metricName, metricValue]) => {
          if (!metricDomains[metricName]) {
            metricDomains[metricName] = { min: Infinity, max: -Infinity };
          }
          metricDomains[metricName].min = Math.min(metricDomains[metricName].min, metricValue);
          metricDomains[metricName].max = Math.max(metricDomains[metricName].max, metricValue);
        });
      });

      console.log("Final pre-sorted stories, after processing RandomForest and FPGrowth:", stories);
      stories.sort((a, b) => b.metrics.importance - a.metrics.importance);
      console.log("Final sorted stories:", stories);

      metricDomainsRef.current = metricDomains;
      console.log("metricDomains:", metricDomains);

      let metricValues = {};
      stories.forEach(story => {
        Object.entries(story.metrics).forEach(([metricName, metricValue]) => {
          if (!metricValues[metricName]) {
            metricValues[metricName] = [];
          }
          metricValues[metricName].push(metricValue);
        });
      });
      console.log("metricValues:", metricValues);

      let metricDomainsAndQuartiles = {};
      Object.entries(metricValues).forEach(([metricName, values]) => {
        const sortedValues = values.sort(d3.ascending);
        metricDomainsAndQuartiles[metricName] = {
          '25': d3.quantile(sortedValues, 0.25),
          '50': d3.quantile(sortedValues, 0.5),
          '75': d3.quantile(sortedValues, 0.75),
          '100': d3.quantile(sortedValues, 1.0)
        };
      });
      
      metricQuartilesRef.current = metricDomainsAndQuartiles;
      // Store the calculated metricDomainsAndQuartiles in the ref

      console.log("metricDomainsAndQuartiles:", metricDomainsAndQuartiles);
      console.log("metricFilters:", metricFilters);

      console.log("Before filtering:", stories);
      console.log("Feature for filtering:", feature);
      console.log("Filtering by:", filterBy);

      stories = stories.filter(item => {
        if (item.feature && !item.feature.includes(feature.value)) {
            return false;
        } else if (filterBy === 'FROM' && item.from && !item.from.includes(feature.value)) {
            return false;
        } else if (filterBy === 'TO' && item.to && !item.to.includes(feature.value)) {
            return false;
        }
        return true;
      });
      
      console.log("metricFilters:", metricFilters);

      console.log("After filtering:", stories);
  }

  setFeatureStories(stories);
  setStoriesGenerated(true);
  return stories; // Ensure you return the stories array

}, [RandomForest, FPGrowth, filterBy, metricFilters]);



  const isGraphDataLoaded = () => {
    return !!document.querySelector('script[data-graph-positions]');
  };

  const handleFeatureSelection = useCallback((type, event) => {
    if (event && event.target) {
        const selectedFeature = {
            type: type,
            value: event.target.value
        };
        setFeatureSelection(selectedFeature);
    }
}, []);
  
  useEffect(() => {
    if (!isDataFetchedRef.current) {
      console.log("Fetching dataset...");
      // Fetch dataset here using useDataset
      setIsBaseDataLoaded(isGraphDataLoaded()); // Check if GraphPositions is in DOM
      isDataFetchedRef.current = true;
    }

    if (status !== 'success' || !isDataFetchedRef.current) {
      console.log("Data not ready yet.");
      return;
    }

    if (UniqueURL) {
      const urls = UniqueURL;
      const maxURL = Object.entries(urls).reduce(
        (max, [url, count]) => (count > max.count ? { url, count } : max),
        { url: '', count: 0 },
      );
      handleFeatureSelection('URL', { target: { value: maxURL.url } });
    }
    console.log('UniqueURL:', UniqueURL);
  
  }, [UniqueURL, handleFeatureSelection, status]);
  
  useEffect(() => {
    if (featureSelection) {
        generateFeatureStories(featureSelection);
    }
}, [featureSelection, generateFeatureStories]);

  
  useEffect(() => {
    if (featureDefinitionRef.current && featureStories.length > 0) {
      const linesData = featureStories.map((story, idx) => {
        const featureDefinitionRect =
          featureDefinitionRef.current.getBoundingClientRect();
        const featureStoryRect =
          featureStoryRefs.current[idx]?.getBoundingClientRect();

        return {
          x1: featureDefinitionRect.x + featureDefinitionRect.width / 2,
          y1: featureDefinitionRect.y + featureDefinitionRect.height / 2,
          x2: featureStoryRect.x + featureStoryRect.width / 2,
          y2: featureStoryRect.y + featureStoryRect.height / 2,
        };
      });

      setLines(linesData);
      console.log('featureStories:', featureStories);    
      
    }
  }, [featureStories]);

  function wrap(text, maxLength) {
    text.each(function() {
        let text = d3.select(this),
            originalText = text.text(),
            words = originalText.split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            y = text.attr("y"),
            dy = parseFloat(text.attr("dy")),
            tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");

        if (originalText.length > maxLength) {
            let splitIndex = originalText.lastIndexOf('-', maxLength);
            if (splitIndex === -1) splitIndex = maxLength; // If no hyphen found, split at maxLength
            words = [originalText.substring(0, splitIndex), originalText.substring(splitIndex + 1)];
        }

        while (word = words.pop()) {
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > maxLength) {
                line.pop();
                tspan.text(line.join(" "));
                line = [word];
                tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
            }
        }
    });
}

  useEffect(() => {
    console.log("Feature Selection Changed:", featureSelection);
}, [featureSelection]);

  const toggleMetricFilter = (metricName, quartile) => {
    setMetricFilters(prev => {
      if (prev[metricName].includes(quartile)) {
        return { ...prev, [metricName]: prev[metricName].filter(q => q !== quartile) };
      } else {
        return { ...prev, [metricName]: [...prev[metricName], quartile] };
      }
    });
  };

  function adjustLineEnd(x1, y1, x2, y2, offset) {
    const length = Math.sqrt((x2-x1)*(x2-x1) + (y2-y1)*(y2-y1));
    const percent = (length - offset) / length;  // Subtract additional 10 units for arrowhead size
    const newX = x1 + percent * (x2 - x1);
    const newY = y1 + percent * (y2 - y1);
    return {x: newX, y: newY};
}

useEffect(() => {
  if (
    !featureStories ||
    featureStories.length === 0 ||
    !Object.keys(metricQuartilesRef.current).length
  )
    return;

  console.log("metricFilters:", metricFilters);
  console.log("metricQuartilesRef.current:", metricQuartilesRef.current);

  const epsilon = 1e-12; // to account for floating point imprecision
  // Only allow these datasets in the final chart
  const allowedMetrics = ["support", "confidence", "lift", "leverage", "zhangs_metric"];

  // Filter stories based on allowed metrics only.
  const filteredStories = featureStories.filter((story) => {
    let passed = false;
    allowedMetrics.forEach((metricName) => {
      // Skip if the story doesn't have the allowed metric.
      if (story.metrics[metricName] === undefined) return;
      const quartiles = metricQuartilesRef.current[metricName];
      if (!quartiles || !metricFilters[metricName]) return;
      const value = story.metrics[metricName];

      if (value <= quartiles["25"] + epsilon && metricFilters[metricName].includes("25")) {
        passed = true;
      } else if (
        value > quartiles["25"] &&
        value <= quartiles["50"] + epsilon &&
        metricFilters[metricName].includes("50")
      ) {
        passed = true;
      } else if (
        value > quartiles["50"] &&
        value <= quartiles["75"] + epsilon &&
        metricFilters[metricName].includes("75")
      ) {
        passed = true;
      } else if (
        value > quartiles["75"] - epsilon &&
        value <= quartiles["100"] + epsilon &&
        metricFilters[metricName].includes("100")
      ) {
        passed = true;
      }
    });
    return passed;
  });

  console.log("Filtered Stories:", filteredStories);

  // Now update the chart drawing.
  const svg = d3.select(svgRef.current);
  svg.selectAll("circle").remove();
  svg.selectAll("line").remove();
  svg.selectAll("*").remove();

  const width = parseInt(dimensions.width, 10) || 1000;
  const height = parseInt(dimensions.height - 100, 10) || 1000;
  const centerX = width / 2;
  const centerY = height / 2 + 50;
  const radius = Math.min(width, height) * 0.40;

  console.log("centerX", centerX);
  console.log("centerY", centerY);
  console.log("radius", radius);

  // Node radius calculation.
  const getNodeRadius = (totalStories) => {
    return 200 / ((totalStories / 10) + 1);
  };
  const nodeRadius = getNodeRadius(filteredStories.length);
  console.log("nodeRadius", nodeRadius);

  filteredStories.forEach((story, index) => {
    const angle = (index / filteredStories.length) * 2 * Math.PI;
    const x = centerX + radius * Math.cos(angle);
    const y = centerY + radius * Math.sin(angle);

    svg.append("defs")
      .selectAll("marker")
      .data(["end"])
      .enter()
      .append("marker")
      .attr("id", "end")
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 20)
      .attr("refY", 0)
      .attr("markerWidth", 12)
      .attr("markerHeight", 12)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M0,-5L10,0L0,5")
      .attr("fill", "#e08a3c");

    let adjustedEnd = { x, y };
    if (filterBy === "FROM") {
      adjustedEnd = adjustLineEnd(centerX, centerY, x, y, nodeRadius);
    }

    svg.append("line")
      .attr("x1", centerX)
      .attr("y1", centerY)
      .attr("x2", adjustedEnd.x)
      .attr("y2", adjustedEnd.y)
      .attr("stroke", "#e08a3c")
      .attr("marker-end", "url(#end)");

    // Draw each story node.
    const storyGroup = svg.append("g").attr("transform", `translate(${x}, ${y})`);
    storyGroup
      .append("circle")
      .attr("r", nodeRadius)
      .attr("fill", "#ebb844")
      .attr("stroke", "#de6736");

    // Determine the story name based on filter direction.
    let storyName;
    if (filterBy === "FROM" && story.from) {
      storyName = story.to;
    } else if (filterBy === "TO" && story.to) {
      storyName = story.from;
    } else {
      storyName = story.feature;
    }

    // Truncate long story names.
    if (storyName.length > 25) {
      const lastSlashBefore25 = storyName.lastIndexOf("/", 25);
      if (lastSlashBefore25 !== -1) {
        storyName =
          storyName.substring(0, lastSlashBefore25) +
          "\n" +
          storyName.substring(lastSlashBefore25 + 1);
      } else {
        storyName = storyName.substring(0, 25) + "...";
      }
    }

    storyGroup
      .append("text")
      .attr("dy", "-3em")
      .attr("text-anchor", "middle")
      .text(storyName)
      .attr("font-size", "20px");

    // Render only the allowed metrics.
    allowedMetrics.forEach((metricName, metricIndex) => {
      if (story.metrics[metricName] === undefined) return;
      
      // Render metric name label.
      storyGroup
        .append("text")
        .attr("x", -100)
        .attr("y", (metricIndex + 1) * 20 - 55)
        .attr("alignment-baseline", "middle")
        .text(metricName)
        .attr("font-size", "14px")
        .attr("fill", "#3b3484");

      // Create a scale based on the stored domain for this metric.
      const metricScale = d3.scaleLinear()
        .domain([
          metricDomainsRef.current[metricName].min,
          metricDomainsRef.current[metricName].max
        ])
        .range([1, 5])
        .clamp(true);

      const starRating = Math.round(metricScale(story.metrics[metricName]));
      // Render star circles representing the metric value.
      for (let i = 0; i < starRating; i++) {
        storyGroup
          .append("circle")
          .attr("cx", 16 * i + 20)
          .attr("cy", (metricIndex + 1) * 20 - 55)
          .attr("r", 6)
          .attr("fill", "#3b3484");
      }
    });
  });

  // Draw the central feature node if a feature is selected.
  if (featureSelection) {
    const centralGroup = svg
      .append("g")
      .attr("class", "central-feature")
      .attr("transform", `translate(${centerX}, ${centerY})`);

    centralGroup.append("circle").attr("r", 80).attr("fill", "#de6736");

    let featureText = centralGroup
      .append("text")
      .attr("dy", "-1em")
      .attr("text-anchor", "middle")
      .text(featureSelection.value);

    wrap(featureText, 25);

    centralGroup
      .append("text")
      .attr("dy", "1em")
      .attr("text-anchor", "middle")
      .text(featureSelection.type);
  }
}, [
  featureStories,
  filterBy,
  dimensions,
  metricQuartilesRef,
  metricFilters,
  storiesGenerated,
]);


// useEffect(() => {
//   if (
//     !featureStories ||
//     featureStories.length === 0 ||
//     !Object.keys(metricQuartilesRef.current).length
//   )
//     return;

//   console.log("metricFilters:", metricFilters);
//   console.log("metricQuartilesRef.current:", metricQuartilesRef.current);

//   const epsilon = 1e-12; // to account for floating point imprecision
//   // Define a subset of key metrics (adjust as needed)
//   const keyMetrics = ["support", "confidence", "lift", "leverage"];

//   const filteredStories = featureStories.filter((story) => {
//     // Check only the selected key metrics:
//     let passed = false;
//     keyMetrics.forEach((metricName) => {
//       // If the story doesn't have the metric, skip it
//       if (story.metrics[metricName] === undefined) return;

//       const quartiles = metricQuartilesRef.current[metricName];
//       if (!quartiles || !metricFilters[metricName]) return;

//       const value = story.metrics[metricName];
//       if (value <= quartiles["25"] + epsilon && metricFilters[metricName].includes("25")) {
//         passed = true;
//       } else if (
//         value > quartiles["25"] &&
//         value <= quartiles["50"] + epsilon &&
//         metricFilters[metricName].includes("50")
//       ) {
//         passed = true;
//       } else if (
//         value > quartiles["50"] &&
//         value <= quartiles["75"] + epsilon &&
//         metricFilters[metricName].includes("75")
//       ) {
//         passed = true;
//       } else if (
//         value > quartiles["75"] - epsilon &&
//         value <= quartiles["100"] + epsilon &&
//         metricFilters[metricName].includes("100")
//       ) {
//         passed = true;
//       }
//     });
//     return passed;
//   });
  

//     console.log("useEffect called");
//     console.log("filteredStories: ", filteredStories);

//     const svg = d3.select(svgRef.current);
//     console.log("metricFilters:", metricFilters);

//     // Remove existing circles and lines from previous renders
//     svg.selectAll("circle").remove();
//     svg.selectAll("line").remove();
//     svg.selectAll("*").remove();

//     const width = parseInt(dimensions.width, 10) || 1000;
//     const height = parseInt(dimensions.height - 100, 10) || 1000;
//     const centerX = width / 2;
//     const centerY = (height / 2) + 50;
//     const radius = Math.min(width, height) * 0.40;  // 40% of the smallest dimension
    
//     console.log("centerX", centerX)
//     console.log("centerY", centerY)
//     console.log("radius", radius)

//     // 2. Calculate Node Radius Based on the Number of Stories
//     const getNodeRadius = (totalStories) => {
//       // For demonstration purposes, we are inversely scaling the radius
//       // with the number of stories. You can adjust the scaling factor as needed.
//       return (200 / ((totalStories / 10) + 1));
//   };
//   console.log("getNodeRadius", getNodeRadius)

//     const nodeRadius = getNodeRadius(filteredStories.length);
//     console.log("nodeRadius", nodeRadius)

//     // Position and draw other nodes and lines
//     filteredStories.forEach((story, index) => {
//         // Compute angle for this node

//         const angle = (index / filteredStories.length) * 2 * Math.PI;

//         // Compute x and y using trigonometry
//         const x = (centerX + radius * Math.cos(angle));
//         const y = (centerY + radius * Math.sin(angle));

//         // const markerOffset = filterBy === 'FROM' ? nodeRadius : 0;

//         svg.append("defs").selectAll("marker")
//       .data(["end"])
//       .enter().append("marker")
//       .attr("id", "end")
//       .attr("viewBox", "0 -5 10 10")
//       .attr("refX", 20)  // This determines the protrusion of the arrowhead
//       .attr("refY", 0)
//       .attr("markerWidth", 12)
//       .attr("markerHeight", 12)
//       .attr("orient", "auto")
//       .append("path")
//       .attr("d", "M0,-5L10,0L0,5")
//       .attr("fill", "#e08a3c");

//         let adjustedEnd = { x, y };
//         if (filterBy === 'FROM') {
//             adjustedEnd = adjustLineEnd(centerX, centerY, x, y, nodeRadius);
//         }
    
//         // Draw line connecting to the central feature circle
//         svg.append("line")
//             .attr("x1", centerX)
//             .attr("y1", centerY)
//             .attr("x2", adjustedEnd.x)
//             .attr("y2", adjustedEnd.y)
//             .attr("stroke", "#e08a3c")
//             .attr("marker-end", "url(#end)");         

//         // Draw the node
//         const storyGroup = svg.append("g")
//             .attr("transform", `translate(${x}, ${y})`);

//         storyGroup.append("circle")
//             .attr("r", nodeRadius) // Use calculated node radius
//             .attr("fill", "#ebb844")
//             .attr("stroke", "#de6736");

//         let storyName;
//             if (filterBy === 'FROM' && story.from) {
//                 storyName = story.to;
//             } else if (filterBy === 'TO' && story.to) {
//                 storyName = story.from;
//             } else {
//                 storyName = story.feature;
//             }      

//         // FIGURE OUT WHY THIS DOESN'T WORK=
//         if (storyName.length > 25) {
//           const lastSlashBefore25 = storyName.lastIndexOf('/', 25);
//           if (lastSlashBefore25 !== -1) {
//               storyName = storyName.substring(0, lastSlashBefore25) + '\n' + storyName.substring(lastSlashBefore25 + 1);
//           } else {
//               storyName = storyName.substring(0, 25) + '...';
//           }
//         }

//         // Display the story name
//         storyGroup.append("text")
//         .attr("dy", "-4.5em")  // Move the story name further up
//         .attr("text-anchor", "middle")
//         .text(storyName)
//         .attr('font-size', "18px");

//         const metricScales = {};
//         Object.entries(metricDomainsRef.current).forEach(([metricName, domain]) => {
//         metricScales[metricName] = d3.scaleLinear()
//             .domain([domain.min, domain.max])
//             .range([1, 5])
//             .clamp(true);
//         });

//         // Iterate over the story metrics
//         Object.entries(story.metrics).forEach(([metricName, metricValue], metricIndex) => {
//         const starRating = Math.round(metricScales[metricName](metricValue));
//         const yOffset = -35; // Define an offset at the top of your function or outside

//         // Display metric names to the left
//         storyGroup.append("text")
//             .attr("x", -100)  // Move text to the left of the circles
//             .attr("y", (metricIndex + 1) * 20 + yOffset) // Adjust vertical spacing between metrics
//             .attr("alignment-baseline", "middle")
//             .text(`${metricName}`)
//             .attr('font-size', "14px")
//             .attr('fill', '#3b3484');

//         // Display circles (stars) based on the star rating
//         for (let i = 0; i < starRating; i++) {
//             storyGroup.append("circle")
//                 .attr("cx", 20 * i + 20)  // Add an offset to the right for the circles
//                 .attr("cy", (metricIndex + 1) * 20 + yOffset) // Match the circle's vertical position with the text
//                 .attr("r", 6)
//                 .attr("fill", "#3b3484");
//         }
//         });

//     if (featureSelection) {
//       const centralGroup = svg.append("g")
//           .attr("class", "central-feature")
//           .attr("transform", `translate(${centerX}, ${centerY})`);

//       // Circle
//       centralGroup.append("circle")
//           .attr("r", 80)
//           .attr("fill", "#de6736");

//     // Feature Name
//     let featureText = centralGroup.append("text")
//         .attr("dy", "-1em")
//         .attr("text-anchor", "middle")
//         .text(featureSelection.value);

//     // Wrap the feature name text if it's too long
//     wrap(featureText, 25); // 25 characters max length

//     // Feature Type
//     centralGroup.append("text")
//         .attr("dy", "1em")
//         .attr("text-anchor", "middle")
//         .text(featureSelection.type);
//     };  

//   }); 

// }, [featureStories, lines, featureSelection, filterBy, dimensions, metricDomainsAndQuartiles, metricQuartilesRef, metricFilters, storiesGenerated]);


    if (status === 'loading') {
      return <LoadingSpinner />;
    }
  
    return (
        <>
<div className="feature-insights">
    <div className="feature-selection flex">
    <h1 className="h1">Feature Insights</h1>

            <div className="feature-select">
                <label className="block-label">Page:</label>
                <select 
                    className="full-width-border-select"
                    id="uniqueURLSelection"
                    onChange={(e) => handleFeatureSelection('URL', e)}
                >
                    {availableURLs.map((url, index) => (
                      <option key={index} value={url}>
                        {url || 'No URLs'} - {UniqueURL[url]}
                      </option>
                    ))}
                </select>
            </div>
            {/* <div className="feature-select">
                <label className="block-label">Event:</label>
                <select 
                    className="full-width-border-select"
                    id="uniqueEventDescSelection"
                    onChange={(e) => handleFeatureSelection('EventDesc', e)}
                >
                    {Object.keys(UniqueEventDesc).map((key, index) => (
                        <option key={index} value={key}>
                            {key || 'No EventDescs'} - {UniqueEventDesc[key]}
                        </option>
                    ))}
                </select>
            </div> */}

            <ToggleSwitch filterBy={filterBy} setFilterBy={setFilterBy} />

    <MetricsFilters metricFilters={metricFilters} toggleMetricFilter={toggleMetricFilter} />

</div> {/* Closing tag for feature-selection */}
</div> {/* Closing tag for feature-insights */}

        <svg ref={svgRef} width={dimensions.width || 1200} height={dimensions.height || 1200}></svg>
          <div>
            <SEO
              title="Feature Insights"
              description="Deep dive into one element to find the drivers and detractors"
              url="/featureinsights"
            />
          </div>    
          <InfoBox title={'What impacts behavior?'} description={'Select the feature to analyze from the drop downs. Choose whether you want the direction of action from the feature to the nodes, or vice verse.'} />
          </>
    );
   };
  export default FeatureInsights;
