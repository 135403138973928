import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const Sidebar = ({ 
    pages, 
    activeOrgID, 
    handleOrgChange, 
    onNavigate, 
    canAccessFeature,
    dataset 
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isFirstRender = useRef(true);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [openCategory, setOpenCategory] = useState("Insights");
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [parsedDataset, setParsedDataset] = useState(null);

    const categoryOrder = ["Insights", "Exploration", "Validation", "Actions", "Admin"];

    const roleRequirements = {
        "/onboarding": ["superadmin"],
    };

    const dataRequirements = {
        "/benchmarks": ["UniqueURL", "UniqueEventDescList"],
        "/conversations": ["UniqueURL", "FrequentPatterns", "MarkovChain"],
        "/featureinsights": ["FPGrowth"],
        "/funnel": ["UniqueURL"],
        "/happypath": ["HappyPath"],
        "/journeyflow": ["MarkovChain"],
        "/pathanalyzer": ["GraphPositions"],
        "/peaks": ["FPGrowth"],
        "/treeexplorer": ["MarkovChain"]
    };

    const getFilteredPages = () => {
        return pages.filter(
            (page) => page.route !== "/auth" && page.route !== "/signup"
        );
    };

    // Parse JSON strings in dataset if needed
    const parseDatasetField = (field) => {
        if (!field) return null;
        if (typeof field === 'object') return field;
        try {
            return JSON.parse(field);
        } catch (e) {
            console.log(`Failed to parse field:`, e);
            return field;
        }
    };

    // Process the dataset to ensure all fields are properly parsed
// Process the dataset to ensure all fields are properly parsed
useEffect(() => {
    if (!dataset) return;

    const processed = {
        ...dataset,
        UniqueURL: parseDatasetField(dataset.UniqueURL) || parseDatasetField(dataset.UniqueUrls)?.URL || {},
        FrequentPatterns: parseDatasetField(dataset.FrequentPatterns),
        GraphPositions: parseDatasetField(dataset.GraphPositions),
        MarkovChain: parseDatasetField(dataset.MarkovChain),
        FPGrowth: parseDatasetField(dataset.FPGrowth),
        HappyPath: parseDatasetField(dataset.HappyPath),
        UniqueEventDescList: dataset.UniqueEventDescList || parseDatasetField(dataset.UniqueEventDesc)
    };

    setParsedDataset(processed);
    setIsDataLoading(false); 
}, [dataset]);

useEffect(() => {
    if (isDataLoading || !parsedDataset) return;

    const filteredPages = getFilteredPages();

    filteredPages.forEach(page => {
        const hasData = hasRequiredData(page.route);
        console.log(`Page ${page.route} has required data:`, hasData);
    });

    // Move initial category setting here
    if (isFirstRender.current) {
        const currentRoute = location.pathname;
        const activePage = filteredPages.find((page) => page.route === currentRoute);
        if (activePage) {
            setOpenCategory(activePage.category || "Insights");
        }
        isFirstRender.current = false;
    }
}, [isDataLoading, parsedDataset, pages, location.pathname]);

    // const hasRequiredData = (route) => {
    //     // If parsedDataset is not ready yet, return false
    //     if (!parsedDataset) {
    //         console.log(`Parsed dataset not ready yet for ${route}`);
    //         return false;
    //     }
    
    //     // If no data requirements for this route, return true
    //     if (!dataRequirements[route]) {
    //         console.log(`No data requirements for ${route}`);
    //         return true;
    //     }
    
    //     // Get required data types for this route
    //     const requiredData = dataRequirements[route];
    //     console.log(`Required data for ${route}:`, requiredData);
        
    //     // Check each required data type
    //     return requiredData.every(dataType => {
    //         // First try direct access
    //         let data = parsedDataset[dataType];
            
    //         // Special handling for UniqueURL which might be in UniqueUrls
    //         if (dataType === 'UniqueURL' && !data && parsedDataset.UniqueUrls) {
    //             try {
    //                 const parsed = JSON.parse(parsedDataset.UniqueUrls);
    //                 data = parsed.URL;
    //             } catch (e) {
    //                 console.log('Failed to parse UniqueUrls:', e);
    //             }
    //         }
    
    //         // Special handling for UniqueEventDescList
    //         if (dataType === 'UniqueEventDescList' && !data) {
    //             data = parsedDataset.UniqueEventDescList;
    //         }
    
    //         // For fields that might be JSON strings
    //         if (typeof data === 'string' && (dataType === 'MarkovChain' || 
    //             dataType === 'GraphPositions' || 
    //             dataType === 'FPGrowth' || 
    //             dataType === 'FrequentPatterns')) {
    //             try {
    //                 data = JSON.parse(data);
    //             } catch (e) {
    //                 console.log(`Failed to parse ${dataType}:`, e);
    //             }
    //         }
    
    //         const hasData = data !== undefined && data !== null && 
    //                         (typeof data === 'object' ? Object.keys(data).length > 0 : Boolean(data));
            
    //         console.log(`${route} - ${dataType}:`, {
    //             exists: data !== undefined && data !== null,
    //             hasContent: hasData,
    //             type: typeof data
    //         });
    
    //         return hasData;
    //     });
    // };

    const hasRequiredData = (route) => {
        // If parsedDataset is not ready yet, return false
        if (!parsedDataset) {
            console.log(`Parsed dataset not ready yet for ${route}`);
            return false;
        }
    
        // If no data requirements for this route, return true
        if (!dataRequirements[route]) {
            console.log(`No data requirements for ${route}`);
            return true;
        }
    
        // Get required data types for this route
        const requiredData = dataRequirements[route];
        console.log(`Required data for ${route}:`, requiredData);
        
        // Check each required data type
        return requiredData.every(dataType => {
            // First try direct access
            let data = parsedDataset[dataType];
            
            // Special handling for UniqueURL which might be in UniqueUrls
            if (dataType === 'UniqueURL' && !data && parsedDataset.UniqueUrls) {
                try {
                    const parsed = JSON.parse(parsedDataset.UniqueUrls);
                    data = parsed.URL;
                } catch (e) {
                    console.log('Failed to parse UniqueUrls:', e);
                }
            }
    
            // Special handling for UniqueEventDescList
            if (dataType === 'UniqueEventDescList' && !data) {
                data = parsedDataset.UniqueEventDescList;
            }
    
            // For fields that might be JSON strings
            if (typeof data === 'string' && (dataType === 'MarkovChain' || 
                dataType === 'GraphPositions' || 
                dataType === 'FPGrowth' || 
                dataType === 'FrequentPatterns')) {
                try {
                    data = JSON.parse(data);
                } catch (e) {
                    console.log(`Failed to parse ${dataType}:`, e);
                }
            }
    
            // Custom logic for table-like objects with index, columns, and data keys
            let hasContent;
            if (data !== undefined && data !== null && typeof data === 'object') {
                // Check if it's a table-like structure
                if ('index' in data && 'columns' in data && 'data' in data &&
                    Array.isArray(data.index) && Array.isArray(data.columns) && Array.isArray(data.data)) {
                    // Consider it as having content only if at least one array is non-empty
                    hasContent = data.index.length > 0 || data.columns.length > 0 || data.data.length > 0;
                } else {
                    // Fallback: check that there is at least one key with a non-falsy value
                    hasContent = Object.keys(data).length > 0;
                }
            } else {
                hasContent = Boolean(data);
            }
            
            console.log(`${route} - ${dataType}:`, {
                exists: data !== undefined && data !== null,
                hasContent,
                type: typeof data
            });
    
            return hasContent;
        });
    };
    

    // Fetch and validate Organizations data
    const getOrganizations = () => {
        const orgsString = localStorage.getItem('Organizations');
        if (!orgsString) return null;
        
        try {
            const orgs = JSON.parse(orgsString);
            if (!Array.isArray(orgs)) return null;
            return orgs;
        } catch (e) {
            console.error('Error parsing Organizations:', e);
            return null;
        }
    };

    // Check if LastEventReceived is valid (within 31 days)
    const isLastEventValid = (orgId) => {
        if (!orgId) return false;

        const organizations = getOrganizations();
        if (!organizations) return null;

        const org = organizations.find(org => org.OrgID === orgId);
        if (!org || !org.LastEventReceived) return false;

        const [year, month, day] = org.LastEventReceived.split('-').map(Number);
        const lastEvent = new Date(year, month - 1, day);
        
        const thirtyOneDaysAgo = new Date();
        thirtyOneDaysAgo.setDate(thirtyOneDaysAgo.getDate() - 31);
        
        return lastEvent >= thirtyOneDaysAgo;
    };

    // Filter out auth and signup pages
    const filteredPages = pages.filter(
        (page) => page.route !== "/auth" && page.route !== "/signup"
    );

    // Check global data validity and handle redirects
    useEffect(() => {
        if (['/auth', '/signup', '/datapipeline'].includes(location.pathname)) {
            return;
        }

        if (isDataLoading) return;

        const organizations = getOrganizations();
        if (!organizations) return;

        const isValid = isLastEventValid(activeOrgID);
        const hasData = parsedDataset && Object.keys(parsedDataset).length > 0;

        if (isValid === false && !hasData) {
            navigate("/datapipeline");
        }
    }, [activeOrgID, parsedDataset, isDataLoading, navigate, location.pathname]);

    const handleCategoryToggle = (category) => {
        setOpenCategory((prev) => (prev === category ? null : category));
    };

    const handleLogout = () => {
        localStorage.removeItem("authToken");
        sessionStorage.removeItem("sessionId");
        setIsLoggedIn(false);
        navigate("/auth");
    };

    const groupedPages = getFilteredPages().reduce(
        (acc, page) => {
            const requiredRoles = roleRequirements[page.route] || ["enduser"];
            const isAccessible = canAccessFeature(requiredRoles);
            const hasData = hasRequiredData(page.route);

            console.log(
                `Processing Page: ${page.route}`,
                `\nRoles: ${requiredRoles}`,
                `\nAccessible: ${isAccessible}`,
                `\nHas Data: ${hasData}`
            );

            if (!isAccessible || !hasData) return acc;

            if (categoryOrder.includes(page.category)) {
                acc.categories[page.category] = acc.categories[page.category] || [];
                acc.categories[page.category].push(page);
            }
            
            return acc;
        },
        { categories: {}, admin: [] }
    );

    return (
        <div className="sidebar-container">
            <div className="sidebar-logo">
                <img src="/logo-Teraace-250.png" alt="logo" />
            </div>
            {categoryOrder.map((category, index) => (
                <div key={index} className="sidebar-category">
                    <button
                        className="category-toggle"
                        onClick={() => handleCategoryToggle(category)}
                    >
                        {category}
                    </button>
                    <AnimatePresence initial={false}>
                        {openCategory === category && (
                            <motion.div
                                initial={{ height: 0 }}
                                animate={{ height: "auto" }}
                                exit={{ height: 0 }}
                                transition={{ duration: 0.3, ease: "easeInOut" }}
                                className="sidebar-category-content"
                                style={{ overflow: "hidden" }}
                            >
                                {groupedPages.categories[category]?.map((page, idx) => (
                                    <NavLink
                                        key={idx}
                                        to={page.route}
                                        className="sidebar-button"
                                        activeClassName="filter-active"
                                        onClick={() => onNavigate(page.route.replace("/", ""))}
                                    >
                                        {page.name}
                                    </NavLink>
                                ))}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            ))}
            {groupedPages.admin.map((page, idx) => (
                <NavLink
                    key={idx}
                    to={page.route}
                    className="sidebar-button"
                    activeClassName="filter-active"
                    onClick={() => onNavigate(page.route.replace("/", ""))}
                >
                    {page.name}
                </NavLink>
            ))}
            <button className="auth-logout-btn" onClick={handleLogout}>
                Logout
            </button>
        </div>
    );
};

export default Sidebar;




// import React, { useState, useEffect, useRef } from 'react';
// import { NavLink, useNavigate, useLocation } from 'react-router-dom';
// import { motion, AnimatePresence } from 'framer-motion';

// const Sidebar = ({ 
//     pages, 
//     activeOrgID, 
//     handleOrgChange, 
//     onNavigate, 
//     canAccessFeature,
//     dataset 
// }) => {
//     const navigate = useNavigate();
//     const location = useLocation();
//     const isFirstRender = useRef(true);
//     const [isLoggedIn, setIsLoggedIn] = useState(true);
//     const [openCategory, setOpenCategory] = useState("Insights");
//     const [isDataLoading, setIsDataLoading] = useState(true);

//     const categoryOrder = ["Insights", "Exploration", "Validation"];

//     const roleRequirements = {
//         "/onboarding": ["superadmin"],
//     };

//     const dataRequirements = {
//         "/benchmarks": ["UniqueURL", "UniqueEventDescList"],
//         "/conversations": ["UniqueURL", "FrequentPatterns", "MarkovChain"],
//         "/featureinsights": ["FPGrowth"],
//         "/funnel": ["UniqueURL"],
//         "/happypath": ["HappyPath"],
//         "/journeyflow": ["MarkovChain"],
//         "/pathanalyzer": ["GraphPositions"],
//         "/peaks": ["FPGrowth"],
//         "/treeexplorer": ["MarkovChain"]
//     };


//     // Fetch and validate Organizations data
//     const getOrganizations = () => {
//         const orgsString = localStorage.getItem('Organizations');
//         console.log('Raw Organizations data:', orgsString);
        
//         if (!orgsString) {
//             console.log('No Organizations data in localStorage');
//             return null;
//         }
        
//         try {
//             const orgs = JSON.parse(orgsString);
//             if (!Array.isArray(orgs)) {
//                 console.log('Organizations data is not an array');
//                 return null;
//             }
//             console.log('Parsed Organizations:', orgs);
//             return orgs;
//         } catch (e) {
//             console.error('Error parsing Organizations:', e);
//             return null;
//         }
//     };

//     // Check if LastEventReceived is valid (within 31 days)
//     const isLastEventValid = (orgId) => {
//         console.log('Checking LastEventReceived for orgId:', orgId);
        
//         if (!orgId) {
//             console.log('No orgId provided');
//             return false;
//         }

//         const organizations = getOrganizations();
//         if (!organizations) {
//             console.log('No organizations data available');
//             return null;
//         }

//         const org = organizations.find(org => org.OrgID === orgId);
//         console.log('Found organization:', org);

//         if (!org) {
//             console.log('Organization not found');
//             return false;
//         }

//         if (!org.LastEventReceived || org.LastEventReceived === "") {
//             console.log('No LastEventReceived for organization');
//             return false;
//         }

//         // Parse the date - assuming format "YYYY-MM-DD"
//         const [year, month, day] = org.LastEventReceived.split('-').map(Number);
//         const lastEvent = new Date(year, month - 1, day);
        
//         const thirtyOneDaysAgo = new Date();
//         thirtyOneDaysAgo.setDate(thirtyOneDaysAgo.getDate() - 31);
        
//         const isValid = lastEvent >= thirtyOneDaysAgo;
//         console.log(`LastEventReceived: ${org.LastEventReceived}, Is within 31 days: ${isValid}`);
        
//         return isValid;
//     };

//     // Filter out auth and signup pages
//     const filteredPages = pages.filter(
//         (page) => page.route !== "/auth" && page.route !== "/signup"
//     );


//     // Handle initial category setting
//     useEffect(() => {
//         if (isFirstRender.current) {
//             const currentRoute = location.pathname;
//             const activePage = filteredPages.find((page) => page.route === currentRoute);
//             if (activePage) {
//                 setOpenCategory(activePage.category || "Insights");
//             }
//             isFirstRender.current = false;
//         }
//     }, [location.pathname, filteredPages]);

//     // Check global data validity and handle redirects
//     useEffect(() => {
//         console.log('=== Starting data validation check ===');
//         console.log('Current path:', location.pathname);
//         console.log('Active OrgID:', activeOrgID);
//         console.log('Dataset present:', !!dataset);
//         console.log('Is data loading:', isDataLoading);

//         // Don't process if we're on these routes
//         if (['/auth', '/signup', '/datapipeline'].includes(location.pathname)) {
//             console.log('On excluded page, skipping checks');
//             return;
//         }

//         // Wait for initial data load
//         if (isDataLoading) {
//             console.log('Still loading data, waiting...');
//             return;
//         }

//         const organizations = getOrganizations();
//         // If we can't get org data yet, wait
//         if (!organizations) {
//             console.log('Organizations data not available yet, waiting...');
//             return;
//         }

//         const isValid = isLastEventValid(activeOrgID);
//         const hasData = dataset && Object.keys(dataset).length > 0;

//         console.log('LastEventReceived valid:', isValid);
//         console.log('Has dataset:', hasData);

//         // Only redirect if:
//         // 1. We have org data
//         // 2. LastEventReceived is definitely invalid (not null)
//         // 3. We have no dataset
//         if (isValid === false && !hasData) {
//             console.log('Conditions met for redirect to DataPipeline');
//             navigate("/datapipeline");
//         }

//         console.log('=== End data validation check ===');
//     }, [activeOrgID, dataset, isDataLoading, navigate, location.pathname]);

//     // Update data loading state
//     useEffect(() => {
//         if (dataset) {
//             console.log('Dataset received, setting loading to false');
//             setIsDataLoading(false);
//         }
//     }, [dataset]);

//     const handleCategoryToggle = (category) => {
//         setOpenCategory((prev) => (prev === category ? null : category));
//     };

//     const handleLogout = () => {
//         localStorage.removeItem("authToken");
//         sessionStorage.removeItem("sessionId");
//         setIsLoggedIn(false);
//         navigate("/auth");
//     };

//     const groupedPages = filteredPages.reduce(
//         (acc, page) => {
//             const requiredRoles = roleRequirements[page.route] || ["enduser"];
//             const isAccessible = canAccessFeature(requiredRoles);
//             const hasData = hasRequiredData(page.route);

//             console.log(
//                 `Processing Page: ${page.route}, Roles: ${requiredRoles}, ` +
//                 `Accessible: ${isAccessible}, Has Data: ${hasData}`
//             );

//             // Only include pages that have both role access and required data
//             if (!isAccessible || !hasData) return acc;

//             if (page.category === "Admin") {
//                 acc.admin.push(page);
//             } else if (categoryOrder.includes(page.category)) {
//                 acc.categories[page.category] = acc.categories[page.category] || [];
//                 acc.categories[page.category].push(page);
//             }

//             return acc;
//         },
//         { categories: {}, admin: [] }
//     );

//     return (
//         <div className="sidebar-container">
//             <div className="sidebar-logo">
//                 <img src="/logo-Teraace-250.png" alt="logo" />
//             </div>
//             {categoryOrder.map((category, index) => (
//                 <div key={index} className="sidebar-category">
//                     <button
//                         className="category-toggle"
//                         onClick={() => handleCategoryToggle(category)}
//                     >
//                         {category}
//                     </button>
//                     <AnimatePresence initial={false}>
//                         {openCategory === category && (
//                             <motion.div
//                                 initial={{ height: 0 }}
//                                 animate={{ height: "auto" }}
//                                 exit={{ height: 0 }}
//                                 transition={{ duration: 0.3, ease: "easeInOut" }}
//                                 className="sidebar-category-content"
//                                 style={{ overflow: "hidden" }}
//                             >
//                                 {groupedPages.categories[category]?.map((page, idx) => (
//                                     <NavLink
//                                         key={idx}
//                                         to={page.route}
//                                         className="sidebar-button"
//                                         activeClassName="filter-active"
//                                         onClick={() => onNavigate(page.route.replace("/", ""))}
//                                     >
//                                         {page.name}
//                                     </NavLink>
//                                 ))}
//                             </motion.div>
//                         )}
//                     </AnimatePresence>
//                 </div>
//             ))}
//             {groupedPages.admin.map((page, idx) => (
//                 <NavLink
//                     key={idx}
//                     to={page.route}
//                     className="sidebar-button"
//                     activeClassName="filter-active"
//                     onClick={() => onNavigate(page.route.replace("/", ""))}
//                 >
//                     {page.name}
//                 </NavLink>
//             ))}
//             <button className="auth-logout-btn" onClick={handleLogout}>
//                 Logout
//             </button>
//         </div>
//     );
// };

// export default Sidebar;


// // // import React, { useState, useEffect, useRef } from 'react';
// // // import { NavLink, useNavigate, useLocation } from 'react-router-dom';
// // // import { motion, AnimatePresence } from 'framer-motion';

// // // const Sidebar = ({ pages, orgs, activeOrgID, handleOrgChange, onNavigate, canAccessFeature }) => {
// // //     const navigate = useNavigate();
// // //     const location = useLocation();
// // //     const isFirstRender = useRef(true);
// // //     const [isLoggedIn, setIsLoggedIn] = useState(true);
// // //     const [openCategory, setOpenCategory] = useState("Insights");

// // //     const categoryOrder = ["Insights", "Exploration", "Validation"];

// // //     const roleRequirements = {
// // //         "/onboarding": ["superadmin"],
// // //     };

// // //     const filteredPages = pages.filter(
// // //         (page) => page.route !== "/auth" && page.route !== "/signup"
// // //     );

// // //     useEffect(() => {
// // //         if (isFirstRender.current) {
// // //             const currentRoute = location.pathname;
// // //             const activePage = filteredPages.find((page) => page.route === currentRoute);
// // //             if (activePage) {
// // //                 setOpenCategory(activePage.category || "Insights");
// // //             }
// // //             isFirstRender.current = false;
// // //         }
// // //     }, [location.pathname, filteredPages]);

// // //     const handleCategoryToggle = (category) => {
// // //         setOpenCategory((prev) => (prev === category ? null : category));
// // //     };

// // //     const handleLogout = () => {
// // //         localStorage.removeItem("authToken");
// // //         sessionStorage.removeItem("sessionId");
// // //         setIsLoggedIn(false);
// // //         navigate("/auth");
// // //     };

// // //     const groupedPages = filteredPages.reduce(
// // //         (acc, page) => {
// // //             const requiredRoles = roleRequirements[page.route] || ["enduser"];
// // //             const isAccessible = canAccessFeature(requiredRoles);

// // //             console.log(
// // //                 `Processing Page: ${page.route}, Required Roles: ${requiredRoles}, Accessible: ${isAccessible}`
// // //             );

// // //             if (!isAccessible) return acc;

// // //             if (page.category === "Admin") {
// // //                 acc.admin.push(page);
// // //             } else if (categoryOrder.includes(page.category)) {
// // //                 acc.categories[page.category] = acc.categories[page.category] || [];
// // //                 acc.categories[page.category].push(page);
// // //             }

// // //             return acc;
// // //         },
// // //         { categories: {}, admin: [] }
// // //     );

// // //     return (
// // //         <div className="sidebar-container">
// // //             <div className="sidebar-logo">
// // //                 <img src="/logo-Teraace-250.png" alt="logo" />
// // //             </div>
// // //             {categoryOrder.map((category, index) => (
// // //                 <div key={index} className="sidebar-category">
// // //                     <button
// // //                         className="category-toggle"
// // //                         onClick={() => handleCategoryToggle(category)}
// // //                     >
// // //                         {category}
// // //                     </button>
// // //                     <AnimatePresence initial={false}>
// // //                         {openCategory === category && (
// // //                             <motion.div
// // //                                 initial={{ height: 0 }}
// // //                                 animate={{ height: "auto" }}
// // //                                 exit={{ height: 0 }}
// // //                                 transition={{ duration: 0.3, ease: "easeInOut" }}
// // //                                 className="sidebar-category-content"
// // //                                 style={{ overflow: "hidden" }}
// // //                             >
// // //                                 {groupedPages.categories[category]?.map((page, idx) => (
// // //                                     <NavLink
// // //                                         key={idx}
// // //                                         to={page.route}
// // //                                         className="sidebar-button"
// // //                                         activeClassName="filter-active"
// // //                                         onClick={() => onNavigate(page.route.replace("/", ""))}
// // //                                     >
// // //                                         {page.name}
// // //                                     </NavLink>
// // //                                 ))}
// // //                             </motion.div>
// // //                         )}
// // //                     </AnimatePresence>
// // //                 </div>
// // //             ))}
// // //             {groupedPages.admin.map((page, idx) => (
// // //                 <NavLink
// // //                     key={idx}
// // //                     to={page.route}
// // //                     className="sidebar-button"
// // //                     activeClassName="filter-active"
// // //                     onClick={() => onNavigate(page.route.replace("/", ""))}
// // //                 >
// // //                     {page.name}
// // //                 </NavLink>
// // //             ))}
// // //             <button className="auth-logout-btn" onClick={handleLogout}>
// // //                 Logout
// // //             </button>
// // //         </div>
// // //     );
// // // };

// // // export default Sidebar;

