import { createSlice } from '@reduxjs/toolkit';
import undoable, { ActionCreators } from 'redux-undo';

const initialState = {
  currentTrigger: {
    id: '',
    name: '',
    conditions: [],
    steps: [],
    status: 'draft',
    modelType: '',
  },
  selectedEvents: [],
  mlInsights: {},
  validationResults: [],
};

const patternSlice = createSlice({
  name: 'pattern',
  initialState,
  reducers: {
    setCurrentTrigger(state, action) {
      state.currentTrigger = action.payload;
    },

    // -- CONDITIONS --
    addCondition(state, action) {
      // action.payload = { id: string, type: string, parameters: Record<string, any> }
      state.currentTrigger.conditions.push(action.payload);
    },
    updateCondition(state, action) {
      // action.payload = { index: number, updatedCondition: Condition }
      const { index, updatedCondition } = action.payload;
      state.currentTrigger.conditions[index] = updatedCondition;
    },
    removeCondition(state, action) {
      // action.payload = index to remove
      state.currentTrigger.conditions.splice(action.payload, 1);
    },

    // -- STEPS --
    addStep(state, action) {
      // action.payload = { id: string, webhookId: string[], nextStepIds: string[], otherActionParams: {} }
      state.currentTrigger.steps.push(action.payload);
    },
    updateStep(state, action) {
      // action.payload = { index: number, updatedStep: WorkflowStep }
      const { index, updatedStep } = action.payload;
      state.currentTrigger.steps[index] = updatedStep;
    },
    removeStep(state, action) {
      // action.payload = index to remove
      state.currentTrigger.steps.splice(action.payload, 1);
    },
  },
});

export const {
  setCurrentTrigger,
  addCondition,
  updateCondition,
  removeCondition,
  addStep,
  updateStep,
  removeStep,
} = patternSlice.actions;

export const undoAction = ActionCreators.undo;
export const redoAction = ActionCreators.redo;

export default undoable(patternSlice.reducer);



// import { createSlice } from '@reduxjs/toolkit';
// import undoable, { ActionCreators } from 'redux-undo';

// const initialState = {
//   currentTrigger: {}, // renamed from currentPattern
//   selectedEvents: [],
//   mlInsights: {},
//   validationResults: [],
// };

// const patternSlice = createSlice({
//   name: 'pattern',
//   initialState,
//   reducers: {
//     setCurrentTrigger(state, action) {
//       state.currentTrigger = action.payload;
//     },
//     // Additional reducers can be added here as needed
//   },
// });

// export const { setCurrentTrigger } = patternSlice.actions;
// export const undoAction = ActionCreators.undo;
// export const redoAction = ActionCreators.redo;

// export default undoable(patternSlice.reducer);
