import React, { useState, useEffect } from 'react';

const DataPipeline = () => {
  // Synchronized state for both benefit tab and installation method.
  const [activeTab, setActiveTab] = useState('auto-capture');
  const [activeInstallMethod, setActiveInstallMethod] = useState('auto-capture');

  // Organization Data from localStorage
  const [orgID, setOrgID] = useState('');
  const [orgName, setOrgName] = useState('');
  const [orgDomain, setOrgDomain] = useState('');
  const [orgSubscription, setOrgSubscription] = useState('');
  const [lastEventReceived, setLastEventReceived] = useState('');
  const [startPage, setStartPage] = useState('');
  const [endPage, setEndPage] = useState('');
  const [modelDate, setModelDate] = useState(new Date());

  // Computed difference (in days) since last event received.
  const [daysSinceLastEvent, setDaysSinceLastEvent] = useState(0);

  useEffect(() => {
    const activeOrgID = localStorage.getItem('ActiveOrgID');
    const organizations = JSON.parse(localStorage.getItem('Organizations') || '[]');
    const matchedOrg = organizations.find(org => org.OrgID === activeOrgID);

    if (matchedOrg) {
      setOrgName(matchedOrg.OrgName);
      setOrgDomain(matchedOrg.OrgDomain);
      setOrgSubscription(matchedOrg.OrgSubscription);
      setLastEventReceived(matchedOrg.LastEventReceived);
      setStartPage(matchedOrg.StartPage);
      setEndPage(matchedOrg.EndPage);
      setModelDate(new Date(matchedOrg.ModelDate || '2023-02-01'));
      setOrgID(matchedOrg.OrgID);
    }
  }, []);

  // Calculate days since last event was received.
  useEffect(() => {
    if (lastEventReceived) {
      const lastDate = new Date(lastEventReceived);
      const today = new Date();
      const diffInMs = today - lastDate;
      const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
      setDaysSinceLastEvent(diffInDays);
    }
  }, [lastEventReceived]);

  // Handler to sync benefit tab and installation method.
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setActiveInstallMethod(tab);
  };

  // Handler for installation box clicks; also syncs the benefit tab.
  const handleInstallMethodClick = (method) => {
    setActiveInstallMethod(method);
    setActiveTab(method);
  };

  const CodeBlock = ({ children }) => (
    <div className="datapipe-codeblock-wrapper">
      <pre className="datapipe-codeblock-pre">
        <code className="datapipe-codeblock-code">
          {children}
        </code>
      </pre>
    </div>
  );

  const FeatureList = ({ items }) => (
    <ul className="datapipe-featurelist">
      {items.map((item, index) => (
        <li key={index} className="datapipe-featurelist-item">
          <span className="datapipe-featurelist-bullet">•</span>
          {item}
        </li>
      ))}
    </ul>
  );

  // Benefit Content (Tabs)
  const renderTabContent = () => {
    if (activeTab === 'auto-capture') {
      return (
        <div>
          <h2 className="datapipe-tab-heading">Auto-Capture Script</h2>
          <p className="datapipe-tab-subheading">Instant Insights, Zero Configuration</p>
          <p className="datapipe-tab-description">
            Drop in our lightweight JavaScript snippet and start collecting user behavior data immediately.
          </p>

          <div className="datapipe-section">
            <h3 className="datapipe-section-heading">Key Features</h3>
            <FeatureList
              items={[
                'One-line installation',
                'Automatic event capture',
                'Smart sampling to minimize performance impact',
                'Configurable privacy controls',
                'Built-in sensitive data filtering',
                'Under 10kb gzipped',
              ]}
            />
          </div>

          <div className="datapipe-section">
            <h3 className="datapipe-section-heading">Best For</h3>
            <FeatureList
              items={[
                'Quick implementation',
                'Maximum coverage with minimum effort',
                'Teams without extensive engineering resources',
                'Rapid prototyping and testing',
              ]}
            />
          </div>
        </div>
      );
    } else if (activeTab === 'direct-api') {
      return (
        <div>
          <h2 className="datapipe-tab-heading">Direct API</h2>
          <p className="datapipe-tab-subheading">Complete Control Over Your Data</p>
          <p className="datapipe-tab-description">
            Send exactly the events you want, when you want, with our comprehensive REST API.
          </p>

          <div className="datapipe-section">
            <h3 className="datapipe-section-heading">Key Features</h3>
            <FeatureList
              items={[
                'RESTful JSON API',
                'Batch event support',
                'Custom property mapping',
                'Strong type checking',
                'Detailed error reporting',
                'Real-time data validation',
                'SDK support for major languages',
              ]}
            />
          </div>

          <div className="datapipe-section">
            <h3 className="datapipe-section-heading">Sample API Request</h3>
            <CodeBlock>
{`POST https://api.teraace.com/tpd/private

Headers:
  Content-Type: application/json
  Authorization: Bearer YOUR_AUTH_TOKEN_HERE

Body:
{
  "event": "Login",
  "url": "/login",
  "timestamp": "2024-01-17T20:20:51.080Z",
  "userID": "sdklj234jkljkerkljs3",
  "subscriptionTier": "Gold",
  "ARR": "250000"
}`}
            </CodeBlock>
          </div>
        </div>
      );
    } else if (activeTab === 'pipeline') {
      return (
        <div>
          <h2 className="datapipe-tab-heading">Data Pipeline Integration</h2>
          <p className="datapipe-tab-subheading">Use Your Existing Data Infrastructure</p>
          <p className="datapipe-tab-description">
            Leverage your current data pipeline investments with our native integrations.
          </p>

          <div className="datapipe-section">
            <h3 className="datapipe-section-heading">Supported Platforms</h3>
            <FeatureList
              items={[
                'Segment',
                'Rudderstack',
                'Snowplow',
                'mParticle',
                'Custom ETL pipelines',
              ]}
            />
          </div>

          <div className="datapipe-section">
            <h3 className="datapipe-section-heading">Key Features</h3>
            <FeatureList
              items={[
                'Native platform support',
                'Automatic schema mapping',
                'Real-time sync',
                'Historical data import',
                'Transformation support',
              ]}
            />
          </div>
        </div>
      );
    }
  };

  // Installation Instructions Section (clickable boxes)
  const renderInstallationInstructions = () => {
    if (!activeInstallMethod) return null;

    if (activeInstallMethod === 'auto-capture') {
      return (
        <div className="datapipe-install-instructions">
          <h3 className="datapipe-install-heading">Auto-Capture Script Installation</h3>
          <p className="datapipe-install-description">
            Include the following snippet in your HTML to install the auto-capture script:
          </p>
          <CodeBlock>
{`<!-- Standard Use: -->
<script defer src="https://api.teraace.com/happypath.js" data-org-id="${orgID}"></script>

<!-- Including Custom Data: -->
<script>
  window.myCustomData = {
    UserID: 'user123',
    SubscriptionTier: 'premium'
  };
</script>
<script defer src="https://api.teraace.com/happypath.js" data-org-id="${orgID}"></script>`}
          </CodeBlock>
        </div>
      );
    } else if (activeInstallMethod === 'direct-api') {
      return (
        <div className="datapipe-install-instructions">
          <h3 className="datapipe-install-heading">Direct API Installation</h3>
          <p className="datapipe-install-description">
            Use the following details to integrate via our Direct API:
          </p>
          <CodeBlock>
{`API URL: https://api.teraace.com/tpd/private
Method: POST
Headers:
  Content-Type: application/json
  Authorization: Bearer YOUR_AUTH_TOKEN_HERE

Request Body:
{
  "event": "Login",
  "url": "/login",
  "timestamp": "2024-01-17T20:20:51.080Z",
  "userID": "sdklj234jkljkerkljs3",
  "subscriptionTier": "Gold",
  "ARR": "250000"
}`}
          </CodeBlock>
          <p className="datapipe-install-note">
            Minimum Data: Event Name, URL, Timestamp. (Additional fields such as UserID, OrgID, Subscription info, etc. are suggested.)
          </p>
        </div>
      );
    } else if (activeInstallMethod === 'pipeline') {
      return (
        <div className="datapipe-install-instructions">
          <h3 className="datapipe-install-heading">Data Pipeline Integration</h3>
          <p className="datapipe-install-description">
            Configure your data pipeline using our native integrations.
          </p>
          <CodeBlock>
{`API URL: https://api.teraace.com/tpd/pipeline
Method: POST
Headers:
  Content-Type: application/json
  Authorization: Bearer YOUR_AUTH_TOKEN_HERE

Example Request Body:
{
  "event": "Login",
  "url": "/login",
  "timestamp": "2024-01-17T20:20:51.080Z",
  "userID": "sdklj234jkljkerkljs3",
  "subscriptionTier": "Gold",
  "ARR": "250000"
}`}
          </CodeBlock>
        </div>
      );
    }
  };

  return (
    <div className="datapipe-container">
      {/* Conditionally rendered Alert Banner based on last event date */}
      {daysSinceLastEvent > 31 && (
        <div className="datapipe-alertbanner">
          <div className="datapipe-alertbanner-content">
            <h3 className="datapipe-alertbanner-title">
              Data Pipeline Configuration Required
            </h3>
            <p className="datapipe-alertbanner-text">
              We've detected that your organization hasn't received any events in the past {daysSinceLastEvent} days.
              Please set up your data pipeline using one of the methods below.
            </p>
          </div>
        </div>
      )}

      {/* Header */}
      <div className="datapipe-header">
        <h1 className="datapipe-header-title">Flexible Data Collection</h1>
        <p className="datapipe-header-subtitle">That Works Your Way</p>
        <p className="datapipe-header-text">
          Choose the integration method that best fits your needs, or combine multiple approaches for comprehensive coverage.
        </p>
      </div>

      {/* Benefit Tabs */}
      <div className="datapipe-tabs-container">
        <div className="datapipe-tabs-nav">
          {['auto-capture', 'direct-api', 'pipeline'].map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={`datapipe-tabbutton ${
                activeTab === tab ? 'datapipe-tabbutton-active' : ''
              }`}
            >
              {tab === 'auto-capture' && 'Auto-Capture Script'}
              {tab === 'direct-api' && 'Direct API'}
              {tab === 'pipeline' && 'Data Pipeline Integration'}
            </button>
          ))}
        </div>
      </div>

      {/* Benefit Tab Content */}
      <div className="datapipe-tabcontent">
        {renderTabContent()}
      </div>

      {/* Installation Instructions Section */}
      <div className="datapipe-install-section">
        <h2 className="datapipe-install-section-title">Installation Instructions</h2>
        <p className="datapipe-install-section-desc">
          Click on one of the boxes below to reveal the installation details for that method.
        </p>
        <div className="datapipe-install-grid">
          <div
            className={`datapipe-install-box ${
              activeInstallMethod === 'auto-capture' ? 'datapipe-install-box-active' : ''
            }`}
            onClick={() => handleInstallMethodClick('auto-capture')}
          >
            <h3 className="datapipe-install-box-title">Auto-Capture Script</h3>
            <p className="datapipe-install-box-subtitle">
              One-line install with auto event capture.
            </p>
          </div>
          <div
            className={`datapipe-install-box ${
              activeInstallMethod === 'direct-api' ? 'datapipe-install-box-active' : ''
            }`}
            onClick={() => handleInstallMethodClick('direct-api')}
          >
            <h3 className="datapipe-install-box-title">Direct API</h3>
            <p className="datapipe-install-box-subtitle">
              Send events directly via our REST API.
            </p>
          </div>
          <div
            className={`datapipe-install-box ${
              activeInstallMethod === 'pipeline' ? 'datapipe-install-box-active' : ''
            }`}
            onClick={() => handleInstallMethodClick('pipeline')}
          >
            <h3 className="datapipe-install-box-title">Data Pipeline</h3>
            <p className="datapipe-install-box-subtitle">
              Integrate with your existing data systems.
            </p>
          </div>
        </div>
        {renderInstallationInstructions()}
      </div>

      {/* Additional Info Sections */}
      <div className="datapipe-info-sections">
        <div className="datapipe-info-block">
          <h2 className="datapipe-info-title">Data Security &amp; Privacy</h2>
          <div className="datapipe-info-content">
            <div>
              <h3 className="datapipe-info-heading">Built-in Protections</h3>
              <FeatureList
                items={[
                  'Automatic PII detection and filtering',
                  'Data encryption in transit and at rest',
                  'Configurable data retention',
                  'GDPR compliant tools',
                ]}
              />
            </div>
          </div>
        </div>

        <div className="datapipe-info-block">
          <h2 className="datapipe-info-title">Implementation Support</h2>
          <div className="datapipe-info-content">
            <div>
              <h3 className="datapipe-info-heading">Resources for Success</h3>
              <FeatureList
                items={[
                  'Detailed implementation guides',
                  'Sample code repositories',
                  'Interactive debugging tools',
                  'Schema validation tools',
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Help Section */}
      <div className="datapipe-help-section">
        <h2 className="datapipe-help-title">Need Help Choosing?</h2>
        <p className="datapipe-help-text">
          Our integration experts can help you select and implement the right data collection method for your needs.
        </p>
        <button className="datapipe-help-button">
          <a href="mailto:support@teraace.com">Contact Support</a>
        </button>
      </div>

      {/* Admin-like Display of the Install Script (for reference) */}
      <div className="datapipe-admin-section">
        <label className="datapipe-admin-label">
          JavaScript Install Script:
        </label>
        <code className="datapipe-admin-codeblock">
          {`<script defer src="https://api.teraace.com/happypath.js" data-org-id="${orgID}"></script>`}
        </code>
      </div>
    </div>
  );
};

export default DataPipeline;



// import React, { useState, useEffect } from 'react';

// const DataPipeline = () => {
//   const [activeTab, setActiveTab] = useState('auto-capture');
//   const [activeInstallMethod, setActiveInstallMethod] = useState(null);

//   // Organization Data from localStorage (for injecting orgID in install scripts)
//   const [orgID, setOrgID] = useState('');
//   const [orgName, setOrgName] = useState('');
//   const [orgDomain, setOrgDomain] = useState('');
//   const [orgSubscription, setOrgSubscription] = useState('');
//   const [lastEventReceived, setLastEventReceived] = useState('');
//   const [startPage, setStartPage] = useState('');
//   const [endPage, setEndPage] = useState('');
//   const [modelDate, setModelDate] = useState(new Date());
//   const [daysSinceLastEvent, setDaysSinceLastEvent] = useState(0);

//   useEffect(() => {
//     const activeOrgID = localStorage.getItem('ActiveOrgID');
//     const organizations = JSON.parse(localStorage.getItem('Organizations') || '[]');
//     const matchedOrg = organizations.find(org => org.OrgID === activeOrgID);

//     if (matchedOrg) {
//       setOrgName(matchedOrg.OrgName);
//       setOrgDomain(matchedOrg.OrgDomain);
//       setOrgSubscription(matchedOrg.OrgSubscription);
//       setLastEventReceived(matchedOrg.LastEventReceived);
//       setStartPage(matchedOrg.StartPage);
//       setEndPage(matchedOrg.EndPage);
//       setModelDate(new Date(matchedOrg.ModelDate || '2023-02-01'));
//       setOrgID(matchedOrg.OrgID);
//     }
//   }, []);


//   // Calculate days since last event was received.
//   useEffect(() => {
//     if (lastEventReceived) {
//       const lastDate = new Date(lastEventReceived);
//       const today = new Date();
//       const diffInMs = today - lastDate;
//       const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
//       setDaysSinceLastEvent(diffInDays);
//     }
//   }, [lastEventReceived]);
  
//   // Handler to sync benefit tab and installation method.
//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//     setActiveInstallMethod(tab);
//   };

//   // Handler for installation box clicks; also syncs the benefit tab.
//   const handleInstallMethodClick = (method) => {
//     setActiveInstallMethod(method);
//     setActiveTab(method);
//   };

//   const CodeBlock = ({ children }) => (
//     <div className="datapipe-codeblock-wrapper">
//       <pre className="datapipe-codeblock-pre">
//         <code className="datapipe-codeblock-code">
//           {children}
//         </code>
//       </pre>
//     </div>
//   );

//   const FeatureList = ({ items }) => (
//     <ul className="datapipe-featurelist">
//       {items.map((item, index) => (
//         <li key={index} className="datapipe-featurelist-item">
//           <span className="datapipe-featurelist-bullet">•</span>
//           {item}
//         </li>
//       ))}
//     </ul>
//   );

//   // Benefit Content (Tabs)
//   const renderTabContent = () => {
//     if (activeTab === 'auto-capture') {
//       return (
//         <div>
//           <h2 className="datapipe-tab-heading">Auto-Capture Script</h2>
//           <p className="datapipe-tab-subheading">Instant Insights, Zero Configuration</p>
//           <p className="datapipe-tab-description">
//             Drop in our lightweight JavaScript snippet and start collecting user behavior data immediately.
//           </p>

//           <div className="datapipe-section">
//             <h3 className="datapipe-section-heading">Key Features</h3>
//             <FeatureList
//               items={[
//                 'One-line installation',
//                 'Automatic event capture',
//                 'Smart sampling to minimize performance impact',
//                 'Configurable privacy controls',
//                 'Built-in sensitive data filtering',
//                 'Under 10kb gzipped',
//               ]}
//             />
//           </div>

//           <div className="datapipe-section">
//             <h3 className="datapipe-section-heading">Best For</h3>
//             <FeatureList
//               items={[
//                 'Quick implementation',
//                 'Maximum coverage with minimum effort',
//                 'Teams without extensive engineering resources',
//                 'Rapid prototyping and testing',
//               ]}
//             />
//           </div>
//         </div>
//       );
//     } else if (activeTab === 'direct-api') {
//       return (
//         <div>
//           <h2 className="datapipe-tab-heading">Direct API</h2>
//           <p className="datapipe-tab-subheading">Complete Control Over Your Data</p>
//           <p className="datapipe-tab-description">
//             Send exactly the events you want, when you want, with our comprehensive REST API.
//           </p>

//           <div className="datapipe-section">
//             <h3 className="datapipe-section-heading">Key Features</h3>
//             <FeatureList
//               items={[
//                 'RESTful JSON API',
//                 'Batch event support',
//                 'Custom property mapping',
//                 'Strong type checking',
//                 'Detailed error reporting',
//                 'Real-time data validation',
//                 'SDK support for major languages',
//               ]}
//             />
//           </div>

//           <div className="datapipe-section">
//             <h3 className="datapipe-section-heading">Sample API Request</h3>
//             <CodeBlock>
// {`POST https://api.teraace.com/tpd/private

// Headers:
//   Content-Type: application/json
//   Authorization: Bearer YOUR_AUTH_TOKEN_HERE

// Body:
// {
//   "event": "Login",
//   "url": "/login",
//   "timestamp": "2024-01-17T20:20:51.080Z"
// }`}
//             </CodeBlock>
//           </div>
//         </div>
//       );
//     } else if (activeTab === 'pipeline') {
//       return (
//         <div>
//           <h2 className="datapipe-tab-heading">Data Pipeline Integration</h2>
//           <p className="datapipe-tab-subheading">Use Your Existing Data Infrastructure</p>
//           <p className="datapipe-tab-description">
//             Leverage your current data pipeline investments with our native integrations.
//           </p>

//           <div className="datapipe-section">
//             <h3 className="datapipe-section-heading">Supported Platforms</h3>
//             <FeatureList
//               items={[
//                 'Segment',
//                 'Rudderstack',
//                 'Snowplow',
//                 'mParticle',
//                 'Custom ETL pipelines',
//               ]}
//             />
//           </div>

//           <div className="datapipe-section">
//             <h3 className="datapipe-section-heading">Key Features</h3>
//             <FeatureList
//               items={[
//                 'Native platform support',
//                 'Automatic schema mapping',
//                 'Real-time sync',
//                 'Historical data import',
//                 'Transformation support',
//               ]}
//             />
//           </div>
//         </div>
//       );
//     }
//   };

//   // Installation Instructions Section (clickable boxes)
//   const renderInstallationInstructions = () => {
//     if (!activeInstallMethod) return null;

//     if (activeInstallMethod === 'auto-capture') {
//       return (
//         <div className="datapipe-install-instructions">
//           <h3 className="datapipe-install-heading">Auto-Capture Script Installation</h3>
//           <p className="datapipe-install-description">
//             Include the following snippet in your HTML to install the auto-capture script:
//           </p>
//           <CodeBlock>
// {`<!-- Standard Use: -->
// <script defer src="https://api.teraace.com/happypath.js" data-org-id="${orgID}"></script>

// <!-- Including Custom Data: -->
// <script>
//   window.myCustomData = {
//     UserID: 'user123',
//     SubscriptionTier: 'premium'
//   };
// </script>
// <script defer src="https://api.teraace.com/happypath.js" data-org-id="${orgID}"></script>`}
//           </CodeBlock>
//         </div>
//       );
//     } else if (activeInstallMethod === 'direct-api') {
//       return (
//         <div className="datapipe-install-instructions">
//           <h3 className="datapipe-install-heading">Direct API Installation</h3>
//           <p className="datapipe-install-description">
//             Use the following details to integrate via our Direct API:
//           </p>
//           <CodeBlock>
// {`API URL: https://api.teraace.com/tpd/private
// Method: POST
// Headers:
//   Content-Type: application/json
//   Authorization: Bearer YOUR_AUTH_TOKEN_HERE

// Request Body:
// {
//   "event": "Login",
//   "url": "/login",
//   "timestamp": "2024-01-17T20:20:51.080Z",
//   "userID": "sdklj234jkljkerkljs3",
//   "subscriptionTier": "Gold",
//   "ARR": "250000",
// }`}
//           </CodeBlock>
//           <p className="datapipe-install-note">
//             Minimum Data: Event Name, URL, Timestamp. (Additional fields such as UserID, OrgID, Subscription info, etc. are suggested.)
//           </p>
//         </div>
//       );
//     } else if (activeInstallMethod === 'pipeline') {
//       return (
//         <div className="datapipe-install-instructions">
//           <h3 className="datapipe-install-heading">Data Pipeline Integration</h3>
//           <p className="datapipe-install-description">
//             Configure your data pipeline using our native integrations.
//           </p>
//           <CodeBlock>
// {`API URL: https://api.teraace.com/tpd/pipeline
// Method: POST
// Headers:
//   Content-Type: application/json
//   Authorization: Bearer YOUR_AUTH_TOKEN_HERE

// Example Request Body:
// {
//   "event": "Login",
//   "url": "/login",
//   "timestamp": "2024-01-17T20:20:51.080Z",
//   "userID": "sdklj234jkljkerkljs3",
//   "subscriptionTier": "Gold",
//   "ARR": "250000",
// }`}
//           </CodeBlock>
//         </div>
//       );
//     }
//   };

//   return (
//     <div className="datapipe-container">
//       {/* Alert Banner */}
//       <div className="datapipe-alertbanner">
//         <div className="datapipe-alertbanner-content">
//           <h3 className="datapipe-alertbanner-title">
//             Data Pipeline Configuration Required
//           </h3>
//           <p className="datapipe-alertbanner-text">
//             We've detected that your organization hasn't received any events in the past 31 days.
//             Please set up your data pipeline using one of the methods below.
//           </p>
//         </div>
//       </div>

//       {/* Header */}
//       <div className="datapipe-header">
//         <h1 className="datapipe-header-title">Flexible Data Collection</h1>
//         <p className="datapipe-header-subtitle">That Works Your Way</p>
//         <p className="datapipe-header-text">
//           Choose the integration method that best fits your needs, or combine multiple approaches for comprehensive coverage.
//         </p>
//       </div>

//       {/* Benefit Tabs */}
//       <div className="datapipe-tabs-container">
//         <div className="datapipe-tabs-nav">
//           {['auto-capture', 'direct-api', 'pipeline'].map((tab) => (
//             <button
//               key={tab}
//               onClick={() => setActiveTab(tab)}
//               className={`datapipe-tabbutton ${
//                 activeTab === tab ? 'datapipe-tabbutton-active' : ''
//               }`}
//             >
//               {tab === 'auto-capture' && 'Auto-Capture Script'}
//               {tab === 'direct-api' && 'Direct API'}
//               {tab === 'pipeline' && 'Data Pipeline Integration'}
//             </button>
//           ))}
//         </div>
//       </div>

//       {/* Benefit Tab Content */}
//       <div className="datapipe-tabcontent">
//         {renderTabContent()}
//       </div>

//       {/* Installation Instructions Section */}
//       <div className="datapipe-install-section">
//         <h2 className="datapipe-install-section-title">Installation Instructions</h2>
//         <p className="datapipe-install-section-desc">
//           Click on one of the boxes below to reveal the installation details for that method.
//         </p>
//         <div className="datapipe-install-grid">
//           <div
//             className={`datapipe-install-box ${
//               activeInstallMethod === 'auto-capture' ? 'datapipe-install-box-active' : ''
//             }`}
//             onClick={() =>
//               setActiveInstallMethod(activeInstallMethod === 'auto-capture' ? null : 'auto-capture')
//             }
//           >
//             <h3 className="datapipe-install-box-title">Auto-Capture Script</h3>
//             <p className="datapipe-install-box-subtitle">
//               One-line install with auto event capture.
//             </p>
//           </div>
//           <div
//             className={`datapipe-install-box ${
//               activeInstallMethod === 'direct-api' ? 'datapipe-install-box-active' : ''
//             }`}
//             onClick={() =>
//               setActiveInstallMethod(activeInstallMethod === 'direct-api' ? null : 'direct-api')
//             }
//           >
//             <h3 className="datapipe-install-box-title">Direct API</h3>
//             <p className="datapipe-install-box-subtitle">
//               Send events directly via our REST API.
//             </p>
//           </div>
//           <div
//             className={`datapipe-install-box ${
//               activeInstallMethod === 'pipeline' ? 'datapipe-install-box-active' : ''
//             }`}
//             onClick={() =>
//               setActiveInstallMethod(activeInstallMethod === 'pipeline' ? null : 'pipeline')
//             }
//           >
//             <h3 className="datapipe-install-box-title">Data Pipeline</h3>
//             <p className="datapipe-install-box-subtitle">
//               Integrate with your existing data systems.
//             </p>
//           </div>
//         </div>
//         {renderInstallationInstructions()}
//       </div>

//       {/* Additional Info Sections */}
//       <div className="datapipe-info-sections">
//         <div className="datapipe-info-block">
//           <h2 className="datapipe-info-title">Data Security &amp; Privacy</h2>
//           <div className="datapipe-info-content">
//             <div>
//               <h3 className="datapipe-info-heading">Built-in Protections</h3>
//               <FeatureList
//                 items={[
//                   'Automatic PII detection and filtering',
//                   'Data encryption in transit and at rest',
//                   'Configurable data retention',
//                   'GDPR compliance tools',
//                 ]}
//               />
//             </div>
//           </div>
//         </div>

//         <div className="datapipe-info-block">
//           <h2 className="datapipe-info-title">Implementation Support</h2>
//           <div className="datapipe-info-content">
//             <div>
//               <h3 className="datapipe-info-heading">Resources for Success</h3>
//               <FeatureList
//                 items={[
//                   'Detailed implementation guides',
//                   'Sample code repositories',
//                   'Interactive debugging tools',
//                   'Schema validation tools',
//                 ]}
//               />
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Help Section */}
//       <div className="datapipe-help-section">
//         <h2 className="datapipe-help-title">Need Help Choosing?</h2>
//         <p className="datapipe-help-text">
//           Our integration experts can help you select and implement the right data collection method for your needs.
//         </p>
//         <button className="datapipe-help-button">
//           <a href="mailto:support@teraace.com">Contact Support</a>
//         </button>
//       </div>

//       {/* Admin-like Display of the Install Script (for reference) */}
//       <div className="datapipe-admin-section">
//         <label className="datapipe-admin-label">
//           JavaScript Install Script:
//         </label>
//         <code className="datapipe-admin-codeblock">
//           {`<script defer src="https://api.teraace.com/happypath.js" data-org-id="${orgID}"></script>`}
//         </code>
//       </div>
//     </div>
//   );
// };

// export default DataPipeline;



// // import React, { useState, useEffect, useRef } from 'react';

// // const DataPipeline = () => {
// //   // Benefit Content Tabs
// //   const [activeTab, setActiveTab] = useState('auto-capture');
// //   // Installation Instructions (null = none expanded)
// //   const [activeInstallMethod, setActiveInstallMethod] = useState(null);

// //   // Organization Data from localStorage (for injecting orgID in install scripts)
// //   const [orgID, setOrgID] = useState('');
// //   const [orgName, setOrgName] = useState('');
// //   const [orgDomain, setOrgDomain] = useState('');
// //   const [orgSubscription, setOrgSubscription] = useState('');
// //   const [lastEventReceived, setLastEventReceived] = useState('');
// //   const [startPage, setStartPage] = useState('');
// //   const [endPage, setEndPage] = useState('');
// //   const [modelDate, setModelDate] = useState(new Date());

// //   useEffect(() => {
// //     const activeOrgID = localStorage.getItem('ActiveOrgID');
// //     const organizations = JSON.parse(localStorage.getItem('Organizations') || "[]");
// //     const matchedOrg = organizations.find(org => org.OrgID === activeOrgID);

// //     if (matchedOrg) {
// //       setOrgName(matchedOrg.OrgName);
// //       setOrgDomain(matchedOrg.OrgDomain);
// //       setOrgSubscription(matchedOrg.OrgSubscription);
// //       setLastEventReceived(matchedOrg.LastEventReceived);
// //       setStartPage(matchedOrg.StartPage);
// //       setEndPage(matchedOrg.EndPage);
// //       setModelDate(new Date(matchedOrg.ModelDate || '2023-02-01'));
// //       setOrgID(matchedOrg.OrgID);
// //     }
// //   }, []);

// //   const CodeBlock = ({ children }) => (
// //     <div className="relative mt-4 rounded-md bg-gray-900 p-4">
// //       <pre className="text-sm text-gray-50 overflow-x-auto">
// //         <code>{children}</code>
// //       </pre>
// //     </div>
// //   );

// //   const FeatureList = ({ items }) => (
// //     <ul className="space-y-2 mt-4">
// //       {items.map((item, index) => (
// //         <li key={index} className="flex items-start">
// //           <span className="text-blue-500 mr-2">•</span>
// //           {item}
// //         </li>
// //       ))}
// //     </ul>
// //   );

// //   // Benefit Content (Tabs)
// //   const renderTabContent = () => {
// //     if (activeTab === 'auto-capture') {
// //       return (
// //         <div>
// //           <h2 className="text-2xl font-bold mb-2">Auto-Capture Script</h2>
// //           <p className="text-gray-600 mb-4">Instant Insights, Zero Configuration</p>
// //           <p>Drop in our lightweight JavaScript snippet and start collecting user behavior data immediately.</p>
// //           <div className="mt-6">
// //             <h3 className="text-lg font-semibold">Key Features</h3>
// //             <FeatureList items={[
// //               'One-line installation',
// //               'Automatic event capture',
// //               'Smart sampling to minimize performance impact',
// //               'Configurable privacy controls',
// //               'Built-in sensitive data filtering',
// //               'Under 10kb gzipped'
// //             ]} />
// //           </div>
// //           <div className="mt-6">
// //             <h3 className="text-lg font-semibold">Best For</h3>
// //             <FeatureList items={[
// //               'Quick implementation',
// //               'Maximum coverage with minimum effort',
// //               'Teams without extensive engineering resources',
// //               'Rapid prototyping and testing'
// //             ]} />
// //           </div>
// //         </div>
// //       );
// //     } else if (activeTab === 'direct-api') {
// //       return (
// //         <div>
// //           <h2 className="text-2xl font-bold mb-2">Direct API</h2>
// //           <p className="text-gray-600 mb-4">Complete Control Over Your Data</p>
// //           <p>Send exactly the events you want, when you want, with our comprehensive REST API.</p>
// //           <div className="mt-6">
// //             <h3 className="text-lg font-semibold">Key Features</h3>
// //             <FeatureList items={[
// //               'RESTful JSON API',
// //               'Batch event support',
// //               'Custom property mapping',
// //               'Strong type checking',
// //               'Detailed error reporting',
// //               'Real-time data validation',
// //               'SDK support for major languages'
// //             ]} />
// //           </div>
// //           <div className="mt-6">
// //             <h3 className="text-lg font-semibold">Sample API Request</h3>
// //             <CodeBlock>
// // {`POST https://api.teraace.com/tpd/private

// // Headers:
// //   Content-Type: application/json
// //   Authorization: Bearer YOUR_AUTH_TOKEN_HERE

// // Body:
// // {
// //   "event": "Login",
// //   "url": "/login",
// //   "timestamp": "2024-01-17T20:20:51.080Z"
// // }`}
// //             </CodeBlock>
// //           </div>
// //         </div>
// //       );
// //     } else if (activeTab === 'pipeline') {
// //       return (
// //         <div>
// //           <h2 className="text-2xl font-bold mb-2">Data Pipeline Integration</h2>
// //           <p className="text-gray-600 mb-4">Use Your Existing Data Infrastructure</p>
// //           <p>Leverage your current data pipeline investments with our native integrations.</p>
// //           <div className="mt-6">
// //             <h3 className="text-lg font-semibold">Supported Platforms</h3>
// //             <FeatureList items={[
// //               'Segment',
// //               'Rudderstack',
// //               'Snowplow',
// //               'mParticle',
// //               'Custom ETL pipelines'
// //             ]} />
// //           </div>
// //           <div className="mt-6">
// //             <h3 className="text-lg font-semibold">Key Features</h3>
// //             <FeatureList items={[
// //               'Native platform support',
// //               'Automatic schema mapping',
// //               'Real-time sync',
// //               'Historical data import',
// //               'Transformation support'
// //             ]} />
// //           </div>
// //         </div>
// //       );
// //     }
// //   };

// //   // Installation Instructions Section (clickable boxes)
// //   const renderInstallationInstructions = () => {
// //     if (!activeInstallMethod) return null;

// //     if (activeInstallMethod === 'auto-capture') {
// //       return (
// //         <div className="mt-4">
// //           <h3 className="text-lg font-semibold">Auto-Capture Script Installation</h3>
// //           <p className="mt-2">Include the following snippet in your HTML to install the auto-capture script:</p>
// //           <CodeBlock>
// // {`<!-- Standard Use: -->
// // <script defer src="https://api.teraace.com/happypath.js" data-org-id="${orgID}"></script>

// // <!-- Including Custom Data: -->
// // <script>
// //   window.myCustomData = {
// //     UserID: 'user123',
// //     SubscriptionTier: 'premium'
// //   };
// // </script>
// // <script defer src="https://api.teraace.com/happypath.js" data-org-id="${orgID}"></script>`}
// //           </CodeBlock>
// //         </div>
// //       );
// //     } else if (activeInstallMethod === 'direct-api') {
// //       return (
// //         <div className="mt-4">
// //           <h3 className="text-lg font-semibold">Direct API Installation</h3>
// //           <p className="mt-2">Use the following details to integrate via our Direct API:</p>
// //           <CodeBlock>
// // {`API URL: https://api.teraace.com/tpd/private
// // Method: POST
// // Headers:
// //   Content-Type: application/json
// //   Authorization: Bearer YOUR_AUTH_TOKEN_HERE

// // Request Body:
// // {
// //   "event": "Login",
// //   "url": "/login",
// //   "timestamp": "2024-01-17T20:20:51.080Z"
// // }`}
// //           </CodeBlock>
// //           <p className="mt-2 text-sm text-gray-400">Minimum Data: Event Name, URL, Timestamp. (Additional fields such as UserID, OrgID, Subscription info, etc. are suggested.)</p>
// //         </div>
// //       );
// //     } else if (activeInstallMethod === 'pipeline') {
// //       return (
// //         <div className="mt-4">
// //           <h3 className="text-lg font-semibold">Data Pipeline Integration</h3>
// //           <p className="mt-2">Configure your data pipeline using our native integrations.</p>
// //           <CodeBlock>
// // {`Supported Platforms:
// // - Segment
// // - Rudderstack
// // - Snowplow
// // - mParticle
// // - Custom ETL pipelines

// // Additional Configuration:
// // - Automatic schema mapping
// // - Real-time data sync
// // - Historical data import`}
// //           </CodeBlock>
// //         </div>
// //       );
// //     }
// //   };

// //   return (
// //     <div className="datapipe-container mx-auto px-4 py-8">
// //       {/* Alert Banner */}
// //       <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-8">
// //         <div className="flex">
// //           <div className="ml-3">
// //             <h3 className="text-lg font-medium text-blue-800">
// //               Data Pipeline Configuration Required
// //             </h3>
// //             <p className="mt-2 text-blue-700">
// //               We've detected that your organization hasn't received any events in the past 31 days.
// //               Please set up your data pipeline using one of the methods below.
// //             </p>
// //           </div>
// //         </div>
// //       </div>

// //       {/* Header */}
// //       <div className="mb-8">
// //         <h1 className="text-4xl font-bold mb-2">Flexible Data Collection</h1>
// //         <p className="text-xl text-gray-600 italic">That Works Your Way</p>
// //         <p className="text-gray-600 mt-4">
// //           Choose the integration method that best fits your needs, or combine multiple approaches for comprehensive coverage.
// //         </p>
// //       </div>

// //       {/* Benefit Tabs */}
// //       <div className="mb-8">
// //         <div className="border-b border-gray-200">
// //           <nav className="-mb-px flex space-x-8">
// //             {['auto-capture', 'direct-api', 'pipeline'].map((tab) => (
// //               <button
// //                 key={tab}
// //                 onClick={() => setActiveTab(tab)}
// //                 className={`py-4 px-1 border-b-2 font-medium text-sm ${
// //                   activeTab === tab
// //                     ? 'border-blue-500 text-blue-600'
// //                     : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
// //                 }`}
// //               >
// //                 {tab === 'auto-capture' && 'Auto-Capture Script'}
// //                 {tab === 'direct-api' && 'Direct API'}
// //                 {tab === 'pipeline' && 'Data Pipeline Integration'}
// //               </button>
// //             ))}
// //           </nav>
// //         </div>
// //       </div>

// //       {/* Benefit Tab Content */}
// //       <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
// //         {renderTabContent()}
// //       </div>

// //       {/* Installation Instructions Section */}
// //       <div className="mb-8">
// //         <h2 className="text-3xl font-bold mb-4">Installation Instructions</h2>
// //         <p className="mb-4 text-gray-600">Click on one of the boxes below to reveal the installation details for that method.</p>
// //         <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
// //           <div
// //             className={`threebythree p-4 border rounded-lg cursor-pointer hover:bg-gray-50 ${
// //               activeInstallMethod === 'auto-capture' ? 'bg-gray-100' : ''
// //             }`}
// //             onClick={() => setActiveInstallMethod(activeInstallMethod === 'auto-capture' ? null : 'auto-capture')}
// //           >
// //             <h3 className="font-semibold">Auto-Capture Script</h3>
// //             <p className="text-sm text-gray-500">One-line install with auto event capture.</p>
// //           </div>
// //           <div
// //             className={`threebythree p-4 border rounded-lg cursor-pointer hover:bg-gray-50 ${
// //               activeInstallMethod === 'direct-api' ? 'bg-gray-100' : ''
// //             }`}
// //             onClick={() => setActiveInstallMethod(activeInstallMethod === 'direct-api' ? null : 'direct-api')}
// //           >
// //             <h3 className="font-semibold">Direct API</h3>
// //             <p className="text-sm text-gray-500">Send events directly via our REST API.</p>
// //           </div>
// //           <div
// //             className={`threebythree p-4 border rounded-lg cursor-pointer hover:bg-gray-50 ${
// //               activeInstallMethod === 'pipeline' ? 'bg-gray-100' : ''
// //             }`}
// //             onClick={() => setActiveInstallMethod(activeInstallMethod === 'pipeline' ? null : 'pipeline')}
// //           >
// //             <h3 className="font-semibold">Data Pipeline</h3>
// //             <p className="text-sm text-gray-500">Integrate with your existing data systems.</p>
// //           </div>
// //         </div>
// //         {renderInstallationInstructions()}
// //       </div>

// //       {/* Additional Info Sections */}
// //       <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
// //         <div className="bg-white rounded-lg shadow-sm p-6">
// //           <h2 className="text-2xl font-bold mb-4">Data Security & Privacy</h2>
// //           <div className="space-y-6">
// //             <div>
// //               <h3 className="text-lg font-semibold">Built-in Protections</h3>
// //               <FeatureList items={[
// //                 'Automatic PII detection and filtering',
// //                 'Data encryption in transit and at rest',
// //                 'Configurable data retention',
// //                 'GDPR compliance tools'
// //               ]} />
// //             </div>
// //           </div>
// //         </div>

// //         <div className="bg-white rounded-lg shadow-sm p-6">
// //           <h2 className="text-2xl font-bold mb-4">Implementation Support</h2>
// //           <div className="space-y-6">
// //             <div>
// //               <h3 className="text-lg font-semibold">Resources for Success</h3>
// //               <FeatureList items={[
// //                 'Detailed implementation guides',
// //                 'Sample code repositories',
// //                 'Interactive debugging tools',
// //                 'Schema validation tools'
// //               ]} />
// //             </div>
// //           </div>
// //         </div>
// //       </div>

// //       {/* Help Section */}
// //       <div className="mt-8 text-center">
// //         <h2 className="text-2xl font-bold mb-4">Need Help Choosing?</h2>
// //         <p className="text-gray-600">
// //           Our integration experts can help you select and implement the right data collection method for your needs.
// //         </p>
// //         <button className="mt-4 bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600">
// //           <a href="mailto:support@teraace.com">Contact Support</a>
// //         </button>
// //       </div>

// //       {/* Admin-like Display of the Install Script (for reference) */}
// //       <div className="admin-section mt-8">
// //         <label className="jsscript label block font-semibold mb-2">JavaScript Install Script:</label>
// //         <code className="code-block block p-4 bg-gray-800 text-gray-50 rounded">
// //           {`<script defer src="https://api.teraace.com/happypath.js" data-org-id="${orgID}"></script>`}
// //         </code>
// //       </div>
// //     </div>
// //   );
// // };

// // export default DataPipeline;




// // // import React, { useState } from 'react';

// // // const DataPipeline = () => {
// // //     const [activeTab, setActiveTab] = useState('auto-capture');

// // //     const CodeBlock = ({ children }) => (
// // //         <div className="relative mt-4 rounded-md bg-gray-900 p-4">
// // //             <pre className="text-sm text-gray-50 overflow-x-auto">
// // //                 <code>{children}</code>
// // //             </pre>
// // //         </div>
// // //     );

// // //     const FeatureList = ({ items }) => (
// // //         <ul className="space-y-2 mt-4">
// // //             {items.map((item, index) => (
// // //                 <li key={index} className="flex items-start">
// // //                     <span className="text-blue-500 mr-2">•</span>
// // //                     {item}
// // //                 </li>
// // //             ))}
// // //         </ul>
// // //     );

// // //     return (
// // //         <div className="container mx-auto px-4 py-8">
// // //             {/* Alert Banner */}
// // //             <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-8">
// // //                 <div className="flex">
// // //                     <div className="ml-3">
// // //                         <h3 className="text-lg font-medium text-blue-800">
// // //                             Data Pipeline Configuration Required
// // //                         </h3>
// // //                         <p className="mt-2 text-blue-700">
// // //                             We've detected that your organization hasn't received any events in the past 31 days. 
// // //                             Please set up your data pipeline using one of the methods below.
// // //                         </p>
// // //                     </div>
// // //                 </div>
// // //             </div>

// // //             {/* Header */}
// // //             <div className="mb-8">
// // //                 <h1 className="text-4xl font-bold mb-2">Flexible Data Collection</h1>
// // //                 <p className="text-xl text-gray-600 italic">That Works Your Way</p>
// // //                 <p className="text-gray-600 mt-4">
// // //                     Choose the integration method that best fits your needs, or combine multiple 
// // //                     approaches for comprehensive coverage.
// // //                 </p>
// // //             </div>

// // //             {/* Tabs */}
// // //             <div className="mb-8">
// // //                 <div className="border-b border-gray-200">
// // //                     <nav className="-mb-px flex space-x-8">
// // //                         {['auto-capture', 'direct-api', 'pipeline'].map((tab) => (
// // //                             <button
// // //                                 key={tab}
// // //                                 onClick={() => setActiveTab(tab)}
// // //                                 className={`py-4 px-1 border-b-2 font-medium text-sm ${
// // //                                     activeTab === tab
// // //                                         ? 'border-blue-500 text-blue-600'
// // //                                         : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
// // //                                 }`}
// // //                             >
// // //                                 {tab === 'auto-capture' && 'Auto-Capture Script'}
// // //                                 {tab === 'direct-api' && 'Direct API'}
// // //                                 {tab === 'pipeline' && 'Data Pipeline Integration'}
// // //                             </button>
// // //                         ))}
// // //                     </nav>
// // //                 </div>
// // //             </div>

// // //             {/* Tab Content */}
// // //             <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
// // //                 {activeTab === 'auto-capture' && (
// // //                     <div>
// // //                         <h2 className="text-2xl font-bold mb-2">Auto-Capture Script</h2>
// // //                         <p className="text-gray-600 mb-4">Instant Insights, Zero Configuration</p>
// // //                         <p>Drop in our lightweight JavaScript snippet and start collecting user behavior data immediately.</p>
                        
// // //                         <div className="mt-6">
// // //                             <h3 className="text-lg font-semibold">Key Features</h3>
// // //                             <FeatureList items={[
// // //                                 'One-line installation',
// // //                                 'Automatic event capture',
// // //                                 'Smart sampling to minimize performance impact',
// // //                                 'Configurable privacy controls',
// // //                                 'Built-in sensitive data filtering',
// // //                                 'Under 10kb gzipped'
// // //                             ]} />
// // //                         </div>

// // //                         <div className="mt-6">
// // //                             <h3 className="text-lg font-semibold">Best For</h3>
// // //                             <FeatureList items={[
// // //                                 'Quick implementation',
// // //                                 'Maximum coverage with minimum effort',
// // //                                 'Teams without extensive engineering resources',
// // //                                 'Rapid prototyping and testing'
// // //                             ]} />
// // //                         </div>

// // //                         <div className="mt-6">
// // //                             <h3 className="text-lg font-semibold">Sample Implementation</h3>
// // //                             <CodeBlock>
// // // {`<script>
// // //   (function(t,e,r,a,c,e){
// // //     t[a]=t[a]||function(){(t[a].q=t[a].q||[]).push(arguments)};
// // //     var s=e.createElement(r);s.async=1;s.src=c;
// // //     var g=e.getElementsByTagName(r)[0];
// // //     g.parentNode.insertBefore(s,g);
// // //   })(window,document,'script','tra','//cdn.teraace.com/tracker.js');
  
// // //   tra('init', 'YOUR_PROJECT_ID');
// // // </script>`}
// // //                             </CodeBlock>
// // //                         </div>
// // //                     </div>
// // //                 )}

// // //                 {activeTab === 'direct-api' && (
// // //                     <div>
// // //                         <h2 className="text-2xl font-bold mb-2">Direct API</h2>
// // //                         <p className="text-gray-600 mb-4">Complete Control Over Your Data</p>
// // //                         <p>Send exactly the events you want, when you want, with our comprehensive REST API.</p>
                        
// // //                         <div className="mt-6">
// // //                             <h3 className="text-lg font-semibold">Key Features</h3>
// // //                             <FeatureList items={[
// // //                                 'RESTful JSON API',
// // //                                 'Batch event support',
// // //                                 'Custom property mapping',
// // //                                 'Strong type checking',
// // //                                 'Detailed error reporting',
// // //                                 'Real-time data validation',
// // //                                 'SDK support for major languages'
// // //                             ]} />
// // //                         </div>

// // //                         <div className="mt-6">
// // //                             <h3 className="text-lg font-semibold">Sample API Request</h3>
// // //                             <CodeBlock>
// // // {`POST https://api.teraace.com/v1/events

// // // {
// // //   "event_type": "feature_usage",
// // //   "user_id": "usr_123",
// // //   "properties": {
// // //     "feature_name": "export_report",
// // //     "duration_seconds": 45,
// // //     "success": true
// // //   },
// // //   "timestamp": "2025-02-04T10:15:30Z"
// // // }`}
// // //                             </CodeBlock>
// // //                         </div>
// // //                     </div>
// // //                 )}

// // //                 {activeTab === 'pipeline' && (
// // //                     <div>
// // //                         <h2 className="text-2xl font-bold mb-2">Data Pipeline Integration</h2>
// // //                         <p className="text-gray-600 mb-4">Use Your Existing Data Infrastructure</p>
// // //                         <p>Leverage your current data pipeline investments with our native integrations.</p>
                        
// // //                         <div className="mt-6">
// // //                             <h3 className="text-lg font-semibold">Supported Platforms</h3>
// // //                             <FeatureList items={[
// // //                                 'Segment',
// // //                                 'Rudderstack',
// // //                                 'Snowplow',
// // //                                 'mParticle',
// // //                                 'Custom ETL pipelines'
// // //                             ]} />
// // //                         </div>

// // //                         <div className="mt-6">
// // //                             <h3 className="text-lg font-semibold">Key Features</h3>
// // //                             <FeatureList items={[
// // //                                 'Native platform support',
// // //                                 'Automatic schema mapping',
// // //                                 'Real-time sync',
// // //                                 'Historical data import',
// // //                                 'Transformation support'
// // //                             ]} />
// // //                         </div>
// // //                     </div>
// // //                 )}
// // //             </div>

// // //             {/* Additional Info */}
// // //             <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
// // //                 <div className="bg-white rounded-lg shadow-sm p-6">
// // //                     <h2 className="text-2xl font-bold mb-4">Data Security & Privacy</h2>
// // //                     <div className="space-y-6">
// // //                         <div>
// // //                             <h3 className="text-lg font-semibold">Built-in Protections</h3>
// // //                             <FeatureList items={[
// // //                                 'Automatic PII detection and filtering',
// // //                                 'Data encryption in transit and at rest',
// // //                                 'Configurable data retention',
// // //                                 'GDPR compliance tools'
// // //                             ]} />
// // //                         </div>
// // //                     </div>
// // //                 </div>

// // //                 <div className="bg-white rounded-lg shadow-sm p-6">
// // //                     <h2 className="text-2xl font-bold mb-4">Implementation Support</h2>
// // //                     <div className="space-y-6">
// // //                         <div>
// // //                             <h3 className="text-lg font-semibold">Resources for Success</h3>
// // //                             <FeatureList items={[
// // //                                 'Detailed implementation guides',
// // //                                 'Sample code repositories',
// // //                                 'Interactive debugging tools',
// // //                                 'Schema validation tools'
// // //                             ]} />
// // //                         </div>
// // //                     </div>
// // //                 </div>
// // //             </div>

// // //             {/* Help Section */}
// // //             <div className="mt-8 text-center">
// // //                 <h2 className="text-2xl font-bold mb-4">Need Help Choosing?</h2>
// // //                 <p className="text-gray-600">
// // //                     Our integration experts can help you select and implement the right data 
// // //                     collection method for your needs.
// // //                 </p>
// // //                 <button className="mt-4 bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600">
// // //                     Contact Support
// // //                 </button>
// // //             </div>
// // //         </div>
// // //     );
// // // };

// // // export default DataPipeline;