import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const OrganizationGuard = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasCheckedRef = useRef(false);
  const intervalRef = useRef(null);

  useEffect(() => {
    // Skip these routes
    const excludedRoutes = ['/auth', '/signup', '/datapipeline'];
    if (excludedRoutes.includes(location.pathname)) {
      console.log('OrganizationGuard: Skipping check for excluded route');
      return;
    }

    const checkOrg = () => {
      console.log('OrganizationGuard: Checking organization data');
      const orgsString = localStorage.getItem('Organizations');
      const activeOrgId = localStorage.getItem('ActiveOrgID'); // Fixed key name

      console.log('OrganizationGuard: Organizations:', orgsString);
      console.log('OrganizationGuard: ActiveOrg:', activeOrgId);

      try {
        const orgs = JSON.parse(orgsString || 'null');

        // Basic validation
        if (!activeOrgId || !orgs || !Array.isArray(orgs) || orgs.length === 0) {
          console.log('OrganizationGuard: Basic validation failed');
          return false;
        }

        // Find the active organization
        const activeOrg = orgs.find(org => org.OrgID === activeOrgId);
        if (!activeOrg) {
          console.log('OrganizationGuard: Active organization not found in orgs list');
          return false;
        }

        // Check LastEventReceived date
        if (activeOrg.LastEventReceived) {
          const lastEvent = new Date(activeOrg.LastEventReceived);
          const thirtyOneDaysAgo = new Date();
          thirtyOneDaysAgo.setDate(thirtyOneDaysAgo.getDate() - 31);

          if (lastEvent >= thirtyOneDaysAgo) {
            console.log('OrganizationGuard: Organization has recent activity');
            return true;
          }
          console.log('OrganizationGuard: Organization activity is older than 31 days');
        }
        return false;
      } catch (e) {
        console.error('OrganizationGuard: Error parsing Organizations:', e);
        return false;
      }
    };

    const performCheck = () => {
      // If we've already performed a successful check, do nothing
      if (hasCheckedRef.current) return;

      const isValid = checkOrg();

      if (isValid) {
        console.log('OrganizationGuard: Valid organization data found');
        hasCheckedRef.current = true;
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      } else {
        console.log('OrganizationGuard: No valid organization data found');
        // Redirect only once if not already checked and not on /datapipeline
        if (!hasCheckedRef.current && location.pathname !== '/datapipeline') {
          hasCheckedRef.current = true;
          window.alert('No organization data found. Redirecting to data pipeline...');
          navigate('/datapipeline', { replace: true });
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
        }
      }
    };

    // Delay initial check by 4 seconds to allow data to load
    const initialDelayId = setTimeout(() => {
      performCheck();
      // If not yet checked, set up a recurring check every 20 seconds
      if (!hasCheckedRef.current) {
        intervalRef.current = setInterval(performCheck, 20000);
      }
    }, 4000);

    // Cleanup timers on unmount or dependency change
    return () => {
      clearTimeout(initialDelayId);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [navigate, location]);

  return children;
};

export default OrganizationGuard;


// import React, { useEffect, useState } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';

// const OrganizationGuard = ({ children }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [hasChecked, setHasChecked] = useState(false);
  
//   useEffect(() => {
//     // Skip these routes
//     const excludedRoutes = ['/auth', '/signup', '/datapipeline'];
//     if (excludedRoutes.includes(location.pathname)) {
//       console.log('OrganizationGuard: Skipping check for excluded route');
//       return;
//     }

//     const checkOrg = () => {
//       console.log('OrganizationGuard: Checking organization data');
//       const orgsString = localStorage.getItem('Organizations');
//       const activeOrgId = localStorage.getItem('ActiveOrgID'); // Fixed key name
      
//       console.log('OrganizationGuard: Organizations:', orgsString);
//       console.log('OrganizationGuard: ActiveOrg:', activeOrgId);
      
//       try {
//         const orgs = JSON.parse(orgsString || 'null');
        
//         // Basic validation
//         if (!activeOrgId || !orgs || !Array.isArray(orgs) || orgs.length === 0) {
//           console.log('OrganizationGuard: Basic validation failed');
//           return false;
//         }

//         // Find the active organization
//         const activeOrg = orgs.find(org => org.OrgID === activeOrgId);
//         if (!activeOrg) {
//           console.log('OrganizationGuard: Active organization not found in orgs list');
//           return false;
//         }

//         // Check LastEventReceived date
//         if (activeOrg.LastEventReceived) {
//           const lastEvent = new Date(activeOrg.LastEventReceived);
//           const thirtyOneDaysAgo = new Date();
//           thirtyOneDaysAgo.setDate(thirtyOneDaysAgo.getDate() - 31);

//           if (lastEvent >= thirtyOneDaysAgo) {
//             console.log('OrganizationGuard: Organization has recent activity');
//             return true;
//           }
//           console.log('OrganizationGuard: Organization activity is older than 31 days');
//         }

//         return false;
//       } catch (e) {
//         console.error('OrganizationGuard: Error parsing Organizations:', e);
//         return false;
//       }
//     };

//     let initialDelayId;
//     let checkTimeoutId;

//     const performCheck = () => {
//       try {
//         const isValid = checkOrg();
        
//         if (!isValid) {
//           console.log('OrganizationGuard: No valid organization data found');
//           // Only redirect if we've already done our initial check
//           if (!hasChecked && location.pathname !== '/datapipeline') {
//             setHasChecked(true); // Set this only once
//             window.alert('No organization data found. Redirecting to data pipeline...');
//             navigate('/datapipeline', { replace: true });
//           }
//         } else {
//           console.log('OrganizationGuard: Valid organization data found');
//           if (!hasChecked) {
//             setHasChecked(true); // Set this only once
//           }
//         }
//       } catch (error) {
//         console.error('OrganizationGuard: Error in check:', error);
//       }
//     };

//     // Initial delay to allow data to load
//     initialDelayId = setTimeout(() => {
//       performCheck();
//       // Set up recurring check only if we haven't validated yet
//       if (!hasChecked) {
//         checkTimeoutId = setInterval(performCheck, 20000);
//       }
//     }, 4000); // 4 second initial delay

//     return () => {
//       if (initialDelayId) clearTimeout(initialDelayId);
//       if (checkTimeoutId) clearInterval(checkTimeoutId);
//     };
//   }, [navigate, location]); // Removed hasChecked from dependencies

//   return children;
// };

// export default OrganizationGuard;