import React, { useState, useEffect } from 'react';
import HeadersEditor from './HeadersEditor';
import JSONEditor from './JSONEditor';
import TemplateSelector from './TemplateSelector';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CustomModal } from './CustomModal';

// Default JSON payload for a FormattedEvent using token placeholders.
export const defaultRequestTemplate = JSON.stringify(
  {
    Timestamp: "{{Timestamp}}",
    EventType: "{{EventType}}",
    EventDesc: "{{EventDesc}}",
    URL: "{{URL}}",
    BaseURL: "{{BaseURL}}",
    QueryString: "{{QueryString}}",
    HPorgID: "{{HPorgID}}",
    SessionId: "{{SessionId}}",
    SessionStatus: "{{SessionStatus}}",
    CustomData: "{{CustomData}}",
    Referrer: "{{Referrer}}",
    PageDuration: "{{PageDuration}}",
    DurationF: "{{DurationF}}",
    DurationCalculated: "{{DurationCalculated}}",
    PageCalculated: "{{PageCalculated}}",
    HappyID: "{{HappyID}}",
    GCLID: "{{GCLID}}",
    OrgID: "{{OrgID}}",
    ParsedTime: "{{ParsedTime}}",
    ProcessingTime: "{{ProcessingTime}}",
    TagName: "{{TagName}}",
    Class: "{{Class}}",
    ID: "{{ID}}",
    Href: "{{Href}}",
    DataToggle: "{{DataToggle}}",
    Role: "{{Role}}",
    AriaControls: "{{AriaControls}}",
    Type: "{{Type}}",
    TextValue: "{{TextValue}}",
    HPTarget: "{{HPTarget}}"
  },
  null,
  2
);

const initialState = {
  id: '',
  name: '',
  description: '',
  url: '',
  method: 'POST',
  headers: [],
  authentication: {
    type: 'none',
    credentials: {},
  },
  request_template: defaultRequestTemplate,
  requestBody: {
    bodyContent: defaultRequestTemplate,
    contentType: 'application/json',
  },
  status: 'active',
};

const WebhookAuth = ({ webhook, templates, onSubmit, onTest }) => {
  const [localWebhook, setLocalWebhook] = useState(initialState);
  // Ephemeral state for the JSON editor modal content.
  const [editorContent, setEditorContent] = useState('');
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [showDefaultsModal, setShowDefaultsModal] = useState(false);

  useEffect(() => {
    if (webhook) {
      const normalizedWebhook = {
        id: webhook.id || webhook.ID,
        name: webhook.name || webhook.Name || '',
        description: webhook.description || webhook.Description || '',
        url: webhook.url || webhook.URL || '',
        method: webhook.method || webhook.Method || 'POST',
        headers: webhook.headers || [],
        authentication: {
          ...initialState.authentication,
          ...(webhook.authentication || {}),
        },
        request_template:
          (webhook.request_template && webhook.request_template.trim()) ||
          defaultRequestTemplate,
        status: webhook.status || webhook.Status || 'active',
      };

      normalizedWebhook.requestBody = {
        bodyContent: normalizedWebhook.request_template,
        contentType: 'application/json',
      };

      setLocalWebhook(normalizedWebhook);
    }
  }, [webhook]);

  const handleChange = (field, value) => {
    setLocalWebhook(prev => ({ ...prev, [field]: value }));
  };

  const handleAuthChange = (auth) => {
    setLocalWebhook(prev => ({ ...prev, authentication: auth }));
  };

  const handleHeadersChange = (headers) => {
    setLocalWebhook(prev => ({ ...prev, headers }));
  };

  const safeParseJSON = (str, fallback) => {
    try {
      return JSON.parse(str);
    } catch (err) {
      console.error("Error parsing JSON:", err, "Input:", str);
      return fallback;
    }
  };

  const isProbablyJSON = (str) => {
    const trimmed = str.trim();
    return trimmed.startsWith("{") || trimmed.startsWith("[");
  };

  const handleTemplateSelect = (template) => {
    let templateContent =
      template.request_template || template.RequestTemplate || defaultRequestTemplate;

    if (isProbablyJSON(templateContent)) {
      try {
        JSON.parse(templateContent);
      } catch (e) {
        const fixedContent = templateContent.replace(/\n/g, "\\n");
        if (safeParseJSON(fixedContent, null)) {
          templateContent = fixedContent;
        }
      }
    }

    let rawHeaders = template.headers || template.Headers;
    let parsedHeaders;
    if (rawHeaders && typeof rawHeaders === "string") {
      parsedHeaders = safeParseJSON(rawHeaders, null);
      if (parsedHeaders === null) {
        const fixedHeaders = rawHeaders.replace(/\n/g, "\\n");
        parsedHeaders = safeParseJSON(fixedHeaders, []);
      }
    } else {
      parsedHeaders = rawHeaders || [];
    }
    if (Array.isArray(parsedHeaders)) {
      parsedHeaders = parsedHeaders.reduce((acc, header) => {
        if (header.key) {
          acc[header.key] = header.value;
        }
        return acc;
      }, {});
    }

    let rawAuth = template.auth || template.Auth;
    let parsedAuth;
    if (rawAuth && typeof rawAuth === "string") {
      parsedAuth = safeParseJSON(rawAuth, null);
      if (parsedAuth === null) {
        const fixedAuth = rawAuth.replace(/\n/g, "\\n");
        parsedAuth = safeParseJSON(fixedAuth, { type: "none", credentials: {} });
      }
    } else {
      parsedAuth = rawAuth || { type: "none", credentials: {} };
    }
    if (parsedAuth && parsedAuth.type) {
      parsedAuth.type = parsedAuth.type.toLowerCase();
    }

    let contentType = "application/json";
    if (parsedHeaders && parsedHeaders["Content-Type"]) {
      contentType = parsedHeaders["Content-Type"];
    }

    const newWebhookState = {
      id: template.id || template.ID || '',
      name: template.name || template.Name || '',
      description: template.description || template.Description || '',
      url: template.url || template.URL || '',
      method: template.method || template.Method || 'POST',
      headers: parsedHeaders,
      authentication: parsedAuth,
      request_template: templateContent,
      requestBody: {
        bodyContent: templateContent,
        contentType: contentType,
      },
      status: template.status || template.Status || 'active',
    };

    setLocalWebhook(newWebhookState);
    // Removed non-critical toast for template application.
  };

  // Update the local webhook only on save.
  const handleSaveEditorContent = () => {
    if (handleValidateJSON(editorContent)) {
      setLocalWebhook(prev => ({
        ...prev,
        request_template: editorContent,
        requestBody: { ...prev.requestBody, bodyContent: editorContent }
      }));
      closeEditor();
      toast.success("Request body updated!");
    } else {
      alert("Please fix JSON validation errors before saving");
    }
  };

  // Open editor modal and initialize the editor content state.
  const openEditor = () => {
    setEditorContent(localWebhook.requestBody.bodyContent);
    setIsEditorOpen(true);
    // Removed non-critical toast notification here.
  };

  const closeEditor = () => {
    setIsEditorOpen(false);
  };

  // --- Functions for Default Values ---
  const handleSeeDefaultValues = () => {
    setShowDefaultsModal(true);
  };

  const handleAddAllDefaults = () => {
    try {
      const currentData = isProbablyJSON(editorContent) ? JSON.parse(editorContent) : {};
      const defaultData = JSON.parse(defaultRequestTemplate);
      Object.keys(defaultData).forEach((key) => {
        if (!(key in currentData)) {
          currentData[key] = defaultData[key];
        }
      });
      const updatedJson = JSON.stringify(currentData, null, 2);
      setEditorContent(updatedJson);
    } catch (error) {
      alert("Error parsing JSON. Please check your JSON.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const content = localWebhook.request_template || defaultRequestTemplate;
    try {
      JSON.parse(content);
    } catch (err) {
      alert('Request Body contains invalid JSON');
      return;
    }
    const webhookToSubmit = {
      ...localWebhook,
      request_template: content,
    };
    onSubmit(webhookToSubmit);
  };

  const handleTestClick = () => {
    const content = localWebhook.request_template || defaultRequestTemplate;
    const webhookToTest = {
      ...localWebhook,
      request_template: content,
    };
    onTest(webhookToTest);
    // Removed non-critical toast notification here.
  };

  const handleValidateJSON = (value) => {
    try {
      JSON.parse(value);
      return true;
    } catch {
      return false;
    }
  };

  return (
    <div className="webhook-auth-container">
      <TemplateSelector templates={templates} onSelect={handleTemplateSelect} />
      <form onSubmit={handleSubmit}>
        {/* Name */}
        <div className="form-group">
          <label className="form-label">Name</label>
          <input
            type="text"
            value={localWebhook.name}
            onChange={(e) => handleChange('name', e.target.value)}
            className="form-input"
            required
          />
        </div>

        {/* Description */}
        <div className="form-group">
          <label className="form-label">Description</label>
          <input
            type="text"
            value={localWebhook.description}
            onChange={(e) => handleChange('description', e.target.value)}
            className="form-input"
          />
        </div>

        {/* URL */}
        <div className="form-group">
          <label className="form-label">URL</label>
          <input
            type="url"
            value={localWebhook.url}
            onChange={(e) => handleChange('url', e.target.value)}
            className="form-input"
            required
          />
        </div>

        {/* Method */}
        <div className="form-group">
          <label className="form-label">Method</label>
          <select
            value={localWebhook.method}
            onChange={(e) => handleChange('method', e.target.value)}
            className="form-input"
          >
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
            <option value="DELETE">DELETE</option>
          </select>
        </div>

        {/* Authentication */}
        <div className="auth-section">
          <label className="form-label">Authentication Type</label>
          <select
            value={localWebhook.authentication.type}
            onChange={(e) =>
              handleAuthChange({
                ...localWebhook.authentication,
                type: e.target.value,
                credentials: {},
              })
            }
            className="form-input"
          >
            <option value="none">None</option>
            <option value="basic">Basic</option>
            <option value="bearer">Bearer</option>
            <option value="apiKey">API Key</option>
          </select>
          {localWebhook.authentication.type === 'basic' && (
            <div className="auth-basic">
              <label className="form-label">Username</label>
              <input
                type="text"
                value={localWebhook.authentication.credentials.username || ''}
                onChange={(e) =>
                  handleAuthChange({
                    ...localWebhook.authentication,
                    credentials: {
                      ...localWebhook.authentication.credentials,
                      username: e.target.value,
                    },
                  })
                }
                className="form-input"
              />
              <label className="form-label">Password</label>
              <input
                type="password"
                value={localWebhook.authentication.credentials.password || ''}
                onChange={(e) =>
                  handleAuthChange({
                    ...localWebhook.authentication,
                    credentials: {
                      ...localWebhook.authentication.credentials,
                      password: e.target.value,
                    },
                  })
                }
                className="form-input"
              />
            </div>
          )}
          {localWebhook.authentication.type === 'bearer' && (
            <div className="auth-bearer">
              <label className="form-label">Token</label>
              <input
                type="text"
                value={localWebhook.authentication.credentials.token || ''}
                onChange={(e) =>
                  handleAuthChange({
                    ...localWebhook.authentication,
                    credentials: {
                      ...localWebhook.authentication.credentials,
                      token: e.target.value,
                    },
                  })
                }
                className="form-input"
              />
            </div>
          )}
          {localWebhook.authentication.type === 'apiKey' && (
            <div className="auth-apikey">
              <label className="form-label">API Key</label>
              <input
                type="text"
                value={localWebhook.authentication.credentials.key || ''}
                onChange={(e) =>
                  handleAuthChange({
                    ...localWebhook.authentication,
                    credentials: {
                      ...localWebhook.authentication.credentials,
                      key: e.target.value,
                    },
                  })
                }
                className="form-input"
              />
            </div>
          )}
        </div>

        {/* Headers */}
        <div className="form-group">
          <label className="form-label">Headers</label>
          <HeadersEditor 
            key={JSON.stringify(localWebhook.headers)}
            headers={localWebhook.headers} 
            onChange={handleHeadersChange} 
          />        
        </div>

        {/* Request Body */}
        <div className="form-group">
          <label className="form-label">Request Body (JSON)</label>
          <button type="button" onClick={openEditor} className="btn-edit-request">
            Edit Request Body
          </button>
        </div>

        {isEditorOpen && (
          <div className="modal-overlay">
            <div className="modal-container">
              <div className="modal-header">
                <h2 className="modal-title">Edit Request Body</h2>
                <button onClick={closeEditor} className="modal-close-button">
                  ✕
                </button>
              </div>
              <div className="modal-actions-top">
                <button
                  type="button"
                  onClick={handleSeeDefaultValues}
                  className="btn-see-defaults"
                >
                  See Default Values
                </button>
                <button
                  type="button"
                  onClick={handleAddAllDefaults}
                  className="btn-add-defaults"
                >
                  Add All Default Values
                </button>
              </div>
              <div className="modal-body">
                <div className="json-editor-wrapper">
                  <JSONEditor
                    value={editorContent}
                    onChange={(newContent) => setEditorContent(newContent)}
                    variables={[]}
                    onValidate={handleValidateJSON}
                  />
                </div>
              </div>
              <div className="modal-actions">
                <button onClick={closeEditor} className="btn-close-modal">
                  Cancel
                </button>
                <button onClick={handleSaveEditorContent} className="btn-save-modal">
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Test and Save buttons */}
        <div className="button-group">
          <button type="button" onClick={handleTestClick} className="btn-test">
            Test
          </button>
          <button type="submit" className="btn-save">
            Save
          </button>
        </div>
      </form>
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
      />

      <CustomModal isOpen={showDefaultsModal} onClose={() => setShowDefaultsModal(false)}>
        <div className="default-values-modal">
          <div className="flex justify-between w-full items-center">
            <h3>Default Values</h3>
          </div>
          <p className="default-values-description">
            Below are the default key/value pairs. Copy them as needed.
          </p>
          <div className="default-values-table-container">
            <table className="w-full">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(JSON.parse(defaultRequestTemplate)).map(
                  ([key, value]) => (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>{value}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default WebhookAuth;


// import React, { useState, useEffect } from 'react';
// import HeadersEditor from './HeadersEditor';
// import JSONEditor from './JSONEditor';
// import TemplateSelector from './TemplateSelector';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { CustomModal } from './CustomModal';

// // Default JSON payload for a FormattedEvent using token placeholders.
// export const defaultRequestTemplate = JSON.stringify(
//   {
//     Timestamp: "{{Timestamp}}",
//     EventType: "{{EventType}}",
//     EventDesc: "{{EventDesc}}",
//     URL: "{{URL}}",
//     BaseURL: "{{BaseURL}}",
//     QueryString: "{{QueryString}}",
//     HPorgID: "{{HPorgID}}",
//     SessionId: "{{SessionId}}",
//     SessionStatus: "{{SessionStatus}}",
//     CustomData: "{{CustomData}}",
//     Referrer: "{{Referrer}}",
//     PageDuration: "{{PageDuration}}",
//     DurationF: "{{DurationF}}",
//     DurationCalculated: "{{DurationCalculated}}",
//     PageCalculated: "{{PageCalculated}}",
//     HappyID: "{{HappyID}}",
//     GCLID: "{{GCLID}}",
//     OrgID: "{{OrgID}}",
//     ParsedTime: "{{ParsedTime}}",
//     ProcessingTime: "{{ProcessingTime}}",
//     TagName: "{{TagName}}",
//     Class: "{{Class}}",
//     ID: "{{ID}}",
//     Href: "{{Href}}",
//     DataToggle: "{{DataToggle}}",
//     Role: "{{Role}}",
//     AriaControls: "{{AriaControls}}",
//     Type: "{{Type}}",
//     TextValue: "{{TextValue}}",
//     HPTarget: "{{HPTarget}}"
//   },
//   null,
//   2
// );

// // Adjust initial state so headers is an array
// const initialState = {
//   id: '',
//   name: '',
//   description: '',
//   url: '',
//   method: 'POST',
//   headers: [],
//   authentication: {
//     type: 'none',
//     credentials: {},
//   },
//   request_template: defaultRequestTemplate,
//   requestBody: {
//     bodyContent: defaultRequestTemplate,
//     contentType: 'application/json',
//   },
//   status: 'active',
// };

// const WebhookAuth = ({ webhook, templates, onSubmit, onTest }) => {
//   const [localWebhook, setLocalWebhook] = useState(initialState);
//   // Ephemeral state for the JSON editor modal content.
//   const [editorContent, setEditorContent] = useState('');
//   const [isEditorOpen, setIsEditorOpen] = useState(false);
//   const [showDefaultsModal, setShowDefaultsModal] = useState(false);

//   useEffect(() => {
//     if (webhook) {
//       const normalizedWebhook = {
//         id: webhook.id || webhook.ID,
//         name: webhook.name || webhook.Name || '',
//         description: webhook.description || webhook.Description || '',
//         url: webhook.url || webhook.URL || '',
//         method: webhook.method || webhook.Method || 'POST',
//         headers: webhook.headers || [],
//         authentication: {
//           ...initialState.authentication,
//           ...(webhook.authentication || {}),
//         },
//         request_template:
//           (webhook.request_template && webhook.request_template.trim()) ||
//           defaultRequestTemplate,
//         status: webhook.status || webhook.Status || 'active',
//       };

//       normalizedWebhook.requestBody = {
//         bodyContent: normalizedWebhook.request_template,
//         contentType: 'application/json',
//       };

//       setLocalWebhook(normalizedWebhook);
//     }
//   }, [webhook]);

//   const handleChange = (field, value) => {
//     setLocalWebhook(prev => ({ ...prev, [field]: value }));
//   };

//   const handleAuthChange = (auth) => {
//     setLocalWebhook(prev => ({ ...prev, authentication: auth }));
//   };

//   const handleHeadersChange = (headers) => {
//     setLocalWebhook(prev => ({ ...prev, headers }));
//   };

//   const safeParseJSON = (str, fallback) => {
//     try {
//       return JSON.parse(str);
//     } catch (err) {
//       console.error("Error parsing JSON:", err, "Input:", str);
//       return fallback;
//     }
//   };

//   const isProbablyJSON = (str) => {
//     const trimmed = str.trim();
//     return trimmed.startsWith("{") || trimmed.startsWith("[");
//   };

//   const handleTemplateSelect = (template) => {
//     console.log("Template selected:", template);

//     let templateContent =
//       template.request_template || template.RequestTemplate || defaultRequestTemplate;

//     if (isProbablyJSON(templateContent)) {
//       try {
//         JSON.parse(templateContent);
//       } catch (e) {
//         const fixedContent = templateContent.replace(/\n/g, "\\n");
//         if (safeParseJSON(fixedContent, null)) {
//           templateContent = fixedContent;
//         }
//       }
//     }

//     let rawHeaders = template.headers || template.Headers;
//     let parsedHeaders;
//     if (rawHeaders && typeof rawHeaders === "string") {
//       parsedHeaders = safeParseJSON(rawHeaders, null);
//       if (parsedHeaders === null) {
//         const fixedHeaders = rawHeaders.replace(/\n/g, "\\n");
//         parsedHeaders = safeParseJSON(fixedHeaders, []);
//       }
//     } else {
//       parsedHeaders = rawHeaders || [];
//     }
//     if (Array.isArray(parsedHeaders)) {
//       parsedHeaders = parsedHeaders.reduce((acc, header) => {
//         if (header.key) {
//           acc[header.key] = header.value;
//         }
//         return acc;
//       }, {});
//     }

//     let rawAuth = template.auth || template.Auth;
//     let parsedAuth;
//     if (rawAuth && typeof rawAuth === "string") {
//       parsedAuth = safeParseJSON(rawAuth, null);
//       if (parsedAuth === null) {
//         const fixedAuth = rawAuth.replace(/\n/g, "\\n");
//         parsedAuth = safeParseJSON(fixedAuth, { type: "none", credentials: {} });
//       }
//     } else {
//       parsedAuth = rawAuth || { type: "none", credentials: {} };
//     }
//     if (parsedAuth && parsedAuth.type) {
//       parsedAuth.type = parsedAuth.type.toLowerCase();
//     }

//     let contentType = "application/json";
//     if (parsedHeaders && parsedHeaders["Content-Type"]) {
//       contentType = parsedHeaders["Content-Type"];
//     }

//     const newWebhookState = {
//       id: template.id || template.ID || '',
//       name: template.name || template.Name || '',
//       description: template.description || template.Description || '',
//       url: template.url || template.URL || '',
//       method: template.method || template.Method || 'POST',
//       headers: parsedHeaders,
//       authentication: parsedAuth,
//       request_template: templateContent,
//       requestBody: {
//         bodyContent: templateContent,
//         contentType: contentType,
//       },
//       status: template.status || template.Status || 'active',
//     };

//     console.log("New local webhook state:", newWebhookState);
//     setLocalWebhook(newWebhookState);
//     toast.info("Template applied.");
//   };

//   // Update the local webhook only on save.
//   const handleSaveEditorContent = () => {
//     if (handleValidateJSON(editorContent)) {
//       setLocalWebhook(prev => ({
//         ...prev,
//         request_template: editorContent,
//         requestBody: { ...prev.requestBody, bodyContent: editorContent }
//       }));
//       closeEditor();
//       toast.success("Request body updated!");
//     } else {
//       alert("Please fix JSON validation errors before saving");
//     }
//   };

//   // Open editor modal and initialize the editor content state.
//   const openEditor = () => {
//     setEditorContent(localWebhook.requestBody.bodyContent);
//     setIsEditorOpen(true);
//     toast.info("Editing webhook...");
//   };

//   const closeEditor = () => {
//     setIsEditorOpen(false);
//   };

//   // --- New Functions for Default Values ---
//   const handleSeeDefaultValues = () => {
//     setShowDefaultsModal(true);
//   };

//   const handleAddAllDefaults = () => {
//     try {
//       const currentData = isProbablyJSON(editorContent) ? JSON.parse(editorContent) : {};
//       const defaultData = JSON.parse(defaultRequestTemplate);
//       let keysAdded = false;
//       Object.keys(defaultData).forEach((key) => {
//         if (!(key in currentData)) {
//           currentData[key] = defaultData[key];
//           keysAdded = true;
//         }
//       });
//       const updatedJson = JSON.stringify(currentData, null, 2);
//       setEditorContent(updatedJson);
//     } catch (error) {
//       alert("Error parsing JSON. Please check your JSON.");
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const content = localWebhook.request_template || defaultRequestTemplate;
//     try {
//       JSON.parse(content);
//     } catch (err) {
//       alert('Request Body contains invalid JSON');
//       return;
//     }
//     const webhookToSubmit = {
//       ...localWebhook,
//       request_template: content,
//     };
//     onSubmit(webhookToSubmit);
//   };

//   const handleTestClick = () => {
//     const content = localWebhook.request_template || defaultRequestTemplate;
//     const webhookToTest = {
//       ...localWebhook,
//       request_template: content,
//     };
//     onTest(webhookToTest);
//     toast.info("Webhook Test");
//   };

//   const handleValidateJSON = (value) => {
//     try {
//       JSON.parse(value);
//       return true;
//     } catch {
//       return false;
//     }
//   };

//   return (
//     <div className="webhook-auth-container">
//       <TemplateSelector templates={templates} onSelect={handleTemplateSelect} />
//       <form onSubmit={handleSubmit}>
//         {/* Name */}
//         <div className="form-group">
//           <label className="form-label">Name</label>
//           <input
//             type="text"
//             value={localWebhook.name}
//             onChange={(e) => handleChange('name', e.target.value)}
//             className="form-input"
//             required
//           />
//         </div>

//         {/* Description */}
//         <div className="form-group">
//           <label className="form-label">Description</label>
//           <input
//             type="text"
//             value={localWebhook.description}
//             onChange={(e) => handleChange('description', e.target.value)}
//             className="form-input"
//           />
//         </div>

//         {/* URL */}
//         <div className="form-group">
//           <label className="form-label">URL</label>
//           <input
//             type="url"
//             value={localWebhook.url}
//             onChange={(e) => handleChange('url', e.target.value)}
//             className="form-input"
//             required
//           />
//         </div>

//         {/* Method */}
//         <div className="form-group">
//           <label className="form-label">Method</label>
//           <select
//             value={localWebhook.method}
//             onChange={(e) => handleChange('method', e.target.value)}
//             className="form-input"
//           >
//             <option value="GET">GET</option>
//             <option value="POST">POST</option>
//             <option value="PUT">PUT</option>
//             <option value="DELETE">DELETE</option>
//           </select>
//         </div>

//         {/* Authentication */}
//         <div className="auth-section">
//           <label className="form-label">Authentication Type</label>
//           <select
//             value={localWebhook.authentication.type}
//             onChange={(e) =>
//               handleAuthChange({
//                 ...localWebhook.authentication,
//                 type: e.target.value,
//                 credentials: {},
//               })
//             }
//             className="form-input"
//           >
//             <option value="none">None</option>
//             <option value="basic">Basic</option>
//             <option value="bearer">Bearer</option>
//             <option value="apiKey">API Key</option>
//           </select>
//           {localWebhook.authentication.type === 'basic' && (
//             <div className="auth-basic">
//               <label className="form-label">Username</label>
//               <input
//                 type="text"
//                 value={localWebhook.authentication.credentials.username || ''}
//                 onChange={(e) =>
//                   handleAuthChange({
//                     ...localWebhook.authentication,
//                     credentials: {
//                       ...localWebhook.authentication.credentials,
//                       username: e.target.value,
//                     },
//                   })
//                 }
//                 className="form-input"
//               />
//               <label className="form-label">Password</label>
//               <input
//                 type="password"
//                 value={localWebhook.authentication.credentials.password || ''}
//                 onChange={(e) =>
//                   handleAuthChange({
//                     ...localWebhook.authentication,
//                     credentials: {
//                       ...localWebhook.authentication.credentials,
//                       password: e.target.value,
//                     },
//                   })
//                 }
//                 className="form-input"
//               />
//             </div>
//           )}
//           {localWebhook.authentication.type === 'bearer' && (
//             <div className="auth-bearer">
//               <label className="form-label">Token</label>
//               <input
//                 type="text"
//                 value={localWebhook.authentication.credentials.token || ''}
//                 onChange={(e) =>
//                   handleAuthChange({
//                     ...localWebhook.authentication,
//                     credentials: {
//                       ...localWebhook.authentication.credentials,
//                       token: e.target.value,
//                     },
//                   })
//                 }
//                 className="form-input"
//               />
//             </div>
//           )}
//           {localWebhook.authentication.type === 'apiKey' && (
//             <div className="auth-apikey">
//               <label className="form-label">API Key</label>
//               <input
//                 type="text"
//                 value={localWebhook.authentication.credentials.key || ''}
//                 onChange={(e) =>
//                   handleAuthChange({
//                     ...localWebhook.authentication,
//                     credentials: {
//                       ...localWebhook.authentication.credentials,
//                       key: e.target.value,
//                     },
//                   })
//                 }
//                 className="form-input"
//               />
//             </div>
//           )}
//         </div>

//         {/* Headers */}
//         <div className="form-group">
//           <label className="form-label">Headers</label>
//           <HeadersEditor 
//             key={JSON.stringify(localWebhook.headers)}
//             headers={localWebhook.headers} 
//             onChange={handleHeadersChange} 
//           />        
//         </div>

//         {/* Request Body */}
//         <div className="form-group">
//           <label className="form-label">Request Body (JSON)</label>
//           <button type="button" onClick={openEditor} className="btn-edit-request">
//             Edit Request Body
//           </button>
//         </div>

//         {isEditorOpen && (
//           <div className="modal-overlay">
//             <div className="modal-container">
//               <div className="modal-header">
//                 <h2 className="modal-title">Edit Request Body</h2>
//                 <button onClick={closeEditor} className="modal-close-button">
//                   ✕
//                 </button>
//               </div>
//               <div className="modal-actions-top">
//                 <button
//                   type="button"
//                   onClick={handleSeeDefaultValues}
//                   className="btn-see-defaults"
//                 >
//                   See Default Values
//                 </button>
//                 <button
//                   type="button"
//                   onClick={handleAddAllDefaults}
//                   className="btn-add-defaults"
//                 >
//                   Add All Default Values
//                 </button>
//               </div>
//               <div className="modal-body">
//                 <JSONEditor
//                   value={editorContent}
//                   onChange={(newContent) => setEditorContent(newContent)}
//                   variables={[]}
//                   onValidate={handleValidateJSON}
//                 />
//               </div>
//               <div className="modal-actions">
//                 <button onClick={closeEditor} className="btn-close-modal">
//                   Cancel
//                 </button>
//                 <button onClick={handleSaveEditorContent} className="btn-save-modal">
//                   Save Changes
//                 </button>
//               </div>
//             </div>
//           </div>
//         )}

//         {/* Test and Save buttons */}
//         <div className="button-group">
//           <button type="button" onClick={handleTestClick} className="btn-test">
//             Test
//           </button>
//           <button type="submit" className="btn-save">
//             Save
//           </button>
//         </div>
//       </form>
//       <ToastContainer 
//         position="top-right"
//         autoClose={3000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         pauseOnHover
//       />

//       {/* Custom Modal to display default values */}
//       <CustomModal isOpen={showDefaultsModal} onClose={() => setShowDefaultsModal(false)}>
//         <div className="default-values-modal">
//           <h3>Default Values</h3>
//           <p className="default-values-description">
//             Below are the default key/value pairs. Copy them as needed.
//           </p>
//           <table>
//             <thead>
//               <tr>
//                 <th>Key</th>
//                 <th>Value</th>
//               </tr>
//             </thead>
//             <tbody>
//               {Object.entries(JSON.parse(defaultRequestTemplate)).map(
//                 ([key, value]) => (
//                   <tr key={key}>
//                     <td>{key}</td>
//                     <td>{value}</td>
//                   </tr>
//                 )
//               )}
//             </tbody>
//           </table>
//         </div>
//       </CustomModal>
//     </div>
//   );
// };

// export default WebhookAuth;

// // import React, { useState, useEffect } from 'react';
// // import HeadersEditor from './HeadersEditor';
// // import JSONEditor from './JSONEditor';
// // import TemplateSelector from './TemplateSelector';
// // import { toast, ToastContainer } from 'react-toastify';
// // import 'react-toastify/dist/ReactToastify.css';
// // import { CustomModal } from './CustomModal';

// // // Default JSON payload for a FormattedEvent using token placeholders.
// // export const defaultRequestTemplate = JSON.stringify(
// //   {
// //     Timestamp: "{{Timestamp}}",
// //     EventType: "{{EventType}}",
// //     EventDesc: "{{EventDesc}}",
// //     URL: "{{URL}}",
// //     BaseURL: "{{BaseURL}}",
// //     QueryString: "{{QueryString}}",
// //     HPorgID: "{{HPorgID}}",
// //     SessionId: "{{SessionId}}",
// //     SessionStatus: "{{SessionStatus}}",
// //     CustomData: "{{CustomData}}",
// //     Referrer: "{{Referrer}}",
// //     PageDuration: "{{PageDuration}}",
// //     DurationF: "{{DurationF}}",
// //     DurationCalculated: "{{DurationCalculated}}",
// //     PageCalculated: "{{PageCalculated}}",
// //     HappyID: "{{HappyID}}",
// //     GCLID: "{{GCLID}}",
// //     OrgID: "{{OrgID}}",
// //     ParsedTime: "{{ParsedTime}}",
// //     ProcessingTime: "{{ProcessingTime}}",
// //     TagName: "{{TagName}}",
// //     Class: "{{Class}}",
// //     ID: "{{ID}}",
// //     Href: "{{Href}}",
// //     DataToggle: "{{DataToggle}}",
// //     Role: "{{Role}}",
// //     AriaControls: "{{AriaControls}}",
// //     Type: "{{Type}}",
// //     TextValue: "{{TextValue}}",
// //     HPTarget: "{{HPTarget}}"
// //   },
// //   null,
// //   2
// // );

// // // Adjust initial state so headers is an array
// // const initialState = {
// //   id: '',
// //   name: '',
// //   description: '',
// //   url: '',
// //   method: 'POST',
// //   headers: [],
// //   authentication: {
// //     type: 'none',
// //     credentials: {},
// //   },
// //   request_template: defaultRequestTemplate,
// //   requestBody: {
// //     bodyContent: defaultRequestTemplate,
// //     contentType: 'application/json',
// //   },
// //   status: 'active',
// // };

// // const WebhookAuth = ({ webhook, templates, onSubmit, onTest }) => {
// //   const [localWebhook, setLocalWebhook] = useState(initialState);
// //   const [isEditorOpen, setIsEditorOpen] = useState(false);
// //   const [showDefaultsModal, setShowDefaultsModal] = useState(false);

// //   useEffect(() => {
// //     if (webhook) {
// //       const normalizedWebhook = {
// //         id: webhook.id || webhook.ID,
// //         name: webhook.name || webhook.Name || '',
// //         description: webhook.description || webhook.Description || '',
// //         url: webhook.url || webhook.URL || '',
// //         method: webhook.method || webhook.Method || 'POST',
// //         headers: webhook.headers || [],
// //         authentication: {
// //           ...initialState.authentication,
// //           ...(webhook.authentication || {}),
// //         },
// //         request_template:
// //           (webhook.request_template && webhook.request_template.trim()) ||
// //           defaultRequestTemplate,
// //         status: webhook.status || webhook.Status || 'active',
// //       };

// //       normalizedWebhook.requestBody = {
// //         bodyContent: normalizedWebhook.request_template,
// //         contentType: 'application/json',
// //       };

// //       setLocalWebhook(normalizedWebhook);
// //     }
// //   }, [webhook]);

// //   const handleChange = (field, value) => {
// //     setLocalWebhook(prev => ({ ...prev, [field]: value }));
// //   };

// //   const handleAuthChange = (auth) => {
// //     setLocalWebhook(prev => ({ ...prev, authentication: auth }));
// //   };

// //   const handleHeadersChange = (headers) => {
// //     setLocalWebhook(prev => ({ ...prev, headers }));
// //   };

// //   const safeParseJSON = (str, fallback) => {
// //     try {
// //       return JSON.parse(str);
// //     } catch (err) {
// //       console.error("Error parsing JSON:", err, "Input:", str);
// //       return fallback;
// //     }
// //   };

// //   const isProbablyJSON = (str) => {
// //     const trimmed = str.trim();
// //     return trimmed.startsWith("{") || trimmed.startsWith("[");
// //   };

// //   const handleTemplateSelect = (template) => {
// //     console.log("Template selected:", template);

// //     let templateContent =
// //       template.request_template || template.RequestTemplate || defaultRequestTemplate;

// //     if (isProbablyJSON(templateContent)) {
// //       try {
// //         JSON.parse(templateContent);
// //       } catch (e) {
// //         const fixedContent = templateContent.replace(/\n/g, "\\n");
// //         if (safeParseJSON(fixedContent, null)) {
// //           templateContent = fixedContent;
// //         }
// //       }
// //     }

// //     let rawHeaders = template.headers || template.Headers;
// //     let parsedHeaders;
// //     if (rawHeaders && typeof rawHeaders === "string") {
// //       parsedHeaders = safeParseJSON(rawHeaders, null);
// //       if (parsedHeaders === null) {
// //         const fixedHeaders = rawHeaders.replace(/\n/g, "\\n");
// //         parsedHeaders = safeParseJSON(fixedHeaders, []);
// //       }
// //     } else {
// //       parsedHeaders = rawHeaders || [];
// //     }
// //     if (Array.isArray(parsedHeaders)) {
// //       parsedHeaders = parsedHeaders.reduce((acc, header) => {
// //         if (header.key) {
// //           acc[header.key] = header.value;
// //         }
// //         return acc;
// //       }, {});
// //     }

// //     let rawAuth = template.auth || template.Auth;
// //     let parsedAuth;
// //     if (rawAuth && typeof rawAuth === "string") {
// //       parsedAuth = safeParseJSON(rawAuth, null);
// //       if (parsedAuth === null) {
// //         const fixedAuth = rawAuth.replace(/\n/g, "\\n");
// //         parsedAuth = safeParseJSON(fixedAuth, { type: "none", credentials: {} });
// //       }
// //     } else {
// //       parsedAuth = rawAuth || { type: "none", credentials: {} };
// //     }
// //     if (parsedAuth && parsedAuth.type) {
// //       parsedAuth.type = parsedAuth.type.toLowerCase();
// //     }

// //     let contentType = "application/json";
// //     if (parsedHeaders && parsedHeaders["Content-Type"]) {
// //       contentType = parsedHeaders["Content-Type"];
// //     }

// //     const newWebhookState = {
// //       id: template.id || template.ID || '',
// //       name: template.name || template.Name || '',
// //       description: template.description || template.Description || '',
// //       url: template.url || template.URL || '',
// //       method: template.method || template.Method || 'POST',
// //       headers: parsedHeaders,
// //       authentication: parsedAuth,
// //       request_template: templateContent,
// //       requestBody: {
// //         bodyContent: templateContent,
// //         contentType: contentType,
// //       },
// //       status: template.status || template.Status || 'active',
// //     };

// //     console.log("New local webhook state:", newWebhookState);
// //     setLocalWebhook(newWebhookState);
// //     toast.info("Template applied.");
// //   };

// //   const handleRequestBodyChange = (newBodyContent) => {
// //     setLocalWebhook(prev => ({
// //       ...prev,
// //       requestBody: {
// //         ...prev.requestBody,
// //         bodyContent: newBodyContent,
// //       },
// //       request_template: newBodyContent || defaultRequestTemplate,
// //     }));
// //     toast.info("Request Body Changed");
// //   };

// //   const handleSubmit = (e) => {
// //     e.preventDefault();
// //     const content = localWebhook.request_template || defaultRequestTemplate;
// //     try {
// //       JSON.parse(content);
// //     } catch (err) {
// //       alert('Request Body contains invalid JSON');
// //       return;
// //     }
// //     const webhookToSubmit = {
// //       ...localWebhook,
// //       request_template: content,
// //     };
// //     onSubmit(webhookToSubmit);
// //   };

// //   const handleTestClick = () => {
// //     const content = localWebhook.request_template || defaultRequestTemplate;
// //     const webhookToTest = {
// //       ...localWebhook,
// //       request_template: content,
// //     };
// //     onTest(webhookToTest);
// //     toast.info("Webhook Test");
// //   };

// //   const handleValidateJSON = (value) => {
// //     try {
// //       JSON.parse(value);
// //       return true;
// //     } catch {
// //       return false;
// //     }
// //   };

// //   const openEditor = () => {
// //     setIsEditorOpen(true);
// //     toast.info("Editing webhook...");
// //   };

// //   const closeEditor = () => setIsEditorOpen(false);

// //   // --- New Functions for Default Values ---
// //   const handleSeeDefaultValues = () => {
// //     setShowDefaultsModal(true);
// //   };

// //   const handleAddAllDefaults = () => {
// //     try {
// //       const currentJson = localWebhook.requestBody.bodyContent;
// //       const currentData = isProbablyJSON(currentJson) ? JSON.parse(currentJson) : {};
// //       const defaultData = JSON.parse(defaultRequestTemplate);
// //       let keysAdded = false;
// //       Object.keys(defaultData).forEach((key) => {
// //         if (!(key in currentData)) {
// //           currentData[key] = defaultData[key];
// //           keysAdded = true;
// //         }
// //       });
// //       const updatedJson = JSON.stringify(currentData, null, 2);
// //       handleRequestBodyChange(updatedJson);
// //       toast.dismiss();
// //       if (keysAdded) {
// //         toast.success("Default values added.");
// //       } else {
// //         toast.info("All default values already exist.");
// //       }
// //     } catch (error) {
// //       alert("Error parsing JSON. Please check your JSON.");
// //     }
// //   };

// //   return (
// //     <div className="webhook-auth-container">
// //       <TemplateSelector templates={templates} onSelect={handleTemplateSelect} />
// //       <form onSubmit={handleSubmit}>
// //         {/* Name */}
// //         <div className="form-group">
// //           <label className="form-label">Name</label>
// //           <input
// //             type="text"
// //             value={localWebhook.name}
// //             onChange={(e) => handleChange('name', e.target.value)}
// //             className="form-input"
// //             required
// //           />
// //         </div>

// //         {/* Description */}
// //         <div className="form-group">
// //           <label className="form-label">Description</label>
// //           <input
// //             type="text"
// //             value={localWebhook.description}
// //             onChange={(e) => handleChange('description', e.target.value)}
// //             className="form-input"
// //           />
// //         </div>

// //         {/* URL */}
// //         <div className="form-group">
// //           <label className="form-label">URL</label>
// //           <input
// //             type="url"
// //             value={localWebhook.url}
// //             onChange={(e) => handleChange('url', e.target.value)}
// //             className="form-input"
// //             required
// //           />
// //         </div>

// //         {/* Method */}
// //         <div className="form-group">
// //           <label className="form-label">Method</label>
// //           <select
// //             value={localWebhook.method}
// //             onChange={(e) => handleChange('method', e.target.value)}
// //             className="form-input"
// //           >
// //             <option value="GET">GET</option>
// //             <option value="POST">POST</option>
// //             <option value="PUT">PUT</option>
// //             <option value="DELETE">DELETE</option>
// //           </select>
// //         </div>

// //         {/* Authentication */}
// //         <div className="auth-section">
// //           <label className="form-label">Authentication Type</label>
// //           <select
// //             value={localWebhook.authentication.type}
// //             onChange={(e) =>
// //               handleAuthChange({
// //                 ...localWebhook.authentication,
// //                 type: e.target.value,
// //                 credentials: {},
// //               })
// //             }
// //             className="form-input"
// //           >
// //             <option value="none">None</option>
// //             <option value="basic">Basic</option>
// //             <option value="bearer">Bearer</option>
// //             <option value="apiKey">API Key</option>
// //           </select>
// //           {localWebhook.authentication.type === 'basic' && (
// //             <div className="auth-basic">
// //               <label className="form-label">Username</label>
// //               <input
// //                 type="text"
// //                 value={localWebhook.authentication.credentials.username || ''}
// //                 onChange={(e) =>
// //                   handleAuthChange({
// //                     ...localWebhook.authentication,
// //                     credentials: {
// //                       ...localWebhook.authentication.credentials,
// //                       username: e.target.value,
// //                     },
// //                   })
// //                 }
// //                 className="form-input"
// //               />
// //               <label className="form-label">Password</label>
// //               <input
// //                 type="password"
// //                 value={localWebhook.authentication.credentials.password || ''}
// //                 onChange={(e) =>
// //                   handleAuthChange({
// //                     ...localWebhook.authentication,
// //                     credentials: {
// //                       ...localWebhook.authentication.credentials,
// //                       password: e.target.value,
// //                     },
// //                   })
// //                 }
// //                 className="form-input"
// //               />
// //             </div>
// //           )}
// //           {localWebhook.authentication.type === 'bearer' && (
// //             <div className="auth-bearer">
// //               <label className="form-label">Token</label>
// //               <input
// //                 type="text"
// //                 value={localWebhook.authentication.credentials.token || ''}
// //                 onChange={(e) =>
// //                   handleAuthChange({
// //                     ...localWebhook.authentication,
// //                     credentials: {
// //                       ...localWebhook.authentication.credentials,
// //                       token: e.target.value,
// //                     },
// //                   })
// //                 }
// //                 className="form-input"
// //               />
// //             </div>
// //           )}
// //           {localWebhook.authentication.type === 'apiKey' && (
// //             <div className="auth-apikey">
// //               <label className="form-label">API Key</label>
// //               <input
// //                 type="text"
// //                 value={localWebhook.authentication.credentials.key || ''}
// //                 onChange={(e) =>
// //                   handleAuthChange({
// //                     ...localWebhook.authentication,
// //                     credentials: {
// //                       ...localWebhook.authentication.credentials,
// //                       key: e.target.value,
// //                     },
// //                   })
// //                 }
// //                 className="form-input"
// //               />
// //             </div>
// //           )}
// //         </div>

// //         {/* Headers */}
// //         <div className="form-group">
// //           <label className="form-label">Headers</label>
// //           <HeadersEditor 
// //             key={JSON.stringify(localWebhook.headers)}
// //             headers={localWebhook.headers} 
// //             onChange={handleHeadersChange} 
// //           />        
// //         </div>

// //         {/* Request Body */}
// //         <div className="form-group">
// //           <label className="form-label">Request Body (JSON)</label>
// //           <button type="button" onClick={openEditor} className="btn-edit-request">
// //             Edit Request Body
// //           </button>
// //         </div>

// //         {isEditorOpen && (
// //           <div className="modal-overlay">
// //             <div className="modal-container">
// //               <div className="modal-header">
// //                 <h2 className="modal-title">Edit Request Body</h2>
// //                 <button onClick={closeEditor} className="modal-close-button">
// //                   ✕
// //                 </button>
// //               </div>
// //               <div className="modal-actions-top">
// //                 <button
// //                   type="button"
// //                   onClick={handleSeeDefaultValues}
// //                   className="btn-see-defaults"
// //                 >
// //                   See Default Values
// //                 </button>
// //                 <button
// //                   type="button"
// //                   onClick={handleAddAllDefaults}
// //                   className="btn-add-defaults"
// //                 >
// //                   Add All Default Values
// //                 </button>
// //               </div>
// //               <div className="modal-body">
// //                 <JSONEditor
// //                   value={localWebhook.requestBody.bodyContent}
// //                   onChange={handleRequestBodyChange}
// //                   variables={[]}
// //                   onValidate={handleValidateJSON}
// //                 />
// //               </div>
// //               <div className="modal-actions">
// //                 <button onClick={closeEditor} className="btn-close-modal">
// //                   Close
// //                 </button>
// //                 <button
// //                   onClick={() => {
// //                     if (handleValidateJSON(localWebhook.requestBody.bodyContent)) {
// //                       closeEditor();
// //                       toast.success("Request body updated!");
// //                     } else {
// //                       alert('Please fix JSON validation errors before closing');
// //                     }
// //                   }}
// //                   className="btn-save-modal"
// //                 >
// //                   Save Changes
// //                 </button>
// //               </div>
// //             </div>
// //           </div>
// //         )}

// //         {/* Test and Save buttons */}
// //         <div className="button-group">
// //           <button type="button" onClick={handleTestClick} className="btn-test">
// //             Test
// //           </button>
// //           <button type="submit" className="btn-save">
// //             Save
// //           </button>
// //         </div>
// //       </form>
// //       <ToastContainer 
// //         position="top-right"
// //         autoClose={3000}
// //         hideProgressBar={false}
// //         newestOnTop={false}
// //         closeOnClick
// //         pauseOnHover
// //       />

// //       {/* Custom Modal to display default values */}
// //       <CustomModal isOpen={showDefaultsModal} onClose={() => setShowDefaultsModal(false)}>
// //         <div className="default-values-modal">
// //           <h3>Default Values</h3>
// //           <p className="default-values-description">
// //             Below are the default key/value pairs. Copy them as needed.
// //           </p>
// //           <table>
// //             <thead>
// //               <tr>
// //                 <th>Key</th>
// //                 <th>Value</th>
// //               </tr>
// //             </thead>
// //             <tbody>
// //               {Object.entries(JSON.parse(defaultRequestTemplate)).map(
// //                 ([key, value]) => (
// //                   <tr key={key}>
// //                     <td>{key}</td>
// //                     <td>{value}</td>
// //                   </tr>
// //                 )
// //               )}
// //             </tbody>
// //           </table>
// //           <button onClick={() => setShowDefaultsModal(false)} className="btn-close-modal">
// //             Close
// //           </button>
// //         </div>
// //       </CustomModal>
// //     </div>
// //   );
// // };

// // export default WebhookAuth;




// // // import React, { useState, useEffect } from 'react';
// // // import HeadersEditor from './HeadersEditor';
// // // import JSONEditor from './JSONEditor';
// // // import TemplateSelector from './TemplateSelector';
// // // import { toast, ToastContainer } from 'react-toastify';
// // // import 'react-toastify/dist/ReactToastify.css';
// // // import { CustomModal } from './CustomModal';

// // // // Default JSON payload for a FormattedEvent using token placeholders.
// // // export const defaultRequestTemplate = JSON.stringify(
// // //   {
// // //     Timestamp: "{{Timestamp}}",
// // //     EventType: "{{EventType}}",
// // //     EventDesc: "{{EventDesc}}",
// // //     URL: "{{URL}}",
// // //     BaseURL: "{{BaseURL}}",
// // //     QueryString: "{{QueryString}}",
// // //     HPorgID: "{{HPorgID}}",
// // //     SessionId: "{{SessionId}}",
// // //     SessionStatus: "{{SessionStatus}}",
// // //     CustomData: "{{CustomData}}",
// // //     Referrer: "{{Referrer}}",
// // //     PageDuration: "{{PageDuration}}",
// // //     DurationF: "{{DurationF}}",
// // //     DurationCalculated: "{{DurationCalculated}}",
// // //     PageCalculated: "{{PageCalculated}}",
// // //     HappyID: "{{HappyID}}",
// // //     GCLID: "{{GCLID}}",
// // //     OrgID: "{{OrgID}}",
// // //     ParsedTime: "{{ParsedTime}}",
// // //     ProcessingTime: "{{ProcessingTime}}",
// // //     TagName: "{{TagName}}",
// // //     Class: "{{Class}}",
// // //     ID: "{{ID}}",
// // //     Href: "{{Href}}",
// // //     DataToggle: "{{DataToggle}}",
// // //     Role: "{{Role}}",
// // //     AriaControls: "{{AriaControls}}",
// // //     Type: "{{Type}}",
// // //     TextValue: "{{TextValue}}",
// // //     HPTarget: "{{HPTarget}}"
// // //   },
// // //   null,
// // //   2
// // // );

// // // // Adjust initial state so headers is an array
// // // const initialState = {
// // //   id: '',
// // //   name: '',
// // //   description: '',
// // //   url: '',
// // //   method: 'POST',
// // //   headers: [],
// // //   authentication: {
// // //     type: 'none',
// // //     credentials: {},
// // //   },
// // //   request_template: defaultRequestTemplate,
// // //   requestBody: {
// // //     bodyContent: defaultRequestTemplate,
// // //     contentType: 'application/json',
// // //   },
// // //   status: 'active',
// // // };

// // // const WebhookAuth = ({ webhook, templates, onSubmit, onTest }) => {
// // //   const [localWebhook, setLocalWebhook] = useState(initialState);
// // //   const [isEditorOpen, setIsEditorOpen] = useState(false);
// // //   const [showDefaultsModal, setShowDefaultsModal] = useState(false);

// // //   useEffect(() => {
// // //     if (webhook) {
// // //       const normalizedWebhook = {
// // //         id: webhook.id || webhook.ID,
// // //         name: webhook.name || webhook.Name || '',
// // //         description: webhook.description || webhook.Description || '',
// // //         url: webhook.url || webhook.URL || '',
// // //         method: webhook.method || webhook.Method || 'POST',
// // //         headers: webhook.headers || [],
// // //         authentication: {
// // //           ...initialState.authentication,
// // //           ...(webhook.authentication || {}),
// // //         },
// // //         request_template:
// // //           (webhook.request_template && webhook.request_template.trim()) ||
// // //           defaultRequestTemplate,
// // //         status: webhook.status || webhook.Status || 'active',
// // //       };

// // //       normalizedWebhook.requestBody = {
// // //         bodyContent: normalizedWebhook.request_template,
// // //         contentType: 'application/json',
// // //       };

// // //       setLocalWebhook(normalizedWebhook);
// // //     }
// // //   }, [webhook]);

// // //   const handleChange = (field, value) => {
// // //     setLocalWebhook(prev => ({ ...prev, [field]: value }));
// // //   };

// // //   const handleAuthChange = (auth) => {
// // //     setLocalWebhook(prev => ({ ...prev, authentication: auth }));
// // //   };

// // //   const handleHeadersChange = (headers) => {
// // //     setLocalWebhook(prev => ({ ...prev, headers }));
// // //   };

// // //   const safeParseJSON = (str, fallback) => {
// // //     try {
// // //       return JSON.parse(str);
// // //     } catch (err) {
// // //       console.error("Error parsing JSON:", err, "Input:", str);
// // //       return fallback;
// // //     }
// // //   };

// // //   const isProbablyJSON = (str) => {
// // //     const trimmed = str.trim();
// // //     return trimmed.startsWith("{") || trimmed.startsWith("[");
// // //   };

// // //   const handleTemplateSelect = (template) => {
// // //     console.log("Template selected:", template);

// // //     let templateContent =
// // //       template.request_template || template.RequestTemplate || defaultRequestTemplate;

// // //     if (isProbablyJSON(templateContent)) {
// // //       try {
// // //         JSON.parse(templateContent);
// // //       } catch (e) {
// // //         const fixedContent = templateContent.replace(/\n/g, "\\n");
// // //         if (safeParseJSON(fixedContent, null)) {
// // //           templateContent = fixedContent;
// // //         }
// // //       }
// // //     }

// // //     let rawHeaders = template.headers || template.Headers;
// // //     let parsedHeaders;
// // //     if (rawHeaders && typeof rawHeaders === "string") {
// // //       parsedHeaders = safeParseJSON(rawHeaders, null);
// // //       if (parsedHeaders === null) {
// // //         const fixedHeaders = rawHeaders.replace(/\n/g, "\\n");
// // //         parsedHeaders = safeParseJSON(fixedHeaders, []);
// // //       }
// // //     } else {
// // //       parsedHeaders = rawHeaders || [];
// // //     }
// // //     if (Array.isArray(parsedHeaders)) {
// // //       parsedHeaders = parsedHeaders.reduce((acc, header) => {
// // //         if (header.key) {
// // //           acc[header.key] = header.value;
// // //         }
// // //         return acc;
// // //       }, {});
// // //     }

// // //     let rawAuth = template.auth || template.Auth;
// // //     let parsedAuth;
// // //     if (rawAuth && typeof rawAuth === "string") {
// // //       parsedAuth = safeParseJSON(rawAuth, null);
// // //       if (parsedAuth === null) {
// // //         const fixedAuth = rawAuth.replace(/\n/g, "\\n");
// // //         parsedAuth = safeParseJSON(fixedAuth, { type: "none", credentials: {} });
// // //       }
// // //     } else {
// // //       parsedAuth = rawAuth || { type: "none", credentials: {} };
// // //     }
// // //     if (parsedAuth && parsedAuth.type) {
// // //       parsedAuth.type = parsedAuth.type.toLowerCase();
// // //     }

// // //     let contentType = "application/json";
// // //     if (parsedHeaders && parsedHeaders["Content-Type"]) {
// // //       contentType = parsedHeaders["Content-Type"];
// // //     }

// // //     const newWebhookState = {
// // //       id: template.id || template.ID || '',
// // //       name: template.name || template.Name || '',
// // //       description: template.description || template.Description || '',
// // //       url: template.url || template.URL || '',
// // //       method: template.method || template.Method || 'POST',
// // //       headers: parsedHeaders,
// // //       authentication: parsedAuth,
// // //       request_template: templateContent,
// // //       requestBody: {
// // //         bodyContent: templateContent,
// // //         contentType: contentType,
// // //       },
// // //       status: template.status || template.Status || 'active',
// // //     };

// // //     console.log("New local webhook state:", newWebhookState);
// // //     setLocalWebhook(newWebhookState);
// // //     toast.info("Template applied.");
// // //   };

// // //   const handleRequestBodyChange = (newBodyContent) => {
// // //     setLocalWebhook(prev => ({
// // //       ...prev,
// // //       requestBody: {
// // //         ...prev.requestBody,
// // //         bodyContent: newBodyContent,
// // //       },
// // //       request_template: newBodyContent || defaultRequestTemplate,
// // //     }));
// // //     toast.info("Request Body Changed");
// // //   };

// // //   const handleSubmit = (e) => {
// // //     e.preventDefault();
// // //     const content = localWebhook.request_template || defaultRequestTemplate;
// // //     try {
// // //       JSON.parse(content);
// // //     } catch (err) {
// // //       alert('Request Body contains invalid JSON');
// // //       return;
// // //     }
// // //     const webhookToSubmit = {
// // //       ...localWebhook,
// // //       request_template: content,
// // //     };
// // //     onSubmit(webhookToSubmit);
// // //   };

// // //   const handleTestClick = () => {
// // //     const content = localWebhook.request_template || defaultRequestTemplate;
// // //     const webhookToTest = {
// // //       ...localWebhook,
// // //       request_template: content,
// // //     };
// // //     onTest(webhookToTest);
// // //     toast.info("Webhook Test");
// // //   };

// // //   const handleValidateJSON = (value) => {
// // //     try {
// // //       JSON.parse(value);
// // //       return true;
// // //     } catch {
// // //       return false;
// // //     }
// // //   };

// // //   const openEditor = () => {
// // //     setIsEditorOpen(true);
// // //     toast.info("Editing webhook...");
// // //   };

// // //   const closeEditor = () => setIsEditorOpen(false);

// // //   // --- New Functions for Default Values ---
// // //   const handleSeeDefaultValues = () => {
// // //     setShowDefaultsModal(true);
// // //   };

// // //   const handleAddAllDefaults = () => {
// // //     try {
// // //       const currentJson = localWebhook.requestBody.bodyContent;
// // //       const currentData = isProbablyJSON(currentJson) ? JSON.parse(currentJson) : {};
// // //       const defaultData = JSON.parse(defaultRequestTemplate);
// // //       // Append missing keys from defaultData to currentData.
// // //       Object.keys(defaultData).forEach((key) => {
// // //         if (!(key in currentData)) {
// // //           currentData[key] = defaultData[key];
// // //         }
// // //       });
// // //       const updatedJson = JSON.stringify(currentData, null, 2);
// // //       handleRequestBodyChange(updatedJson);
// // //       toast.success("Default values added.");
// // //     } catch (error) {
// // //       alert("Error parsing JSON. Please check your JSON.");
// // //     }
// // //   };

// // //   return (
// // //     <div className="webhook-auth-container">
// // //       <TemplateSelector templates={templates} onSelect={handleTemplateSelect} />
// // //       <form onSubmit={handleSubmit}>
// // //         {/* Name */}
// // //         <div className="form-group">
// // //           <label className="form-label">Name</label>
// // //           <input
// // //             type="text"
// // //             value={localWebhook.name}
// // //             onChange={(e) => handleChange('name', e.target.value)}
// // //             className="form-input"
// // //             required
// // //           />
// // //         </div>

// // //         {/* Description */}
// // //         <div className="form-group">
// // //           <label className="form-label">Description</label>
// // //           <input
// // //             type="text"
// // //             value={localWebhook.description}
// // //             onChange={(e) => handleChange('description', e.target.value)}
// // //             className="form-input"
// // //           />
// // //         </div>

// // //         {/* URL */}
// // //         <div className="form-group">
// // //           <label className="form-label">URL</label>
// // //           <input
// // //             type="url"
// // //             value={localWebhook.url}
// // //             onChange={(e) => handleChange('url', e.target.value)}
// // //             className="form-input"
// // //             required
// // //           />
// // //         </div>

// // //         {/* Method */}
// // //         <div className="form-group">
// // //           <label className="form-label">Method</label>
// // //           <select
// // //             value={localWebhook.method}
// // //             onChange={(e) => handleChange('method', e.target.value)}
// // //             className="form-input"
// // //           >
// // //             <option value="GET">GET</option>
// // //             <option value="POST">POST</option>
// // //             <option value="PUT">PUT</option>
// // //             <option value="DELETE">DELETE</option>
// // //           </select>
// // //         </div>

// // //         {/* Authentication */}
// // //         <div className="auth-section">
// // //           <label className="form-label">Authentication Type</label>
// // //           <select
// // //             value={localWebhook.authentication.type}
// // //             onChange={(e) =>
// // //               handleAuthChange({
// // //                 ...localWebhook.authentication,
// // //                 type: e.target.value,
// // //                 credentials: {},
// // //               })
// // //             }
// // //             className="form-input"
// // //           >
// // //             <option value="none">None</option>
// // //             <option value="basic">Basic</option>
// // //             <option value="bearer">Bearer</option>
// // //             <option value="apiKey">API Key</option>
// // //           </select>
// // //           {localWebhook.authentication.type === 'basic' && (
// // //             <div className="auth-basic">
// // //               <label className="form-label">Username</label>
// // //               <input
// // //                 type="text"
// // //                 value={localWebhook.authentication.credentials.username || ''}
// // //                 onChange={(e) =>
// // //                   handleAuthChange({
// // //                     ...localWebhook.authentication,
// // //                     credentials: {
// // //                       ...localWebhook.authentication.credentials,
// // //                       username: e.target.value,
// // //                     },
// // //                   })
// // //                 }
// // //                 className="form-input"
// // //               />
// // //               <label className="form-label">Password</label>
// // //               <input
// // //                 type="password"
// // //                 value={localWebhook.authentication.credentials.password || ''}
// // //                 onChange={(e) =>
// // //                   handleAuthChange({
// // //                     ...localWebhook.authentication,
// // //                     credentials: {
// // //                       ...localWebhook.authentication.credentials,
// // //                       password: e.target.value,
// // //                     },
// // //                   })
// // //                 }
// // //                 className="form-input"
// // //               />
// // //             </div>
// // //           )}
// // //           {localWebhook.authentication.type === 'bearer' && (
// // //             <div className="auth-bearer">
// // //               <label className="form-label">Token</label>
// // //               <input
// // //                 type="text"
// // //                 value={localWebhook.authentication.credentials.token || ''}
// // //                 onChange={(e) =>
// // //                   handleAuthChange({
// // //                     ...localWebhook.authentication,
// // //                     credentials: {
// // //                       ...localWebhook.authentication.credentials,
// // //                       token: e.target.value,
// // //                     },
// // //                   })
// // //                 }
// // //                 className="form-input"
// // //               />
// // //             </div>
// // //           )}
// // //           {localWebhook.authentication.type === 'apiKey' && (
// // //             <div className="auth-apikey">
// // //               <label className="form-label">API Key</label>
// // //               <input
// // //                 type="text"
// // //                 value={localWebhook.authentication.credentials.key || ''}
// // //                 onChange={(e) =>
// // //                   handleAuthChange({
// // //                     ...localWebhook.authentication,
// // //                     credentials: {
// // //                       ...localWebhook.authentication.credentials,
// // //                       key: e.target.value,
// // //                     },
// // //                   })
// // //                 }
// // //                 className="form-input"
// // //               />
// // //             </div>
// // //           )}
// // //         </div>

// // //         {/* Headers */}
// // //         <div className="form-group">
// // //           <label className="form-label">Headers</label>
// // //           <HeadersEditor 
// // //             key={JSON.stringify(localWebhook.headers)}
// // //             headers={localWebhook.headers} 
// // //             onChange={handleHeadersChange} 
// // //           />        
// // //         </div>

// // //         {/* Request Body */}
// // //         <div className="form-group">
// // //           <label className="form-label">Request Body (JSON)</label>
// // //           <button type="button" onClick={openEditor} className="btn-edit-request">
// // //             Edit Request Body
// // //           </button>
// // //         </div>

// // //         {isEditorOpen && (
// // //           <div className="modal-overlay">
// // //             <div className="modal-container">
// // //               <div className="modal-header">
// // //                 <h2 className="modal-title">Edit Request Body</h2>
// // //                 <button onClick={closeEditor} className="modal-close-button">
// // //                   ✕
// // //                 </button>
// // //               </div>
// // //               {/* New action buttons */}
// // //               <div className="modal-actions-top" style={{ padding: '0 1rem 0.5rem' }}>
// // //                 <button
// // //                   type="button"
// // //                   onClick={handleSeeDefaultValues}
// // //                   className="btn-see-defaults"
// // //                 >
// // //                   See Default Values
// // //                 </button>
// // //                 <button
// // //                   type="button"
// // //                   onClick={handleAddAllDefaults}
// // //                   className="btn-add-defaults"
// // //                   style={{ marginLeft: '1rem' }}
// // //                 >
// // //                   Add All Default Values
// // //                 </button>
// // //               </div>
// // //               <div className="modal-body">
// // //                 <JSONEditor
// // //                   value={localWebhook.requestBody.bodyContent}
// // //                   onChange={handleRequestBodyChange}
// // //                   variables={[]}
// // //                   onValidate={handleValidateJSON}
// // //                 />
// // //               </div>
// // //               <div className="modal-actions">
// // //                 <button onClick={closeEditor} className="btn-close-modal">
// // //                   Close
// // //                 </button>
// // //                 <button
// // //                   onClick={() => {
// // //                     if (handleValidateJSON(localWebhook.requestBody.bodyContent)) {
// // //                       closeEditor();
// // //                       toast.success("Request body updated!");
// // //                     } else {
// // //                       alert('Please fix JSON validation errors before closing');
// // //                     }
// // //                   }}
// // //                   className="btn-save-modal"
// // //                 >
// // //                   Save Changes
// // //                 </button>
// // //               </div>
// // //             </div>
// // //           </div>
// // //         )}

// // //         {/* Test and Save buttons */}
// // //         <div className="button-group">
// // //           <button type="button" onClick={handleTestClick} className="btn-test">
// // //             Test
// // //           </button>
// // //           <button type="submit" className="btn-save">
// // //             Save
// // //           </button>
// // //         </div>
// // //       </form>
// // //       <ToastContainer 
// // //         position="top-right"
// // //         autoClose={3000}
// // //         hideProgressBar={false}
// // //         newestOnTop={false}
// // //         closeOnClick
// // //         pauseOnHover
// // //       />

// // //       {/* Custom Modal to display default values */}
// // //       <CustomModal isOpen={showDefaultsModal} onClose={() => setShowDefaultsModal(false)}>
// // //         <div className="p-4">
// // //           <h3 className="text-xl font-bold mb-2">Default Values</h3>
// // //           <table className="min-w-full">
// // //             <thead>
// // //               <tr>
// // //                 <th className="px-4 py-2 border">Key</th>
// // //                 <th className="px-4 py-2 border">Value</th>
// // //               </tr>
// // //             </thead>
// // //             <tbody>
// // //               {Object.entries(JSON.parse(defaultRequestTemplate)).map(
// // //                 ([key, value]) => (
// // //                   <tr key={key}>
// // //                     <td className="px-4 py-2 border">{key}</td>
// // //                     <td className="px-4 py-2 border">{value}</td>
// // //                   </tr>
// // //                 )
// // //               )}
// // //             </tbody>
// // //           </table>
// // //           <button onClick={() => setShowDefaultsModal(false)} className="mt-4 btn-close-modal">
// // //             Close
// // //           </button>
// // //         </div>
// // //       </CustomModal>
// // //     </div>
// // //   );
// // // };

// // // export default WebhookAuth;




// // // // import React, { useState, useEffect } from 'react';
// // // // import HeadersEditor from './HeadersEditor';
// // // // import JSONEditor from './JSONEditor';
// // // // import TemplateSelector from './TemplateSelector';
// // // // import { toast, ToastContainer } from 'react-toastify';
// // // // import 'react-toastify/dist/ReactToastify.css';

// // // // // Default JSON payload for a FormattedEvent using token placeholders.
// // // // const defaultRequestTemplate = JSON.stringify({
// // // //   Timestamp: "{{Timestamp}}",
// // // //   EventType: "{{EventType}}",
// // // //   EventDesc: "{{EventDesc}}",
// // // //   URL: "{{URL}}",
// // // //   BaseURL: "{{BaseURL}}",
// // // //   QueryString: "{{QueryString}}",
// // // //   HPorgID: "{{HPorgID}}",
// // // //   SessionId: "{{SessionId}}",
// // // //   SessionStatus: "{{SessionStatus}}",
// // // //   CustomData: "{{CustomData}}",
// // // //   Referrer: "{{Referrer}}",
// // // //   PageDuration: "{{PageDuration}}",
// // // //   DurationF: "{{DurationF}}",
// // // //   DurationCalculated: "{{DurationCalculated}}",
// // // //   PageCalculated: "{{PageCalculated}}",
// // // //   HappyID: "{{HappyID}}",
// // // //   GCLID: "{{GCLID}}",
// // // //   OrgID: "{{OrgID}}",
// // // //   ParsedTime: "{{ParsedTime}}",
// // // //   ProcessingTime: "{{ProcessingTime}}",
// // // //   TagName: "{{TagName}}",
// // // //   Class: "{{Class}}",
// // // //   ID: "{{ID}}",
// // // //   Href: "{{Href}}",
// // // //   DataToggle: "{{DataToggle}}",
// // // //   Role: "{{Role}}",
// // // //   AriaControls: "{{AriaControls}}",
// // // //   Type: "{{Type}}",
// // // //   TextValue: "{{TextValue}}",
// // // //   HPTarget: "{{HPTarget}}"
// // // // }, null, 2);

// // // // // Adjust initial state so headers is an array (if that's what your templates use)
// // // // const initialState = {
// // // //   id: '',
// // // //   name: '',
// // // //   description: '',
// // // //   url: '',
// // // //   method: 'POST',
// // // //   headers: [], // changed from {} to [] for consistency
// // // //   authentication: {
// // // //     type: 'none',
// // // //     credentials: {},
// // // //   },
// // // //   request_template: defaultRequestTemplate,
// // // //   requestBody: {
// // // //     bodyContent: defaultRequestTemplate,
// // // //     contentType: 'application/json',
// // // //   },
// // // //   status: 'active',
// // // // };

// // // // const WebhookAuth = ({ webhook, templates, onSubmit, onTest }) => {
// // // //   const [localWebhook, setLocalWebhook] = useState(initialState);
// // // //   const [isEditorOpen, setIsEditorOpen] = useState(false);

// // // //   useEffect(() => {
// // // //     if (webhook) {
// // // //       // Normalize the webhook object so that our form fields populate correctly.
// // // //       const normalizedWebhook = {
// // // //         id: webhook.id || webhook.ID,
// // // //         name: webhook.name || webhook.Name || '',
// // // //         description: webhook.description || webhook.Description || '',
// // // //         url: webhook.url || webhook.URL || '',
// // // //         method: webhook.method || webhook.Method || 'POST',
// // // //         headers: webhook.headers || [],
// // // //         authentication: {
// // // //           ...initialState.authentication,
// // // //           ...(webhook.authentication || {}),
// // // //         },
// // // //         // Use request_template if available (trimmed) or fallback to default.
// // // //         request_template:
// // // //           (webhook.request_template && webhook.request_template.trim()) ||
// // // //           defaultRequestTemplate,
// // // //         status: webhook.status || webhook.Status || 'active',
// // // //       };
  
// // // //       normalizedWebhook.requestBody = {
// // // //         bodyContent: normalizedWebhook.request_template,
// // // //         contentType: 'application/json',
// // // //       };
  
// // // //       setLocalWebhook(normalizedWebhook);
// // // //     }
// // // //   }, [webhook]);
  

// // // //   const handleChange = (field, value) => {
// // // //     setLocalWebhook(prev => ({ ...prev, [field]: value }));
// // // //   };

// // // //   const handleAuthChange = (auth) => {
// // // //     setLocalWebhook(prev => ({ ...prev, authentication: auth }));
// // // //   };

// // // //   const handleHeadersChange = (headers) => {
// // // //     setLocalWebhook(prev => ({ ...prev, headers }));
// // // //   };

// // // // // Helper: safely parse a JSON string, returning a fallback if parsing fails.
// // // // const safeParseJSON = (str, fallback) => {
// // // //   try {
// // // //     return JSON.parse(str);
// // // //   } catch (err) {
// // // //     console.error("Error parsing JSON:", err, "Input:", str);
// // // //     return fallback;
// // // //   }
// // // // };

// // // // const isProbablyJSON = (str) => {
// // // //   const trimmed = str.trim();
// // // //   return trimmed.startsWith("{") || trimmed.startsWith("[");
// // // // };

// // // // const handleTemplateSelect = (template) => {
// // // //   console.log("Template selected:", template);

// // // //   let templateContent =
// // // //     template.request_template || template.RequestTemplate || defaultRequestTemplate;

// // // //   // Validate and fix JSON if necessary.
// // // //   if (isProbablyJSON(templateContent)) {
// // // //     try {
// // // //       JSON.parse(templateContent);
// // // //     } catch (e) {
// // // //       const fixedContent = templateContent.replace(/\n/g, "\\n");
// // // //       if (safeParseJSON(fixedContent, null)) {
// // // //         templateContent = fixedContent;
// // // //       }
// // // //     }
// // // //   }

// // // //   // Process headers
// // // //   let rawHeaders = template.headers || template.Headers;
// // // //   let parsedHeaders;
// // // //   if (rawHeaders && typeof rawHeaders === "string") {
// // // //     parsedHeaders = safeParseJSON(rawHeaders, null);
// // // //     if (parsedHeaders === null) {
// // // //       const fixedHeaders = rawHeaders.replace(/\n/g, "\\n");
// // // //       parsedHeaders = safeParseJSON(fixedHeaders, []);
// // // //     }
// // // //   } else {
// // // //     parsedHeaders = rawHeaders || [];
// // // //   }
// // // //   if (Array.isArray(parsedHeaders)) {
// // // //     parsedHeaders = parsedHeaders.reduce((acc, header) => {
// // // //       if (header.key) {
// // // //         acc[header.key] = header.value;
// // // //       }
// // // //       return acc;
// // // //     }, {});
// // // //   }

// // // //   // Process authentication
// // // //   let rawAuth = template.auth || template.Auth;
// // // //   let parsedAuth;
// // // //   if (rawAuth && typeof rawAuth === "string") {
// // // //     parsedAuth = safeParseJSON(rawAuth, null);
// // // //     if (parsedAuth === null) {
// // // //       const fixedAuth = rawAuth.replace(/\n/g, "\\n");
// // // //       parsedAuth = safeParseJSON(fixedAuth, { type: "none", credentials: {} });
// // // //     }
// // // //   } else {
// // // //     parsedAuth = rawAuth || { type: "none", credentials: {} };
// // // //   }
// // // //   if (parsedAuth && parsedAuth.type) {
// // // //     parsedAuth.type = parsedAuth.type.toLowerCase();
// // // //   }

// // // //   // Determine content type based on headers
// // // //   let contentType = "application/json";
// // // //   if (parsedHeaders && parsedHeaders["Content-Type"]) {
// // // //     contentType = parsedHeaders["Content-Type"];
// // // //   }

// // // //   // Create a new webhook state object based solely on the template.
// // // //   const newWebhookState = {
// // // //     id: template.id || template.ID || '', // start fresh
// // // //     name: template.name || template.Name || '',
// // // //     description: template.description || template.Description || '',
// // // //     url: template.url || template.URL || '',
// // // //     method: template.method || template.Method || 'POST',
// // // //     headers: parsedHeaders,
// // // //     authentication: parsedAuth,
// // // //     request_template: templateContent,
// // // //     requestBody: {
// // // //       bodyContent: templateContent,
// // // //       contentType: contentType,
// // // //     },
// // // //     status: template.status || template.Status || 'active',
// // // //   };

// // // //   console.log("New local webhook state:", newWebhookState);
// // // //   setLocalWebhook(newWebhookState);
// // // //   toast.info("Template applied.");
// // // // };

// // // //   const handleRequestBodyChange = (newBodyContent) => {
// // // //     setLocalWebhook(prev => ({
// // // //       ...prev,
// // // //       requestBody: {
// // // //         ...prev.requestBody,
// // // //         bodyContent: newBodyContent,
// // // //       },
// // // //       request_template: newBodyContent || defaultRequestTemplate,
// // // //     }));
// // // //     toast.info("Request Body Changed");
// // // //   };

// // // //   const handleSubmit = (e) => {
// // // //     e.preventDefault();
// // // //     const content = localWebhook.request_template || defaultRequestTemplate;
// // // //     try {
// // // //       JSON.parse(content); // Validate JSON.
// // // //     } catch (err) {
// // // //       alert('Request Body contains invalid JSON');
// // // //       return;
// // // //     }
// // // //     const webhookToSubmit = {
// // // //       ...localWebhook,
// // // //       request_template: content,
// // // //     };
// // // //     onSubmit(webhookToSubmit);
// // // //     // toast.info("Webhook Save Submitted");

// // // //   };

// // // //   const handleTestClick = () => {
// // // //     const content = localWebhook.request_template || defaultRequestTemplate;
// // // //     const webhookToTest = {
// // // //       ...localWebhook,
// // // //       request_template: content,
// // // //     };
// // // //     onTest(webhookToTest);
// // // //     toast.info("Webhook Test");
// // // //   };

// // // //   const handleValidateJSON = (value) => {
// // // //     try {
// // // //       JSON.parse(value);
// // // //       return true;
// // // //     } catch {
// // // //       return false;
// // // //     }
// // // //   };

// // // //   const openEditor = () => {setIsEditorOpen(true); toast.info("Editing webhook...");}
// // // //   const closeEditor = () => setIsEditorOpen(false);

// // // //   return (
// // // //     <div className="webhook-auth-container">
// // // //       <TemplateSelector templates={templates} onSelect={handleTemplateSelect} />
// // // //       <form onSubmit={handleSubmit}>
// // // //         {/* Name */}
// // // //         <div className="form-group">
// // // //           <label className="form-label">Name</label>
// // // //           <input
// // // //             type="text"
// // // //             value={localWebhook.name}
// // // //             onChange={(e) => handleChange('name', e.target.value)}
// // // //             className="form-input"
// // // //             required
// // // //           />
// // // //         </div>

// // // //         {/* Description */}
// // // //         <div className="form-group">
// // // //           <label className="form-label">Description</label>
// // // //           <input
// // // //             type="text"
// // // //             value={localWebhook.description}
// // // //             onChange={(e) => handleChange('description', e.target.value)}
// // // //             className="form-input"
// // // //           />
// // // //         </div>

// // // //         {/* URL */}
// // // //         <div className="form-group">
// // // //           <label className="form-label">URL</label>
// // // //           <input
// // // //             type="url"
// // // //             value={localWebhook.url}
// // // //             onChange={(e) => handleChange('url', e.target.value)}
// // // //             className="form-input"
// // // //             required
// // // //           />
// // // //         </div>

// // // //         {/* Method */}
// // // //         <div className="form-group">
// // // //           <label className="form-label">Method</label>
// // // //           <select
// // // //             value={localWebhook.method}
// // // //             onChange={(e) => handleChange('method', e.target.value)}
// // // //             className="form-input"
// // // //           >
// // // //             <option value="GET">GET</option>
// // // //             <option value="POST">POST</option>
// // // //             <option value="PUT">PUT</option>
// // // //             <option value="DELETE">DELETE</option>
// // // //           </select>
// // // //         </div>

// // // //         {/* Authentication */}
// // // //         <div className="auth-section">
// // // //           <label className="form-label">Authentication Type</label>
// // // //           <select
// // // //             value={localWebhook.authentication.type}
// // // //             onChange={(e) =>
// // // //               handleAuthChange({
// // // //                 ...localWebhook.authentication,
// // // //                 type: e.target.value,
// // // //                 credentials: {},
// // // //               })
// // // //             }
// // // //             className="form-input"
// // // //           >
// // // //             <option value="none">None</option>
// // // //             <option value="basic">Basic</option>
// // // //             <option value="bearer">Bearer</option>
// // // //             <option value="apiKey">API Key</option>
// // // //           </select>
// // // //           {localWebhook.authentication.type === 'basic' && (
// // // //             <div className="auth-basic">
// // // //               <label className="form-label">Username</label>
// // // //               <input
// // // //                 type="text"
// // // //                 value={localWebhook.authentication.credentials.username || ''}
// // // //                 onChange={(e) =>
// // // //                   handleAuthChange({
// // // //                     ...localWebhook.authentication,
// // // //                     credentials: {
// // // //                       ...localWebhook.authentication.credentials,
// // // //                       username: e.target.value,
// // // //                     },
// // // //                   })
// // // //                 }
// // // //                 className="form-input"
// // // //               />
// // // //               <label className="form-label">Password</label>
// // // //               <input
// // // //                 type="password"
// // // //                 value={localWebhook.authentication.credentials.password || ''}
// // // //                 onChange={(e) =>
// // // //                   handleAuthChange({
// // // //                     ...localWebhook.authentication,
// // // //                     credentials: {
// // // //                       ...localWebhook.authentication.credentials,
// // // //                       password: e.target.value,
// // // //                     },
// // // //                   })
// // // //                 }
// // // //                 className="form-input"
// // // //               />
// // // //             </div>
// // // //           )}
// // // //           {localWebhook.authentication.type === 'bearer' && (
// // // //             <div className="auth-bearer">
// // // //               <label className="form-label">Token</label>
// // // //               <input
// // // //                 type="text"
// // // //                 value={localWebhook.authentication.credentials.token || ''}
// // // //                 onChange={(e) =>
// // // //                   handleAuthChange({
// // // //                     ...localWebhook.authentication,
// // // //                     credentials: {
// // // //                       ...localWebhook.authentication.credentials,
// // // //                       token: e.target.value,
// // // //                     },
// // // //                   })
// // // //                 }
// // // //                 className="form-input"
// // // //               />
// // // //             </div>
// // // //           )}
// // // //           {localWebhook.authentication.type === 'apiKey' && (
// // // //             <div className="auth-apikey">
// // // //               <label className="form-label">API Key</label>
// // // //               <input
// // // //                 type="text"
// // // //                 value={localWebhook.authentication.credentials.key || ''}
// // // //                 onChange={(e) =>
// // // //                   handleAuthChange({
// // // //                     ...localWebhook.authentication,
// // // //                     credentials: {
// // // //                       ...localWebhook.authentication.credentials,
// // // //                       key: e.target.value,
// // // //                     },
// // // //                   })
// // // //                 }
// // // //                 className="form-input"
// // // //               />
// // // //             </div>
// // // //           )}
// // // //         </div>

// // // //         {/* Headers */}
// // // //         <div className="form-group">
// // // //           <label className="form-label">Headers</label>
// // // //           <HeadersEditor 
// // // //             key={JSON.stringify(localWebhook.headers)}
// // // //             headers={localWebhook.headers} 
// // // //             onChange={handleHeadersChange} 
// // // //           />        
// // // //           </div>

// // // //         {/* Request Body */}
// // // //         <div className="form-group">
// // // //           <label className="form-label">Request Body (JSON)</label>
// // // //           <button type="button" onClick={openEditor} className="btn-edit-request">
// // // //             Edit Request Body
// // // //           </button>
// // // //         </div>

// // // //         {isEditorOpen && (
// // // //           <div className="modal-overlay">
// // // //             <div className="modal-container">
// // // //               <div className="modal-header">
// // // //                 <h2 className="modal-title">Edit Request Body</h2>
// // // //                 <button onClick={closeEditor} className="modal-close-button">
// // // //                   ✕
// // // //                 </button>
// // // //               </div>
// // // //               <div className="modal-body">
// // // //                 <JSONEditor
// // // //                   value={localWebhook.requestBody.bodyContent}
// // // //                   onChange={handleRequestBodyChange}
// // // //                   variables={[]}
// // // //                   onValidate={handleValidateJSON}
// // // //                 />
// // // //               </div>
// // // //               <div className="modal-actions">
// // // //                 <button onClick={closeEditor} className="btn-close-modal">
// // // //                   Close
// // // //                 </button>
// // // //                 <button
// // // //                   onClick={() => {
// // // //                     if (handleValidateJSON(localWebhook.requestBody.bodyContent)) {
// // // //                       closeEditor();
// // // //                       toast.success("Request body updated!");
// // // //                     } else {
// // // //                       alert('Please fix JSON validation errors before closing');
// // // //                     }
// // // //                   }}
// // // //                   className="btn-save-modal"
// // // //                 >
// // // //                   Save Changes
// // // //                 </button>
// // // //               </div>
// // // //             </div>
// // // //           </div>
// // // //         )}

// // // //         {/* Test and Save buttons */}
// // // //         <div className="button-group">
// // // //           <button type="button" onClick={handleTestClick} className="btn-test">
// // // //             Test
// // // //           </button>
// // // //           <button type="submit" className="btn-save">
// // // //             Save
// // // //           </button>
// // // //         </div>
// // // //       </form>
// // // //     </div>
// // // //   );
// // // // };

// // // // export default WebhookAuth;



