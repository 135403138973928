// Funnel.jsx
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { ResponsiveFunnel } from '@nivo/funnel';
import LoadingSpinner from '../components/LoadingSpinner';
import { DimensionContext } from '../components/ResponsiveWrapper';
import { useDataset } from '../components/DataFetcher';
import FunnelConfig from '../components/FunnelConfig';
import { Button } from '@mantine/core';
import { askOracle } from '../components/AskOracle';

const FunnelPage = () => {
    const { width, height } = useContext(DimensionContext);
    const { data: dataset, isLoading } = useDataset();

    const [funnelStages, setFunnelStages] = useState([]);
    const [isConfigOpen, setIsConfigOpen] = useState(false);
    const [initialStages, setInitialStages] = useState([]);

    const toggleConfigModal = () => {
        console.log('toggleConfigModal called. isConfigOpen before toggle:', isConfigOpen);
        setIsConfigOpen((prev) => !prev);
    };

    const saveFunnelConfig = (stages) => {
        console.log('saveFunnelConfig called with stages:', stages);
        setFunnelStages(stages);
        setInitialStages(stages);
        toggleConfigModal();
    };

    useEffect(() => {
        console.log('Fetching funnel configuration from askOracle...');
        askOracle('oracle/askFunnelConfig')
            .then((data) => {
                console.log('askOracle response data:', data);
                // Assuming 'data' contains an array of stages with 'key' and 'label'
                const stagesFromServer = data.map((stage) => ({
                    value: stage.key,
                    label: stage.label,
                }));
                console.log('Processed stagesFromServer:', stagesFromServer);
                setFunnelStages(stagesFromServer);
                setInitialStages(stagesFromServer);
            })
            .catch((error) => {
                console.error('Failed to fetch funnel configuration:', error);
            });
    }, []);

    useEffect(() => {
        console.log('funnelStages updated:', funnelStages);
    }, [funnelStages]);

    useEffect(() => {
        console.log('initialStages updated:', initialStages);
    }, [initialStages]);

    
    
    const funnelData = useMemo(() => {
        if (!dataset || !funnelStages.length) {
            console.log('No dataset or funnelStages is empty.');
            return [];
        }
        const data = funnelStages.map((stage, index) => ({
            id: `stage${index + 1}`,
            value: dataset.UniqueURL[stage.value] || 0,
            label: stage.label,
        }));
        console.log('Computed funnelData:', data);
        return data;
    }, [dataset, funnelStages]);

    return (
        <div>
          <div className="config-button-container">
            <Button className="config-button" onClick={toggleConfigModal}>
                Configure
            </Button>
            </div>
            <h1 className="h1 negTop">Conversion Funnel</h1>
            {isLoading ? (
                <LoadingSpinner />
            ) : funnelData.length > 0 ? (
                <MyResponsiveFunnel data={funnelData} width={width} height={height} />
            ) : (
                <div>Please configure the funnel stages to view the visualization.</div>
            )}
            {console.log('Rendering FunnelConfig. isConfigOpen:', isConfigOpen, 'initialStages:', initialStages)}
            <FunnelConfig
                isOpen={isConfigOpen}
                onClose={toggleConfigModal}
                onSave={saveFunnelConfig}
                initialStages={initialStages}
            />
        </div>
    );
};

// const CustomLabelLayer = ({ parts }) => {
//     return parts.map((part) => (
//         <text
//             value={part.id}
//             x={part.x}
//             y={part.y - 20}
//             textAnchor="middle"
//             dominantBaseline="central"
//             style={{
//                 fill: '#fff',
//                 fontSize: '20px',
//                 backgroundColor: 'white',
//             }}
//         >
//             {`${part.data.label}`}
//         </text>
//     ));
// };

const CombinedLabelLayer = ({ parts }) => {
    return parts.map((part) => {
      // Adjust these as needed
      const smallLabelOffset = 14;  // how far above the center the small label goes
      const bigValueOffset   = 20;  // how far below the center the big value goes
  
      return (
        <text
          key={part.id}
          x={part.x}
          y={part.y}
          textAnchor="middle"
          dominantBaseline="central"
          style={{ pointerEvents: 'none' }} // so it doesn't block hover
        >
          {/* Small URL/title above center */}
          <tspan
            x={part.x}
            dy={-smallLabelOffset}
            style={{ fill: '#fff', fontSize: '14px' }}
          >
            {part.data.label}
          </tspan>
  
          {/* Big numeric value below center */}
          <tspan
            x={part.x}
            dy={smallLabelOffset + bigValueOffset}
            style={{ fill: '#fff', fontSize: '24px', fontWeight: 'bold' }}
          >
            {part.data.value}
          </tspan>
        </text>
      );
    });
  };
  
const conversionRateLayer = (funnelData, height) => ({ labelTextColor }) => {
    const adjustedHeight = height * 0.9;
    return funnelData
        .map((stage, i) => {
            if (i === funnelData.length - 1) return null;

            const rate = ((funnelData[i + 1].value / stage.value) * 100).toFixed(2);
            const segmentHeight = adjustedHeight / (funnelData.length - 1);
            const yPosition = segmentHeight * i + segmentHeight / 2;
            const xPosition = 100;

            return (
                <text
                    value={stage.id}
                    x={xPosition}
                    y={yPosition}
                    textAnchor="middle"
                    style={{ fill: labelTextColor || 'black', fontSize: '32px' }}
                >
                    {rate}%
                </text>
            );
        })
        .filter((element) => element !== null);
};

const theme = {
    labels: {
        text: {
            fontSize: 24,
        },
    },
};

const MyResponsiveFunnel = ({ data, width, height }) => (
    <div className="visualization-container" style={{ width, height }}>
        <ResponsiveFunnel
            data={data}
            theme={theme}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            layers={[
                'grid',
                'axis',
                'bars',
                'separators',
                'parts',
                // 'labels',
                'annotations',
                CombinedLabelLayer,
                conversionRateLayer(data, height),
            ]}
            shapeBlending={0.5}
            valueFormat="d"
            colors={[
                '#de6736',
                '#e08a3c',
                '#ebb844',
                '#7174b0',
                '#3b3484',
                '#762861',
                '#2c294b',
                '#80ba55',
                '#c5316a',
                '#ebe5df',
                '#958e86',
                '#5d3c23',
            ]}
            borderWidth={20}
            borderOpacity={0.65}
            labelColor={{ from: 'color', modifiers: [['brighter', 3]] }}
            beforeSeparatorLength={100}
            beforeSeparatorOffset={20}
            afterSeparatorLength={100}
            afterSeparatorOffset={20}
            currentPartSizeExtension={24}
            currentBorderWidth={85}
            motionConfig="molasses"
        />
    </div>
);

export default FunnelPage;


