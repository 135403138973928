import React, { useState, useEffect } from 'react';
import WebhookList from '../components/webhooks/WebhookList';
import WebhookAuth from '../components/webhooks/WebhookAuth';
import WebhookTest from '../components/webhooks/WebhookTest';
import WebhookHistory from '../components/webhooks/WebhookHistory';
import {
  getWebhooks,
  createWebhook,
  updateWebhook,
  deleteWebhook,
  testWebhook,
  getWebhookTemplates,
} from '../components/webhooks/WebhookAPI';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WebhooksPage = () => {
  const [webhooks, setWebhooks] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedWebhook, setSelectedWebhook] = useState(null);
  const [testResult, setTestResult] = useState(null);
  const [historyWebhookId, setHistoryWebhookId] = useState(null);

  useEffect(() => {
    fetchWebhooks();
    fetchTemplates();
  }, []);

  const fetchWebhooks = async () => {
    try {
      const data = await getWebhooks();
      setWebhooks(data);
    } catch (error) {
      console.error('Error fetching webhooks:', error);
    }
  };

  const fetchTemplates = async () => {
    try {
      const data = await getWebhookTemplates();
      setTemplates(data);
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  const handleSubmit = async (webhook) => {
    try {
      if (webhook.id || webhook.ID) {
        const id = webhook.id || webhook.ID;
        await updateWebhook(id, webhook);
      } else {
        await createWebhook(webhook);
      }
      setSelectedWebhook(null);
      fetchWebhooks();
    } catch (error) {
      console.error('Error saving webhook:', error);
    }
  };

  const handleTest = async (id) => {
    const webhook = webhooks.find((wh) => (wh.id || wh.ID) === id);
    if (!webhook) return;
    try {
      const result = await testWebhook(id);
      setTestResult(result);
    } catch (error) {
      console.error('Error testing webhook:', error);
    }
  };

  const handleEdit = (name) => {
    console.log(`[handleEdit] Called with name: ${name} (type: ${typeof name}).`);
  
    console.log(
      `[handleEdit] Current webhooks:`,
      webhooks.map((wh) => ({
        id: wh.id || wh.ID,
        name: wh.name || wh.Name,
        type: typeof (wh.name || wh.Name),
      }))
    );
  
    // Find the webhook by matching its name.
    const webhook = webhooks.find(
      (wh) => String(wh.name || wh.Name) === String(name)
    );
  
    if (!webhook) {
      console.error(
        `[handleEdit] Webhook not found for name: ${name}. Available names: ${webhooks
          .map((wh) => String(wh.name || wh.Name))
          .join(', ')}`
      );
      return;
    }
  
    console.log(`[handleEdit] Found webhook for name: ${name}`, webhook);
  
    setSelectedWebhook(webhook);
    setHistoryWebhookId(name); // or update this if needed to reflect your new identifier
  };
  
  const handleDelete = async (id) => {
    try {
      await deleteWebhook(id);
      fetchWebhooks();
    } catch (error) {
      console.error('Error deleting webhook:', error);
    }
  };

  const handleStatusToggle = async (id, newStatus) => {
    const normalizedId = id || '';
    if (!normalizedId) {
      console.error('No valid id provided for status toggle.');
      return;
    }
  
    const webhook = webhooks.find(
      (wh) => String(wh.id || wh.ID || wh._id) === String(normalizedId)
    );
    if (!webhook) {
      console.error(`Webhook not found for id: ${normalizedId}`);
      return;
    }
  
    const updatedWebhook = { ...webhook, status: newStatus };
    try {
      await updateWebhook(normalizedId, updatedWebhook);
      fetchWebhooks();
    } catch (error) {
      console.error('Error toggling status:', error);
    }
  };  
  

  const closeTestResult = () => {
    setTestResult(null);
  };

  return (
    <div className="webhooks-page-container">
      <h1 className="webhooks-title">Webhooks</h1>

      {/* Create / Edit Webhook Form */}
      <div className="create-webhook-card">
        <h2 className="webhooks-subtitle">
          {selectedWebhook ? 'Edit Webhook' : 'Create New Webhook'}
        </h2>
        <WebhookAuth
          webhook={selectedWebhook}
          templates={templates}
          onSubmit={handleSubmit}
          onTest={(name) => handleTest(name)}
        />
      </div>

      {/* Test Results */}
      {testResult && (
        <div className="webhooks-test-result">
          <WebhookTest result={testResult} onClose={closeTestResult} />
        </div>
      )}

      {/* History */}
      {historyWebhookId && (
        <div className="webhooks-history">
          <WebhookHistory webhookId={historyWebhookId} />
        </div>
      )}

      {/* Existing Webhooks Table */}
      <div className="existing-webhooks-card">
        <h2 className="webhooks-subtitle">Existing Webhooks</h2>
        <WebhookList
          webhooks={webhooks}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onTest={handleTest}
          onStatusToggle={handleStatusToggle}
        />
      </div>
       {/* Toast container */}
       <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
      />
    </div>
  );
};

export default WebhooksPage;
