import React from 'react';

const StatusBadge = ({ status }) => {
  const badgeStyle = status === 'active' ? 'bg-green-500' : 'bg-gray-500';
  return (
    <span className={`text-white px-2 py-1 rounded ${badgeStyle}`}>
      {status}
    </span>
  );
};

export default StatusBadge;
