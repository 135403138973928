import React from 'react';

const WebhookTest = ({ result, onClose }) => {
  return (
    <div className="border p-4">
      <h3 className="text-lg font-bold">Test Result</h3>
      <pre>{JSON.stringify(result, null, 2)}</pre>
      <button onClick={onClose} className="bg-blue-500 text-white p-2">Close</button>
    </div>
  );
};

export default WebhookTest;
