import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import LoadingSpinner from './LoadingSpinner';
import SanitizedInput from './SanitizedInput';
import { askOracle, tellOracle } from './AskOracle';
import { useNavigate } from 'react-router-dom';

console.log('Initializing Signup.jsx');

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';

const GO_BASE_URL = process.env.REACT_APP_GO_BASE;

const AccountSetup = ({ email, navigate }) => {
  const [step, setStep] = useState('choice');
  const [formData, setFormData] = useState({
    companyName: '',
    domain: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const setupAccountFlow = async () => {
    setIsLoading(true);
    setError('');
    
    try {
      // Get UserID from localStorage
      const userID = localStorage.getItem('UserID');
      if (!userID) {
        throw new Error("No UserID found in localStorage");
      }
      
      // 1. Create organization
      await tellOracle('oracle/tellOrgs', {
        name: formData.companyName,
        domain: formData.domain,
        subscription: 'Hike'
      });
      
      // 2. Get orgs to find the newly created one
      const orgsResponse = await askOracle('oracle/askOrgs');
      console.log('Available orgs:', orgsResponse);
      
      // Find our org by name and domain
      const newOrg = orgsResponse.find(org => 
        org.Name === formData.companyName && 
        org.Domain === formData.domain
      );
      
      if (!newOrg || !newOrg.ID) {
        throw new Error('Could not find newly created organization');
      }
      
      console.log('Found new org:', newOrg);

      // 3. Create org member with the found org ID
      const memberPayload = {
        orgId: newOrg.ID,
        userId: userID
      };
      console.log('Creating org member with payload:', memberPayload);
      await tellOracle('oracle/tellOrgMembers', memberPayload);

      // 4. Run successLoginFlow pattern
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error("No token found");
      }

      // Clear existing data
      localStorage.removeItem('Organizations');
      localStorage.removeItem('dataset');

      // Re-set token and userID
      localStorage.setItem('authToken', token);
      localStorage.setItem('UserID', userID);

      // Get fresh data
      const orgsData = await askOracle(`oracle/askLogin?ID=${userID}`);
      console.log('Login data received:', orgsData);

      if (!orgsData || Object.keys(orgsData).length === 0) {
        console.error("No organization data received from Oracle");
        return;
      }

      // Set user role
      if (orgsData.UserRole) {
        localStorage.setItem('UserRole', orgsData.UserRole);
      } else {
        console.error("No user role found in response");
      }

      // Set org data
      if (orgsData.orgs) {
        localStorage.setItem('Organizations', JSON.stringify(orgsData.orgs));
        const activeOrg = orgsData.orgs[0];
        if (activeOrg) {
          localStorage.setItem('ActiveOrgID', activeOrg.OrgID);
        }
      }

      // Send notification email
      const emailPayload = {
        to: "cheerful@outlook.com",
        subject: "New Account Created",
        htmlContent: `
          <h1>New Account Created</h1>
          <p>Please update the org middleware for this domain to allow traffic</p>
          <p><strong>Company:</strong> ${formData.companyName}</p>
          <p><strong>Domain:</strong> ${formData.domain}</p>
          <p><strong>User Email:</strong> ${email}</p>
        `
      };
      
      await axios.post(`${GO_BASE_URL}/email`, emailPayload, {
        headers: {'Content-Type': 'application/json'},
        withCredentials: true
      });

      // Complete setup
      window.dispatchEvent(new Event('localStorageUpdate'));
      setSuccess('Account created successfully!');

      console.log('Final localStorage state:', {
        Organizations: localStorage.getItem('Organizations'),
        ActiveOrgID: localStorage.getItem('ActiveOrgID'),
        UserRole: localStorage.getItem('UserRole'),
        UserID: localStorage.getItem('UserID'),
        AuthToken: localStorage.getItem('authToken')
      });

      setTimeout(() => navigate('/admin'), 2000);

    } catch (error) {
      console.error('Account setup error:', error);
      setError('Failed to create account. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateAccount = async () => {
    await setupAccountFlow();
  };

  const handleJoinRequest = async () => {
    setIsLoading(true);
    setError('');
    
    try {
      // Send join request email
      const emailPayload = {
        to: "cheerful@outlook.com",
        subject: "Organization Join Request",
        htmlContent: `
          <h1>New Join Request</h1>
          <p>A user has requested to join an organization.</p>
          <p><strong>Company:</strong> ${formData.companyName}</p>
          <p><strong>Domain:</strong> ${formData.domain}</p>
          <p><strong>User Email:</strong> ${email}</p>
        `
      };
      
      await axios.post(`${GO_BASE_URL}/email`, emailPayload, {
        headers: {'Content-Type': 'application/json'},
        withCredentials: true
      });

      setSuccess('Join request sent successfully! You will be contacted shortly.');
      setTimeout(() => navigate('/auth'), 2000);

    } catch (error) {
      console.error('Join request error:', error);
      setError('Failed to send join request. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="auth-form-container"
    >
      {step === 'choice' && (
        <div>
          <h2>Welcome to Teraace!</h2>
          <p>Would you like to create a new account or join an existing one?</p>
          <motion.button
            className="auth-btn-signup"
            onClick={() => setStep('create')}
          >
            Create New Account
          </motion.button>
          <motion.button
            className="auth-btn-signup"
            onClick={() => setStep('join')}
          >
            Join Existing Account
          </motion.button>
        </div>
      )}

      {step === 'create' && (
        <div>
          <h2>Create New Account</h2>
          <form onSubmit={e => e.preventDefault()}>
            <SanitizedInput
              className="auth-input-email"
              type="text"
              placeholder="Company Name"
              value={formData.companyName}
              onChange={e => setFormData({...formData, companyName: e.target.value})}
            />
            <SanitizedInput
              className="auth-input-email"
              type="text"
              placeholder="Domain (e.g., company.com)"
              value={formData.domain}
              onChange={e => setFormData({...formData, domain: e.target.value})}
            />
            {error && <div className="auth-error">{error}</div>}
            {success && <div className="auth-success">{success}</div>}
            <motion.button
              className="auth-btn-signin"
              onClick={handleCreateAccount}
              disabled={isLoading || !formData.companyName || !formData.domain}
            >
              {isLoading ? <LoadingSpinner /> : 'Create Account'}
            </motion.button>
          </form>
        </div>
      )}

      {step === 'join' && (
        <div>
          <h2>Join Existing Account</h2>
          <form onSubmit={e => e.preventDefault()}>
            <SanitizedInput
              className="auth-input-email"
              type="text"
              placeholder="Company Name"
              value={formData.companyName}
              onChange={e => setFormData({...formData, companyName: e.target.value})}
            />
            <SanitizedInput
              className="auth-input-email"
              type="text"
              placeholder="Domain (e.g., company.com)"
              value={formData.domain}
              onChange={e => setFormData({...formData, domain: e.target.value})}
            />
            {error && <div className="auth-error">{error}</div>}
            {success && <div className="auth-success">{success}</div>}
            <motion.button
              className="auth-btn-signin"
              onClick={handleJoinRequest}
              disabled={isLoading || !formData.companyName || !formData.domain}
            >
              {isLoading ? <LoadingSpinner /> : 'Request to Join'}
            </motion.button>
          </form>
        </div>
      )}
    </motion.div>
  );
};

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  console.log('Initializing Auth function in jsx');

  const verifyAuthToken = useCallback(async () => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      try {
        const response = await axios.post(`${GO_BASE_URL}/verify-token`, {}, {
          headers: {
            'Authorization': `Bearer ${authToken}`
          },    
          withCredentials: true
        });
        setIsLoggedIn(response.data.valid);
      } catch (error) {
        setIsLoggedIn(false);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('authToken');
          console.log('User is not logged in');
          navigate("/signup");
        }
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [navigate]);

  const handleEmailPasswordAuth = async () => {
    setIsLoading(true);
    try {
      // 1. Create user account
      const signupResponse = await axios.post(
        `${GO_BASE_URL}/signup`,
        { email, password },
        { withCredentials: true }
      );
      console.log('User creation response:', signupResponse.data);

      // 2. Store auth token and user ID
      const { token, UserID } = signupResponse.data;
      if (!token || !UserID) {
        throw new Error('Missing token or UserID in signup response');
      }

      await localStorage.setItem('authToken', token);
      await localStorage.setItem('UserID', UserID);
      console.log('Stored credentials:', {
        token: localStorage.getItem('authToken'),
        UserID: localStorage.getItem('UserID')
      });

      // 3. Set logged in state
      setIsLoggedIn(true);

    } catch (error) {
      console.error('User creation error:', error);
      setErrorMessage('Failed to create user account');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="auth-container">
      {console.log('Rendering signup-container')}
      {isLoading && <LoadingSpinner />}
      {errorMessage && <div className="auth-error">{errorMessage}</div>}
      {isLoggedIn ? (
        <AccountSetup 
          email={email} 
          navigate={navigate}
        />
      ) : (
        <>
          <div className={`auth-form-container ${isLoading ? 'hidden' : ''}`}>
            <h1>New User Sign Up</h1>
            <motion.img 
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              src="/logo-Teraace-250.png" 
              alt="logo" 
              className="auth-logo" 
            />
            
            <form className="auth-form" onSubmit={(e) => e.preventDefault()}>
              <SanitizedInput
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
                className="auth-input-email email"
                type="email"
                id="email"
                name="email"
                autoComplete="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <SanitizedInput
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                className="auth-input-password password"
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <motion.button 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                className="auth-btn-signin"
                onClick={handleEmailPasswordAuth}
              >
                Sign Up
              </motion.button>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default Signup;







// import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
// import { motion } from 'framer-motion';
// import LoadingSpinner from './LoadingSpinner';
// import SanitizedInput from './SanitizedInput';
// import { askOracle, tellOracle } from './AskOracle';
// import { useNavigate } from 'react-router-dom';

// console.log('Initializing Signup.jsx');

// axios.defaults.withCredentials = true;
// axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
// axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';

// const GO_BASE_URL = process.env.REACT_APP_GO_BASE;

// const AccountSetup = ({ email, navigate }) => {
//   const [step, setStep] = useState('choice');
//   const [formData, setFormData] = useState({
//     companyName: '',
//     domain: '',
//   });
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');

//   const setupAccountFlow = async () => {
//     setIsLoading(true);
//     setError('');
    
//     try {
//       // Get UserID from localStorage
//       const userID = localStorage.getItem('UserID');
//       if (!userID) {
//         throw new Error("No UserID found in localStorage");
//       }
      
//       // 1. Create organization
//       await tellOracle('oracle/tellOrgs', {
//         name: formData.companyName,
//         domain: formData.domain,
//         subscription: 'Hike'
//       });
      
//       // 2. Get orgs to find the newly created one
//       const orgsResponse = await askOracle('oracle/askOrgs');
//       console.log('Available orgs:', orgsResponse);
      
//       // Find our org by name and domain
//       const newOrg = orgsResponse.find(org => 
//         org.Name === formData.companyName && 
//         org.Domain === formData.domain
//       );
      
//       if (!newOrg || !newOrg.ID) {
//         throw new Error('Could not find newly created organization');
//       }
      
//       console.log('Found new org:', newOrg);

//       // 3. Create org member with the found org ID
//       const memberPayload = {
//         orgId: newOrg.ID,
//         userId: userID
//       };
//       console.log('Creating org member with payload:', memberPayload);
//       await tellOracle('oracle/tellOrgMembers', memberPayload);

//       // 4. Run successLoginFlow pattern
//       const token = localStorage.getItem('authToken');
//       if (!token) {
//         throw new Error("No token found");
//       }

//       // Clear existing data
//       localStorage.removeItem('Organizations');
//       localStorage.removeItem('dataset');

//       // Re-set token and userID
//       localStorage.setItem('authToken', token);
//       localStorage.setItem('UserID', userID);

//       // Get fresh data
//       const orgsData = await askOracle(`oracle/askLogin?ID=${userID}`);
//       console.log('Login data received:', orgsData);

//       if (!orgsData || Object.keys(orgsData).length === 0) {
//         console.error("No organization data received from Oracle");
//         return;
//       }

//       // Set user role
//       if (orgsData.UserRole) {
//         localStorage.setItem('UserRole', orgsData.UserRole);
//       } else {
//         console.error("No user role found in response");
//       }

//       // Set org data
//       if (orgsData.orgs) {
//         localStorage.setItem('Organizations', JSON.stringify(orgsData.orgs));
//         const activeOrg = orgsData.orgs[0];
//         if (activeOrg) {
//           localStorage.setItem('ActiveOrgID', activeOrg.OrgID);
//         }
//       }

//       // Send notification email
//       const emailPayload = {
//         to: "cheerful@outlook.com",
//         subject: "New Account Created",
//         htmlContent: `
//           <h1>New Account Created</h1>
//           <p>Please update the org middleware for this domain to allow traffic</p>
//           <p><strong>Company:</strong> ${formData.companyName}</p>
//           <p><strong>Domain:</strong> ${formData.domain}</p>
//           <p><strong>User Email:</strong> ${email}</p>
//         `
//       };
      
//       await axios.post(`${GO_BASE_URL}/email`, emailPayload, {
//         headers: {'Content-Type': 'application/json'},
//         withCredentials: true
//       });

//       // Complete setup
//       window.dispatchEvent(new Event('localStorageUpdate'));
//       setSuccess('Account created successfully!');

//       console.log('Final localStorage state:', {
//         Organizations: localStorage.getItem('Organizations'),
//         ActiveOrgID: localStorage.getItem('ActiveOrgID'),
//         UserRole: localStorage.getItem('UserRole'),
//         UserID: localStorage.getItem('UserID'),
//         AuthToken: localStorage.getItem('authToken')
//       });

//       setTimeout(() => navigate('/admin'), 2000);

//     } catch (error) {
//       console.error('Account setup error:', error);
//       setError('Failed to create account. Please try again.');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleCreateAccount = async () => {
//     await setupAccountFlow();
//   };

//   return (
//     <motion.div 
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1 }}
//       className="auth-form-container"
//     >
//       {step === 'choice' && (
//         <div>
//           <h2>Welcome to Teraace!</h2>
//           <p>Would you like to create a new account or join an existing one?</p>
//           <motion.button
//             className="auth-btn-signup"
//             onClick={() => setStep('create')}
//           >
//             Create New Account
//           </motion.button>
//           <motion.button
//             className="auth-btn-signup"
//             onClick={() => setStep('join')}
//           >
//             Join Existing Account
//           </motion.button>
//         </div>
//       )}

//       {step === 'create' && (
//         <div>
//           <h2>Create New Account</h2>
//           <form onSubmit={e => e.preventDefault()}>
//             <SanitizedInput
//               className="auth-input-email"
//               type="text"
//               placeholder="Company Name"
//               value={formData.companyName}
//               onChange={e => setFormData({...formData, companyName: e.target.value})}
//             />
//             <SanitizedInput
//               className="auth-input-email"
//               type="text"
//               placeholder="Domain (e.g., company.com)"
//               value={formData.domain}
//               onChange={e => setFormData({...formData, domain: e.target.value})}
//             />
//             {error && <div className="auth-error">{error}</div>}
//             {success && <div className="auth-success">{success}</div>}
//             <motion.button
//               className="auth-btn-signin"
//               onClick={handleCreateAccount}
//               disabled={isLoading || !formData.companyName || !formData.domain}
//             >
//               {isLoading ? <LoadingSpinner /> : 'Create Account'}
//             </motion.button>
//           </form>
//         </div>
//       )}
//     </motion.div>
//   );
// };

// const Signup = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');
//   const navigate = useNavigate();

//   console.log('Initializing Auth function in jsx');

//   const verifyAuthToken = useCallback(async () => {
//     const authToken = localStorage.getItem('authToken');
//     if (authToken) {
//       try {
//         const response = await axios.post(`${GO_BASE_URL}/verify-token`, {}, {
//           headers: {
//             'Authorization': `Bearer ${authToken}`
//           },    
//           withCredentials: true
//         });
//         setIsLoggedIn(response.data.valid);
//       } catch (error) {
//         setIsLoggedIn(false);
//         if (error.response && error.response.status === 401) {
//           localStorage.removeItem('authToken');
//           console.log('User is not logged in');
//           navigate("/signup");
//         }
//       }
//     } else {
//       setIsLoggedIn(false);
//     }
//   }, [navigate]);

//   const handleEmailPasswordAuth = async () => {
//     setIsLoading(true);
//     try {
//       // 1. Create user account
//       const signupResponse = await axios.post(
//         `${GO_BASE_URL}/signup`,
//         { email, password },
//         { withCredentials: true }
//       );
//       console.log('User creation response:', signupResponse.data);

//       // 2. Store auth token and user ID
//       const { token, UserID } = signupResponse.data;
//       if (!token || !UserID) {
//         throw new Error('Missing token or UserID in signup response');
//       }

//       await localStorage.setItem('authToken', token);
//       await localStorage.setItem('UserID', UserID);
//       console.log('Stored credentials:', {
//         token: localStorage.getItem('authToken'),
//         UserID: localStorage.getItem('UserID')
//       });

//       // 3. Set logged in state
//       setIsLoggedIn(true);

//     } catch (error) {
//       console.error('User creation error:', error);
//       setErrorMessage('Failed to create user account');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="auth-container">
//       {console.log('Rendering signup-container')}
//       {isLoading && <LoadingSpinner />}
//       {errorMessage && <div className="auth-error">{errorMessage}</div>}
//       {isLoggedIn ? (
//         <AccountSetup 
//           email={email} 
//           navigate={navigate}
//         />
//       ) : (
//         <>
//           <div className={`auth-form-container ${isLoading ? 'hidden' : ''}`}>
//             <h1>New User Sign Up</h1>
//             <motion.img 
//               initial={{ opacity: 0, y: -10 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ delay: 0.2 }}
//               src="/logo-Teraace-250.png" 
//               alt="logo" 
//               className="auth-logo" 
//             />
            
//             <form className="auth-form" onSubmit={(e) => e.preventDefault()}>
//               <SanitizedInput
//                 initial={{ opacity: 0, y: -10 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 transition={{ delay: 0.3 }}
//                 className="auth-input-email email"
//                 type="email"
//                 id="email"
//                 name="email"
//                 autoComplete="email"
//                 placeholder="Email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//               <SanitizedInput
//                 initial={{ opacity: 0, y: -10 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 transition={{ delay: 0.4 }}
//                 className="auth-input-password password"
//                 id="password"
//                 name="password"
//                 type="password"
//                 autoComplete="current-password"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//               />
//               <motion.button 
//                 initial={{ opacity: 0, y: 20 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 transition={{ delay: 0.4 }}
//                 className="auth-btn-signin"
//                 onClick={handleEmailPasswordAuth}
//               >
//                 Sign Up
//               </motion.button>
//             </form>
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default Signup;


// // import React, { useState, useEffect, useCallback } from 'react';
// // import axios from 'axios';
// // import { motion } from 'framer-motion';
// // import LoadingSpinner from './LoadingSpinner';
// // import SanitizedInput from './SanitizedInput';
// // import { askOracle, tellOracle } from './AskOracle';
// // import { useNavigate } from 'react-router-dom';

// // console.log('Initializing Signup.jsx');

// // axios.defaults.withCredentials = true;
// // axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
// // axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';

// // const GO_BASE_URL = process.env.REACT_APP_GO_BASE;

// // const AccountSetup = ({ email, navigate }) => {
// //   const [step, setStep] = useState('choice');
// //   const [formData, setFormData] = useState({
// //     companyName: '',
// //     domain: '',
// //   });
// //   const [isLoading, setIsLoading] = useState(false);
// //   const [error, setError] = useState('');
// //   const [success, setSuccess] = useState('');

// //   const setupAccountFlow = async () => {
// //     setIsLoading(true);
// //     setError('');
    
// //     try {
// //       // Get UserID from localStorage
// //       const userID = localStorage.getItem('UserID');
// //       if (!userID) {
// //         throw new Error("No UserID found in localStorage");
// //       }
      
// //       // 1. Create organization
// //       const orgResponse = await tellOracle('oracle/tellOrgs', {
// //         name: formData.companyName,
// //         domain: formData.domain,
// //         subscription: 'Hike'
// //       });
      
// //       console.log('Organization created:', orgResponse);
      
// //       // 2. Create org member connection
// //       await tellOracle('oracle/tellOrgMembers', {
// //         orgId: orgResponse.ID,
// //         userId: userID
// //       });
      
// //       // 3. Get fresh login data (following successLoginFlow pattern)
// //       const orgsData = await askOracle(`oracle/askLogin?ID=${userID}`);
// //       console.log('Login data received:', orgsData);
      
// //       if (!orgsData || Object.keys(orgsData).length === 0) {
// //         throw new Error("No organization data received from Oracle");
// //       }

// //       // 4. Clear existing organization data
// //       localStorage.removeItem('Organizations');
// //       localStorage.removeItem('dataset');
      
// //       // 5. Set user role and organization data
// //       if (orgsData.UserRole) {
// //         localStorage.setItem('UserRole', orgsData.UserRole);
// //       }
      
// //       if (orgsData.orgs && Array.isArray(orgsData.orgs)) {
// //         localStorage.setItem('Organizations', JSON.stringify(orgsData.orgs));
        
// //         const activeOrg = orgsData.orgs[0];
// //         if (activeOrg) {
// //           localStorage.setItem('ActiveOrgID', activeOrg.OrgID);
// //         }
// //       }
      
// //       // 6. Send notification email
// //       const emailPayload = {
// //         to: "cheerful@outlook.com",
// //         subject: "New Account Created",
// //         htmlContent: `
// //           <h1>New Account Created</h1>
// //           <p>Please update the org middleware for this domain to allow traffic</p>
// //           <p><strong>Company:</strong> ${formData.companyName}</p>
// //           <p><strong>Domain:</strong> ${formData.domain}</p>
// //           <p><strong>User Email:</strong> ${email}</p>
// //         `
// //       };
      
// //       await axios.post(`${GO_BASE_URL}/email`, emailPayload, {
// //         headers: {'Content-Type': 'application/json'},
// //         withCredentials: true
// //       });

// //       // 7. Dispatch event and set success state
// //       window.dispatchEvent(new Event('localStorageUpdate'));
// //       setSuccess('Account created successfully!');
      
// //       // Log final state before navigation
// //       console.log('Final localStorage state:', {
// //         Organizations: localStorage.getItem('Organizations'),
// //         ActiveOrgID: localStorage.getItem('ActiveOrgID'),
// //         UserRole: localStorage.getItem('UserRole'),
// //         UserID: localStorage.getItem('UserID'),
// //         AuthToken: localStorage.getItem('authToken')
// //       });

// //       // 8. Navigate after short delay
// //       setTimeout(() => navigate('/admin'), 2000);

// //     } catch (error) {
// //       console.error('Account setup error:', error);
// //       setError('Failed to create account. Please try again.');
// //     } finally {
// //       setIsLoading(false);
// //     }
// //   };

// //   const handleCreateAccount = async () => {
// //     await setupAccountFlow();
// //   };

// //   return (
// //     <motion.div 
// //       initial={{ opacity: 0 }}
// //       animate={{ opacity: 1 }}
// //       className="auth-form-container"
// //     >
// //       {step === 'choice' && (
// //         <div>
// //           <h2>Welcome to Teraace!</h2>
// //           <p>Would you like to create a new account or join an existing one?</p>
// //           <motion.button
// //             className="auth-btn-signup"
// //             onClick={() => setStep('create')}
// //           >
// //             Create New Account
// //           </motion.button>
// //           <motion.button
// //             className="auth-btn-signup"
// //             onClick={() => setStep('join')}
// //           >
// //             Join Existing Account
// //           </motion.button>
// //         </div>
// //       )}

// //       {step === 'create' && (
// //         <div>
// //           <h2>Create New Account</h2>
// //           <form onSubmit={e => e.preventDefault()}>
// //             <SanitizedInput
// //               className="auth-input-email"
// //               type="text"
// //               placeholder="Company Name"
// //               value={formData.companyName}
// //               onChange={e => setFormData({...formData, companyName: e.target.value})}
// //             />
// //             <SanitizedInput
// //               className="auth-input-email"
// //               type="text"
// //               placeholder="Domain (e.g., company.com)"
// //               value={formData.domain}
// //               onChange={e => setFormData({...formData, domain: e.target.value})}
// //             />
// //             {error && <div className="auth-error">{error}</div>}
// //             {success && <div className="auth-success">{success}</div>}
// //             <motion.button
// //               className="auth-btn-signin"
// //               onClick={handleCreateAccount}
// //               disabled={isLoading || !formData.companyName || !formData.domain}
// //             >
// //               {isLoading ? <LoadingSpinner /> : 'Create Account'}
// //             </motion.button>
// //           </form>
// //         </div>
// //       )}
// //     </motion.div>
// //   );
// // };

// // const Signup = () => {
// //   const [email, setEmail] = useState('');
// //   const [password, setPassword] = useState('');
// //   const [isLoggedIn, setIsLoggedIn] = useState(false);
// //   const [isLoading, setIsLoading] = useState(false);
// //   const [errorMessage, setErrorMessage] = useState('');
// //   const navigate = useNavigate();

// //   console.log('Initializing Auth function in jsx');

// //   const verifyAuthToken = useCallback(async () => {
// //     const authToken = localStorage.getItem('authToken');
// //     if (authToken) {
// //       try {
// //         const response = await axios.post(`${GO_BASE_URL}/verify-token`, {}, {
// //           headers: {
// //             'Authorization': `Bearer ${authToken}`
// //           },    
// //           withCredentials: true
// //         });
// //         setIsLoggedIn(response.data.valid);
// //       } catch (error) {
// //         setIsLoggedIn(false);
// //         if (error.response && error.response.status === 401) {
// //           localStorage.removeItem('authToken');
// //           console.log('User is not logged in');
// //           navigate("/signup");
// //         }
// //       }
// //     } else {
// //       setIsLoggedIn(false);
// //     }
// //   }, [navigate]);

// //   const handleEmailPasswordAuth = async () => {
// //     setIsLoading(true);
// //     try {
// //       // 1. Create user account
// //       const signupResponse = await axios.post(
// //         `${GO_BASE_URL}/signup`,
// //         { email, password },
// //         { withCredentials: true }
// //       );
// //       console.log('User creation response:', signupResponse.data);

// //       // 2. Store auth token and user ID
// //       const { token, UserID } = signupResponse.data;
// //       if (!token || !UserID) {
// //         throw new Error('Missing token or UserID in signup response');
// //       }

// //       await localStorage.setItem('authToken', token);
// //       await localStorage.setItem('UserID', UserID);
// //       console.log('Stored credentials:', {
// //         token: localStorage.getItem('authToken'),
// //         UserID: localStorage.getItem('UserID')
// //       });

// //       // 3. Set logged in state
// //       setIsLoggedIn(true);

// //     } catch (error) {
// //       console.error('User creation error:', error);
// //       setErrorMessage('Failed to create user account');
// //     } finally {
// //       setIsLoading(false);
// //     }
// //   };

// //   return (
// //     <div className="auth-container">
// //       {console.log('Rendering signup-container')}
// //       {isLoading && <LoadingSpinner />}
// //       {errorMessage && <div className="auth-error">{errorMessage}</div>}
// //       {isLoggedIn ? (
// //         <AccountSetup 
// //           email={email} 
// //           navigate={navigate}
// //         />
// //       ) : (
// //         <>
// //           <div className={`auth-form-container ${isLoading ? 'hidden' : ''}`}>
// //             <h1>New User Sign Up</h1>
// //             <motion.img 
// //               initial={{ opacity: 0, y: -10 }}
// //               animate={{ opacity: 1, y: 0 }}
// //               transition={{ delay: 0.2 }}
// //               src="/logo-Teraace-250.png" 
// //               alt="logo" 
// //               className="auth-logo" 
// //             />
            
// //             <form className="auth-form" onSubmit={(e) => e.preventDefault()}>
// //               <SanitizedInput
// //                 initial={{ opacity: 0, y: -10 }}
// //                 animate={{ opacity: 1, y: 0 }}
// //                 transition={{ delay: 0.3 }}
// //                 className="auth-input-email email"
// //                 type="email"
// //                 id="email"
// //                 name="email"
// //                 autoComplete="email"
// //                 placeholder="Email"
// //                 value={email}
// //                 onChange={(e) => setEmail(e.target.value)}
// //               />
// //               <SanitizedInput
// //                 initial={{ opacity: 0, y: -10 }}
// //                 animate={{ opacity: 1, y: 0 }}
// //                 transition={{ delay: 0.4 }}
// //                 className="auth-input-password password"
// //                 id="password"
// //                 name="password"
// //                 type="password"
// //                 autoComplete="current-password"
// //                 placeholder="Password"
// //                 value={password}
// //                 onChange={(e) => setPassword(e.target.value)}
// //               />
// //               <motion.button 
// //                 initial={{ opacity: 0, y: 20 }}
// //                 animate={{ opacity: 1, y: 0 }}
// //                 transition={{ delay: 0.4 }}
// //                 className="auth-btn-signin"
// //                 onClick={handleEmailPasswordAuth}
// //               >
// //                 Sign Up
// //               </motion.button>
// //             </form>
// //           </div>
// //         </>
// //       )}
// //     </div>
// //   );
// // };

// // export default Signup;






// // // import React, { useState, useEffect, useCallback } from 'react';
// // // import axios from 'axios';
// // // // import { CookieJar } from 'tough-cookie';
// // // // import { useAuth } from './AuthContext'; 

// // // import { motion } from 'framer-motion';
// // // import LoadingSpinner from './LoadingSpinner';
// // // import SanitizedInput from './SanitizedInput';
// // // import { askOracle, tellOracle } from './AskOracle';
// // // import { useNavigate } from 'react-router-dom';

// // // console.log('Initializing Signup.jsx');

// // // axios.defaults.withCredentials = true;
// // // axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
// // // axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';

// // // const GO_BASE_URL = process.env.REACT_APP_GO_BASE;
// // // console.log(process.env.REACT_APP_GO_BASE);

// // // const AccountSetup = ({ email, onComplete, navigate }) => {

// // //   const [step, setStep] = useState('choice'); // 'choice', 'create', or 'join'
// // //   const [formData, setFormData] = useState({
// // //     companyName: '',
// // //     domain: '',
// // //   });
// // //   const [isLoading, setIsLoading] = useState(false);
// // //   const [error, setError] = useState('');
// // //   const [success, setSuccess] = useState('');

// // //   const handleCreateAccount = async () => {
// // //     setIsLoading(true);
// // //     setError('');
    
// // //     try {
// // //       // Create organization
// // //       const orgResponse = await tellOracle('oracle/tellOrgs', {
// // //         name: formData.companyName,
// // //         domain: formData.domain,
// // //         subscription: 'Hike'
// // //       });
  
// // //       console.log('Organization created:', orgResponse);
  
// // //       // Create org member connection
// // //       const userID = localStorage.getItem('UserID');
// // //       if (!userID) {
// // //         throw new Error("No UserID found in localStorage");
// // //       }
  
// // //       await tellOracle('oracle/tellOrgMembers', {
// // //         orgId: orgResponse.ID,
// // //         userId: userID
// // //       });
  
// // //       // Add a delay to allow backend processing
// // //       await new Promise(resolve => setTimeout(resolve, 1000));
  
// // //       // Now get a fresh copy of orgs data
// // //       const orgsData = await askOracle(`oracle/askLogin?ID=${userID}`);
// // //       console.log('Login data received:', orgsData);
  
// // //       if (!orgsData) {
// // //         throw new Error("No organization data received from Oracle");
// // //       }
  
// // //       // Clear existing localStorage data
// // //       localStorage.removeItem('Organizations');
// // //       localStorage.removeItem('ActiveOrgID');
// // //       localStorage.removeItem('dataset');
  
// // //       // Set the auth data
// // //       const token = localStorage.getItem('authToken');
// // //       if (token) {
// // //         localStorage.setItem('authToken', token);
// // //         localStorage.setItem('UserID', userID);
// // //       }
  
// // //       // Set role and org data
// // //       localStorage.setItem('UserRole', 'enduser');
      
// // //       if (orgsData.orgs && Array.isArray(orgsData.orgs)) {
// // //         localStorage.setItem('Organizations', JSON.stringify(orgsData.orgs));
// // //         if (orgsData.orgs.length > 0) {
// // //           localStorage.setItem('ActiveOrgID', orgsData.orgs[0].ID);
// // //         }
// // //       }
  
// // //       // Dispatch event for any listeners
// // //       window.dispatchEvent(new Event('localStorageUpdate'));
  
// // //       // Send email notification
// // //       const emailPayload = {
// // //         to: "cheerful@outlook.com",
// // //         subject: "New Account Created",
// // //         htmlContent: `
// // //           <h1>New Account Created</h1>
// // //           <p>Please update the org middleware for this domain to allow traffic</p>
// // //           <p><strong>Company:</strong> ${formData.companyName}</p>
// // //           <p><strong>Domain:</strong> ${formData.domain}</p>
// // //           <p><strong>User Email:</strong> ${email}</p>
// // //         `
// // //       };
      
// // //       await axios.post(`${GO_BASE_URL}/email`, emailPayload, {
// // //         headers: {'Content-Type': 'application/json'},
// // //         withCredentials: true
// // //       });
  
// // //       setSuccess('Account created successfully!');
      
// // //       // Log the final state before navigation
// // //       console.log('Final localStorage state:', {
// // //         Organizations: localStorage.getItem('Organizations'),
// // //         ActiveOrgID: localStorage.getItem('ActiveOrgID'),
// // //         UserRole: localStorage.getItem('UserRole'),
// // //         UserID: localStorage.getItem('UserID'),
// // //         AuthToken: localStorage.getItem('authToken')
// // //       });
  
// // //       // Navigate after everything is set
// // //       setTimeout(() => navigate('/admin'), 2000);
  
// // //     } catch (error) {
// // //       setError('Failed to create account. Please try again.');
// // //       console.error('Account creation error:', error);
// // //     } finally {
// // //       setIsLoading(false);
// // //     }
// // //   };


// // //   const handleJoinRequest = async () => {
// // //     setIsLoading(true);
// // //     setError('');
    
// // //     try {
// // //       const emailPayload = {
// // //         to: "cheerful@outlook.com",
// // //         subject: "Account Join Request",
// // //         htmlContent: `
// // //           <h1>New Join Request</h1>
// // //           <p><strong>Company:</strong> ${formData.companyName}</p>
// // //           <p><strong>Domain:</strong> ${formData.domain}</p>
// // //           <p><strong>User Email:</strong> ${email}</p>
// // //         `
// // //       };
      
// // //       await axios.post(`${GO_BASE_URL}/email`, emailPayload, {
// // //         headers: {'Content-Type': 'application/json'},
// // //         withCredentials: true
// // //       });

// // //       setSuccess('Join request sent successfully! We will contact you soon.');
// // //       setTimeout(() => onComplete(), 2000);
// // //     } catch (error) {
// // //       setError('Failed to send join request. Please try again.');
// // //     } finally {
// // //       setIsLoading(false);
// // //     }
// // //   };

// // //   return (
// // //     <motion.div 
// // //       initial={{ opacity: 0 }}
// // //       animate={{ opacity: 1 }}
// // //       className="auth-form-container"
// // //     >
// // //       {step === 'choice' && (
// // //         <div>
// // //           <h2>Welcome to Teraace!</h2>
// // //           <p>Would you like to create a new account or join an existing one?</p>
// // //           <motion.button
// // //             className="auth-btn-signup"
// // //             onClick={() => setStep('create')}
// // //           >
// // //             Create New Account
// // //           </motion.button>
// // //           <motion.button
// // //             className="auth-btn-signup"
// // //             onClick={() => setStep('join')}
// // //           >
// // //             Join Existing Account
// // //           </motion.button>
// // //         </div>
// // //       )}

// // //       {(step === 'create' || step === 'join') && (
// // //         <div>
// // //           <h2>{step === 'create' ? 'Create New Account' : 'Request to Join Account'}</h2>
// // //           <form onSubmit={e => e.preventDefault()}>
// // //             <SanitizedInput
// // //               className="auth-input-email"
// // //               type="text"
// // //               placeholder="Company Name"
// // //               value={formData.companyName}
// // //               onChange={e => setFormData({...formData, companyName: e.target.value})}
// // //             />
// // //             <SanitizedInput
// // //               className="auth-input-email"
// // //               type="text"
// // //               placeholder="Domain (e.g., company.com)"
// // //               value={formData.domain}
// // //               onChange={e => setFormData({...formData, domain: e.target.value})}
// // //             />
// // //             {error && <div className="auth-error">{error}</div>}
// // //             {success && <div className="auth-success">{success}</div>}
// // //             <motion.button
// // //               className="auth-btn-signin"
// // //               onClick={step === 'create' ? handleCreateAccount : handleJoinRequest}
// // //               disabled={isLoading || !formData.companyName || !formData.domain}
// // //             >
// // //               {isLoading ? <LoadingSpinner /> : (step === 'create' ? 'Create Account' : 'Send Join Request')}
// // //             </motion.button>
// // //           </form>
// // //         </div>
// // //       )}
// // //     </motion.div>
// // //   );
// // // };

// // // const Signup = () => {
// // //   const [email, setEmail] = useState('');
// // //   const [password, setPassword] = useState('');
// // //   const [isLoggedIn, setIsLoggedIn] = useState(false);
// // //   const [isLoading, setIsLoading] = useState(false);
// // //   const [errorMessage, setErrorMessage] = useState('');
// // //   const navigate = useNavigate();
// // // // const navigation = navigation();
// // //   console.log('Initializing Auth function in jsx');


// // // // const handleGoogleSignInResponse = async (response) => {
// // // //     // Ensure response object exists and has the expected structure
// // // //     console.log('Google Sign-In Response:', response);

// // // //     if (!response || !response.credential) {
// // // //         console.error('Invalid Google sign-in response');
// // // //         return;
// // // //     }

// // // //     try {
// // // //         const result = await axios.post(`${GO_BASE_URL}/google-login`, {
// // // //             idToken: response.credential,
// // // //         }, { withCredentials: true });
// // // //         console.log('handleGoogleSignInResponse', response);
// // // //         localStorage.setItem('authToken', result.data.token);
// // // //         setIsLoggedIn(true);
// // // //     } catch (error) {
// // // //         console.error('Google Authentication failed', error);
// // // //     }
// // // // };
      


// // // // useEffect(() => {
// // // //   console.log('code from 7:16');
// // // //   // Make an API call to backend to get the CSRF token from the response header
// // // //   axios.get(`${GO_BASE_URL}/init`, { withCredentials: true })
// // // //     .then(response => {
// // // //       console.log('Axios response:', response);
// // // //     })
// // // //     .catch(error => {
// // // //       console.error("Error initializing application:", error);
// // // //     });
// // // // }, []);

// // // const verifyAuthToken = useCallback(async () => {
// // //   const authToken = localStorage.getItem('authToken');

// // //   if (authToken) {
// // //     try {
// // //       const response = await axios.post(`${GO_BASE_URL}/verify-token`, {}, {
// // //         headers: {
// // //           'Authorization': `Bearer ${authToken}`
// // //         },    
// // //         withCredentials: true
// // //       });
// // //       setIsLoggedIn(response.data.valid);
// // //     } catch (error) {
// // //       setIsLoggedIn(false);
// // //       if (error.response && error.response.status === 401) {
// // //         localStorage.removeItem('authToken');  // Delete the token
// // //         console.log('User is not logged in');
// // //         navigate("/signup");
// // //       }
// // //     }
// // //   } else {
// // //     setIsLoggedIn(false);
// // //   }
// // // }, [navigate]);


// // //   // const { xsrfToken } = useAuth();  // <-- Use the context here

// // //   // const formData = new FormData();
// // //   // formData.append('email', email);
// // //   // formData.append('password', password);
// // //   // formData.append('xsrfToken', xsrfToken);

// // //   async function handleEmailPasswordAuth(isSignup) {
// // //     setIsLoading(true);
// // //     const endpoint = '/signup';
// // //     try {
// // //       const response = await axios.post(
// // //         `${GO_BASE_URL}${endpoint}`,
// // //         { email, password },
// // //         //   formData,
// // //         { withCredentials: true }
// // //       );
// // //       console.log('API Response:', response.data);
// // //       await localStorage.setItem('authToken', response.data.token);
// // //       console.log('handleEmailPasswordAuth');
// // //       console.log('Token directly after setting:', localStorage.getItem('authToken'));
// // //       // Check if the response contains the UserID and store it in localStorage
// // //       if (response.data.UserID) {
// // //         await localStorage.setItem('UserID', response.data.UserID);
// // //         console.log('UserID directly after setting:', localStorage.getItem('UserID'));
// // //         setIsLoggedIn(true); // Set isLoggedIn to true
// // //       } else {
// // //         console.error("No UserID found in the response");
// // //       }
// // //       // await verifyAuthToken(); // Verify the token immediately after setting it.
// // //       // console.log('verifiedAuthToken');
// // //     } catch (error) {
// // //       console.error('Authentication Error:', error);
// // //       setErrorMessage('Authentication failed');
// // //     }
// // //     setIsLoading(false);
// // //   }

// // // //   useEffect(() => {
// // // //     verifyAuthToken();
// // // //     console.log('verifyAuthToken')

// // // //     const loadGoogleSignInLibrary = (nonce) => {
// // // //       return new Promise((resolve) => {
// // // //         const script = document.createElement('script');
// // // //         script.src = 'https://accounts.google.com/gsi/client';
// // // //         script.async = true;
// // // //         script.defer = true;
// // // //         script.onload = resolve;
// // // //         if (window.nonce) {
// // // //           script.setAttribute('nonce', window.nonce);
// // // //         }
// // // //         document.body.appendChild(script);
// // // //         console.log('loadGoogleSignInLibrary')
// // // //       });
// // // //     };

// // // //     const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
// // // //     loadGoogleSignInLibrary().then(() => {
// // // //       window.google.accounts.id.initialize({
// // // //         client_id: GOOGLE_CLIENT_ID,
// // // //         callback: handleGoogleSignInResponse,
// // // //       });
// // // //       console.log('loadGoogleSignInLibrary')
// // // //     });
// // // //   }, [verifyAuthToken]);

  
// // // //   return (
// // // //     <div className="auth-container">
// // // //   {console.log('Rendering signup-container')}
// // // //       {isLoading && <LoadingSpinner />}
// // // //       {errorMessage && <div className="auth-error">{errorMessage}</div>}
// // // //       {/* {isLoggedIn ? ( */}
// // // //       {isLoggedIn ? (
// // // //   <AccountSetup 
// // // //     email={email} 
// // // //     onComplete={() => navigate('/admin')}
// // // //   />
// // // // ) : (
// // // // <div className="h3 text-dkpurple">User is Logged In
// // // // {navigate('/admin')}    
// // // //   </div>
// // // //                 ) : (
// // // //                 <>
// // // //                 <div className={`auth-form-container ${isLoading ? 'hidden' : ''}`}>
// // // //                 <h1>New User Sign Up</h1>
// // // //                 {/* <div className="auth-form-container"> */}
// // // //                 {console.log('Rendering signup-form-container')}
// // // //                 <motion.img 
// // // //                     initial={{ opacity: 0, y: -10 }}
// // // //                     animate={{ opacity: 1, y: 0 }}
// // // //                     transition={{ delay: 0.2 }}  // Adjust the delay as needed
// // // //                     src="/logo-Teraace-250.png" 
// // // //                     alt="logo" 
// // // //                     className="auth-logo" 
// // // //                 />
            
// // // //                   <form className="auth-form" onSubmit={(e) => e.preventDefault()}>
// // // //                     <SanitizedInput
// // // //                     // <motion.input
// // // //                       initial={{ opacity: 0, y: -10 }}
// // // //                       animate={{ opacity: 1, y: 0 }}
// // // //                       transition={{ delay: 0.3 }}
// // // //                       className="auth-input-email email"
// // // //                       type="email"
// // // //                       id="email"
// // // //                       name="email"
// // // //                       autoComplete="email"
// // // //                       placeholder="Email"
// // // //                       value={email}
// // // //                       onChange={(e) => setEmail(e.target.value)}
// // // //                     />
// // // //                     <SanitizedInput
// // // //                       initial={{ opacity: 0, y: -10 }}
// // // //                       animate={{ opacity: 1, y: 0 }}
// // // //                       transition={{ delay: 0.4 }}
// // // //                       className="auth-input-password password"
// // // //                       id="password"
// // // //                       name="password"
// // // //                       type="password"
// // // //                       autoComplete="current-password"
// // // //                       placeholder="Password"
// // // //                       value={password}
// // // //                       onChange={(e) => setPassword(e.target.value)}
// // // //                     />
// // // //                     {/* <input
// // // //                       className="hidden-input"
// // // //                       type="hidden"
// // // //                       name="xsrfToken"
// // // //                       value={xsrfToken}
// // // //                     /> */}
// // // //                     {/* <motion.button 
// // // //                       initial={{ opacity: 0, y: 10 }}
// // // //                       animate={{ opacity: 1, y: 0 }}
// // // //                       transition={{ delay: 0.5 }}
// // // //                       className="auth-btn-signup"
// // // //                       onClick={() => handleEmailPasswordAuth(true)}>
// // // //                       Sign Up
// // // //                     </motion.button> */}
// // // //                     <motion.button 
// // // //                       initial={{ opacity: 0, y: 20 }}
// // // //                       animate={{ opacity: 1, y: 0 }}
// // // //                       transition={{ delay: 0.4 }}
// // // //                       className="auth-btn-signin"
// // // //                       onClick={() => handleEmailPasswordAuth(false)}>
// // // //                       Sign In
// // // //                     </motion.button>
// // // //                   </form>
// // // //                   {/* <motion.button 
// // // //                     initial={{ opacity: 0, y: 20 }}
// // // //                     animate={{ opacity: 1, y: 0 }}
// // // //                     transition={{ delay: 0.8 }}
// // // //                     className="auth-btn-google"
// // // //                     onClick={handleGoogleSignInResponse}>
// // // //                     Sign In with Google
// // // //                   </motion.button> */}
// // // //                   </div>
// // // //                 </>
// // // //               )}
// // // //             </div>
// // // //   );
// // // // }

// // // return (
// // //   <div className="auth-container">
// // //     {console.log('Rendering signup-container')}
// // //     {isLoading && <LoadingSpinner />}
// // //     {errorMessage && <div className="auth-error">{errorMessage}</div>}
// // //     {isLoggedIn ? (
// // //       <AccountSetup 
// // //         email={email} 
// // //         navigate={navigate}
// // //         onComplete={() => navigate('/admin')}
// // //       />
// // //     ) : (
// // //       <>
// // //         <div className={`auth-form-container ${isLoading ? 'hidden' : ''}`}>
// // //           <h1>New User Sign Up</h1>
// // //           <motion.img 
// // //             initial={{ opacity: 0, y: -10 }}
// // //             animate={{ opacity: 1, y: 0 }}
// // //             transition={{ delay: 0.2 }}
// // //             src="/logo-Teraace-250.png" 
// // //             alt="logo" 
// // //             className="auth-logo" 
// // //           />
          
// // //           <form className="auth-form" onSubmit={(e) => e.preventDefault()}>
// // //             <SanitizedInput
// // //               initial={{ opacity: 0, y: -10 }}
// // //               animate={{ opacity: 1, y: 0 }}
// // //               transition={{ delay: 0.3 }}
// // //               className="auth-input-email email"
// // //               type="email"
// // //               id="email"
// // //               name="email"
// // //               autoComplete="email"
// // //               placeholder="Email"
// // //               value={email}
// // //               onChange={(e) => setEmail(e.target.value)}
// // //             />
// // //             <SanitizedInput
// // //               initial={{ opacity: 0, y: -10 }}
// // //               animate={{ opacity: 1, y: 0 }}
// // //               transition={{ delay: 0.4 }}
// // //               className="auth-input-password password"
// // //               id="password"
// // //               name="password"
// // //               type="password"
// // //               autoComplete="current-password"
// // //               placeholder="Password"
// // //               value={password}
// // //               onChange={(e) => setPassword(e.target.value)}
// // //             />
// // //             <motion.button 
// // //               initial={{ opacity: 0, y: 20 }}
// // //               animate={{ opacity: 1, y: 0 }}
// // //               transition={{ delay: 0.4 }}
// // //               className="auth-btn-signin"
// // //               onClick={() => handleEmailPasswordAuth(true)}
// // //             >
// // //               Sign Up
// // //             </motion.button>
// // //           </form>
// // //         </div>
// // //       </>
// // //     )}
// // //   </div>
// // // );
// // // }

// // // export default Signup;
