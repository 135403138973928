// /redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import undoable from 'redux-undo';
import userJourneyReducer from './slices/userJourneySlice';
import treeExplorerReducer from './slices/treeExplorerSlice';
import patternReducer from './slices/patternSlice';

// Wrap your reducer with undoable
const undoableJourneyReducer = undoable(userJourneyReducer);
const undoableTreeReducer = undoable(treeExplorerReducer);
const undoablePatternReducer = undoable(patternReducer);

const store = configureStore({
  reducer: {
    userJourney: undoableJourneyReducer,
    treeExplorer: undoableTreeReducer,
    pattern: undoablePatternReducer,
  },
});

export default store;
