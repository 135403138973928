import React, { useEffect, useState } from 'react';
import { Select } from '@mantine/core';

/**
 * ConditionEditor
 * - For type=EVENT or PAGE, we show a "Lookback (days)" field
 * - "Event Desc" or "Page URL" selects are Mantine <Select> with search
 */
function ConditionEditor({ cond, onUpdate, dataset }) {
  const [localCond, setLocalCond] = useState(cond);

  // Condition Types
  const CONDITION_TYPES = ['PAGE', 'EVENT', 'ML_PATTERN'];

  // For events, we might use "UniqueEventDescList" from dataset
  // Convert to an array of { value, label } for Mantine <Select>
  const eventList = dataset?.UniqueEventDescList
    ? Object.keys(dataset.UniqueEventDescList).map((ev) => ({
        value: ev,
        label: ev,
      }))
    : [];

  // For pages, we might use "UniqueURL" from dataset
  // Convert to an array of { value, label }
  const pageList = dataset?.UniqueURL
    ? Object.keys(dataset.UniqueURL).map((pg) => ({
        value: pg,
        label: pg,
      }))
    : [];

  // Handler: update entire condition
  const handleChange = (updated) => {
    setLocalCond(updated);
    onUpdate(updated);
  };

  // Sync localCond when the parent prop changes
  useEffect(() => {
    setLocalCond(cond);
  }, [cond]);

  return (
    <div className="condition-editor-container">
      {/* Condition Type */}
      <div className="condition-editor-row">
        <label className="condition-editor-label">Type:</label>
        <select
          className="condition-editor-select"
          value={localCond.type}
          onChange={(e) => {
            const newType = e.target.value;
            const keepLookback = localCond.parameters.lookbackDays || 7;
            const updated = {
              ...localCond,
              type: newType,
              parameters: {
                lookbackDays: keepLookback,
              },
            };
            handleChange(updated);
          }}
        >
          {CONDITION_TYPES.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>

      {/* If type=EVENT, pick an event desc */}
      {localCond.type === 'EVENT' && (
        <>
          <div className="condition-editor-row">
            <label className="condition-editor-label">Event Desc:</label>
            <Select
              data={eventList}
              searchable
              clearable
              nothingFound="No events found"
              placeholder="-- Select an event --"
              className="condition-editor-select-mantine"
              value={localCond.parameters?.eventDesc || ''}
              onChange={(value) => {
                const updated = {
                  ...localCond,
                  parameters: {
                    ...localCond.parameters,
                    eventDesc: value || '',
                  },
                };
                handleChange(updated);
              }}
            />
          </div>

          {/* Lookback (days) */}
          <div className="condition-editor-row">
            <label className="condition-editor-label">Lookback (days):</label>
            <input
              type="number"
              className="condition-editor-input"
              value={localCond.parameters?.lookbackDays || ''}
              onChange={(e) => {
                const updated = {
                  ...localCond,
                  parameters: {
                    ...localCond.parameters,
                    lookbackDays: Number(e.target.value),
                  },
                };
                handleChange(updated);
              }}
            />
          </div>
        </>
      )}

      {/* If type=PAGE, pick a page URL + lookback */}
      {localCond.type === 'PAGE' && (
        <>
          <div className="condition-editor-row">
            <label className="condition-editor-label">Page URL:</label>
            <Select
              data={pageList}
              searchable
              clearable
              nothingFound="No pages found"
              placeholder="-- Select a page --"
              className="condition-editor-select-mantine"
              value={localCond.parameters?.pageUrl || ''}
              onChange={(value) => {
                const updated = {
                  ...localCond,
                  parameters: {
                    ...localCond.parameters,
                    pageUrl: value || '',
                  },
                };
                handleChange(updated);
              }}
            />
          </div>

          {/* Lookback (days) */}
          <div className="condition-editor-row">
            <label className="condition-editor-label">Lookback (days):</label>
            <input
              type="number"
              className="condition-editor-input"
              value={localCond.parameters?.lookbackDays || ''}
              onChange={(e) => {
                const updated = {
                  ...localCond,
                  parameters: {
                    ...localCond.parameters,
                    lookbackDays: Number(e.target.value),
                  },
                };
                handleChange(updated);
              }}
            />
          </div>
        </>
      )}

      {/* If type=ML_PATTERN, advanced fields go here */}
      {localCond.type === 'ML_PATTERN' && (
        <p className="ml-pattern-text">
          (ML pattern fields go here.)
        </p>
      )}
    </div>
  );
}

export default ConditionEditor;
