const GO_BASE = process.env.REACT_APP_GO_BASE; // e.g. https://api.yourdomain.com
const API_BASE = `${GO_BASE}/api/v1`;

function getDefaultHeaders() {
  const token = localStorage.getItem('authToken');
  const OrgID = localStorage.getItem('ActiveOrgID');
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'OrgID': OrgID,
  };
}

function transformWebhook(webhook) {
  // Convert headers from an object (map) to an array of header objects.
  let headersArray = [];
  if (webhook.headers && typeof webhook.headers === "object" && !Array.isArray(webhook.headers)) {
    for (const key in webhook.headers) {
      if (Object.prototype.hasOwnProperty.call(webhook.headers, key)) {
        headersArray.push({ Key: key, Value: webhook.headers[key] });
      }
    }
  } else if (Array.isArray(webhook.headers)) {
    headersArray = webhook.headers;
  }
  
  return {
    ...webhook,
    headers: headersArray,
    // Keep request_template as a string (or fallback if needed)
    request_template: webhook.request_template || (webhook.requestBody && webhook.requestBody.bodyContent) || "",
  };
}




export async function getWebhooks() {
  const response = await fetch(`${API_BASE}/webhooks`, {
    headers: getDefaultHeaders(),
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Failed to fetch webhooks');
  }
  return response.json();
}

export async function createWebhook(webhook) {
  const transformedWebhook = transformWebhook(webhook);
  const response = await fetch(`${API_BASE}/webhooks`, {
    method: 'POST',
    headers: getDefaultHeaders(),
    body: JSON.stringify(transformedWebhook),
    credentials: 'include', // send cookies if needed
  });
  if (!response.ok) {
    throw new Error('Failed to create webhook');
  }
  return response.json();
}


export async function updateWebhook(id, webhook) {
  let transformedWebhook = transformWebhook(webhook);
  // If OrgID is missing, add it from localStorage (ActiveOrgID)
  if (!transformedWebhook.OrgID) {
    transformedWebhook = {
      ...transformedWebhook,
      OrgID: localStorage.getItem('ActiveOrgID'),
    };
  }
  const response = await fetch(`${API_BASE}/webhooks/${id}`, {
    method: 'PUT',
    headers: getDefaultHeaders(),
    body: JSON.stringify(transformedWebhook),
    credentials: 'include', // send cookies if needed
  });
  if (!response.ok) {
    throw new Error('Failed to update webhook');
  }
  return response.json();
}

export async function deleteWebhook(id) {
  const response = await fetch(`${API_BASE}/webhooks/${id}`, {
    method: 'DELETE',
    headers: getDefaultHeaders(),
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Failed to delete webhook');
  }
  return response.json();
}

export async function testWebhook(id) {
  const response = await fetch(`${API_BASE}/webhooks/${id}/test`, {
    method: 'POST',
    headers: getDefaultHeaders(),
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Failed to test webhook');
  }
  return response.json();
}

export async function getWebhookTemplates() {
  const response = await fetch(`${API_BASE}/webhook-templates`, {
    headers: getDefaultHeaders(),
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Failed to fetch webhook templates');
  }
  return response.json();
}



// const GO_BASE = process.env.REACT_APP_GO_BASE; // e.g. https://api.yourdomain.com
// const API_BASE = `${GO_BASE}/api/v1`;

// function getDefaultHeaders() {
//   const token = localStorage.getItem('authToken');
//   const OrgID = localStorage.getItem('ActiveOrgID');
//   return {
//     'Content-Type': 'application/json',
//     'Authorization': `Bearer ${token}`,
//     'OrgID': OrgID,
//   };
// }

// // Transform the frontend webhook object to match backend expectations.
// function transformWebhook(webhook) {
//   return {
//     ...webhook,
//     // Map requestBody.template to request_template
//     request_template: webhook.requestBody ? webhook.requestBody.template : '',
//     // Optionally, include other fields or remove requestBody if not needed.
//     // Remove requestBody if it's not needed:
//     // requestBody: undefined,
//   };
// }

// export async function getWebhooks() {
//   const response = await fetch(`${API_BASE}/webhooks`, {
//     headers: getDefaultHeaders(),
//     credentials: 'include',
//   });
//   if (!response.ok) {
//     throw new Error('Failed to fetch webhooks');
//   }
//   return response.json();
// }

// export async function createWebhook(webhook) {
//   const transformedWebhook = transformWebhook(webhook);
//   const response = await fetch(`${API_BASE}/webhooks`, {
//     method: 'POST',
//     headers: getDefaultHeaders(),
//     body: JSON.stringify(transformedWebhook),
//     credentials: 'include', // send cookies if needed
//   });
//   if (!response.ok) {
//     throw new Error('Failed to create webhook');
//   }
//   return response.json();
// }

// export async function updateWebhook(id, webhook) {
//   const transformedWebhook = transformWebhook(webhook);
//   const response = await fetch(`${API_BASE}/webhooks/${id}`, {
//     method: 'PUT',
//     headers: getDefaultHeaders(),
//     body: JSON.stringify(transformedWebhook),
//     credentials: 'include', // send cookies if needed
//   });
//   if (!response.ok) {
//     throw new Error('Failed to update webhook');
//   }
//   return response.json();
// }

// export async function deleteWebhook(id) {
//   const response = await fetch(`${API_BASE}/webhooks/${id}`, {
//     method: 'DELETE',
//     headers: getDefaultHeaders(),
//     credentials: 'include',
//   });
//   if (!response.ok) {
//     throw new Error('Failed to delete webhook');
//   }
//   return response.json();
// }

// export async function testWebhook(id) {
//   const response = await fetch(`${API_BASE}/webhooks/${id}/test`, {
//     method: 'POST',
//     headers: getDefaultHeaders(),
//     credentials: 'include',
//   });
//   if (!response.ok) {
//     throw new Error('Failed to test webhook');
//   }
//   return response.json();
// }

// export async function getWebhookTemplates() {
//   const response = await fetch(`${API_BASE}/webhook-templates`, {
//     headers: getDefaultHeaders(),
//     credentials: 'include',
//   });
//   if (!response.ok) {
//     throw new Error('Failed to fetch webhook templates');
//   }
//   return response.json();
// }


// // import axios from 'axios';

// // const GO_BASE = process.env.REACT_APP_GO_BASE; // e.g. https://api.yourdomain.com
// // const API_BASE = `${GO_BASE}/api/v1`;

// // export const askOracle = async (endpoint, config = {}) => {
// //   const token = localStorage.getItem('authToken');
// //   const OrgID = localStorage.getItem('ActiveOrgID');
// //   console.log("Sent askOracle to:", endpoint);
// //   console.log("Sent askOracle for OrgID:", OrgID);

// //   const headers = {
// //     'Authorization': `Bearer ${token}`,
// //     ...config.headers,
// //   };

// //   if (OrgID) {
// //     headers['OrgID'] = OrgID;
// //   }
  
// //   try {
// //     const response = await axios.get(`${GO_BASE}/${endpoint}`, {
// //       ...config,
// //       headers,
// //       withCredentials: true,
// //     });
// //     return response.data;
// //   } catch (error) {
// //     if (error.response && error.response.status === 401) {
// //       localStorage.removeItem('authToken');
// //       console.log("I had to remove the auth token");
// //       window.location.href = '/auth';
// //     }
// //     throw error;
// //   }
// // };

// // export async function getWebhooks() {
// //   const response = await fetch(`${API_BASE}/webhooks`);
// //   if (!response.ok) {
// //     throw new Error('Failed to fetch webhooks');
// //   }
// //   return response.json();
// // }

// // export async function createWebhook(webhook) {
// //   const response = await fetch(`${API_BASE}/webhooks`, {
// //     method: 'POST',
// //     headers: { 'Content-Type': 'application/json' },
// //     body: JSON.stringify(webhook),
// //   });
// //   if (!response.ok) {
// //     throw new Error('Failed to create webhook');
// //   }
// //   return response.json();
// // }

// // export async function updateWebhook(id, webhook) {
// //   const response = await fetch(`${API_BASE}/webhooks/${id}`, {
// //     method: 'PUT',
// //     headers: { 'Content-Type': 'application/json' },
// //     body: JSON.stringify(webhook),
// //   });
// //   if (!response.ok) {
// //     throw new Error('Failed to update webhook');
// //   }
// //   return response.json();
// // }

// // export async function deleteWebhook(id) {
// //   const response = await fetch(`${API_BASE}/webhooks/${id}`, {
// //     method: 'DELETE',
// //   });
// //   if (!response.ok) {
// //     throw new Error('Failed to delete webhook');
// //   }
// //   return response.json();
// // }

// // export async function testWebhook(id) {
// //   const response = await fetch(`${API_BASE}/webhooks/${id}/test`, {
// //     method: 'POST',
// //   });
// //   if (!response.ok) {
// //     throw new Error('Failed to test webhook');
// //   }
// //   return response.json();
// // }

// // export async function getWebhookTemplates() {
// //   const response = await fetch(`${API_BASE}/webhook-templates`);
// //   if (!response.ok) {
// //     throw new Error('Failed to fetch webhook templates');
// //   }
// //   return response.json();
// // }



// // // const GO_BASE = process.env.REACT_APP_GO_BASE;
// // // const API_BASE = `${GO_BASE}/api/v1`;

// // // export const askOracle = async (endpoint, config = {}) => {
// // //   const token = localStorage.getItem('authToken');
// // //   const OrgID = localStorage.getItem('ActiveOrgID');
// // //   console.log("Sent askOracle to:", endpoint);
// // //   console.log("Sent askOracle for OrgID:", OrgID);

// // //   const headers = {
// // //     'Authorization': `Bearer ${token}`,
// // //     ...config.headers,
// // //   };

// // //   if (OrgID) {
// // //     headers['OrgID'] = OrgID;
// // //   }
  
// // //   try {
// // //     const response = await axios.get(`${GO_BASE}/${endpoint}`, {
// // //       ...config,
// // //       headers,
// // //       withCredentials: true,
// // //     });
// // //     return response.data;
// // //   } catch (error) {
// // //     if (error.response && error.response.status === 401) {
// // //       localStorage.removeItem('authToken');
// // //       console.log("I had to remove the auth token");
// // //       window.location.href = '/auth';
// // //     }
// // //     throw error;
// // //   }
// // // };

// // // export async function getWebhooks() {
// // //   const response = await fetch(`${API_BASE}/webhooks`);
// // //   if (!response.ok) {
// // //     throw new Error('Failed to fetch webhooks');
// // //   }
// // //   return response.json();
// // // }

// // // export async function createWebhook(webhook) {
// // //   const response = await fetch(`${API_BASE}/webhooks`, {
// // //     method: 'POST',
// // //     headers: { 'Content-Type': 'application/json' },
// // //     body: JSON.stringify(webhook),
// // //   });
// // //   if (!response.ok) {
// // //     throw new Error('Failed to create webhook');
// // //   }
// // //   return response.json();
// // // }

// // // export async function updateWebhook(id, webhook) {
// // //   const response = await fetch(`${API_BASE}/webhooks/${id}`, {
// // //     method: 'PUT',
// // //     headers: { 'Content-Type': 'application/json' },
// // //     body: JSON.stringify(webhook),
// // //   });
// // //   if (!response.ok) {
// // //     throw new Error('Failed to update webhook');
// // //   }
// // //   return response.json();
// // // }

// // // export async function deleteWebhook(id) {
// // //   const response = await fetch(`${API_BASE}/webhooks/${id}`, {
// // //     method: 'DELETE',
// // //   });
// // //   if (!response.ok) {
// // //     throw new Error('Failed to delete webhook');
// // //   }
// // //   return response.json();
// // // }

// // // export async function testWebhook(id) {
// // //   const response = await fetch(`${API_BASE}/webhooks/${id}/test`, {
// // //     method: 'POST',
// // //   });
// // //   if (!response.ok) {
// // //     throw new Error('Failed to test webhook');
// // //   }
// // //   return response.json();
// // // }

// // // export async function getWebhookTemplates() {
// // //   const response = await fetch(`${API_BASE}/webhook-templates`);
// // //   if (!response.ok) {
// // //     throw new Error('Failed to fetch webhook templates');
// // //   }
// // //   return response.json();
// // // }
