import React, { useEffect, useState } from 'react';

const WebhookHistory = ({ webhookId }) => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await fetch(`/api/webhooks/${webhookId}/history`);
        const data = await response.json();
        setHistory(data);
      } catch (error) {
        console.error('Failed to fetch history:', error);
      }
    };

    if (webhookId) {
      fetchHistory();
    }
  }, [webhookId]);

  return (
    <div className="border p-4">
      <h3 className="text-lg font-bold">Execution History</h3>
      <ul>
        {history.map((entry) => (
          <li key={entry.id}>
            {entry.timestamp}: {entry.status}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WebhookHistory;
