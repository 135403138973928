import React, { useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveBar } from '@nivo/bar';
import ReactFlow, { Background, Controls } from 'react-flow-renderer';
import { Modal, Button, Select, Slider } from '@mantine/core';

// Custom Nivo theme using your orange palette
const customNivoTheme = {
  axis: {
    domain: {
      line: {
        stroke: '#e08a3c', // mdorange
        strokeWidth: 1,
      },
    },
    legend: {
      text: {
        fill: '#de6736', // dkorange
      },
    },
    ticks: {
      line: {
        stroke: '#ebb844', // ltorange
        strokeWidth: 1,
      },
      text: {
        fill: '#e08a3c', // mdorange
      },
    },
  },
  legends: {
    text: {
      fill: '#de6736', // dkorange
    },
  },
  tooltip: {
    container: {
      background: '#fff',
      color: '#de6736', // dkorange
      fontSize: 12,
    },
  },
};

// Define the color scale to be used for chart series
const colorScale = ['#e08a3c', '#ebb844', '#de6736'];

// =======================
// Top Metrics Cards
// =======================
function DashboardOverview() {
  return (
    <div className="dashboard-overview">
      <div className="dashboard-card">
        <h3 className="dashboard-card-title">Active Automations</h3>
        <p className="dashboard-card-value">123</p>
      </div>
      <div className="dashboard-card">
        <h3 className="dashboard-card-title">Executions (7d)</h3>
        <p className="dashboard-card-value">456</p>
      </div>
      <div className="dashboard-card">
        <h3 className="dashboard-card-title">Success Rate</h3>
        <p className="dashboard-card-value">89%</p>
      </div>
      <div className="dashboard-card">
        <h3 className="dashboard-card-title">Engagement Lift</h3>
        <p className="dashboard-card-value">+12%</p>
      </div>
    </div>
  );
}

// =======================
// Flow Visualization
// =======================
function FlowVisualization() {
  const nodes = [
    { id: '1', data: { label: 'Pattern A' }, position: { x: 50, y: 50 } },
    { id: '2', data: { label: 'Webhook X' }, position: { x: 250, y: 50 } },
    { id: '3', data: { label: 'Webhook Y' }, position: { x: 250, y: 150 } },
  ];
  const edges = [
    { id: 'e1-2', source: '1', target: '2', animated: true },
    { id: 'e1-3', source: '1', target: '3', animated: true },
  ];

  return (
    <div className="flow-visualization">
      <h2 className="flow-visualization-header">Automation Flow</h2>
      <div className="chart-h-400">
        <ReactFlow nodes={nodes} edges={edges} fitView>
          <Background />
          <Controls />
        </ReactFlow>
      </div>
    </div>
  );
}

// =======================
// Performance Analytics Charts
// =======================
function ExecutionVolumeChart() {
  const data = [
    {
      id: 'Executions',
      data: [
        { x: '2025-02-12', y: 30 },
        { x: '2025-02-13', y: 45 },
        { x: '2025-02-14', y: 50 },
        { x: '2025-02-15', y: 60 },
        { x: '2025-02-16', y: 70 },
        { x: '2025-02-17', y: 55 },
        { x: '2025-02-18', y: 80 },
      ],
    },
  ];

  return (
    <div className="chart-card">
      <h3 className="chart-card-title">Execution Volume Over Time</h3>
      <div className="chart-h-300">
        <ResponsiveLine
          data={data}
          theme={customNivoTheme}
          colors={colorScale}
          margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{ type: 'linear', stacked: false }}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            legend: 'Date',
            legendOffset: 36,
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            legend: 'Executions',
            legendOffset: -40,
          }}
          pointSize={6}
          pointBorderWidth={1}
          useMesh={true}
        />
      </div>
    </div>
  );
}

function SuccessFailureChart() {
  const data = [
    { metric: 'Success', count: 80 },
    { metric: 'Failure', count: 20 },
  ];

  return (
    <div className="chart-card">
      <h3 className="chart-card-title">Success vs Failure Rates</h3>
      <div className="chart-h-300">
        <ResponsiveBar
          data={data}
          theme={customNivoTheme}
          colors={colorScale}
          keys={['count']}
          indexBy="metric"
          margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
          padding={0.3}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Metric',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Count',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
        />
      </div>
    </div>
  );
}

function ResponseTimeChart() {
  const data = [
    {
      id: 'Response Time',
      data: [
        { x: '100ms', y: 10 },
        { x: '200ms', y: 30 },
        { x: '300ms', y: 25 },
        { x: '400ms', y: 15 },
        { x: '500ms', y: 5 },
      ],
    },
  ];

  return (
    <div className="chart-card">
      <h3 className="chart-card-title">Response Time Distribution</h3>
      <div className="chart-h-300">
        <ResponsiveLine
          data={data}
          theme={customNivoTheme}
          colors={colorScale}
          margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{ type: 'linear', stacked: false }}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Response Time',
            legendOffset: 36,
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            legend: 'Frequency',
            legendOffset: -40,
          }}
          pointSize={6}
          pointBorderWidth={1}
          useMesh={true}
        />
      </div>
    </div>
  );
}

function PerformanceAnalytics() {
  return (
    <div className="performance-analytics">
      <ExecutionVolumeChart />
      <SuccessFailureChart />
      <ResponseTimeChart />
    </div>
  );
}

// =======================
// Performance Table and Detailed Automation Modal
// =======================
function PerformanceTable({ onViewDetails }) {
  const automations = [
    {
      id: 1,
      pattern: 'Pattern A',
      webhook: 'Webhook X',
      executions: 120,
      successRate: '85%',
      failureRate: '15%',
      activeDuration: '30 days',
      status: 'Active',
    },
    {
      id: 2,
      pattern: 'Pattern B',
      webhook: 'Webhook Y',
      executions: 200,
      successRate: '92%',
      failureRate: '8%',
      activeDuration: '45 days',
      status: 'Active',
    },
  ];

  return (
    <div className="performance-table">
      <h2>Automations</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Pattern</th>
            <th>Webhook</th>
            <th>Executions</th>
            <th>Success</th>
            <th>Failure</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {automations.map((auto) => (
            <tr key={auto.id}>
              <td>{auto.id}</td>
              <td>{auto.pattern}</td>
              <td>{auto.webhook}</td>
              <td>{auto.executions}</td>
              <td>{auto.successRate}</td>
              <td>{auto.failureRate}</td>
              <td>
                <Button size="xs" onClick={() => onViewDetails(auto)}>
                  View Details
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function DetailedAutomationModal({ automation, opened, onClose }) {
  if (!automation) return null;

  const detailExecutionData = [
    {
      id: 'Execution Trend',
      data: [
        { x: 'Day 1', y: 20 },
        { x: 'Day 2', y: 35 },
        { x: 'Day 3', y: 40 },
        { x: 'Day 4', y: 50 },
        { x: 'Day 5', y: 45 },
      ],
    },
  ];

  const detailSuccessData = [
    { metric: 'Success', count: 90 },
    { metric: 'Failure', count: 10 },
  ];

  return (
    <Modal opened={opened} onClose={onClose} title="Automation Details" size="xl">
      <div className="modal-details">
        <div className="border-bottom">
          <h2>
            {automation.pattern} - {automation.webhook}
          </h2>
          <p>Status: {automation.status} | Active for: {automation.activeDuration}</p>
        </div>
        <div className="grid-2cols">
          <div>
            <h3>Execution Trend</h3>
            <div className="chart-h-200">
              <ResponsiveLine
                data={detailExecutionData}
                theme={customNivoTheme}
                colors={colorScale}
                margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
                xScale={{ type: 'point' }}
                yScale={{ type: 'linear', stacked: false }}
                axisBottom={{
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 45,
                  legend: 'Day',
                  legendOffset: 36,
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  legend: 'Executions',
                  legendOffset: -40,
                }}
                pointSize={4}
                pointBorderWidth={1}
                useMesh={true}
              />
            </div>
          </div>
          <div>
            <h3>Success vs Failure</h3>
            <div className="chart-h-200">
              <ResponsiveBar
                data={detailSuccessData}
                theme={customNivoTheme}
                colors={colorScale}
                keys={['count']}
                indexBy="metric"
                margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
                padding={0.3}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  legend: 'Metric',
                  legendPosition: 'middle',
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  legend: 'Count',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
              />
            </div>
          </div>
        </div>
        <div className="border-top">
          <h3>Technical Details</h3>
          <p>
            Recent logs, error distribution and configuration summary would be displayed here.
          </p>
        </div>
      </div>
    </Modal>
  );
}

// =======================
// Alert Configuration
// =======================
function AlertConfiguration() {
  return (
    <div className="alert-configuration">
      <h3>Alert Configuration</h3>
      <div className="form-group">
        <label>Metric</label>
        <Select
          data={[
            { value: 'successRate', label: 'Success Rate' },
            { value: 'executionVolume', label: 'Execution Volume' },
            { value: 'responseTime', label: 'Response Time' },
            { value: 'errorRate', label: 'Error Rate' },
          ]}
          placeholder="Select Metric"
        />
      </div>
      <div className="form-group">
        <label>Threshold</label>
        <Slider
          min={0}
          max={100}
          defaultValue={50}
          marks={[
            { value: 0, label: '0%' },
            { value: 50, label: '50%' },
            { value: 100, label: '100%' },
          ]}
        />
      </div>
      <div className="form-group">
        <label>Time Window</label>
        <Select
          data={[
            { value: '1h', label: '1 Hour' },
            { value: '24h', label: '24 Hours' },
            { value: '7d', label: '7 Days' },
          ]}
          placeholder="Select Time Window"
        />
      </div>
      <Button variant="filled">Save Alert</Button>
    </div>
  );
}

// =======================
// Recommendation Center and Detail Modal
// =======================
function RecommendationCenter({ onViewRecommendation }) {
  const recommendations = [
    {
      id: 1,
      title: 'Increase webhook timeout',
      impact: 'High',
      effort: 'Medium',
      details:
        'By increasing the webhook timeout, you may see fewer failures and improved execution rates. Historical data shows a trend of improvement when timeouts are adjusted.',
    },
    {
      id: 2,
      title: 'Optimize pattern matching',
      impact: 'Medium',
      effort: 'Low',
      details:
        'Adjusting pattern matching logic can reduce false negatives. Similar configurations in other cases have resulted in improved overall success rates.',
    },
  ];

  return (
    <div className="recommendation-center">
      <h3>Recommendation Center</h3>
      {recommendations.map((rec) => (
        <div key={rec.id} className="recommendation-item">
          <div className="recommendation-header">
            <span className="recommendation-title">{rec.title}</span>
            <Button variant="outline" size="xs" onClick={() => onViewRecommendation(rec)}>
              View Details
            </Button>
          </div>
          <p>
            Impact: {rec.impact} | Effort: {rec.effort}
          </p>
        </div>
      ))}
    </div>
  );
}

function RecommendationDetailModal({ recommendation, opened, onClose }) {
  if (!recommendation) return null;

  const beforeAfterData = [
    { metric: 'Success Rate', before: 80, after: 90 },
    { metric: 'Execution Volume', before: 150, after: 180 },
  ];

  const chartData = beforeAfterData.map((item) => ({
    metric: item.metric,
    before: item.before,
    after: item.after,
  }));

  return (
    <Modal opened={opened} onClose={onClose} title="Recommendation Details" size="xl">
      <div className="modal-details">
        <div className="border-bottom">
          <h2>{recommendation.title}</h2>
          <p>{recommendation.details}</p>
        </div>
        <div>
          <h3 className="section-title">Before/After Comparison</h3>
          <div className="chart-h-300">
            <ResponsiveBar
              data={chartData}
              theme={customNivoTheme}
              colors={colorScale}
              keys={['before', 'after']}
              indexBy="metric"
              margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
              padding={0.3}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                legend: 'Metric',
                legendPosition: 'middle',
                legendOffset: 32,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                legend: 'Value',
                legendPosition: 'middle',
                legendOffset: -40,
              }}
            />
          </div>
        </div>
        <div className="border-top">
          <h3 className="section-title">Implementation Workflow</h3>
          <div className="workflow-steps">
            <p>Step 1: Review recommendation details and preview expected impact.</p>
            <p>Step 2: Choose to Accept or Reject the recommendation.</p>
            <p>Step 3: If accepted, schedule implementation and monitor progress.</p>
          </div>
          <div className="workflow-actions">
            <Button color="green">Accept</Button>
            <Button color="red">Reject</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

// =======================
// Main Dashboard Page
// =======================
function ActionsPerformanceDashboard() {
  const [dateRange, setDateRange] = useState('7d');
  const [segment, setSegment] = useState('all');
  const [selectedAutomation, setSelectedAutomation] = useState(null);
  const [automationModalOpen, setAutomationModalOpen] = useState(false);
  const [selectedRecommendation, setSelectedRecommendation] = useState(null);
  const [recommendationModalOpen, setRecommendationModalOpen] = useState(false);

  const handleViewDetails = (automation) => {
    setSelectedAutomation(automation);
    setAutomationModalOpen(true);
  };

  const handleViewRecommendation = (recommendation) => {
    setSelectedRecommendation(recommendation);
    setRecommendationModalOpen(true);
  };

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <h1 className="dashboard-title">Automation Performance Dashboard</h1>
        <div className="filter-container">
          <div className="filter-group">
            <label className="filter-label">Date Range:</label>
            <Select
              value={dateRange}
              onChange={setDateRange}
              data={[
                { value: '24h', label: 'Last 24h' },
                { value: '7d', label: 'Last 7 days' },
                { value: '30d', label: 'Last 30 days' },
                { value: 'custom', label: 'Custom' },
              ]}
              className="filter-select"
            />
          </div>
          <div className="filter-group">
            <label className="filter-label">Segment:</label>
            <Select
              value={segment}
              onChange={setSegment}
              data={[
                { value: 'all', label: 'All Users' },
                { value: 'segmentA', label: 'Segment A' },
                { value: 'segmentB', label: 'Segment B' },
              ]}
              className="filter-select"
            />
          </div>
        </div>
      </header>

      <DashboardOverview />
      <FlowVisualization />
      <PerformanceAnalytics />
      <PerformanceTable onViewDetails={handleViewDetails} />

      <div className="dashboard-two-columns">
        <AlertConfiguration />
        <RecommendationCenter onViewRecommendation={handleViewRecommendation} />
      </div>

      <DetailedAutomationModal
        automation={selectedAutomation}
        opened={automationModalOpen}
        onClose={() => setAutomationModalOpen(false)}
      />

      <RecommendationDetailModal
        recommendation={selectedRecommendation}
        opened={recommendationModalOpen}
        onClose={() => setRecommendationModalOpen(false)}
      />
    </div>
  );
}

export default ActionsPerformanceDashboard;



// import React, { useState } from 'react';
// import { ResponsiveLine } from '@nivo/line';
// import { ResponsiveBar } from '@nivo/bar';
// import ReactFlow, { Background, Controls } from 'react-flow-renderer';
// import { Modal, Button, Select, Slider } from '@mantine/core';

// // =======================
// // Top Metrics Cards
// // =======================
// function DashboardOverview() {
//   return (
//     <div className="dashboard-overview">
//       <div className="dashboard-card">
//         <h3 className="dashboard-card-title">Active Automations</h3>
//         <p className="dashboard-card-value">123</p>
//       </div>
//       <div className="dashboard-card">
//         <h3 className="dashboard-card-title">Executions (7d)</h3>
//         <p className="dashboard-card-value">456</p>
//       </div>
//       <div className="dashboard-card">
//         <h3 className="dashboard-card-title">Success Rate</h3>
//         <p className="dashboard-card-value">89%</p>
//       </div>
//       <div className="dashboard-card">
//         <h3 className="dashboard-card-title">Engagement Lift</h3>
//         <p className="dashboard-card-value">+12%</p>
//       </div>
//     </div>
//   );
// }

// // =======================
// // Flow Visualization
// // =======================
// function FlowVisualization() {
//   const nodes = [
//     { id: '1', data: { label: 'Pattern A' }, position: { x: 50, y: 50 } },
//     { id: '2', data: { label: 'Webhook X' }, position: { x: 250, y: 50 } },
//     { id: '3', data: { label: 'Webhook Y' }, position: { x: 250, y: 150 } },
//   ];
//   const edges = [
//     { id: 'e1-2', source: '1', target: '2', animated: true },
//     { id: 'e1-3', source: '1', target: '3', animated: true },
//   ];

//   return (
//     <div className="flow-visualization">
//       <h2 className="flow-visualization-header">Automation Flow</h2>
//       <div className="chart-h-400">
//         <ReactFlow nodes={nodes} edges={edges} fitView>
//           <Background />
//           <Controls />
//         </ReactFlow>
//       </div>
//     </div>
//   );
// }

// // =======================
// // Performance Analytics Charts
// // =======================
// function ExecutionVolumeChart() {
//   const data = [
//     {
//       id: 'Executions',
//       data: [
//         { x: '2025-02-12', y: 30 },
//         { x: '2025-02-13', y: 45 },
//         { x: '2025-02-14', y: 50 },
//         { x: '2025-02-15', y: 60 },
//         { x: '2025-02-16', y: 70 },
//         { x: '2025-02-17', y: 55 },
//         { x: '2025-02-18', y: 80 },
//       ],
//     },
//   ];

//   return (
//     <div className="chart-card">
//       <h3 className="chart-card-title">Execution Volume Over Time</h3>
//       <div className="chart-h-300">
//         <ResponsiveLine
//           data={data}
//           margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
//           xScale={{ type: 'point' }}
//           yScale={{ type: 'linear', stacked: false }}
//           axisBottom={{
//             orient: 'bottom',
//             tickSize: 5,
//             tickPadding: 5,
//             tickRotation: 45,
//             legend: 'Date',
//             legendOffset: 36,
//           }}
//           axisLeft={{
//             orient: 'left',
//             tickSize: 5,
//             tickPadding: 5,
//             legend: 'Executions',
//             legendOffset: -40,
//           }}
//           // Colors defined in your CSS
//           colors={() => "var(--mdorange)"}
//           pointSize={6}
//           pointBorderWidth={1}
//           useMesh={true}
//         />
//       </div>
//     </div>
//   );
// }

// function SuccessFailureChart() {
//   const data = [
//     { metric: 'Success', count: 80 },
//     { metric: 'Failure', count: 20 },
//   ];

//   return (
//     <div className="chart-card">
//       <h3 className="chart-card-title">Success vs Failure Rates</h3>
//       <div className="chart-h-300">
//         <ResponsiveBar
//           data={data}
//           keys={['count']}
//           indexBy="metric"
//           margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
//           padding={0.3}
//           colors={() => "var(--mdorange)"}
//           axisBottom={{
//             tickSize: 5,
//             tickPadding: 5,
//             tickRotation: 0,
//             legend: 'Metric',
//             legendPosition: 'middle',
//             legendOffset: 32,
//           }}
//           axisLeft={{
//             tickSize: 5,
//             tickPadding: 5,
//             tickRotation: 0,
//             legend: 'Count',
//             legendPosition: 'middle',
//             legendOffset: -40,
//           }}
//         />
//       </div>
//     </div>
//   );
// }

// function ResponseTimeChart() {
//   const data = [
//     {
//       id: 'Response Time',
//       data: [
//         { x: '100ms', y: 10 },
//         { x: '200ms', y: 30 },
//         { x: '300ms', y: 25 },
//         { x: '400ms', y: 15 },
//         { x: '500ms', y: 5 },
//       ],
//     },
//   ];

//   return (
//     <div className="chart-card">
//       <h3 className="chart-card-title">Response Time Distribution</h3>
//       <div className="chart-h-300">
//         <ResponsiveLine
//           data={data}
//           margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
//           xScale={{ type: 'point' }}
//           yScale={{ type: 'linear', stacked: false }}
//           axisBottom={{
//             orient: 'bottom',
//             tickSize: 5,
//             tickPadding: 5,
//             tickRotation: 0,
//             legend: 'Response Time',
//             legendOffset: 36,
//           }}
//           axisLeft={{
//             orient: 'left',
//             tickSize: 5,
//             tickPadding: 5,
//             legend: 'Frequency',
//             legendOffset: -40,
//           }}
//           colors={() => "var(--mdorange)"}
//           pointSize={6}
//           pointBorderWidth={1}
//           useMesh={true}
//         />
//       </div>
//     </div>
//   );
// }

// function PerformanceAnalytics() {
//   return (
//     <div className="performance-analytics">
//       <ExecutionVolumeChart />
//       <SuccessFailureChart />
//       <ResponseTimeChart />
//     </div>
//   );
// }

// // =======================
// // Performance Table and Detailed Automation Modal
// // =======================
// function PerformanceTable({ onViewDetails }) {
//   const automations = [
//     {
//       id: 1,
//       pattern: 'Pattern A',
//       webhook: 'Webhook X',
//       executions: 120,
//       successRate: '85%',
//       failureRate: '15%',
//       activeDuration: '30 days',
//       status: 'Active',
//     },
//     {
//       id: 2,
//       pattern: 'Pattern B',
//       webhook: 'Webhook Y',
//       executions: 200,
//       successRate: '92%',
//       failureRate: '8%',
//       activeDuration: '45 days',
//       status: 'Active',
//     },
//   ];

//   return (
//     <div className="performance-table">
//       <h2>Automations</h2>
//       <table>
//         <thead>
//           <tr>
//             <th>ID</th>
//             <th>Pattern</th>
//             <th>Webhook</th>
//             <th>Executions</th>
//             <th>Success</th>
//             <th>Failure</th>
//             <th>Action</th>
//           </tr>
//         </thead>
//         <tbody>
//           {automations.map((auto) => (
//             <tr key={auto.id}>
//               <td>{auto.id}</td>
//               <td>{auto.pattern}</td>
//               <td>{auto.webhook}</td>
//               <td>{auto.executions}</td>
//               <td>{auto.successRate}</td>
//               <td>{auto.failureRate}</td>
//               <td>
//                 <Button size="xs" onClick={() => onViewDetails(auto)}>
//                   View Details
//                 </Button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }

// function DetailedAutomationModal({ automation, opened, onClose }) {
//   if (!automation) return null;

//   const detailExecutionData = [
//     {
//       id: 'Execution Trend',
//       data: [
//         { x: 'Day 1', y: 20 },
//         { x: 'Day 2', y: 35 },
//         { x: 'Day 3', y: 40 },
//         { x: 'Day 4', y: 50 },
//         { x: 'Day 5', y: 45 },
//       ],
//     },
//   ];

//   const detailSuccessData = [
//     { metric: 'Success', count: 90 },
//     { metric: 'Failure', count: 10 },
//   ];

//   return (
//     <Modal opened={opened} onClose={onClose} title="Automation Details" size="xl">
//       <div className="modal-details">
//         <div className="border-bottom">
//           <h2>
//             {automation.pattern} - {automation.webhook}
//           </h2>
//           <p>Status: {automation.status} | Active for: {automation.activeDuration}</p>
//         </div>
//         <div className="grid-2cols">
//           <div>
//             <h3>Execution Trend</h3>
//             <div className="chart-h-200">
//               <ResponsiveLine
//                 data={detailExecutionData}
//                 margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
//                 xScale={{ type: 'point' }}
//                 yScale={{ type: 'linear', stacked: false }}
//                 axisBottom={{
//                   orient: 'bottom',
//                   tickSize: 5,
//                   tickPadding: 5,
//                   tickRotation: 45,
//                   legend: 'Day',
//                   legendOffset: 36,
//                 }}
//                 axisLeft={{
//                   orient: 'left',
//                   tickSize: 5,
//                   tickPadding: 5,
//                   legend: 'Executions',
//                   legendOffset: -40,
//                 }}
//                 colors={() => "var(--mdorange)"}
//                 pointSize={4}
//                 pointBorderWidth={1}
//                 useMesh={true}
//               />
//             </div>
//           </div>
//           <div>
//             <h3>Success vs Failure</h3>
//             <div className="chart-h-200">
//               <ResponsiveBar
//                 data={detailSuccessData}
//                 keys={['count']}
//                 indexBy="metric"
//                 margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
//                 padding={0.3}
//                 colors={() => "var(--mdorange)"}
//                 axisBottom={{
//                   tickSize: 5,
//                   tickPadding: 5,
//                   legend: 'Metric',
//                   legendPosition: 'middle',
//                   legendOffset: 32,
//                 }}
//                 axisLeft={{
//                   tickSize: 5,
//                   tickPadding: 5,
//                   legend: 'Count',
//                   legendPosition: 'middle',
//                   legendOffset: -40,
//                 }}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="border-top">
//           <h3>Technical Details</h3>
//           <p>
//             Recent logs, error distribution and configuration summary would be displayed here.
//           </p>
//         </div>
//       </div>
//     </Modal>
//   );
// }

// // =======================
// // Alert Configuration
// // =======================
// function AlertConfiguration() {
//   return (
//     <div className="alert-configuration">
//       <h3>Alert Configuration</h3>
//       <div className="form-group">
//         <label>Metric</label>
//         <Select
//           data={[
//             { value: 'successRate', label: 'Success Rate' },
//             { value: 'executionVolume', label: 'Execution Volume' },
//             { value: 'responseTime', label: 'Response Time' },
//             { value: 'errorRate', label: 'Error Rate' },
//           ]}
//           placeholder="Select Metric"
//         />
//       </div>
//       <div className="form-group">
//         <label>Threshold</label>
//         <Slider
//           min={0}
//           max={100}
//           defaultValue={50}
//           marks={[
//             { value: 0, label: '0%' },
//             { value: 50, label: '50%' },
//             { value: 100, label: '100%' },
//           ]}
//         />
//       </div>
//       <div className="form-group">
//         <label>Time Window</label>
//         <Select
//           data={[
//             { value: '1h', label: '1 Hour' },
//             { value: '24h', label: '24 Hours' },
//             { value: '7d', label: '7 Days' },
//           ]}
//           placeholder="Select Time Window"
//         />
//       </div>
//       <Button variant="filled">Save Alert</Button>
//     </div>
//   );
// }

// // =======================
// // Recommendation Center and Detail Modal
// // =======================
// function RecommendationCenter({ onViewRecommendation }) {
//   const recommendations = [
//     {
//       id: 1,
//       title: 'Increase webhook timeout',
//       impact: 'High',
//       effort: 'Medium',
//       details:
//         'By increasing the webhook timeout, you may see fewer failures and improved execution rates. Historical data shows a trend of improvement when timeouts are adjusted.',
//     },
//     {
//       id: 2,
//       title: 'Optimize pattern matching',
//       impact: 'Medium',
//       effort: 'Low',
//       details:
//         'Adjusting pattern matching logic can reduce false negatives. Similar configurations in other cases have resulted in improved overall success rates.',
//     },
//   ];

//   return (
//     <div className="recommendation-center">
//       <h3>Recommendation Center</h3>
//       {recommendations.map((rec) => (
//         <div key={rec.id} className="recommendation-item">
//           <div className="recommendation-header">
//             <span className="recommendation-title">{rec.title}</span>
//             <Button variant="outline" size="xs" onClick={() => onViewRecommendation(rec)}>
//               View Details
//             </Button>
//           </div>
//           <p>
//             Impact: {rec.impact} | Effort: {rec.effort}
//           </p>
//         </div>
//       ))}
//     </div>
//   );
// }

// function RecommendationDetailModal({ recommendation, opened, onClose }) {
//   if (!recommendation) return null;

//   const beforeAfterData = [
//     { metric: 'Success Rate', before: 80, after: 90 },
//     { metric: 'Execution Volume', before: 150, after: 180 },
//   ];

//   const chartData = beforeAfterData.map((item) => ({
//     metric: item.metric,
//     before: item.before,
//     after: item.after,
//   }));

//   return (
//     <Modal opened={opened} onClose={onClose} title="Recommendation Details" size="xl">
//       <div className="modal-details">
//         <div className="border-bottom">
//           <h2>{recommendation.title}</h2>
//           <p>{recommendation.details}</p>
//         </div>
//         <div>
//           <h3 className="section-title">Before/After Comparison</h3>
//           <div className="chart-h-300">
//             <ResponsiveBar
//               data={chartData}
//               keys={['before', 'after']}
//               indexBy="metric"
//               margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
//               padding={0.3}
//               colors={() => "var(--mdorange)"}
//               axisBottom={{
//                 tickSize: 5,
//                 tickPadding: 5,
//                 legend: 'Metric',
//                 legendPosition: 'middle',
//                 legendOffset: 32,
//               }}
//               axisLeft={{
//                 tickSize: 5,
//                 tickPadding: 5,
//                 legend: 'Value',
//                 legendPosition: 'middle',
//                 legendOffset: -40,
//               }}
//             />
//           </div>
//         </div>
//         <div className="border-top">
//           <h3 className="section-title">Implementation Workflow</h3>
//           <div className="workflow-steps">
//             <p>Step 1: Review recommendation details and preview expected impact.</p>
//             <p>Step 2: Choose to Accept or Reject the recommendation.</p>
//             <p>Step 3: If accepted, schedule implementation and monitor progress.</p>
//           </div>
//           <div className="workflow-actions">
//             <Button color="green">Accept</Button>
//             <Button color="red">Reject</Button>
//           </div>
//         </div>
//       </div>
//     </Modal>
//   );
// }

// // =======================
// // Main Dashboard Page
// // =======================
// function ActionsPerformanceDashboard() {
//   const [dateRange, setDateRange] = useState('7d');
//   const [segment, setSegment] = useState('all');
//   const [selectedAutomation, setSelectedAutomation] = useState(null);
//   const [automationModalOpen, setAutomationModalOpen] = useState(false);
//   const [selectedRecommendation, setSelectedRecommendation] = useState(null);
//   const [recommendationModalOpen, setRecommendationModalOpen] = useState(false);

//   const handleViewDetails = (automation) => {
//     setSelectedAutomation(automation);
//     setAutomationModalOpen(true);
//   };

//   const handleViewRecommendation = (recommendation) => {
//     setSelectedRecommendation(recommendation);
//     setRecommendationModalOpen(true);
//   };

//   return (
//     <div className="dashboard-container">
//       <header className="dashboard-header">
//         <h1 className="dashboard-title">Automation Performance Dashboard</h1>
//         <div className="filter-container">
//           <div className="filter-group">
//             <label className="filter-label">Date Range:</label>
//             <Select
//               value={dateRange}
//               onChange={setDateRange}
//               data={[
//                 { value: '24h', label: 'Last 24h' },
//                 { value: '7d', label: 'Last 7 days' },
//                 { value: '30d', label: 'Last 30 days' },
//                 { value: 'custom', label: 'Custom' },
//               ]}
//               className="filter-select"
//             />
//           </div>
//           <div className="filter-group">
//             <label className="filter-label">Segment:</label>
//             <Select
//               value={segment}
//               onChange={setSegment}
//               data={[
//                 { value: 'all', label: 'All Users' },
//                 { value: 'segmentA', label: 'Segment A' },
//                 { value: 'segmentB', label: 'Segment B' },
//               ]}
//               className="filter-select"
//             />
//           </div>
//         </div>
//       </header>

//       <DashboardOverview />
//       <FlowVisualization />
//       <PerformanceAnalytics />
//       <PerformanceTable onViewDetails={handleViewDetails} />

//       <div className="dashboard-two-columns">
//         <AlertConfiguration />
//         <RecommendationCenter onViewRecommendation={handleViewRecommendation} />
//       </div>

//       <DetailedAutomationModal
//         automation={selectedAutomation}
//         opened={automationModalOpen}
//         onClose={() => setAutomationModalOpen(false)}
//       />

//       <RecommendationDetailModal
//         recommendation={selectedRecommendation}
//         opened={recommendationModalOpen}
//         onClose={() => setRecommendationModalOpen(false)}
//       />
//     </div>
//   );
// }

// export default ActionsPerformanceDashboard;



// // import React, { useState } from 'react';
// // import { ResponsiveLine } from '@nivo/line';
// // import { ResponsiveBar } from '@nivo/bar';
// // import ReactFlow, { Background, Controls } from 'react-flow-renderer';
// // import { Modal, Button, Select, Slider } from '@mantine/core';

// // // =======================
// // // Top Metrics Cards
// // // =======================
// // function DashboardOverview() {
// //   return (
// //     <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
// //       <div className="bg-white shadow p-4 rounded">
// //         <h3 className="text-lg font-bold">Active Automations</h3>
// //         <p className="text-2xl">123</p>
// //       </div>
// //       <div className="bg-white shadow p-4 rounded">
// //         <h3 className="text-lg font-bold">Executions (7d)</h3>
// //         <p className="text-2xl">456</p>
// //       </div>
// //       <div className="bg-white shadow p-4 rounded">
// //         <h3 className="text-lg font-bold">Success Rate</h3>
// //         <p className="text-2xl">89%</p>
// //       </div>
// //       <div className="bg-white shadow p-4 rounded">
// //         <h3 className="text-lg font-bold">Engagement Lift</h3>
// //         <p className="text-2xl">+12%</p>
// //       </div>
// //     </div>
// //   );
// // }

// // // =======================
// // // Flow Visualization
// // // =======================
// // function FlowVisualization() {
// //   const nodes = [
// //     { id: '1', data: { label: 'Pattern A' }, position: { x: 50, y: 50 } },
// //     { id: '2', data: { label: 'Webhook X' }, position: { x: 250, y: 50 } },
// //     { id: '3', data: { label: 'Webhook Y' }, position: { x: 250, y: 150 } },
// //   ];
// //   const edges = [
// //     { id: 'e1-2', source: '1', target: '2', animated: true },
// //     { id: 'e1-3', source: '1', target: '3', animated: true },
// //   ];

// //   return (
// //     <div className="bg-white shadow rounded p-4 mb-6">
// //       <h2 className="text-xl font-bold mb-2">Automation Flow</h2>
// //       <div style={{ height: 400 }}>
// //         <ReactFlow nodes={nodes} edges={edges} fitView>
// //           <Background />
// //           <Controls />
// //         </ReactFlow>
// //       </div>
// //     </div>
// //   );
// // }

// // // =======================
// // // Performance Analytics Charts
// // // -----------------------
// // // Execution Volume Chart
// // function ExecutionVolumeChart() {
// //   const data = [
// //     {
// //       id: 'Executions',
// //       data: [
// //         { x: '2025-02-12', y: 30 },
// //         { x: '2025-02-13', y: 45 },
// //         { x: '2025-02-14', y: 50 },
// //         { x: '2025-02-15', y: 60 },
// //         { x: '2025-02-16', y: 70 },
// //         { x: '2025-02-17', y: 55 },
// //         { x: '2025-02-18', y: 80 },
// //       ],
// //     },
// //   ];

// //   return (
// //     <div className="bg-white shadow rounded p-4">
// //       <h3 className="text-lg font-bold mb-2">Execution Volume Over Time</h3>
// //       <div style={{ height: 300 }}>
// //         <ResponsiveLine
// //           data={data}
// //           margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
// //           xScale={{ type: 'point' }}
// //           yScale={{ type: 'linear', stacked: false }}
// //           axisBottom={{
// //             orient: 'bottom',
// //             tickSize: 5,
// //             tickPadding: 5,
// //             tickRotation: 45,
// //             legend: 'Date',
// //             legendOffset: 36,
// //           }}
// //           axisLeft={{
// //             orient: 'left',
// //             tickSize: 5,
// //             tickPadding: 5,
// //             legend: 'Executions',
// //             legendOffset: -40,
// //           }}
// //           colors={{ scheme: 'nivo' }}
// //           pointSize={6}
// //           pointBorderWidth={1}
// //           useMesh={true}
// //         />
// //       </div>
// //     </div>
// //   );
// // }

// // // Success / Failure Rates Chart
// // function SuccessFailureChart() {
// //   const data = [
// //     { metric: 'Success', count: 80 },
// //     { metric: 'Failure', count: 20 },
// //   ];

// //   return (
// //     <div className="bg-white shadow rounded p-4">
// //       <h3 className="text-lg font-bold mb-2">Success vs Failure Rates</h3>
// //       <div style={{ height: 300 }}>
// //         <ResponsiveBar
// //           data={data}
// //           keys={['count']}
// //           indexBy="metric"
// //           margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
// //           padding={0.3}
// //           colors={{ scheme: 'nivo' }}
// //           axisBottom={{
// //             tickSize: 5,
// //             tickPadding: 5,
// //             tickRotation: 0,
// //             legend: 'Metric',
// //             legendPosition: 'middle',
// //             legendOffset: 32,
// //           }}
// //           axisLeft={{
// //             tickSize: 5,
// //             tickPadding: 5,
// //             tickRotation: 0,
// //             legend: 'Count',
// //             legendPosition: 'middle',
// //             legendOffset: -40,
// //           }}
// //         />
// //       </div>
// //     </div>
// //   );
// // }

// // // Response Time Distribution Chart
// // function ResponseTimeChart() {
// //   const data = [
// //     {
// //       id: 'Response Time',
// //       data: [
// //         { x: '100ms', y: 10 },
// //         { x: '200ms', y: 30 },
// //         { x: '300ms', y: 25 },
// //         { x: '400ms', y: 15 },
// //         { x: '500ms', y: 5 },
// //       ],
// //     },
// //   ];

// //   return (
// //     <div className="bg-white shadow rounded p-4">
// //       <h3 className="text-lg font-bold mb-2">Response Time Distribution</h3>
// //       <div style={{ height: 300 }}>
// //         <ResponsiveLine
// //           data={data}
// //           margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
// //           xScale={{ type: 'point' }}
// //           yScale={{ type: 'linear', stacked: false }}
// //           axisBottom={{
// //             orient: 'bottom',
// //             tickSize: 5,
// //             tickPadding: 5,
// //             tickRotation: 0,
// //             legend: 'Response Time',
// //             legendOffset: 36,
// //           }}
// //           axisLeft={{
// //             orient: 'left',
// //             tickSize: 5,
// //             tickPadding: 5,
// //             legend: 'Frequency',
// //             legendOffset: -40,
// //           }}
// //           colors={{ scheme: 'nivo' }}
// //           pointSize={6}
// //           pointBorderWidth={1}
// //           useMesh={true}
// //         />
// //       </div>
// //     </div>
// //   );
// // }

// // // Container for performance analytics charts
// // function PerformanceAnalytics() {
// //   return (
// //     <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
// //       <ExecutionVolumeChart />
// //       <SuccessFailureChart />
// //       <ResponseTimeChart />
// //     </div>
// //   );
// // }

// // // =======================
// // // Performance Table and Detailed Automation Modal
// // // =======================
// // function PerformanceTable({ onViewDetails }) {
// //   // Dummy automation data
// //   const automations = [
// //     {
// //       id: 1,
// //       pattern: 'Pattern A',
// //       webhook: 'Webhook X',
// //       executions: 120,
// //       successRate: '85%',
// //       failureRate: '15%',
// //       activeDuration: '30 days',
// //       status: 'Active',
// //     },
// //     {
// //       id: 2,
// //       pattern: 'Pattern B',
// //       webhook: 'Webhook Y',
// //       executions: 200,
// //       successRate: '92%',
// //       failureRate: '8%',
// //       activeDuration: '45 days',
// //       status: 'Active',
// //     },
// //   ];

// //   return (
// //     <div className="bg-white shadow rounded p-4 mb-6">
// //       <h2 className="text-xl font-bold mb-4">Automations</h2>
// //       <table className="min-w-full divide-y divide-gray-200">
// //         <thead>
// //           <tr className="text-left">
// //             <th className="px-4 py-2">ID</th>
// //             <th className="px-4 py-2">Pattern</th>
// //             <th className="px-4 py-2">Webhook</th>
// //             <th className="px-4 py-2">Executions</th>
// //             <th className="px-4 py-2">Success</th>
// //             <th className="px-4 py-2">Failure</th>
// //             <th className="px-4 py-2">Action</th>
// //           </tr>
// //         </thead>
// //         <tbody className="divide-y divide-gray-100">
// //           {automations.map((auto) => (
// //             <tr key={auto.id}>
// //               <td className="px-4 py-2">{auto.id}</td>
// //               <td className="px-4 py-2">{auto.pattern}</td>
// //               <td className="px-4 py-2">{auto.webhook}</td>
// //               <td className="px-4 py-2">{auto.executions}</td>
// //               <td className="px-4 py-2">{auto.successRate}</td>
// //               <td className="px-4 py-2">{auto.failureRate}</td>
// //               <td className="px-4 py-2">
// //                 <Button size="xs" onClick={() => onViewDetails(auto)}>
// //                   View Details
// //                 </Button>
// //               </td>
// //             </tr>
// //           ))}
// //         </tbody>
// //       </table>
// //     </div>
// //   );
// // }

// // function DetailedAutomationModal({ automation, opened, onClose }) {
// //   if (!automation) return null;

// //   // Dummy data for detailed charts inside modal (could be refined further)
// //   const detailExecutionData = [
// //     {
// //       id: 'Execution Trend',
// //       data: [
// //         { x: 'Day 1', y: 20 },
// //         { x: 'Day 2', y: 35 },
// //         { x: 'Day 3', y: 40 },
// //         { x: 'Day 4', y: 50 },
// //         { x: 'Day 5', y: 45 },
// //       ],
// //     },
// //   ];

// //   const detailSuccessData = [
// //     { metric: 'Success', count: 90 },
// //     { metric: 'Failure', count: 10 },
// //   ];

// //   return (
// //     <Modal opened={opened} onClose={onClose} title="Automation Details" size="xl">
// //       <div className="space-y-4 space-x-4">
// //         <div className="border-b pb-2">
// //           <h2 className="text-2xl font-bold">
// //             {automation.pattern} - {automation.webhook}
// //           </h2>
// //           <p>Status: {automation.status} | Active for: {automation.activeDuration}</p>
// //         </div>
// //         <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
// //           <div>
// //             <h3 className="font-semibold">Execution Trend</h3>
// //             <div style={{ height: 200 }}>
// //               <ResponsiveLine
// //                 data={detailExecutionData}
// //                 margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
// //                 xScale={{ type: 'point' }}
// //                 yScale={{ type: 'linear', stacked: false }}
// //                 axisBottom={{
// //                   orient: 'bottom',
// //                   tickSize: 5,
// //                   tickPadding: 5,
// //                   tickRotation: 45,
// //                   legend: 'Day',
// //                   legendOffset: 36,
// //                 }}
// //                 axisLeft={{
// //                   orient: 'left',
// //                   tickSize: 5,
// //                   tickPadding: 5,
// //                   legend: 'Executions',
// //                   legendOffset: -40,
// //                 }}
// //                 colors={{ scheme: 'nivo' }}
// //                 pointSize={4}
// //                 pointBorderWidth={1}
// //                 useMesh={true}
// //               />
// //             </div>
// //           </div>
// //           <div>
// //             <h3 className="font-semibold">Success vs Failure</h3>
// //             <div style={{ height: 200 }}>
// //               <ResponsiveBar
// //                 data={detailSuccessData}
// //                 keys={['count']}
// //                 indexBy="metric"
// //                 margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
// //                 padding={0.3}
// //                 colors={{ scheme: 'nivo' }}
// //                 axisBottom={{
// //                   tickSize: 5,
// //                   tickPadding: 5,
// //                   legend: 'Metric',
// //                   legendPosition: 'middle',
// //                   legendOffset: 32,
// //                 }}
// //                 axisLeft={{
// //                   tickSize: 5,
// //                   tickPadding: 5,
// //                   legend: 'Count',
// //                   legendPosition: 'middle',
// //                   legendOffset: -40,
// //                 }}
// //               />
// //             </div>
// //           </div>
// //         </div>
// //         <div className="border-t pt-2">
// //           <h3 className="font-semibold">Technical Details</h3>
// //           <p className="text-sm text-gray-600">
// //             Recent logs, error distribution and configuration summary would be displayed here.
// //           </p>
// //         </div>
// //       </div>
// //     </Modal>
// //   );
// // }

// // // =======================
// // // Alert Configuration
// // // =======================
// // function AlertConfiguration() {
// //   return (
// //     <div className="bg-white shadow rounded p-4">
// //       <h3 className="text-lg font-bold mb-2">Alert Configuration</h3>
// //       <div className="mb-4">
// //         <label className="block mb-1 font-medium">Metric</label>
// //         <Select
// //           data={[
// //             { value: 'successRate', label: 'Success Rate' },
// //             { value: 'executionVolume', label: 'Execution Volume' },
// //             { value: 'responseTime', label: 'Response Time' },
// //             { value: 'errorRate', label: 'Error Rate' },
// //           ]}
// //           placeholder="Select Metric"
// //         />
// //       </div>
// //       <div className="mb-4">
// //         <label className="block mb-1 font-medium">Threshold</label>
// //         <Slider
// //           min={0}
// //           max={100}
// //           defaultValue={50}
// //           marks={[
// //             { value: 0, label: '0%' },
// //             { value: 50, label: '50%' },
// //             { value: 100, label: '100%' },
// //           ]}
// //         />
// //       </div>
// //       <div className="mb-4">
// //         <label className="block mb-1 font-medium">Time Window</label>
// //         <Select
// //           data={[
// //             { value: '1h', label: '1 Hour' },
// //             { value: '24h', label: '24 Hours' },
// //             { value: '7d', label: '7 Days' },
// //           ]}
// //           placeholder="Select Time Window"
// //         />
// //       </div>
// //       <Button variant="filled">Save Alert</Button>
// //     </div>
// //   );
// // }

// // // =======================
// // // Recommendation Center and Detail Modal
// // // =======================
// // function RecommendationCenter({ onViewRecommendation }) {
// //   // Dummy recommendation list
// //   const recommendations = [
// //     {
// //       id: 1,
// //       title: 'Increase webhook timeout',
// //       impact: 'High',
// //       effort: 'Medium',
// //       details:
// //         'By increasing the webhook timeout, you may see fewer failures and improved execution rates. Historical data shows a trend of improvement when timeouts are adjusted.',
// //     },
// //     {
// //       id: 2,
// //       title: 'Optimize pattern matching',
// //       impact: 'Medium',
// //       effort: 'Low',
// //       details:
// //         'Adjusting pattern matching logic can reduce false negatives. Similar configurations in other cases have resulted in improved overall success rates.',
// //     },
// //   ];

// //   return (
// //     <div className="bg-white shadow rounded p-4">
// //       <h3 className="text-lg font-bold mb-2">Recommendation Center</h3>
// //       {recommendations.map((rec) => (
// //         <div key={rec.id} className="border-b py-2 last:border-b-0">
// //           <div className="flex justify-between items-center">
// //             <span className="font-medium">{rec.title}</span>
// //             <Button variant="outline" size="xs" onClick={() => onViewRecommendation(rec)}>
// //               View Details
// //             </Button>
// //           </div>
// //           <p className="text-sm text-gray-500">
// //             Impact: {rec.impact} | Effort: {rec.effort}
// //           </p>
// //         </div>
// //       ))}
// //     </div>
// //   );
// // }

// // function RecommendationDetailModal({ recommendation, opened, onClose }) {
// //   if (!recommendation) return null;

// //   // Dummy before/after visualization data
// //   const beforeAfterData = [
// //     {
// //       metric: 'Success Rate',
// //       before: 80,
// //       after: 90,
// //     },
// //     {
// //       metric: 'Execution Volume',
// //       before: 150,
// //       after: 180,
// //     },
// //   ];

// //   // Transform data for a simple bar chart (two groups: before and after)
// //   const chartData = beforeAfterData.map((item) => ({
// //     metric: item.metric,
// //     before: item.before,
// //     after: item.after,
// //   }));

// //   return (
// //     <Modal opened={opened} onClose={onClose} title="Recommendation Details" size="xl">
// //       <div className="space-y-4">
// //         <div className="border-b pb-2">
// //           <h2 className="text-2xl font-bold">{recommendation.title}</h2>
// //           <p>{recommendation.details}</p>
// //         </div>
// //         <div>
// //           <h3 className="font-semibold mb-2">Before/After Comparison</h3>
// //           <div style={{ height: 300 }}>
// //             <ResponsiveBar
// //               data={chartData}
// //               keys={['before', 'after']}
// //               indexBy="metric"
// //               margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
// //               padding={0.3}
// //               colors={{ scheme: 'nivo' }}
// //               axisBottom={{
// //                 tickSize: 5,
// //                 tickPadding: 5,
// //                 legend: 'Metric',
// //                 legendPosition: 'middle',
// //                 legendOffset: 32,
// //               }}
// //               axisLeft={{
// //                 tickSize: 5,
// //                 tickPadding: 5,
// //                 legend: 'Value',
// //                 legendPosition: 'middle',
// //                 legendOffset: -40,
// //               }}
// //             />
// //           </div>
// //         </div>
// //         <div className="border-t pt-2">
// //           <h3 className="font-semibold mb-2">Implementation Workflow</h3>
// //           <div className="space-y-2">
// //             <p>Step 1: Review recommendation details and preview expected impact.</p>
// //             <p>Step 2: Choose to Accept or Reject the recommendation.</p>
// //             <p>Step 3: If accepted, schedule implementation and monitor progress.</p>
// //           </div>
// //           <div className="flex space-x-2 mt-4">
// //             <Button color="green">Accept</Button>
// //             <Button color="red">Reject</Button>
// //           </div>
// //         </div>
// //       </div>
// //     </Modal>
// //   );
// // }

// // // =======================
// // // Main Dashboard Page
// // // =======================
// // function ActionsPerformanceDashboard() {
// //   // Filter state
// //   const [dateRange, setDateRange] = useState('7d');
// //   const [segment, setSegment] = useState('all');

// //   // Modal state for automation details and recommendation details
// //   const [selectedAutomation, setSelectedAutomation] = useState(null);
// //   const [automationModalOpen, setAutomationModalOpen] = useState(false);
// //   const [selectedRecommendation, setSelectedRecommendation] = useState(null);
// //   const [recommendationModalOpen, setRecommendationModalOpen] = useState(false);

// //   const handleViewDetails = (automation) => {
// //     setSelectedAutomation(automation);
// //     setAutomationModalOpen(true);
// //   };

// //   const handleViewRecommendation = (recommendation) => {
// //     setSelectedRecommendation(recommendation);
// //     setRecommendationModalOpen(true);
// //   };

// //   return (
// //     <div className="p-6 bg-gray-100 min-h-screen space-y-6">
// //       {/* Header & Filters */}
// //       <header className="flex flex-col md:flex-row justify-between items-center">
// //         <h1 className="text-3xl font-bold mb-4 md:mb-0">Automation Performance Dashboard</h1>
// //         <div className="flex space-x-4 items-center">
// //           <div className="flex items-center">
// //             <label className="mr-2 font-medium">Date Range:</label>
// //             <Select
// //               value={dateRange}
// //               onChange={setDateRange}
// //               data={[
// //                 { value: '24h', label: 'Last 24h' },
// //                 { value: '7d', label: 'Last 7 days' },
// //                 { value: '30d', label: 'Last 30 days' },
// //                 { value: 'custom', label: 'Custom' },
// //               ]}
// //               className="w-40"
// //             />
// //           </div>
// //           <div className="flex items-center">
// //             <label className="mr-2 font-medium">Segment:</label>
// //             <Select
// //               value={segment}
// //               onChange={setSegment}
// //               data={[
// //                 { value: 'all', label: 'All Users' },
// //                 { value: 'segmentA', label: 'Segment A' },
// //                 { value: 'segmentB', label: 'Segment B' },
// //               ]}
// //               className="w-40"
// //             />
// //           </div>
// //         </div>
// //       </header>

// //       {/* Top Metrics */}
// //       <DashboardOverview />

// //       {/* Flow Visualization */}
// //       <FlowVisualization />

// //       {/* Performance Analytics */}
// //       <PerformanceAnalytics />

// //       {/* Automation Table */}
// //       <PerformanceTable onViewDetails={handleViewDetails} />

// //       {/* Alerts & Recommendations Section */}
// //       <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
// //         <AlertConfiguration />
// //         <RecommendationCenter onViewRecommendation={handleViewRecommendation} />
// //       </div>

// //       {/* Detailed Automation Modal */}
// //       <DetailedAutomationModal
// //         automation={selectedAutomation}
// //         opened={automationModalOpen}
// //         onClose={() => setAutomationModalOpen(false)}
// //       />

// //       {/* Recommendation Detail Modal */}
// //       <RecommendationDetailModal
// //         recommendation={selectedRecommendation}
// //         opened={recommendationModalOpen}
// //         onClose={() => setRecommendationModalOpen(false)}
// //       />
// //     </div>
// //   );
// // }

// // export default ActionsPerformanceDashboard;
