import React from 'react';
import ReactFlow, { 
    MiniMap, 
    Background, 
    Controls,
    useNodesState,
    useEdgesState,
    Handle,
    Position
} from 'react-flow-renderer';
import { useDataset } from './DataFetcher';

const COLORS = {
    dkpurple: '#2c294b',
    ltgray: '#ebe5df',
    mdgray: '#958e86',
    cranberry: '#762861'
};

const CustomEventNode = ({ data }) => (
    <div
        style={{
            background: COLORS.ltgray,
            color: COLORS.dkpurple,
            padding: '10px',
            borderRadius: '5px',
            border: `1px solid ${data.isAlt ? COLORS.mdgray : COLORS.dkpurple}`,
            fontSize: '12px',
            width: 'auto',
            minWidth: '120px',
            position: 'relative',
            opacity: data.isAlt ? 0.7 : 1,
            cursor: 'pointer'
        }}
    >
        <Handle type="target" position={Position.Top} />
        {!data.isAlt && data.altCount > 0 && (
            <div
                style={{
                    position: 'absolute',
                    top: '-8px',
                    right: '-8px',
                    background: COLORS.cranberry,
                    color: 'white',
                    padding: '2px 6px',
                    borderRadius: '4px',
                    fontSize: '10px',
                    border: `1px solid ${COLORS.cranberry}`,
                    zIndex: 10,
                    pointerEvents: 'none'
                }}
            >
                +{data.altCount}
            </div>
        )}
        {data.label}
        <Handle type="source" position={Position.Bottom} />
    </div>
);

const FlowVisualization = ({ nodeNames }) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [alternativePaths, setAlternativePaths] = React.useState({});
    const [expandedPaths, setExpandedPaths] = React.useState(new Set());
    const { data, status } = useDataset();

    const nodeTypes = React.useMemo(() => ({
        eventNode: CustomEventNode
    }), []);

    // Handle node click
    const onNodeClick = React.useCallback((event, node) => {
        if (node.type === 'eventNode') {
            for (const [pairKey, paths] of Object.entries(alternativePaths)) {
                if (paths.some(p => p.event === node.id || p.event === node.id.replace('-alt', ''))) {
                    setExpandedPaths(prev => {
                        const newSet = new Set(prev);
                        if (newSet.has(pairKey)) {
                            newSet.delete(pairKey);
                        } else {
                            newSet.add(pairKey);
                        }
                        return newSet;
                    });
                    break;
                }
            }
        }
    }, [alternativePaths]);

    React.useEffect(() => {
        if (status === 'success' && nodeNames?.length >= 2 && data?.MarkovChain) {
            try {
                let markovData = JSON.parse(JSON.parse(data.MarkovChain));
                const newNodes = [];
                const newEdges = [];
                const pathsMap = {};

                // Add main page nodes
                nodeNames.forEach((name, index) => {
                    newNodes.push({
                        id: name,
                        position: { 
                            x: 100 + (index * 300), 
                            y: 300 
                        },
                        data: { 
                            label: name,
                            isPage: true
                        },
                        style: {
                            background: COLORS.dkpurple,
                            color: 'white',
                            padding: '10px',
                            borderRadius: '5px',
                            border: `1px solid ${COLORS.dkpurple}`,
                            width: 'auto',
                            minWidth: '150px',
                            fontSize: '14px'
                        }
                    });
                });

                // Find connections between pages
                for (let i = 0; i < nodeNames.length - 1; i++) {
                    const sourcePage = nodeNames[i];
                    const targetPage = nodeNames[i + 1];
                    const pairKey = `${sourcePage}-${targetPage}`;

                    // Find all possible event paths
                    const eventPaths = [];
                    markovData.forEach(conn1 => {
                        if (conn1.From === sourcePage && conn1.To.includes('@')) {
                            markovData.forEach(conn2 => {
                                if (conn2.From === conn1.To && conn2.To === targetPage) {
                                    eventPaths.push({
                                        event: conn1.To,
                                        toEventProb: conn1.Probability,
                                        fromEventProb: conn2.Probability,
                                        totalProb: conn1.Probability * conn2.Probability
                                    });
                                }
                            });
                        }
                    });

                    // Sort paths by probability
                    eventPaths.sort((a, b) => b.totalProb - a.totalProb);
                    pathsMap[pairKey] = eventPaths;

                    // Sort all paths by probability before selecting primary
                    eventPaths.sort((a, b) => b.totalProb - a.totalProb);
                    
                    if (eventPaths.length > 0) {
                        const bestPath = eventPaths[0];
                        const eventNode = bestPath.event;

                        // Add primary event node
                        newNodes.push({
                            id: eventNode,
                            type: 'eventNode',
                            position: { 
                                x: 100 + (i * 300) + 150, 
                                y: 150 // Start higher to leave room for sorting
                            },
                            data: { 
                                label: bestPath.event.split('@')[0],
                                altCount: eventPaths.length - 1,
                                isAlt: false
                            }
                        });

                        // Add edges for primary path
                        newEdges.push({
                            id: `edge-${sourcePage}-${eventNode}`,
                            source: sourcePage,
                            target: eventNode,
                            label: `${(bestPath.toEventProb * 100).toFixed(1)}%`,
                            style: {
                                stroke: COLORS.dkpurple,
                                strokeWidth: 2,
                                opacity: 0.5
                            }
                        });

                        newEdges.push({
                            id: `edge-${eventNode}-${targetPage}`,
                            source: eventNode,
                            target: targetPage,
                            label: `${(bestPath.fromEventProb * 100).toFixed(1)}%`,
                            style: {
                                stroke: COLORS.dkpurple,
                                strokeWidth: 2,
                                opacity: 0.5
                            }
                        });

                        // Add alternative paths if expanded
                        if (expandedPaths.has(pairKey)) {
                            eventPaths.slice(1)
                                .sort((a, b) => b.totalProb - a.totalProb)
                                .forEach((path, altIndex) => {
                                    const altEventNode = path.event;
                                    newNodes.push({
                                        id: `${altEventNode}-alt`,
                                        type: 'eventNode',
                                        position: {
                                            x: 100 + (i * 300) + 150,
                                            y: 150 + ((altIndex + 1) * 100) // Closer vertical spacing for better visibility
                                        },
                                        data: { 
                                            label: path.event.split('@')[0],
                                            isAlt: true
                                        }
                                    });

                                    newEdges.push({
                                        id: `edge-${sourcePage}-${altEventNode}-alt`,
                                        source: sourcePage,
                                        target: `${altEventNode}-alt`,
                                        label: `${(path.toEventProb * 100).toFixed(1)}%`,
                                        style: {
                                            stroke: COLORS.mdgray,
                                            strokeWidth: 1,
                                            opacity: 0.3
                                        }
                                    });

                                    newEdges.push({
                                        id: `edge-${altEventNode}-alt-${targetPage}`,
                                        source: `${altEventNode}-alt`,
                                        target: targetPage,
                                        label: `${(path.fromEventProb * 100).toFixed(1)}%`,
                                        style: {
                                            stroke: COLORS.mdgray,
                                            strokeWidth: 1,
                                            opacity: 0.3
                                        }
                                    });
                                });
                        }
                    }

                    // Direct connections
                    const directConn = markovData.find(conn => 
                        conn.From === sourcePage && conn.To === targetPage
                    );
                    
                    if (directConn && directConn.Probability > 0.001) {
                        newEdges.push({
                            id: `edge-direct-${sourcePage}-${targetPage}`,
                            source: sourcePage,
                            target: targetPage,
                            label: `${(directConn.Probability * 100).toFixed(1)}%`,
                            style: {
                                stroke: COLORS.dkpurple,
                                strokeWidth: Math.max(1, directConn.Probability * 3),
                                opacity: 0.5
                            }
                        });
                    }
                }

                setAlternativePaths(pathsMap);
                setNodes(newNodes);
                setEdges(newEdges);
            } catch (error) {
                console.error("Error processing flow data:", error);
                console.error("Error details:", error.message);
            }
        } else if (nodeNames.length === 1) {
            const newNodes = nodeNames.map((name) => ({
                id: name,
                position: { x: 100, y: 300 },
                data: { 
                    label: name,
                    isPage: true 
                },
                style: {
                    background: COLORS.dkpurple,
                    color: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                    border: `1px solid ${COLORS.dkpurple}`,
                    width: 'auto',
                    minWidth: '150px',
                    fontSize: '14px'
                }
            }));
            setNodes(newNodes);
            setEdges([]);
        }
    }, [nodeNames, data, status, expandedPaths]);

    return (
        <div style={{ height: '600px', border: '1px solid #ddd' }}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodeClick={onNodeClick}
                nodeTypes={nodeTypes}
                fitView
                defaultViewport={{ x: 0, y: 0, zoom: 0.8 }}
            >
                <Background color={COLORS.ltgray} gap={16} />
                <Controls />
                <MiniMap
                    nodeColor={node => {
                        if (node.data?.isPage) return COLORS.dkpurple;
                        return COLORS.ltgray;
                    }}
                    nodeStrokeColor={() => COLORS.dkpurple}
                    nodeBorderRadius={5}
                />
            </ReactFlow>
        </div>
    );
};

export default FlowVisualization;



// // FlowVisualization.jsx
// import React, { useState, useEffect } from 'react';
// import ReactFlow, { 
//     MiniMap, 
//     Background, 
//     Controls,
//     useNodesState,
//     useEdgesState
// } from 'react-flow-renderer';
// import { useDataset } from './DataFetcher';

// const COLORS = {
//     dkpurple: '#2c294b',
//     ltgray: '#ebe5df',
//     mdgray: '#958e86'
// };

// const FlowVisualization = ({ nodeNames }) => {
//     const [nodes, setNodes, onNodesChange] = useNodesState([]);
//     const [edges, setEdges, onEdgesChange] = useEdgesState([]);
//     const [alternativePaths, setAlternativePaths] = useState({});
//     const [expandedPaths, setExpandedPaths] = useState(new Set());
//     const { data, status } = useDataset();

//     useEffect(() => {
//         if (status === 'success' && nodeNames?.length >= 2 && data?.MarkovChain) {
//             try {
//                 let markovData = JSON.parse(JSON.parse(data.MarkovChain));
//                 const newNodes = [];
//                 const newEdges = [];
//                 const pathsMap = {};

//                 // Add main page nodes in a horizontal line
//                 nodeNames.forEach((name, index) => {
//                     newNodes.push({
//                         id: name,
//                         position: { 
//                             x: 100 + (index * 300), 
//                             y: 300 
//                         },
//                         data: { label: name },
//                         style: {
//                             background: COLORS.dkpurple,
//                             color: 'white',
//                             padding: '10px',
//                             borderRadius: '5px',
//                             border: `1px solid ${COLORS.dkpurple}`,
//                             width: 'auto',
//                             minWidth: '150px',
//                             fontSize: '14px'
//                         }
//                     });
//                 });

//                 // Find connections between each pair of pages
//                 for (let i = 0; i < nodeNames.length - 1; i++) {
//                     const sourcePage = nodeNames[i];
//                     const targetPage = nodeNames[i + 1];
//                     const pairKey = `${sourcePage}-${targetPage}`;

//                     // Find all possible event paths between these pages
//                     const eventPaths = [];
//                     markovData.forEach(conn1 => {
//                         if (conn1.From === sourcePage && conn1.To.includes('@')) {
//                             markovData.forEach(conn2 => {
//                                 if (conn2.From === conn1.To && conn2.To === targetPage) {
//                                     eventPaths.push({
//                                         event: conn1.To,
//                                         toEventProb: conn1.Probability,
//                                         fromEventProb: conn2.Probability,
//                                         totalProb: conn1.Probability * conn2.Probability
//                                     });
//                                 }
//                             });
//                         }
//                     });

//                     // Sort paths by probability
//                     eventPaths.sort((a, b) => b.totalProb - a.totalProb);
//                     pathsMap[pairKey] = eventPaths;

//                     // Always show the best path
//                     if (eventPaths.length > 0) {
//                         const bestPath = eventPaths[0];
//                         const eventNode = bestPath.event;

//                         // Add the primary event node
//                         newNodes.push({
//                             id: eventNode,
//                             position: { 
//                                 x: 100 + (i * 300) + 150, 
//                                 y: 200 
//                             },
//                             data: { 
//                                 label: `${bestPath.event.split('@')[0]}${eventPaths.length > 1 ? ` +${eventPaths.length - 1}` : ''}`
//                             },
//                             style: {
//                                 background: COLORS.ltgray,
//                                 color: COLORS.dkpurple,
//                                 padding: '10px',
//                                 borderRadius: '5px',
//                                 border: `1px solid ${COLORS.dkpurple}`,
//                                 fontSize: '12px',
//                                 width: 'auto',
//                                 minWidth: '120px'
//                             }
//                         });

//                         // Add edges for primary path
//                         newEdges.push({
//                             id: `edge-${sourcePage}-${eventNode}`,
//                             source: sourcePage,
//                             target: eventNode,
//                             label: `${(bestPath.toEventProb * 100).toFixed(1)}%`,
//                             style: {
//                                 stroke: COLORS.dkpurple,
//                                 strokeWidth: 2,
//                                 opacity: 0.5
//                             }
//                         });

//                         newEdges.push({
//                             id: `edge-${eventNode}-${targetPage}`,
//                             source: eventNode,
//                             target: targetPage,
//                             label: `${(bestPath.fromEventProb * 100).toFixed(1)}%`,
//                             style: {
//                                 stroke: COLORS.dkpurple,
//                                 strokeWidth: 2,
//                                 opacity: 0.5
//                             }
//                         });

//                         // Add alternative paths if expanded
//                         if (expandedPaths.has(pairKey)) {
//                             eventPaths.slice(1).forEach((path, altIndex) => {
//                                 const altEventNode = path.event;
//                                 const yOffset = 150 + (altIndex + 1) * 100;

//                                 newNodes.push({
//                                     id: `${altEventNode}-alt`,
//                                     position: {
//                                         x: 100 + (i * 300) + 150,
//                                         y: 200 + yOffset
//                                     },
//                                     data: { label: path.event.split('@')[0] },
//                                     style: {
//                                         background: COLORS.ltgray,
//                                         color: COLORS.mdgray,
//                                         padding: '10px',
//                                         borderRadius: '5px',
//                                         border: `1px solid ${COLORS.mdgray}`,
//                                         fontSize: '12px',
//                                         opacity: 0.7
//                                     }
//                                 });

//                                 newEdges.push({
//                                     id: `edge-${sourcePage}-${altEventNode}-alt`,
//                                     source: sourcePage,
//                                     target: `${altEventNode}-alt`,
//                                     label: `${(path.toEventProb * 100).toFixed(1)}%`,
//                                     style: {
//                                         stroke: COLORS.mdgray,
//                                         strokeWidth: 1,
//                                         opacity: 0.3
//                                     }
//                                 });

//                                 newEdges.push({
//                                     id: `edge-${altEventNode}-alt-${targetPage}`,
//                                     source: `${altEventNode}-alt`,
//                                     target: targetPage,
//                                     label: `${(path.fromEventProb * 100).toFixed(1)}%`,
//                                     style: {
//                                         stroke: COLORS.mdgray,
//                                         strokeWidth: 1,
//                                         opacity: 0.3
//                                     }
//                                 });
//                             });
//                         }
//                     }

//                     // Check for direct connection
//                     const directConn = markovData.find(conn => 
//                         conn.From === sourcePage && conn.To === targetPage
//                     );
                    
//                     if (directConn && directConn.Probability > 0.001) {
//                         newEdges.push({
//                             id: `edge-direct-${sourcePage}-${targetPage}`,
//                             source: sourcePage,
//                             target: targetPage,
//                             label: `${(directConn.Probability * 100).toFixed(1)}%`,
//                             style: {
//                                 stroke: COLORS.dkpurple,
//                                 strokeWidth: Math.max(1, directConn.Probability * 3),
//                                 opacity: 0.5
//                             }
//                         });
//                     }
//                 }

//                 setAlternativePaths(pathsMap);
//                 setNodes(newNodes);
//                 setEdges(newEdges);
//             } catch (error) {
//                 console.error("Error processing flow data:", error);
//                 console.error("Error details:", error.message);
//             }
//         } else if (nodeNames.length === 1) {
//             // Show single node without connections
//             const newNodes = nodeNames.map((name) => ({
//                 id: name,
//                 position: { x: 100, y: 300 },
//                 data: { label: name },
//                 style: {
//                     background: COLORS.dkpurple,
//                     color: 'white',
//                     padding: '10px',
//                     borderRadius: '5px',
//                     border: `1px solid ${COLORS.dkpurple}`,
//                     width: 'auto',
//                     minWidth: '150px',
//                     fontSize: '14px'
//                 }
//             }));
//             setNodes(newNodes);
//             setEdges([]);
//         }
//     }, [nodeNames, data, status, expandedPaths]);

//     // Handle node click to show alternative paths
//     const onNodeClick = (event, node) => {
//         // If it's an event node
//         if (node.style.background === COLORS.ltgray) {
//             // Find which pair this event belongs to
//             for (const [pairKey, paths] of Object.entries(alternativePaths)) {
//                 if (paths.some(p => p.event === node.id)) {
//                     setExpandedPaths(prev => {
//                         const newSet = new Set(prev);
//                         if (newSet.has(pairKey)) {
//                             newSet.delete(pairKey);
//                         } else {
//                             newSet.add(pairKey);
//                         }
//                         return newSet;
//                     });
//                     break;
//                 }
//             }
//         }
//     };

//     return (
//         <div style={{ height: '600px', border: '1px solid #ddd' }}>
//             <ReactFlow
//                 nodes={nodes}
//                 edges={edges}
//                 onNodesChange={onNodesChange}
//                 onEdgesChange={onEdgesChange}
//                 onNodeClick={onNodeClick}
//                 fitView
//                 defaultViewport={{ x: 0, y: 0, zoom: 0.8 }}
//             >
//                 <Background color={COLORS.ltgray} gap={16} />
//                 <Controls />
//                 <MiniMap
//                     nodeColor={node => node.style?.background || COLORS.ltgray}
//                     nodeStrokeColor={() => COLORS.dkpurple}
//                     nodeBorderRadius={5}
//                 />
//             </ReactFlow>
//         </div>
//     );
// };

// export default FlowVisualization;


// // // FlowVisualization.jsx
// // import React, { useState, useEffect } from 'react';
// // import ReactFlow, { 
// //     MiniMap, 
// //     Background, 
// //     Controls,
// //     useNodesState,
// //     useEdgesState
// // } from 'react-flow-renderer';
// // import { useDataset } from './DataFetcher';

// // const COLORS = {
// //     dkpurple: '#2c294b',
// //     ltgray: '#ebe5df'
// // };

// // const FlowVisualization = ({ nodeNames }) => {
// //     const [nodes, setNodes, onNodesChange] = useNodesState([]);
// //     const [edges, setEdges, onEdgesChange] = useEdgesState([]);
// //     const { data, status } = useDataset();

// //     useEffect(() => {
// //         if (status === 'success' && nodeNames?.length >= 2 && data?.MarkovChain) {
// //             try {
// //                 let markovData = JSON.parse(JSON.parse(data.MarkovChain));
// //                 console.log("Selected pages:", nodeNames);

// //                 const newNodes = [];
// //                 const newEdges = [];

// //                 // Add main page nodes in a horizontal line
// //                 nodeNames.forEach((name, index) => {
// //                     newNodes.push({
// //                         id: name,
// //                         position: { 
// //                             x: 100 + (index * 300), 
// //                             y: 300 
// //                         },
// //                         data: { label: name },
// //                         style: {
// //                             background: COLORS.dkpurple,
// //                             color: 'white',
// //                             padding: '10px',
// //                             borderRadius: '5px',
// //                             border: `1px solid ${COLORS.dkpurple}`,
// //                             width: 'auto',
// //                             minWidth: '150px',
// //                             fontSize: '14px'
// //                         }
// //                     });
// //                 });

// //                 // Find connections between each pair of pages
// //                 for (let i = 0; i < nodeNames.length - 1; i++) {
// //                     const sourcePage = nodeNames[i];
// //                     const targetPage = nodeNames[i + 1];

// //                     // Find all possible event paths between these pages
// //                     const eventPaths = [];
// //                     markovData.forEach(conn1 => {
// //                         if (conn1.From === sourcePage && conn1.To.includes('@')) {
// //                             markovData.forEach(conn2 => {
// //                                 if (conn2.From === conn1.To && conn2.To === targetPage) {
// //                                     eventPaths.push({
// //                                         event: conn1.To,
// //                                         toEventProb: conn1.Probability,
// //                                         fromEventProb: conn2.Probability,
// //                                         totalProb: conn1.Probability * conn2.Probability
// //                                     });
// //                                 }
// //                             });
// //                         }
// //                     });

// //                     // Sort by total probability and get the highest
// //                     if (eventPaths.length > 0) {
// //                         const bestPath = eventPaths.reduce((max, current) => 
// //                             current.totalProb > max.totalProb ? current : max
// //                         );

// //                         console.log(`Best path between ${sourcePage} and ${targetPage}:`, bestPath);

// //                         // Add event node
// //                         const eventNode = bestPath.event;
// //                         newNodes.push({
// //                             id: eventNode,
// //                             position: { 
// //                                 x: 100 + (i * 300) + 150, 
// //                                 y: 200 
// //                             },
// //                             data: { 
// //                                 label: eventNode.split('@')[0]
// //                             },
// //                             style: {
// //                                 background: COLORS.ltgray,
// //                                 color: COLORS.dkpurple,
// //                                 padding: '10px',
// //                                 borderRadius: '5px',
// //                                 border: `1px solid ${COLORS.dkpurple}`,
// //                                 fontSize: '12px',
// //                                 width: 'auto',
// //                                 minWidth: '120px'
// //                             }
// //                         });

// //                         // Add edges with probabilities for both connections
// //                         newEdges.push({
// //                             id: `edge-${sourcePage}-${eventNode}`,
// //                             source: sourcePage,
// //                             target: eventNode,
// //                             label: `${(bestPath.toEventProb * 100).toFixed(1)}%`,
// //                             style: {
// //                                 stroke: COLORS.dkpurple,
// //                                 strokeWidth: 2,
// //                                 opacity: 0.5
// //                             }
// //                         });

// //                         newEdges.push({
// //                             id: `edge-${eventNode}-${targetPage}`,
// //                             source: eventNode,
// //                             target: targetPage,
// //                             label: `${(bestPath.fromEventProb * 100).toFixed(1)}%`,
// //                             style: {
// //                                 stroke: COLORS.dkpurple,
// //                                 strokeWidth: 2,
// //                                 opacity: 0.5
// //                             }
// //                         });
// //                     }

// //                     // Also check for direct connection
// //                     const directConn = markovData.find(conn => 
// //                         conn.From === sourcePage && conn.To === targetPage
// //                     );
                    
// //                     if (directConn && directConn.Probability > 0.001) {  // Show if >0.1%
// //                         newEdges.push({
// //                             id: `edge-direct-${sourcePage}-${targetPage}`,
// //                             source: sourcePage,
// //                             target: targetPage,
// //                             label: `${(directConn.Probability * 100).toFixed(1)}%`,
// //                             style: {
// //                                 stroke: COLORS.dkpurple,
// //                                 strokeWidth: Math.max(1, directConn.Probability * 3),
// //                                 opacity: 0.5
// //                             }
// //                         });
// //                     }
// //                 }

// //                 setNodes(newNodes);
// //                 setEdges(newEdges);
// //             } catch (error) {
// //                 console.error("Error processing flow data:", error);
// //                 console.error("Error details:", error.message);
// //             }
// //         } else {
// //             // If less than 2 nodes, just show the nodes without connections
// //             if (nodeNames.length === 1) {
// //                 const newNodes = nodeNames.map((name) => ({
// //                     id: name,
// //                     position: { x: 100, y: 300 },
// //                     data: { label: name },
// //                     style: {
// //                         background: COLORS.dkpurple,
// //                         color: 'white',
// //                         padding: '10px',
// //                         borderRadius: '5px',
// //                         border: `1px solid ${COLORS.dkpurple}`,
// //                         width: 'auto',
// //                         minWidth: '150px',
// //                         fontSize: '14px'
// //                     }
// //                 }));
// //                 setNodes(newNodes);
// //                 setEdges([]);
// //             }
// //         }
// //     }, [nodeNames, data, status]);

// //     return (
// //         <div style={{ height: '600px', border: '1px solid #ddd' }}>
// //             <ReactFlow
// //                 nodes={nodes}
// //                 edges={edges}
// //                 onNodesChange={onNodesChange}
// //                 onEdgesChange={onEdgesChange}
// //                 fitView
// //                 defaultViewport={{ x: 0, y: 0, zoom: 0.8 }}
// //             >
// //                 <Background color={COLORS.ltgray} gap={16} />
// //                 <Controls />
// //                 <MiniMap
// //                     nodeColor={node => node.style?.background || COLORS.ltgray}
// //                     nodeStrokeColor={() => COLORS.dkpurple}
// //                     nodeBorderRadius={5}
// //                 />
// //             </ReactFlow>
// //         </div>
// //     );
// // };

// // export default FlowVisualization;

// // // // FlowVisualization.jsx
// // // import React, { useState, useEffect } from 'react';
// // // import ReactFlow, { MiniMap, Background, Controls, Handle } from 'react-flow-renderer';
// // // import { useDataset } from './DataFetcher'; 

// // // console.log("FlowVisualization");

// // // const FlowVisualization = ({ nodeNames }) => {

// // //     console.log("FlowVisualization nodeNames:", nodeNames);
// // //     const [elements, setElements] = useState([]);
// // //     const { data, status } = useDataset();


// // //     useEffect(() => {
// // //     }, [nodeNames]);
    


// // //     useEffect(() => {
// // //         console.log(`Effect triggered: status=${status}, nodeNames=`, nodeNames, `, data=`, data);
// // //         if (status === 'success' && nodeNames && nodeNames?.length > 0) {
// // //             try{
// // //             const newElements = generateElements(nodeNames, data);
// // //             console.log("Setting elements in state:", newElements);
// // //             // setElements(newElements);
// // //             if (JSON.stringify(elements) !== JSON.stringify(newElements)) {
// // //                 setElements(newElements);
// // //             }
// // //             console.log("setElements nodeNames", nodeNames);
// // //         } catch (error) {
// // //             console.error("Error generating elements:", error);
// // //         }
// // //         }
// // //     }, [nodeNames, elements, data, status]);

// // //     const generateElements = (nodeNames, data) => {
// // //         console.log("generateElements called with nodeNames:", nodeNames);

// // //         if (!data.MarkovChain) {
// // //             console.log("No MarkovChain data available.");
// // //             return [];
// // //         }
// // //         // Parse MarkovChain data - replace this with actual parsing logic
// // //         const markovChainData = JSON.parse(data.MarkovChain).data || [];
// // //         console.log("generateElements markovChainData", markovChainData);

        
// // //         // Create nodes
// // //         const nodes = nodeNames.map((name, index) => ({
// // //             id: `node-${name}`,
// // //             type: 'default', // or any other type you might have
// // //             data: { label: name },
// // //             position: { x: 100 * index, y: 100 } // example positioning, adjust as needed
// // //         }));
// // //         console.log("generateElements nodes", nodes);

// // //         console.log("Rendering with elements:", elements);
       
// // //         const edges = markovChainData.reduce((acc, [source, target, probability]) => {
// // //             const sourceId = `node-${source.toString().trim()}`;
// // //             const targetId = `node-${target.toString().trim()}`;        
// // //             if (sourceId === targetId) {
// // //                 return acc;
// // //             }
// // //             if (nodeNames.includes(source) && nodeNames.includes(target)) {
// // //                 // Convert probability to percentage and format it to one decimal place
// // //                 const probabilityPercentage = (probability * 100).toFixed(1);
        
// // //                 acc.push({
// // //                     id: `edge-${sourceId}-${targetId}`,
// // //                     source: sourceId,
// // //                     target: targetId,
// // //                     label: `Probability: ${probabilityPercentage}%`
// // //                 });
// // //             }
// // //             return acc;
// // //         }, []);
        
        
    
// // //         return [...nodes, ...edges];
// // //     };
// // //     console.log("Final elements to render:", elements);

// // //     console.log("Final nodes elements to render:", elements.nodes);
// // //     console.log("Final edges elements to render:", elements.edges);

// // //     console.log("defaultNodes in render:", ReactFlow.defaultNodes);
// // //     console.log("defaultEdges in render:", ReactFlow.defaultEdges);

// // //     return (
// // //         <div className="flow-container">
// // // <ReactFlow
// // //             elements={elements}
// // //             // onNodesChange={handleNodesChange}
// // //             // onEdgesChange={handleEdgesChange}
// // //             // onConnect={handleConnect}
// // //                 defaultNodes={elements.filter(e => e.id.startsWith('node'))}
// // //                 defaultEdges={elements.filter(e => e.id.startsWith('edge'))}
                
// // //                 className="reactflow"
// // //                 minZoom={0.5}
// // //                 maxZoom={2}
// // //                 panOnScroll={true}
// // //                 panOnDrag={true}
// // //             >
// // //                 <Background variant="dots" gap={26} size={2} color={'#ebe5df'}/>
// // //                 <Controls />
// // //                 <MiniMap
// // //                 nodeStrokeColor={() => '#2c294b'}
// // //                 nodeColor={() => '#fff'}
// // //                 nodeBorderRadius={2}    
// // //                 />

// // // <Handle type="target" position="left" />
// // // <Handle type="source" position="right" />


// // //             </ReactFlow>
// // //         </div>
// // //     );
    
// // // };

// // // export default FlowVisualization;

