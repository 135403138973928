
//useD3Graph.jsx
import { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

console.log("useD3Graph load");


const useD3Graph = ({
  svgRef,
  dimensions,
  status,
  graphNodes,
  graphEdges,
  sources,
  targets,
  selectedNodeIds,
  handleNodeClick,
  UniqueURL,
  UniqueEventDescList,
  nodesRef, // Pass refs if needed
  linksRef,
  simulationRef,
  shortestPath,
  iconVisibleNodeIds,
  onIconClick,
}) => {
  const rScaleRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [zoomTransform, setZoomTransform] = useState(d3.zoomIdentity);
  const [nodesState, setNodesState] = useState([]);
  const [pathLinksSet, setPathLinksSet] = useState(new Set());

  // Step 1: Build pathLinksSet from shortestPath
  useEffect(() => {
    if (shortestPath.length < 2) {
      // If shortestPath is empty or has only one node, clear the Set
      setPathLinksSet(new Set());
      console.log('Shortest path is too short. Clearing pathLinksSet.');
      return;
    }
    console.log('shortestPath:', shortestPath);

    const newPathLinksSet = new Set();
    for (let i = 0; i < shortestPath.length - 1; i++) {
      const source = shortestPath[i];
      const target = shortestPath[i + 1];
      newPathLinksSet.add(`${source}->${target}`);
      newPathLinksSet.add(`${target}->${source}`); // Include reverse if undirected
    }
    setPathLinksSet(newPathLinksSet);

    // Debugging: Verify the Set
    console.log('pathLinksSet:', newPathLinksSet);
    console.log('Is pathLinksSet a Set?', newPathLinksSet instanceof Set);
  }, [shortestPath]);

  // Step 2: Helper function using the Set
  function isLinkInShortestPath(link, pathLinksSet) {
    if (!(pathLinksSet instanceof Set)) {
      console.warn('pathLinksSet is not a Set:', pathLinksSet);
      return false;
    }
    
    // Debugging: Verify the Set
    // console.log('pathLinksSet:', pathLinksSet);
    // console.log('Is pathLinksSet a Set?', pathLinksSet instanceof Set); // Should log: true

    const sourceId = link.source.id || link.source; // Adjust based on link structure
    const targetId = link.target.id || link.target;
    const linkId = `${sourceId}->${targetId}`;
    
    const isInPath = pathLinksSet.has(linkId);
    console.log(`pathLinksSet: Link ${linkId} is in shortest path: ${isInPath}`);
    
    return isInPath;
  }

  useEffect(() => {
    const svg = d3.select(svgRef.current);
  
    // Select all link elements
    const linkSelection = svg.selectAll('.link');
  
    // Update link styles based on pathLinksSet
    linkSelection
      .transition() // Optional: Adds a smooth transition
      .duration(500)
      .attr('stroke', d => {
        if (pathLinksSet.size === 0) {
          return '#2c294b'; // Default black color
        }
        return isLinkInShortestPath(d, pathLinksSet) ? '#c5316a' : '#dbdbde'; // Pink or gray
      })
      .attr('stroke-width', d => {
        if (pathLinksSet.size === 0) {
          return 1; // Default stroke width
        }
        return isLinkInShortestPath(d, pathLinksSet) ? 8 : 1; // Thicker stroke for path links
      });
  }, [pathLinksSet]); // Dependency array ensures this runs when pathLinksSet changes
  
useEffect(() => {
  // 1) Bail if data not ready
  if (status !== 'success' || !sources) {
    console.log("Data not ready yet.");
    return;
  }

  // 2) Quick validation
  if (graphNodes.length === 0 || graphEdges.length === 0) {
    console.error('Edges and Nodes are not properly defined in graphData.');
    return;
  }

  if (!svgRef.current) {
    console.log("SVG element not yet available.");
    return;
  }

  // Basic dimension references
  const { width, height } = dimensions;
  const centerX = width / 2;
  const centerY = height / 2;

  console.log("UniqueURL:", UniqueURL);
  console.log("UniqueEventDescList:", UniqueEventDescList);

  // 3) Copy node/edge data
  const nodes = graphNodes.map((d) => ({ ...d }));
  const links = graphEdges.map((d) => ({
    source: nodes.find((n) => n.id === d.source),
    target: nodes.find((n) => n.id === d.target),
  }));

  // 4) Build or reuse our main SVG
  const svg = d3.select(svgRef.current)
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [0, 0, width, height])
    .attr('preserveAspectRatio', 'xMidYMid meet');

  // 5) Container group for everything
  let container = svg.select('g.zoomable');
  if (container.empty()) {
    container = svg.append('g').attr('class', 'zoomable');
  }

  // 6) Zoom behavior
  const zoomBehavior = d3.zoom()
    .scaleExtent([0.1, 10])
    .on('zoom', (event) => {
      container.attr('transform', event.transform);
    });
  svg.call(zoomBehavior);

  // 7) Save references
  nodesRef.current = [...nodes];
  linksRef.current = [...links];

  // 8) Link selection
  const link = container.selectAll('.link')
    .data(links, (d) => `${d.source.id}-${d.target.id}`)
    .join(
      (enter) => enter.append('line').attr('class', 'link'),
      (update) => update,
      (exit) => exit.remove()
    )
    .attr('stroke', (d) => (isLinkInShortestPath(d, pathLinksSet) ? '#c5316a' : '#ebe5df'))
    .attr('stroke-opacity', 0.6)
    .attr('stroke-width', (d) => (isLinkInShortestPath(d, pathLinksSet) ? 8 : 1));

  // Highlighted links on top
  link.filter((d) => isLinkInShortestPath(d, pathLinksSet)).each(function () {
    this.parentNode.appendChild(this);
  });

  // 9) Label div for hover info
  const labelDiv = d3.select('#label');

  // 10) Circle radius scale
  const counts = nodes
    .filter((n) => n.id && !['begin','end','Empty'].includes(n.id))
    .map((n) => UniqueURL[n.id] || UniqueEventDescList[n.id] || 0);

  const minCount = Math.min(...counts);
  const maxCount = Math.max(...counts);
  const domain = minCount === maxCount ? [0, maxCount] : [minCount, maxCount];
  rScaleRef.current = d3.scaleLinear().domain(domain).range([5, 70]);

  console.log('MinCount:', minCount);
  console.log('MaxCount:', maxCount);
  console.log('Counts:', counts);

  // 11) Filter invalid nodes
  const validNodes = nodes.filter((d) => d && typeof d.id === 'string');

  // 11A) Initialize node positions if missing or invalid
  validNodes.forEach((d) => {
    if (!Number.isFinite(d.x) || !Number.isFinite(d.y)) {
      // Place them around the center so they don't start infinitely far away
      d.x = centerX + (Math.random() - 0.5) * 100;
      d.y = centerY + (Math.random() - 0.5) * 100;
    }
  });

  nodesRef.current = validNodes;
  console.log('NodesRef before rendering:', nodesRef.current);

  // 12) Node selection
  const node = container.selectAll('.node')
    .data(nodesRef.current, (d) => (d ? d.id : undefined))
    .join(
      (enter) => enter.append('circle').attr('class', 'node'),
      (update) => update,
      (exit) => exit.remove()
    )
    .attr('class', (d) => `node ${selectedNodeIds.includes(d.id) ? 'selected-node' : ''}`)
    .attr('stroke', '#7174b0')
    .attr('stroke-width', 1.5)
    .attr('r', (d) => {
      const count = UniqueURL[d.id] || UniqueEventDescList[d.id] || 0;
      return rScaleRef.current(count);
    })
    .attr('stroke', (d) => (shortestPath.includes(d.id) ? '#c5316a' : 'none'))
    .attr('stroke-width', (d) => (shortestPath.includes(d.id) ? 1.5 : 0))
    .attr('fill', (d) => (selectedNodeIds.includes(d.id) ? '#c5316a' : '#3b3484'))
    .on('click', (event, d) => {
      if (linksRef.current?.length) {
        handleNodeClick(event, d, linksRef.current);
      } else {
        console.error('Links data not yet available');
      }
    })
    .call(
      d3.drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended)
    );

  // Shortest-path nodes on top
  node.filter((d) => shortestPath.includes(d.id)).each(function () {
    this.parentNode.appendChild(this);
  });

  
  // Create node labels for nodes that are part of the shortest path
const nodeLabels = container.selectAll('.node-label')
.data(nodesRef.current.filter(d => shortestPath.includes(d.id)), d => d.id)
.join(
  enter => enter.append('text')
    .attr('class', 'node-label')
    .text(d => d.id)
    .attr('font-size', '12px')
    .attr('fill', 'black')
    .attr('text-anchor', 'start')
    .attr('dominant-baseline', 'middle'),
  update => update,
  exit => exit.remove()
);

  // 13) Node hover labels
  const sanitizeId = (id) => id.replace(/[^a-zA-Z0-9-_]/g, '_');
  container.selectAll('.hover-node-label')
    .data(nodesRef.current, (d) => d.id)
    .join(
      (enter) =>
        enter
          .append('text')
          .attr('class', 'hover-node-label')
          .attr('id', (d) => `hover-label-${sanitizeId(d.id)}`)
          .text((d) => d.id)
          .attr('font-size', '10px')
          .attr('fill', 'black')
          .style('visibility', 'hidden'),
      (update) => update,
      (exit) => exit.remove()
    );

  // 14) Validate links
  links.forEach((lnk, idx) => {
    const srcExists = nodesRef.current.some((n) => n.id === lnk.source.id);
    const tgtExists = nodesRef.current.some((n) => n.id === lnk.target.id);
    if (!srcExists || !tgtExists) {
      console.warn(`Invalid link at index ${idx}:`, lnk);
    }
  });

  // 15) Simulation
  if (simulationRef.current) {
    simulationRef.current.nodes(nodesRef.current);
    simulationRef.current.force('link').links(links);
    simulationRef.current.alpha(1).restart();
  } else {
    simulationRef.current = d3.forceSimulation(nodesRef.current)
      .force('link', d3.forceLink(links).id((d) => d.id).distance(75))
      .force('charge', d3.forceManyBody().strength(-30))
      .force('center', d3.forceCenter(centerX, centerY))
      .force('collide', d3.forceCollide().radius((d) => {
        const count = UniqueURL[d.id] || UniqueEventDescList[d.id] || 0;
        return rScaleRef.current(count) + 5;
      }))
      .on('tick', ticked)
      .on('end', onSimulationEnd);
  }

  // Extra debug
  console.log('Forces before simulation start:', {
    link: simulationRef.current.force('link'),
    charge: simulationRef.current.force('charge'),
    center: simulationRef.current.force('center'),
  });

  // 16) Node icons if needed
  node.each(function (d) {
    d3.select(this).select('.node-icon').remove();
    if (iconVisibleNodeIds instanceof Set && iconVisibleNodeIds.has(d.id)) {
      d3.select(this)
        .append('text')
        .attr('class', 'node-icon')
        .attr('x', 10)
        .attr('y', -10)
        .attr('text-anchor', 'start')
        .style('font-size', '16px')
        .style('cursor', 'pointer')
        .text('🛈')
        .on('click', (event) => {
          event.stopPropagation();
          onIconClick(d.id);
        });
    }
  });

// 17) Shortest path labels: create a group for each node in the shortest path
const shortestNodeLabels = container.selectAll('.shortest-node-label-group')
  .data(
    shortestPath.length > 1
      ? nodesRef.current.filter((d) => shortestPath.includes(d.id))
      : [],
    (d) => d.id
  )
  .join(
    (enter) => {
      const group = enter.append('g')
        .attr('class', 'shortest-node-label-group')
        .attr('id', (d) => `shortest-label-group-${d.id}`);
      
      // Background rectangle for the label
      group.append('rect')
        .attr('class', 'shortest-label-bg')
        .attr('fill', 'white')
        .attr('rx', 3)
        .attr('ry', 3);
      
      // Text element for the label
      group.append('text')
        .attr('class', 'shortest-node-label')
        .text((d) => d.id)
        .attr('font-size', '12px')
        .attr('fill', 'black');
      
      return group;
    },
    (update) => update.select('text').text((d) => d.id),
    (exit) => exit.remove()
  );

  // 18) Edge labels if desired (hidden by default)
  container.selectAll('.link-label')
    .data(links)
    .enter()
    .append('text')
    .text((d) => `from ${d.source.id} to ${d.target.id}`)
    .attr('class', 'link-label')
    .style('visibility', 'hidden');

  // 19) Hover logic
  node
    .on('mouseenter', function (event, d) {
      d3.select(this).attr(
        'r',
        rScaleRef.current(UniqueURL[d.id] || UniqueEventDescList[d.id] || 0) + 2
      );
      const hoverLabel = d3.select(`#hover-label-${sanitizeId(d.id)}`);
      if (!hoverLabel.empty()) hoverLabel.style('visibility', 'visible');
      labelDiv.text(`Node: ${d.id}`);
    })
    .on('mouseleave', function (event, d) {
      d3.select(this).attr(
        'r',
        rScaleRef.current(UniqueURL[d.id] || UniqueEventDescList[d.id] || 0)
      );
      const hoverLabel = d3.select(`#hover-label-${sanitizeId(d.id)}`);
      if (!hoverLabel.empty()) hoverLabel.style('visibility', 'hidden');
      labelDiv.text('');
    });

  link
    .on('mouseenter', function (event, d) {
      labelDiv.text(`Link from ${d.source.id} to ${d.target.id}`);
    })
    .on('mouseleave', function () {
      labelDiv.text('');
    });

  // 20) Drag callbacks
  function dragstarted(event) {
    if (!event.active) simulationRef.current.alphaTarget(0.3).restart();
    event.subject.fx = event.subject.x;
    event.subject.fy = event.subject.y;
  }
  function dragged(event) {
    event.subject.fx = event.x;
    event.subject.fy = event.y;
  }
  function dragended(event, d) {
    if (!event.active) simulationRef.current.alphaTarget(0);
    d.fx = null;
    d.fy = null;
    if (event.sourceEvent.type === 'drag') {
      event.sourceEvent.stopPropagation();
    }
  }

  // 21) Force tick: CLAMP to avoid outliers
  function ticked() {
    // Optionally clamp in a bigger bounding box to reduce outliers
    // We'll keep them within 3× the width/height in all directions:
    const maxX = width * 3;
    const maxY = height * 3;
    const minX = -width * 2;
    const minY = -height * 2;

    link
      .attr('x1', (d) => d.source.x)
      .attr('y1', (d) => d.source.y)
      .attr('x2', (d) => d.target.x)
      .attr('y2', (d) => d.target.y);

    node
      .attr('cx', (d) => {
        // If a node is outside, clamp it
        if (d.x < minX) d.x = minX;
        if (d.x > maxX) d.x = maxX;
        return d.x;
      })
      .attr('cy', (d) => {
        if (d.y < minY) d.y = minY;
        if (d.y > maxY) d.y = maxY;
        return d.y;
      });

    // Replace the shortestNodeLabels section in the ticked() function:
 shortestNodeLabels
    .attr('transform', (d) => `translate(${d.x}, ${d.y})`)
    .each(function (d) {
      const group = d3.select(this);
      const text = group.select('text');
      const rect = group.select('rect');
      if (!text.node()) return;

      // Compute the node's radius using your scale
      const count = UniqueURL[d.id] || UniqueEventDescList[d.id] || 0;
      const radius = rScaleRef.current(count);
      const offset = radius + 10; // 10px to the right of the circle's edge

      // Position the text element so it sits to the right of the node center
      text
        .attr('x', offset)
        .attr('y', 0)
        .attr('text-anchor', 'start')
        .attr('dominant-baseline', 'middle');

      // Measure the text and adjust the background rectangle accordingly
      const bbox = text.node().getBBox();
      rect
        .attr('x', offset - 2) // 2px left padding
        .attr('y', -bbox.height / 2 - 2)
        .attr('width', bbox.width + 4)
        .attr('height', bbox.height + 4);
    });
  }

  // 22) On simulation end, auto-fit
  function onSimulationEnd() {
    console.log('Simulation ended. Fitting to screen...');
    const bbox = container.node()?.getBBox();
    if (!bbox) {
      console.warn('No bounding box found; skipping fit-to-screen.');
      return;
    }

    const bboxWidth = Math.max(1, bbox.width);
    const bboxHeight = Math.max(1, bbox.height);

    const padding = 0.9; // scale down slightly
    let scale = Math.min(
      (width * padding) / bboxWidth,
      (height * padding) / bboxHeight
    );

    // If scale is weird, fallback
    if (!Number.isFinite(scale) || scale <= 0) {
      console.warn('Calculated scale invalid, fallback to 1');
      scale = 1;
    }

    const translateX = centerX - (bbox.x + bboxWidth / 2) * scale;
    const translateY = centerY - (bbox.y + bboxHeight / 2) * scale;

    console.log('bbox:', bbox);
    console.log('Computed scale:', scale);
    console.log('translateX:', translateX, 'translateY:', translateY);

    const finalTransform = d3.zoomIdentity
      .translate(translateX, translateY)
      .scale(scale);

    svg.call(zoomBehavior.transform, finalTransform);
  }

  // 23) Mark done
  setIsLoading(false);

  // 24) Cleanup
  return () => {
    if (simulationRef.current) {
      simulationRef.current.stop();
    }
  };
}, [
  status,
  graphNodes,
  graphEdges,
  dimensions,
  UniqueEventDescList,
  UniqueURL,
  selectedNodeIds,
  sources,
  handleNodeClick,
  pathLinksSet,
  shortestPath,
]);




useEffect(() => {
  if (!svgRef.current) {
    console.log("SVG element not yet available for updating styles.");
    return;
  }

  const svg = d3.select(svgRef.current);
  const zoomedContainer = svg.select('g.zoomable');

  // Ensure node and link selections are available
  const node = zoomedContainer.selectAll('.node');
  const link = zoomedContainer.selectAll('.link');

  // Function to update node and link styles based on shortestPath
  function updateStyles() {
    if (!shortestPath || shortestPath.length === 0) {
      // Reset styles if there's no shortest path
      node.attr('fill', d => {
        if (selectedNodeIds.length === 2 && selectedNodeIds.includes(d.id)) {
          return '#c5316a'; // Color for selected nodes
        } else {
          return '#3b3484'; // Default node color
        }
      });

      link.attr('stroke', '#2c294b'); // Default link color
      return;
    }

    // Highlight nodes in the shortest path
    node.attr('fill', d => {
      if (shortestPath.includes(d.id)) {
        return 'red'; // Highlight color
      } else if (selectedNodeIds.length === 2 && selectedNodeIds.includes(d.id)) {
        return '#c5316a'; // Color for selected nodes
      } else {
        return '#3b3484'; // Default node color
      }
    });

    // Create a set of node pairs representing the shortest path links
    const pathLinks = new Set();
    for (let i = 0; i < shortestPath.length - 1; i++) {
      const source = shortestPath[i];
      const target = shortestPath[i + 1];
      pathLinks.add(`${source}->${target}`);
      pathLinks.add(`${target}->${source}`); // Include reverse if undirected
    }

    // Highlight links that are part of the shortest path
    link.attr('stroke', d => {
      if (
        pathLinks.has(`${d.source.id}->${d.target.id}`) ||
        pathLinks.has(`${d.target.id}->${d.source.id}`)
      ) {
        return 'red'; // Highlight color
      } else {
        return '#2c294b'; // Default link color
      }
    });
  }

  // Call the updateStyles function
  updateStyles();
}, [shortestPath]); 

  useEffect(() => {
    // Assuming svgRef is a ref to your SVG element
    if (!svgRef.current) {
      console.log("SVG element not yet available.");
      return;
    }
    console.log("SVG Element:", svgRef.current);
    const svg = d3.select(svgRef.current);
    console.log("Establishing Zoom context:");

    // Define the zoom behavior
    const zoom = d3.zoom()
      .scaleExtent([0.5, 20])
      .on('zoom', (event) => {
        // event.preventDefault(); // Prevent default zooming
        // Apply the zoom transform directly to the zoomedContainer
        const zoomedContainer = svg.select('g.zoomable');
        zoomedContainer.attr('transform', event.transform);
        // console.log("Zoom event:", event.transform);
      });
  
    // Apply the zoom behavior to the SVG element
    svg.call(zoom);
  
    // Cleanup function to remove zoom behavior when the component is unmounted
    return () => {
      svg.on('.zoom', null);
    };
  }, [status, sources, graphNodes, graphEdges]);
  

  useEffect(() => {
    // When nodesRef.current is populated, update nodesState to trigger a re-render
    if (nodesRef.current.length > 0) {
      setNodesState(nodesRef.current);
    }
  }, [nodesRef.current.length]); // Trigger only when nodesRef.current.length changes

  useEffect(() => {
    console.log('Current nodesState in LinkRef create:', nodesState);
  
    if (nodesState && nodesState.length > 0) {
      // Calculate weights and find min and max weights
      let minWeight = Infinity;
      let maxWeight = -Infinity;
  
      const linksWithWeights = graphEdges.map(d => {
        const sourceNode = nodesState.find(node => node.id === d.source);
        const targetNode = nodesState.find(node => node.id === d.target);
  
        let weight = Math.sqrt(
          Math.pow(targetNode.x - sourceNode.x, 2) +
          Math.pow(targetNode.y - sourceNode.y, 2)
        );
        // console.log('sourceNode', sourceNode);
        // console.log('targetNode', targetNode);
        // console.log('weight', weight);

        minWeight = Math.min(minWeight, weight);
        maxWeight = Math.max(maxWeight, weight);
  
        return {
          ...d,
          source: sourceNode,
          target: targetNode,
          weight: weight
        };
      });
  
      // Normalize weights to a 1-5 scale
      const normalizedLinks = linksWithWeights.map(link => {
        const distancerating = 1 + 4 * (link.weight - minWeight) / (maxWeight - minWeight);
        return {
          ...link,
          distancerating: Math.round(distancerating), // Round to the nearest integer
          weight: Math.round(link.weight) // Round to the nearest integer
        };
      });
  
      linksRef.current = normalizedLinks;
      console.log('After populating LinksRef - linksRef.current:', linksRef.current);
    }
  }, [nodesState]);
  

  // Return any refs or data you need in your component
  return {
    simulationRef,
    nodesRef,
    linksRef,
    rScaleRef,
  };
};

export default useD3Graph;
