import { useState, useEffect } from 'react';
import { useDataset } from './DataFetcher';

export const useGlobalDataset = () => {
  const [globalDataset, setGlobalDataset] = useState(null);
  const { data: dataset, isLoading, isError } = useDataset();

  useEffect(() => {
    if (dataset) {
      setGlobalDataset(dataset);
    }
  }, [dataset]);

  return { globalDataset, isLoading, isError };
};