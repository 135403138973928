import React, { useEffect } from 'react';

export function CustomModal({ isOpen, onClose, children }) {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="custom-modal" onClick={onClose}>
      <div
        className="custom-modal-inner relative"
        onClick={(e) => e.stopPropagation()}
      >
        <button onClick={onClose} className="modal-close-button absolute top-2 right-2">
          ✕
        </button>
        {children}
      </div>
    </div>
  );
}

// import React from 'react';

// export function CustomModal({ isOpen, onClose, children }) {
//   if (!isOpen) return null;

//   return (
//     <div
//       className="custom-modal fixed top-0 left-0 w-screen h-screen bg-dkpurple/75 flex items-center justify-center z-[1000]"
//       onClick={onClose} // Clicking backdrop will trigger onClose
//     >
//       <div
//         className="custom-modal-inner w-[90vw] h-[90vh] bg-white overflow-hidden relative rounded-md"
//         onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
//       >
//         {children}
//       </div>
//     </div>
//   );
// }
