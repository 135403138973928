import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import TriggerFlowModal from './TriggerFlowModal'; // <— import the new modal

const GO_BASE = process.env.REACT_APP_GO_BASE;
const API_BASE = `${GO_BASE}/api/v1`;

function getDefaultHeaders() {
  const token = localStorage.getItem('authToken');
  const OrgID = localStorage.getItem('ActiveOrgID');
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'OrgID': OrgID,
  };
}

const TriggerList = ({ onEditTrigger }) => {
  const [triggers, setTriggers] = useState([]);
  const [loading, setLoading] = useState(true);

  // For the visualization modal:
  const [selectedTrigger, setSelectedTrigger] = useState(null);
  const [flowModalOpen, setFlowModalOpen] = useState(false);

  const fetchTriggers = async () => {
    console.log('[TriggerList] Fetching triggers from', `${API_BASE}/triggers`);
    try {
      const response = await axios.get(`${API_BASE}/triggers`, {
        headers: getDefaultHeaders(),
      });
      console.log('[TriggerList] Fetched triggers:', response.data);
      setTriggers(response.data);
    } catch (error) {
      console.error('Failed to fetch triggers', error);
      toast.error('Failed to fetch triggers');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTriggers();
  }, []);

  const handleEdit = (trigger) => {
    if (onEditTrigger) {
      onEditTrigger(trigger);
    }
    console.log('[TriggerList] Edit trigger:', trigger);
    toast.info(`Edit trigger: ${trigger}`);
  };

  const handleDelete = async (triggerId) => {
    try {
      console.log('[TriggerList] Deleting trigger:', triggerId);
      await axios.delete(`${API_BASE}/triggers/${triggerId}`, {
        headers: getDefaultHeaders(),
      });
      toast.success('Trigger deleted successfully');
      fetchTriggers();
    } catch (error) {
      console.error('Failed to delete trigger', error);
      toast.error('Failed to delete trigger');
    }
  };

  const handleStatusToggle = async (trigger) => {
    try {
      const endpoint = trigger.status === 'active' ? 'deactivate' : 'deploy';
      const payload = { ...trigger, status: trigger.status === 'active' ? 'draft' : 'active' };
      console.log('[TriggerList] Toggling trigger status for', trigger.id, 'using endpoint', endpoint);
      await axios.post(`${API_BASE}/triggers/${endpoint}`, payload, {
        headers: getDefaultHeaders(),
      });
      toast.success('Trigger status updated successfully');
      fetchTriggers();
    } catch (error) {
      console.error('Failed to toggle trigger status', error);
      toast.error('Failed to toggle trigger status');
    }
  };

  // Click handler for opening the flow visualization modal
  const handleShowFlow = (trigger) => {
    setSelectedTrigger(trigger);
    setFlowModalOpen(true);
  };

  if (loading) {
    return <div className="loading-message">Loading triggers...</div>;
  }

  if (!triggers || triggers.length === 0) {
    return <div className="no-triggers-message">No triggers found.</div>;
  }

  return (
    <div className="trigger-list-container">
      <table className="table-triggers">
        <thead>
          <tr>
            <th>Name</th>
            <th>Conditions / Steps</th>
            <th>Status</th>
            <th>Created</th>
            <th className="table-triggers-actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {triggers.sort((a, b) => a.name.localeCompare(b.name)).map((trigger) => {
            const conditionCount = trigger.conditions ? trigger.conditions.length : 0;
            const stepCount = trigger.steps ? trigger.steps.length : 0;
            const createdDate = new Date(trigger.createdAt).toLocaleDateString();

            return (
              <tr key={trigger.id}>
                {/* Make Name clickable */}
                <td
                  onClick={() => handleShowFlow(trigger)}
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  {trigger.name}
                </td>
                {/* Make Conditions/Steps clickable */}
                <td
                  className="table-cell-center"
                  onClick={() => handleShowFlow(trigger)}
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  {conditionCount} / {stepCount}
                </td>
                <td className="table-cell-center">
                  <span className={`status-badge ${trigger.status}`}>
                    {trigger.status}
                  </span>
                </td>
                <td className="table-cell-center">{createdDate}</td>
                <td className="table-cell-center">
                  <button
                    onClick={() => handleEdit(trigger)}
                    className="btn-add-condition mr-2"
                  >
                    Edit
                  </button>
                  <button onClick={() => handleStatusToggle(trigger)} className="btn-add-condition">
                    {trigger.status === 'active' ? 'Deactivate' : 'Deploy'}
                  </button>
                  <button
                    onClick={() => handleDelete(trigger.id)}
                    className="btn-add-condition mr-2"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Render the new Flow Modal */}
      <TriggerFlowModal
        trigger={selectedTrigger}
        opened={flowModalOpen}
        onClose={() => setFlowModalOpen(false)}
      />
    </div>
  );
};

export default TriggerList;


// // TriggerList.jsx
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { toast } from 'react-toastify';

// const GO_BASE = process.env.REACT_APP_GO_BASE;
// const API_BASE = `${GO_BASE}/api/v1`;

// function getDefaultHeaders() {
//   const token = localStorage.getItem('authToken');
//   const OrgID = localStorage.getItem('ActiveOrgID');
//   return {
//     'Content-Type': 'application/json',
//     'Authorization': `Bearer ${token}`,
//     'OrgID': OrgID,
//   };
// }

// const TriggerList = ({ onEditTrigger }) => {
//   const [triggers, setTriggers] = useState([]);
//   const [loading, setLoading] = useState(true);

//   const fetchTriggers = async () => {
//     console.log('[TriggerList] Fetching triggers from', `${API_BASE}/triggers`);
//     try {
//       const response = await axios.get(`${API_BASE}/triggers`, {
//         headers: getDefaultHeaders(),
//       });
//       console.log('[TriggerList] Fetched triggers:', response.data);
//       setTriggers(response.data);
//     } catch (error) {
//       console.error('Failed to fetch triggers', error);
//       toast.error('Failed to fetch triggers');
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchTriggers();
//   }, []);


//   const handleEdit = (trigger) => {
//     if (onEditTrigger) {
//         onEditTrigger(trigger);
//       }
//     console.log('[TriggerList] Edit trigger:', trigger);
//     toast.info(`Edit trigger: ${trigger}`);
//   };

//   const handleDelete = async (triggerId) => {
//     try {
//       console.log('[TriggerList] Deleting trigger:', triggerId);
//       await axios.delete(`${API_BASE}/triggers/${triggerId}`, {
//         headers: getDefaultHeaders(),
//       });
//       toast.success('Trigger deleted successfully');
//       fetchTriggers();
//     } catch (error) {
//       console.error('Failed to delete trigger', error);
//       toast.error('Failed to delete trigger');
//     }
//   };

//   const handleStatusToggle = async (trigger) => {
//     try {
//       const endpoint = trigger.status === 'active' ? 'deactivate' : 'deploy';
//       const payload = { ...trigger, status: trigger.status === 'active' ? 'draft' : 'active' };
//       console.log('[TriggerList] Toggling trigger status for', trigger.id, 'using endpoint', endpoint);
//       await axios.post(`${API_BASE}/triggers/${endpoint}`, payload, {
//         headers: getDefaultHeaders(),
//       });
//       toast.success('Trigger status updated successfully');
//       fetchTriggers();
//     } catch (error) {
//       console.error('Failed to toggle trigger status', error);
//       toast.error('Failed to toggle trigger status');
//     }
//   };

//   if (loading) {
//     return <div className="loading-message">Loading triggers...</div>;
//   }

//   if (!triggers || triggers.length === 0) {
//     return <div className="no-triggers-message">No triggers found.</div>;
//   }

//   return (
//     <div className="trigger-list-container">
//       {/* 
//         We define only ONE class on the table, which we'll style in the .css file 
//         to be w-full, table-auto, border-collapse, etc.
//       */}
//       <table className="table-triggers">
//         <thead>
//           <tr>
//             <th>Name</th>
//             <th>Conditions / Steps</th>
//             <th>Status</th>
//             <th>Created</th>
//             <th className="table-triggers-actions">Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {triggers.sort((a, b) => a.name.localeCompare(b.name)).map((trigger) => {
//             const conditionCount = trigger.conditions ? trigger.conditions.length : 0;
//             const stepCount = trigger.steps ? trigger.steps.length : 0;
//             const createdDate = new Date(trigger.createdAt).toLocaleDateString();

//             return (
//               <tr key={trigger.id}>
//                 <td>{trigger.name}</td>
//                 <td className="table-cell-center">{conditionCount} / {stepCount}</td>
//                 <td className="table-cell-center">
//                   <span className={`status-badge ${trigger.status}`}>
//                     {trigger.status}
//                   </span>
//                 </td>
//                 <td className="table-cell-center">{createdDate}</td>
//                 <td className="table-cell-center">
//                   {/* 
//                     We still reference button classes that you presumably have
//                     in your PostCSS, like "btn-add-condition" and "mr-2"
//                   */}
//                   <button
//                       onClick={() => handleEdit(trigger)}
//                       className="btn-add-condition mr-2"
//                     >
//                     Edit
//                   </button>
//                   <button onClick={() => handleStatusToggle(trigger)} className="btn-add-condition">
//                     {trigger.status === 'active' ? 'Deactivate' : 'Deploy'}
//                   </button>
//                   <button onClick={() => handleDelete(trigger.id)} className="btn-add-condition mr-2">
//                     Delete
//                   </button>
//                 </td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default TriggerList;
