import React, { useEffect, useState } from 'react';
import ConditionEditor from './ConditionEditor';
import ConditionsGroups from './ConditionGroups'; // Adjust the path accordingly

/**
 * PatternBuilder
 * - Manages Conditions and Steps with a modal-based approach for adding/editing items.
 * - Steps are created/edited in a modal (similar to how Conditions are handled).
 * - The user can pick from a list of webhooks in a multi-select, then confirm by clicking Save.
 *
 * Now enhanced as a controlled component. It accepts:
 *   - currentTrigger: object containing conditions and steps
 *   - onChange: callback to notify parent of updates
 */

function PatternBuilder({ webhookOptions: rawWebhookOptions = [], currentTrigger = {}, onChange }) {

  const [initialized, setInitialized] = useState(false);
  // ---------------------------------------------------------------------------
  //  1) LOGGING + NORMALIZATION
  // ---------------------------------------------------------------------------
  useEffect(() => {
    console.log('[PatternBuilder] rawWebhookOptions received:', rawWebhookOptions);
  }, [rawWebhookOptions]);

  const safeWebhookOptions = Array.isArray(rawWebhookOptions) ? rawWebhookOptions : [];
  useEffect(() => {
    console.log('[PatternBuilder] safeWebhookOptions:', safeWebhookOptions);
  }, [safeWebhookOptions]);

  // ---------------------------------------------------------------------------
  //  2) DATASET LOAD (if needed)
  // ---------------------------------------------------------------------------
  const [dataset, setDataset] = useState({});
  useEffect(() => {
    try {
      const ds = JSON.parse(localStorage.getItem('dataset') || '{}');
      setDataset(ds);
      console.log('[PatternBuilder] Dataset loaded:', ds);
    } catch (error) {
      console.error('[PatternBuilder] Failed to parse dataset from localStorage:', error);
    }
  }, []);

  // ---------------------------------------------------------------------------
  //  3) CONDITIONS STATE
  // ---------------------------------------------------------------------------
  // Initialize conditions from currentTrigger; default to empty array if undefined.
  const [conditions, setConditions] = useState(currentTrigger.conditions || []);
  const [showAddConditionModal, setShowAddConditionModal] = useState(false);
  const [draftCondition, setDraftCondition] = useState(null);

  useEffect(() => {
    if (Array.isArray(currentTrigger.conditions) && currentTrigger.conditions.length > 0) {
      // If the first condition is already nested with a non-empty type, assume it's normalized.
      if (currentTrigger.conditions[0].condition && currentTrigger.conditions[0].condition.type) {
        setConditions(currentTrigger.conditions);
      } else {
        const normalized = currentTrigger.conditions.map(item =>
          item.condition
            ? item
            : { condition: item, operator: item.operator || 'AND' }
        );
        setConditions(normalized);
      }
    }
  }, [currentTrigger.conditions]);  

  const openAddConditionModal = () => {
    console.log('[PatternBuilder] "Add Condition" clicked. Opening modal...');
    setDraftCondition({
      id: `cond-${Date.now()}`,
      type: 'EVENT',
      parameters: {
        lookbackDays: 7,
      },
    });
    setShowAddConditionModal(true);
  };

  const closeAddConditionModal = () => {
    setShowAddConditionModal(false);
    setDraftCondition(null);
  };

  const saveNewCondition = () => {
    if (!draftCondition) return;
    console.log('[PatternBuilder] Saving new condition:', draftCondition);
    // Wrap the condition with the group structure.
    setConditions((prev) => [
      ...prev,
      { condition: draftCondition, operator: 'AND' }
    ]);
    setShowAddConditionModal(false);
    setDraftCondition(null);
  };
  

  const handleUpdateCondition = (index, updatedCond) => {
    console.log('[PatternBuilder] updateCondition index:', index, updatedCond);
    setConditions((prev) => {
      const newConditions = [...prev];
      newConditions[index] = updatedCond;
      return newConditions;
    });
  };

  const renderConditionSummary = (cond) => {
    if (cond.type === 'EVENT') {
      return (
        <div className="condition-summary">
          <div>
            <strong>Event Desc:</strong> {cond.parameters.eventDesc || '(none)'}
          </div>
          <div>
            <strong>Lookback:</strong> {cond.parameters.lookbackDays} Days
          </div>
        </div>
      );
    } else if (cond.type === 'PAGE') {
      return (
        <div className="condition-summary">
          <div>
            <strong>Page URL:</strong> {cond.parameters.pageUrl || '(none)'}
          </div>
          <div>
            <strong>Lookback:</strong> {cond.parameters.lookbackDays} Days
          </div>
        </div>
      );
    } else if (cond.type === 'ML_PATTERN') {
      return (
        <div className="condition-summary">
          <div>
            <strong>ML Pattern:</strong> {cond.parameters?.patternName || 'Custom Pattern'}
          </div>
          <div>
            <strong>Details:</strong> {JSON.stringify(cond.parameters)}
          </div>
        </div>
      );
    }
    return (
      <div className="condition-summary">
        <div>
          <strong>{cond.type}:</strong> {JSON.stringify(cond.parameters)}
        </div>
      </div>
    );
  };

  // ---------------------------------------------------------------------------
  //  4) STEPS STATE
  //     - Uses a modal-based approach to create/edit steps, just like conditions.
  // ---------------------------------------------------------------------------
  // Initialize steps from currentTrigger; default to empty array if undefined.
  const [steps, setSteps] = useState(currentTrigger.steps || []);
  const [showStepModal, setShowStepModal] = useState(false);
  const [draftStep, setDraftStep] = useState(null);
  const [localTriggerKey, setLocalTriggerKey] = useState(currentTrigger.name || '');

// Whenever currentTrigger.id changes, re-init local states from the parent's data.
useEffect(() => {
  if ((currentTrigger.name || '') !== localTriggerKey) {
    setLocalTriggerKey(currentTrigger.name);

    // Re-wrap or normalize conditions if needed
    if (
      Array.isArray(currentTrigger.conditions) && 
      currentTrigger.conditions.length > 0
    ) {
      if (
        currentTrigger.conditions[0].condition &&
        currentTrigger.conditions[0].condition.type
      ) {
        setConditions(currentTrigger.conditions);
      } else {
        const normalized = currentTrigger.conditions.map((item) =>
          item.condition
            ? item
            : { condition: item, operator: item.operator || 'AND' }
        );
        setConditions(normalized);
      }
    } else {
      setConditions([]);
    }

    // Re-initialize steps
    setSteps(currentTrigger.steps || []);
  }
}, [currentTrigger, localTriggerKey]);


  const openAddStepModal = () => {
    console.log('[PatternBuilder] "Add Workflow Step" clicked. Current steps:', steps);
    setDraftStep({
      id: `step-${Date.now()}`,
      webhookId: [], // array of webhook names
    });
    setShowStepModal(true);
  };

  const openEditStepModal = (index) => {
    console.log('[PatternBuilder] "Edit Step" clicked for index:', index);
    const stepToEdit = steps[index];
    setDraftStep({ ...stepToEdit });
    setShowStepModal(true);
  };

  const closeStepModal = () => {
    setShowStepModal(false);
    setDraftStep(null);
  };

  const saveStep = () => {
    if (!draftStep) return;
    console.log('[PatternBuilder] Saving step:', draftStep);
    setSteps((prevSteps) => {
      const existingIndex = prevSteps.findIndex((s) => s.id === draftStep.id);
      if (existingIndex >= 0) {
        const updated = [...prevSteps];
        updated[existingIndex] = draftStep;
        return updated;
      } else {
        return [...prevSteps, draftStep];
      }
    });
    closeStepModal();
  };

  const deleteStep = (index) => {
    console.log('[PatternBuilder] "Delete Step" clicked for index:', index);
    setSteps((prev) => {
      const newSteps = [...prev];
      newSteps.splice(index, 1);
      return newSteps;
    });
  };

  const renderStepSummary = (step) => {
    return (
      <>
        <div className="step-summary-line">
          <strong>Webhook:</strong>{' '}
          {step.webhookId && step.webhookId.length > 0 ? step.webhookId.join(', ') : '(none)'}
        </div>
      </>
    );
  };

  // ---------------------------------------------------------------------------
  //  5) NOTIFY PARENT OF CHANGES
  // ---------------------------------------------------------------------------
  // Whenever local conditions or steps change, notify the parent via onChange.
  // useEffect(() => {
  //   const updatedTrigger = { ...currentTrigger, conditions, steps };
  //   onChange(updatedTrigger);
  // }, [conditions, steps]);
useEffect(() => {
 

  const updatedTrigger = {
    ...currentTrigger,
    conditions,
    // conditions: flattenedConditions,
    steps,
  };
  onChange(updatedTrigger);
}, [conditions, steps, currentTrigger, onChange]);

  // ---------------------------------------------------------------------------
  //  6) RENDER
  // ---------------------------------------------------------------------------
  const safeConditions = Array.isArray(conditions) ? conditions : [];
  const safeSteps = Array.isArray(steps) ? steps : [];

  return (
    <div className="pattern-canvas">
      <div className="pattern-builder-columns">
        {/* -------------------------- CONDITIONS COLUMN -------------------------- */}
        <div className="conditions-list">
        <h3 className="column-title">Conditions</h3>
        <ConditionsGroups
          initialConditions={safeConditions}
          dataset={dataset}
          onGroupUpdate={(updatedConditions) => setConditions(updatedConditions)}
          renderConditionSummary={renderConditionSummary}
          onEditCondition={(index) => {
            const groupItem = safeConditions[index];
            // groupItem is { condition, operator }
            setDraftCondition(groupItem.condition);
            setShowAddConditionModal(true);
          }}
        />
        <button onClick={openAddConditionModal} className="btn-add-condition">
          Add Condition
        </button>
      </div>

        {/* -------------------------- STEPS COLUMN ------------------------------ */}
        <div className="steps-list">
          <h3 className="column-title">Workflow Steps</h3>
          {safeSteps.map((step, idx) => (
            <div key={step.id} className="step-item">
              {renderStepSummary(step)}
              <div className="step-actions">
                <button onClick={() => openEditStepModal(idx)} className="btn-edit-step mr-2">
                  Edit
                </button>
                <button onClick={() => deleteStep(idx)} className="btn-delete-step">
                  Delete
                </button>
              </div>
            </div>
          ))}
          <button onClick={openAddStepModal} className="btn-add-step">
            Add Workflow Step
          </button>
        </div>
      </div>

      {/* -------------------------- MODAL: ADD CONDITION ------------------------ */}
      {showAddConditionModal && draftCondition && (
        <div className="modal-overlay">
          <div className="modal-content-large">
            <h2 className="modal-title">New Condition</h2>
            
            <ConditionEditor
              cond={draftCondition}
              dataset={dataset}
              onUpdate={(updated) => {
                console.log('[PatternBuilder] Modal ConditionEditor updated draft:', updated);
                setDraftCondition(prev => ({ ...prev, ...updated }));
              }}
            />

            <div className="modal-button-group">
              <button onClick={closeAddConditionModal} className="btn-cancel">
                Cancel
              </button>
              <button onClick={saveNewCondition} className="btn-save-condition">
                Save Condition
              </button>
            </div>
          </div>
        </div>
      )}

      {/* -------------------------- MODAL: ADD/EDIT STEP ------------------------ */}
      {showStepModal && draftStep && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2 className="modal-title">Available Webhooks</h2>
            <div className="form-group">
              {safeWebhookOptions.length === 0 ? (
                <p className="no-webhooks-text">
                  No webhooks configured. Please go to <a href="/webhooks">/webhooks</a> to add one.
                </p>
              ) : (
                <select
                  multiple
                  value={draftStep.webhookId}
                  onChange={(e) => {
                    const selected = Array.from(e.target.selectedOptions).map((option) => option.value);
                    console.log('[PatternBuilder] StepEditor: new webhookId selection:', selected);
                    setDraftStep({ ...draftStep, webhookId: selected });
                  }}
                  className="step-select"
                >
                  {safeWebhookOptions.map((wh) => {
                    const name = wh.name || wh.Name || '';
                    return (
                      <option key={name.toLowerCase()} value={name}>
                        {name}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
            <div className="modal-button-group">
              <button onClick={closeStepModal} className="btn-cancel">
                Cancel
              </button>
              <button onClick={saveStep} className="btn-save-step">
                Save Step
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PatternBuilder;
