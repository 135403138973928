import React, { useState, useEffect } from 'react';

function ConditionsGroups({
  initialConditions,
  onGroupUpdate,
  renderConditionSummary,
  onEditCondition, // Called when the user clicks a condition summary.
}) {
  // Normalize each condition: if it’s not wrapped already, wrap it.
  const normalizeConditions = (conds) =>
    conds.map(item =>
      item && item.condition ? item : { condition: item, operator: 'AND' }
    );

  const [conditions, setConditions] = useState(normalizeConditions(initialConditions));

  useEffect(() => {
    setConditions(normalizeConditions(initialConditions));
  }, [initialConditions]);

  const handleOperatorChange = (index, newOperator) => {
    const updatedConditions = conditions.map((item, idx) =>
      idx === index ? { ...item, operator: newOperator } : item
    );
    setConditions(updatedConditions);
    onGroupUpdate && onGroupUpdate(updatedConditions);
  };

  return (
    <div className="conditions-group-editor">
      {conditions.map((item, index) => (
        <div key={item.condition.id || index} className="condition-wrapper">
          <div
            className="condition-summary-wrapper"
            onClick={() => onEditCondition && onEditCondition(index)}
          >
            {renderConditionSummary(item.condition)}
          </div>
          {index < conditions.length - 1 && (
            <div className="operator-selector">
              {['THEN', 'AND', 'OR', 'NOT'].map(op => (
                <button
                  key={op}
                  className={`operator-button ${item.operator === op ? 'selected' : 'unselected'}`}
                  onClick={() => handleOperatorChange(index, op)}
                >
                  {op}
                </button>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ConditionsGroups;


// import React, { useState, useEffect } from 'react';

// function ConditionsGroups({
//   initialConditions,
//   onGroupUpdate,
//   renderConditionSummary,
//   onEditCondition,
// }) {
//   // Normalize: Wrap raw conditions in { condition, operator } if needed.
//   const normalizeConditions = (conds) =>
//     conds.map(item => 
//       item && item.condition ? item : { condition: item, operator: 'AND' }
//     );

//   const [conditions, setConditions] = useState(normalizeConditions(initialConditions));

//   // When the parent updates the conditions, re-normalize.
//   useEffect(() => {
//     setConditions(normalizeConditions(initialConditions));
//   }, [initialConditions]);

//   // Update operator for a given condition.
//   const handleOperatorChange = (index, newOperator) => {
//     const updatedConditions = conditions.map((item, idx) =>
//       idx === index ? { ...item, operator: newOperator } : item
//     );
//     setConditions(updatedConditions);
//     onGroupUpdate && onGroupUpdate(updatedConditions);
//   };

//   return (
//     <div className="conditions-group-editor">
//       {conditions.map((item, index) => (
//         <div key={item.condition.id || index} className="condition-wrapper">
//           {/* Render the condition summary using the nested condition */}
//           <div
//             className="condition-summary-wrapper"
//             onClick={() => onEditCondition && onEditCondition(index)}
//           >
//             {renderConditionSummary(item.condition)}
//           </div>
//           {/* Only show operator buttons if there's another condition */}
//           {index < conditions.length - 1 && (
//             <div className="operator-selector">
//               {['THEN', 'AND', 'OR', 'NOT'].map(op => (
//                 <button
//                   key={op}
//                   className={`operator-button ${item.operator === op ? 'selected' : 'unselected'}`}
//                   onClick={() => handleOperatorChange(index, op)}
//                 >
//                   {op}
//                 </button>
//               ))}
//             </div>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// }

// export default ConditionsGroups;




// // import React, { useState, useEffect } from 'react';

// // function ConditionsGroups({
// //   initialConditions,
// //   dataset,
// //   onGroupUpdate,
// //   renderConditionSummary,
// //   onEditCondition,
// // }) {
// //   // If item is just a condition object, wrap it with { condition, operator }
// //   const normalizeConditions = (conds) =>
// //     conds.map((item) =>
// //       item.condition ? item : { condition: item, operator: 'AND' }
// //     );

// //   const [conditions, setConditions] = useState(normalizeConditions(initialConditions));

// //   useEffect(() => {
// //     setConditions(normalizeConditions(initialConditions));
// //   }, [initialConditions]);

// //   const handleOperatorChange = (index, newOperator) => {
// //     const updatedConditions = [...conditions];
// //     updatedConditions[index].operator = newOperator;
// //     setConditions(updatedConditions);
// //     onGroupUpdate && onGroupUpdate(updatedConditions);
// //   };

// //   return (
// //     <div className="conditions-group-editor">
// //       {conditions.map((item, index) => (
// //         <div key={index} className="condition-wrapper">
// //           {/* Condition summary row */}
// //           <div
// //             className="condition-summary-wrapper"
// //             onClick={() => onEditCondition && onEditCondition(index)}
// //           >
// //             {renderConditionSummary(item.condition)}
// //           </div>

// //           {/* Operator row (only show if there's a next condition) */}
// //           {index < conditions.length - 1 && (
// //             <div className="operator-selector">
// //               {['THEN', 'AND', 'OR', 'NOT'].map((op) => (
// //                 <button
// //                   key={op}
// //                   className={`operator-button ${
// //                     item.operator === op ? 'selected' : 'unselected'
// //                   }`}
// //                   onClick={() => handleOperatorChange(index, op)}
// //                 >
// //                   {op}
// //                 </button>
// //               ))}
// //             </div>
// //           )}
// //         </div>
// //       ))}
// //     </div>
// //   );
// // }

// // export default ConditionsGroups;


// // import React, { useState, useEffect } from 'react';

// // function ConditionsGroups({
// //   initialConditions,
// //   dataset,
// //   onGroupUpdate,
// //   renderConditionSummary,
// //   onEditCondition,
// // }) {
// //   // Normalize each condition: if not wrapped, wrap with a default operator.
// //   const normalizeConditions = (conds) =>
// //     conds.map((item) =>
// //       item.condition ? item : { condition: item, operator: 'AND' }
// //     );

// //   const [conditions, setConditions] = useState(normalizeConditions(initialConditions));

// //   useEffect(() => {
// //     setConditions(normalizeConditions(initialConditions));
// //   }, [initialConditions]);

// //   const handleOperatorChange = (index, newOperator) => {
// //     const updatedConditions = [...conditions];
// //     updatedConditions[index].operator = newOperator;
// //     setConditions(updatedConditions);
// //     onGroupUpdate && onGroupUpdate(updatedConditions);
// //   };

// //   return (
// //     <div className="conditions-group-editor">
// //       {conditions.map((item, index) => (
// //         <div key={index} className="condition-group-item">
// //           <div
// //             className="condition-summary-wrapper"
// //             onClick={() => onEditCondition && onEditCondition(index)}
// //           >
// //             {/* Here we call the passed render function with the condition */}
// //             {renderConditionSummary(item.condition)}
// //           </div>
// //           {index < conditions.length - 1 && (
// //             <div className="operator-selector">
// //               {['AND', 'OR', 'THEN', 'NOT'].map((op) => (
// //                 <button
// //                   key={op}
// //                   className={`operator-button ${item.operator === op ? 'selected' : 'unselected'}`}
// //                   onClick={() => handleOperatorChange(index, op)}
// //                 >
// //                   {op}
// //                 </button>
// //               ))}
// //             </div>
// //           )}
// //         </div>
// //       ))}
// //     </div>
// //   );
// // }

// // export default ConditionsGroups;


// // // // import React, { useState, useEffect } from 'react';
// // // // import './ConditionsGroups.css'; // Import the external CSS file

// // // // function ConditionsGroups({
// // // //   initialConditions,
// // // //   dataset,
// // // //   onGroupUpdate,
// // // //   renderConditionSummary,
// // // //   onEditCondition,
// // // // }) {
// // // //   // Normalize each condition: if it isn't wrapped, wrap it with a default operator.
// // // //   const normalizeConditions = (conds) =>
// // // //     conds.map((item) =>
// // // //       item.condition ? item : { condition: item, operator: 'AND' }
// // // //     );

// // // //   const [conditions, setConditions] = useState(normalizeConditions(initialConditions));

// // // //   useEffect(() => {
// // // //     setConditions(normalizeConditions(initialConditions));
// // // //   }, [initialConditions]);

// // // //   const handleOperatorChange = (index, newOperator) => {
// // // //     const updatedConditions = [...conditions];
// // // //     updatedConditions[index].operator = newOperator;
// // // //     setConditions(updatedConditions);
// // // //     onGroupUpdate && onGroupUpdate(updatedConditions);
// // // //   };

// // // //   return (
// // // //     <div className="conditions-group-editor">
// // // //       {conditions.map((item, index) => (
// // // //         <div key={index} className="condition-group-item">
// // // //           <div
// // // //             className="condition-summary-wrapper"
// // // //             onClick={() => onEditCondition && onEditCondition(index)}
// // // //           >
// // // //             {renderConditionSummary(item.condition)}
// // // //           </div>
// // // //           {index < conditions.length - 1 && (
// // // //             <div className="operator-selector">
// // // //               {['AND', 'OR', 'THEN', 'NOT'].map((op) => (
// // // //                 <button
// // // //                   key={op}
// // // //                   className={`operator-button ${item.operator === op ? 'selected' : 'unselected'}`}
// // // //                   onClick={() => handleOperatorChange(index, op)}
// // // //                 >
// // // //                   {op}
// // // //                 </button>
// // // //               ))}
// // // //             </div>
// // // //           )}
// // // //         </div>
// // // //       ))}
// // // //     </div>
// // // //   );
// // // // }

// // // // export default ConditionsGroups;



// // // // // import React, { useState, useEffect } from 'react';
// // // // // import ConditionEditor from './ConditionEditor'; // Adjust the import path as needed

// // // // // function ConditionsGroups({ initialConditions, dataset, onGroupUpdate }) {
// // // // //     // Normalize each condition: if it isn't wrapped, wrap it with a default operator.
// // // // //     const normalizeConditions = (conds) =>
// // // // //       conds.map((item) =>
// // // // //         item.condition ? item : { condition: item, operator: 'AND' }
// // // // //       );
  
// // // // //     const [conditions, setConditions] = useState(normalizeConditions(initialConditions));
  
// // // // //     useEffect(() => {
// // // // //       setConditions(normalizeConditions(initialConditions));
// // // // //     }, [initialConditions]);
  
// // // // //     const handleConditionUpdate = (index, updatedCondition) => {
// // // // //       const updatedConditions = [...conditions];
// // // // //       updatedConditions[index].condition = updatedCondition;
// // // // //       setConditions(updatedConditions);
// // // // //       onGroupUpdate && onGroupUpdate(updatedConditions);
// // // // //     };
  
// // // // //     const handleOperatorChange = (index, newOperator) => {
// // // // //       const updatedConditions = [...conditions];
// // // // //       updatedConditions[index].operator = newOperator;
// // // // //       setConditions(updatedConditions);
// // // // //       onGroupUpdate && onGroupUpdate(updatedConditions);
// // // // //     };
  
// // // // //     return (
// // // // //       <div className="conditions-group-editor">
// // // // //         {conditions.map((item, index) => (
// // // // //           <div key={index} className="condition-group-item">
// // // // //             <ConditionEditor
// // // // //               cond={item.condition}
// // // // //               dataset={dataset}
// // // // //               onUpdate={(updatedCondition) =>
// // // // //                 handleConditionUpdate(index, updatedCondition)
// // // // //               }
// // // // //             />
// // // // //             {index < conditions.length - 1 && (
// // // // //               <div className="operator-selector">
// // // // //                 {['AND', 'OR', 'THEN', 'NOT'].map((op) => (
// // // // //                   <button
// // // // //                     key={op}
// // // // //                     className={
// // // // //                       item.operator === op
// // // // //                         ? 'operator-button selected'
// // // // //                         : 'operator-button'
// // // // //                     }
// // // // //                     onClick={() => handleOperatorChange(index, op)}
// // // // //                   >
// // // // //                     {op}
// // // // //                   </button>
// // // // //                 ))}
// // // // //               </div>
// // // // //             )}
// // // // //           </div>
// // // // //         ))}
// // // // //       </div>
// // // // //     );
// // // // //   }
  
// // // // //   export default ConditionsGroups;
  
