import React, { useEffect, useState, useContext, useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { motion } from 'framer-motion';
import { Card, Title, SegmentedControl } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import LoadingSpinner from '../components/LoadingSpinner';
import {
  format,
  isWithinInterval,
  parseISO,
  startOfWeek,
  startOfMonth,
  isValid,
} from 'date-fns';
import { askOracle } from '../components/AskOracle';
import { DimensionContext } from '../components/ResponsiveWrapper';
import LZString from 'lz-string';


const WeekOverWeekAnalysis = ({ orgID }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [isWeekly, setIsWeekly] = useState(true);

  const { width, height } = useContext(DimensionContext); // Responsive dimensions if needed

  const customColors = [
    '#ebb844', '#e08a3c', '#de6736', '#c5316a', '#762861',
    '#2c294b', '#3b3484', '#7174b0', '#7174b0', '#7174b0',
  ];

  const NivoTheme = {
    axis: {
      ticks: { text: { fontFamily: 'Manjari, sans-serif', fontSize: '12px' } },
      legend: { text: { fontFamily: 'Manjari, sans-serif', fontSize: '18px' } },
    },
    legends: { text: { fontFamily: 'Manjari, sans-serif' } },
    labels: { text: { fontFamily: 'Manjari, sans-serif' } },
    tooltip: { container: { fontFamily: 'Manjari, sans-serif' } },
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await askOracle('by-page', { params: { orgID } });
  //       const chunkSize = 1000;
  //       const validData = [];
  //       for (let i = 0; i < response.length; i += chunkSize) {
  //         const chunk = response.slice(i, i + chunkSize).filter((entry) => {
  //           return (
  //             entry.Timestamp &&
  //             isValid(parseISO(entry.Timestamp)) &&
  //             entry.URL &&
  //             entry.URL.trim() !== '' &&
  //             entry.HappyID &&
  //             entry.HappyID.trim() !== '' &&
  //             Array.isArray(entry.EventDescList) &&
  //             entry.EventDescList.length > 0 &&
  //             entry.EventDescList.every((event) => event)
  //           );
  //         });
  //         validData.push(...chunk);
  //       }
  //       setData(validData);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchData();
  // }, [orgID]);

// 1. Updated Data Fetching with Caching:
useEffect(() => {
  const fetchData = async () => {
    setLoading(true);
    const cacheKey = "trends";
    try {
      // Check local cache for the "trends" key
      const cache = localStorage.getItem(cacheKey);
      if (cache) {
        const decompressed = LZString.decompress(cache);
        const cachedData = JSON.parse(decompressed);
        // Use cached data if it's not older than 24 hours
        if (
          cachedData.timestamp &&
          Date.now() - cachedData.timestamp < 24 * 60 * 60 * 1000
        ) {
          console.log('Using cached data');
          setData(cachedData.data);
          setLoading(false);
          return;
        }
      }
      
      // Otherwise, fetch new data from the server
      const response = await askOracle('by-page', { params: { orgID } });
      const chunkSize = 1000;
      const validData = [];
      for (let i = 0; i < response.length; i += chunkSize) {
        const chunk = response.slice(i, i + chunkSize).filter((entry) => (
          entry.Timestamp &&
          isValid(parseISO(entry.Timestamp)) &&
          entry.URL &&
          entry.URL.trim() !== '' &&
          entry.HappyID &&
          entry.HappyID.trim() !== '' &&
          Array.isArray(entry.EventDescList) &&
          entry.EventDescList.length > 0 &&
          entry.EventDescList.every((event) => event)
        ));
        validData.push(...chunk);
      }
      setData(validData);
      // Attempt to cache new data using the "trends" key with compression
      try {
        const compressed = LZString.compress(
          JSON.stringify({
            timestamp: Date.now(),
            data: validData,
          })
        );
        localStorage.setItem(cacheKey, compressed);
      } catch (err) {
        console.error("Error caching data", err);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  fetchData();
}, [orgID]);




// 2. Updated Custom Hook for Staggered Progressive Computation:
function useProgressiveData(computeFn, dependencies, delay = 0) {
  const [result, setResult] = useState([]);
  useEffect(() => {
    let canceled = false;
    const idleCallback = window.requestIdleCallback || function (cb) { setTimeout(cb, 200); };
    const timer = setTimeout(() => {
      idleCallback(() => {
        if (!canceled) {
          setResult(computeFn());
        }
      });
    }, delay);
    return () => {
      clearTimeout(timer);
      canceled = true;
    };
  }, dependencies);
  return result;
}

  

  // Helper functions
  const safeNumber = (value, defaultValue = 0) => (
    value === undefined || value === null || value === '' || isNaN(Number(value))
      ? defaultValue || 0.0001
      : Number(value)
  );

  const cleanData = (arr) => arr.filter((d) => (
    d && Object.values(d).every((v) =>
      v !== undefined && v !== null && (typeof v !== 'number' || !isNaN(v))
    )
  ));

  const getTimePeriodKey = (timestamp) => (
    isWeekly
      ? format(startOfWeek(parseISO(timestamp)), 'yyyy-MM-dd')
      : format(startOfMonth(parseISO(timestamp)), 'yyyy-MM')
  );

  const formatDuration = (seconds) => (
    `${Math.floor(seconds / 60)}m ${Math.round(seconds % 60)}s`
  );

  // Unconditional useMemo hooks for all computed data
  const filteredData = useMemo(() => {
    const chunkSize = 1000;
    const finalData = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      const chunk = data.slice(i, i + chunkSize).filter((entry) => (
        dateRange[0] &&
        dateRange[1] &&
        isValid(dateRange[0]) &&
        isValid(dateRange[1])
          ? isWithinInterval(parseISO(entry.Timestamp), {
            start: dateRange[0],
            end: dateRange[1],
          })
          : true
      ));
      finalData.push(...chunk);
    }
    return finalData;
  }, [data, dateRange]);

  const pageVisitsData = useProgressiveData(() => {
    const visits = filteredData.reduce((acc, entry) => {
      if (isValid(parseISO(entry.Timestamp))) {
        const period = getTimePeriodKey(entry.Timestamp);
        acc[period] = (acc[period] || 0) + 1;
      }
      return acc;
    }, {});

    return cleanData(Object.entries(visits).map(([period, count]) => ({
      period: period.replace(/-/g, '/'),
      count: safeNumber(count),
    }))
    .sort((a, b) => parsePeriodForSorting(a.period) - parsePeriodForSorting(b.period)));

  }, [filteredData, isWeekly], 0);

  const averagePageDurationData = useProgressiveData(() => {
    const durations = filteredData.reduce((acc, entry) => {
      if (entry.PageDuration && !isNaN(entry.PageDuration)) {
        if (!acc[entry.URL]) acc[entry.URL] = { total: 0, count: 0 };
        acc[entry.URL].total += entry.PageDuration;
        acc[entry.URL].count += 1;
      }
      return acc;
    }, {});
  
    return cleanData(
      Object.entries(durations)
        .map(([url, { total, count }]) => ({
          url: url || 'No Data',
          averageDurationNumeric: safeNumber(total / count),
          averageDuration: formatDuration(safeNumber(total / count)),
        }))
        .sort((a, b) => a.url.localeCompare(b.url)) // Sort alphabetically by URL
    );
  }, [filteredData], 800);
  

  const usersPerPeriodData = useProgressiveData(() => {
    const users = filteredData.reduce((acc, entry) => {
      if (isValid(parseISO(entry.Timestamp))) {
        const period = getTimePeriodKey(entry.Timestamp);
        acc[period] = acc[period] || new Set();
        acc[period].add(entry.HappyID);
      }
      return acc;
    }, {});

    return cleanData(Object.entries(users).map(([period, setOfUsers]) => ({
      period: period.replace(/-/g, '/'),
      users: safeNumber(setOfUsers.size),
    }))
    .sort((a, b) => parsePeriodForSorting(a.period) - parsePeriodForSorting(b.period)));

  }, [filteredData, isWeekly], 400);

  const sessionsPerPeriodData = useProgressiveData(() => {
    const sessions = filteredData.reduce((acc, entry) => {
      if (isValid(parseISO(entry.Timestamp))) {
        const period = getTimePeriodKey(entry.Timestamp);
        acc[period] = acc[period] || new Set();
        acc[period].add(entry.SessionId);
      }
      return acc;
    }, {});

    return cleanData(Object.entries(sessions).map(([period, setOfSessions]) => ({
      period: period.replace(/-/g, '/'),
      sessions: safeNumber(setOfSessions.size),
    }))
    .sort((a, b) => parsePeriodForSorting(a.period) - parsePeriodForSorting(b.period)));

  }, [filteredData, isWeekly], 600);

  const top10EventsData = useProgressiveData(() => {
    const events = filteredData.reduce((acc, entry) => {
      if (Array.isArray(entry.EventDescList)) {
        entry.EventDescList.forEach((event) => {
          if (event) acc[event] = (acc[event] || 0) + 1;
        });
      }
      return acc;
    }, {});

    return cleanData(Object.entries(events).slice(0, 10).map(([event, count]) => ({
      event: event || 'N/A',
      count: safeNumber(count),
    }))
    .sort((a, b) => parsePeriodForSorting(a.period) - parsePeriodForSorting(b.period)));

  }, [filteredData], 1000);

  const pageVisitsPerPeriodData = useProgressiveData(() => {
    const pagesPerPeriod = filteredData.reduce((acc, entry) => {
      if (isValid(parseISO(entry.Timestamp))) {
        const period = getTimePeriodKey(entry.Timestamp); // Ensure the timestamp is valid and parsed
        const page = entry.URL || 'Other';
        if (!acc[period]) acc[period] = {};
        acc[period][page] = (acc[period][page] || 0) + 1;
      }
      return acc;
    }, {});
  
    return cleanData(
        Object.entries(pagesPerPeriod)
          .map(([period, pages]) => {
            const pageEntries = Object.entries(pages);
      
            const topPages = pageEntries
              .sort((a, b) => b[1] - a[1]) // Sort by count descending
              .slice(0, 5); // Take top 5 pages
      
            const otherCount = pageEntries
              .slice(5) // Remaining pages after top 5
              .reduce((sum, [, count]) => sum + count, 0); // Sum their counts
      
            const row = { period: period.replace(/-/g, '/') }; // Format period
            topPages.forEach(([page, count]) => {
              row[page] = count; // Add top pages to row
            });
      
            if (otherCount) row.Other = otherCount; // Add "Other" count if it exists
            return row;
          })
          .sort((a, b) => parsePeriodForSorting(a.period) - parsePeriodForSorting(b.period)) 
      );
    }, [filteredData, isWeekly], 200);
  

  const keys = useMemo(() => {
    const uniqueKeys = new Set();
    if (Array.isArray(pageVisitsPerPeriodData)) {
      pageVisitsPerPeriodData.forEach((d) => {
        Object.keys(d).forEach((k) => {
          if (k !== 'period' && k) uniqueKeys.add(k);
        });
      });
    }
    return Array.from(uniqueKeys);
  }, [pageVisitsPerPeriodData]);
  

  const colorMapping = useMemo(() => {
    const map = {};
    keys.forEach((key, i) => { map[key] = customColors[i % customColors.length]; });
    map.Other = '#CCCCCC';
    return map;
  }, [keys]);

  if (loading) return <LoadingSpinner />;

  function parsePeriodForSorting(period) {
    if (!period || typeof period !== 'string') {
        console.warn("parsePeriodForSorting received an invalid period:", period);
        return new Date(0); // Return the earliest possible date as a fallback
    }

    const parts = period.split('/');
    if (parts.length === 3) {
        // e.g. "2025/01/27" => weekly
        return new Date(parts[0], parts[1] - 1, parts[2]);
    } else if (parts.length === 2) {
        // e.g. "2025/01" => monthly
        return new Date(parts[0], parts[1] - 1, 1);
    }
    
    // Fallback: Try parsing as a raw date string
    return new Date(period);
}

  // Logs to confirm data shape
  console.log('pageVisitsData:', pageVisitsData);
  console.log('pageVisitsPerPeriodData:', pageVisitsPerPeriodData);
  console.log('usersPerPeriodData:', usersPerPeriodData);
  console.log('sessionsPerPeriodData:', sessionsPerPeriodData);
  console.log('averagePageDurationData:', averagePageDurationData);
  console.log('top10EventsData:', top10EventsData);

  /**
   *  Render all charts
   */
  return (
    <div className="visualization-container">
      <div className="week-analysis-page">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h1 className="h1">{isWeekly ? 'Trends Week Over Week' : 'Trends Month Over Month'}</h1>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <SegmentedControl
              data={[
                { label: 'Weekly', value: 'weekly' },
                { label: 'Monthly', value: 'monthly' },
              ]}
              value={isWeekly ? 'weekly' : 'monthly'}
              onChange={(val) => setIsWeekly(val === 'weekly')}
              style={{ marginLeft: '1rem' }}
            />
            <DatePickerInput
              type="range"
              placeholder="Filter date range"
              value={dateRange}
              onChange={setDateRange}
              style={{ marginLeft: '2rem' }}
            />
            {dateRange[0] && dateRange[1] && (
              <button
                onClick={() => setDateRange([null, null])}
                className="form-button"
                style={{ marginLeft: '1rem', maxWidth: '200px' }}
              >
                Reset Dates
              </button>
            )}
          </div>
        </motion.div>

        <div className="overview-chart">
          {/* 1) Simple Bar: Page Visits */}
          <Card shadow="sm" padding="lg" className="my-4">
            <Title order={3}>
              {isWeekly ? 'Weekly Page Visits' : 'Monthly Page Visits'}
            </Title>
            <div style={{ height: 400 }}>
              <ResponsiveBar
                data={pageVisitsData}
                keys={['count']}
                indexBy="period"
                margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                padding={0.3}
                layout="vertical"
                colors={customColors[0]}
                axisBottom={{
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 40,
                  format: (d) => String(d),
                  tickRotation: 45,
                }}
                axisLeft={{
                  legend: 'Page Visits',
                  legendPosition: 'middle',
                  legendOffset: -60,
                }}
                enableLabel={false}
                animate={true}
                theme={NivoTheme}
                tooltip={({ value, indexValue }) => (
                  <div className="nivoTool">
                    {indexValue} count: <strong>{value}</strong>
                  </div>
                )}
              />
            </div>
          </Card>

          {/* 2) Stacked Bar: Weekly/Monthly Page Visits by Top Pages */}
          <Card shadow="sm" padding="lg" className="my-4">
            <Title order={3}>
              {isWeekly
                ? 'Weekly Page Visits by Top Pages'
                : 'Monthly Page Visits by Top Pages'}
            </Title>
            <div style={{ height: 400 }}>
              <ResponsiveBar
                data={pageVisitsPerPeriodData}
                keys={keys}
                indexBy="period"
                margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                padding={0.3}
                layout="vertical"
                colors={({ id }) => colorMapping[id] || '#000000'}
                theme={NivoTheme}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 45,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 40,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Page Visits',
                  legendPosition: 'middle',
                  legendOffset: -60,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
                ariaLabel="Page Visits by Top Pages"
                barAriaLabel={(e) => `${e.id}: ${e.formattedValue} in period: ${e.indexValue}`}
              />
            </div>
          </Card>

          {/* 3) Simple Bar: Number of Users per Period */}
          <Card shadow="sm" padding="lg" className="my-4">
            <Title order={3}>
              {isWeekly ? 'Number of Users per Week' : 'Number of Users per Month'}
            </Title>
            <div style={{ height: 400 }}>
              <ResponsiveBar
                data={usersPerPeriodData}
                keys={['users']}
                indexBy="period"
                margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                padding={0.3}
                layout="vertical"
                colors={customColors[2]}
                axisBottom={{
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 40,
                  format: (d) => String(d),
                  tickRotation: 45,
                }}
                axisLeft={{
                  legend: 'Number of Users',
                  legendPosition: 'middle',
                  legendOffset: -60,
                }}
                enableLabel={false}
                animate={true}
                theme={NivoTheme}
                tooltip={({ value, indexValue }) => (
                  <div className="nivoTool">
                    {indexValue} user count: <strong>{value}</strong>
                  </div>
                )}
              />
            </div>
          </Card>

          {/* 4) Simple Bar: Number of Sessions per Period */}
          <Card shadow="sm" padding="lg" className="my-4">
            <Title order={3}>
              {isWeekly
                ? 'Number of Sessions per Week'
                : 'Number of Sessions per Month'}
            </Title>
            <div style={{ height: 400 }}>
              <ResponsiveBar
                data={sessionsPerPeriodData}
                keys={['sessions']}
                indexBy="period"
                margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                padding={0.3}
                layout="vertical"
                colors={customColors[3]}
                axisBottom={{
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 40,
                  format: (d) => String(d),
                  tickRotation: 45,
                }}
                axisLeft={{
                  legend: 'Number of Sessions',
                  legendPosition: 'middle',
                  legendOffset: -60,
                }}
                enableLabel={false}
                animate={true}
                theme={NivoTheme}
                tooltip={({ value, indexValue }) => (
                  <div className="nivoTool">
                    {indexValue} session count: <strong>{value}</strong>
                  </div>
                )}
              />
            </div>
          </Card>

          {/* 5) Simple Bar: Average Page Duration */}
          <Card shadow="sm" padding="lg" className="my-4">
            <Title order={3}>Average Page Duration</Title>
            <div style={{ height: 400 }}>
              <ResponsiveBar
                data={averagePageDurationData}
                keys={['averageDurationNumeric']}
                indexBy="url"
                margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                padding={0.3}
                layout="vertical"
                colors={customColors[4]}
                axisBottom={{
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 40,
                  format: (d) => String(d),
                  tickRotation: 45,
                }}
                axisLeft={{
                  legend: 'Average Duration (seconds)',
                  legendPosition: 'middle',
                  legendOffset: -60,
                }}
                enableLabel={false}
                animate={true}
                theme={NivoTheme}
                tooltip={({ value, indexValue }) => (
                  <div className="nivoTool">
                    {indexValue} duration: <strong>{formatDuration(value)}</strong>
                  </div>
                )}
              />
            </div>
          </Card>

          {/* 6) Simple Bar: Top 10 Events by Count */}
          <Card shadow="sm" padding="lg" className="my-4">
            <Title order={3}>Top 10 Events by Count</Title>
            <div style={{ height: 400 }}>
              <ResponsiveBar
                data={top10EventsData}
                keys={['count']}
                indexBy="event"
                margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                padding={0.3}
                layout="vertical"
                colors={customColors[5]}
                axisBottom={{
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 40,
                  format: (d) => String(d),
                  tickRotation: 45,
                }}
                axisLeft={{
                  legend: 'Count',
                  legendPosition: 'middle',
                  legendOffset: -60,
                }}
                enableLabel={false}
                animate={true}
                theme={NivoTheme}
                tooltip={({ value, indexValue }) => (
                  <div className="nivoTool">
                    {indexValue} count: <strong>{value}</strong>
                  </div>
                )}
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default WeekOverWeekAnalysis;
