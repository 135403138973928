import React from 'react';
import { Select } from '@mantine/core';

const TemplateSelector = ({ templates, onSelect }) => {
  // Convert templates to the format Mantine expects:
  // an array of objects with "value" and "label" keys.
  const data = templates.map((template) => ({
    value: template.name,
    label: template.name,
  }));

  // Handle select change; Mantine returns the value directly.
  const handleChange = (value) => {
    const selectedTemplate = templates.find((t) => t.name === value);
    if (selectedTemplate) {
      onSelect(selectedTemplate);
    }
  };

  return (
    <div className="template-selector my-4">
      <Select
        placeholder="-- Start from a template --"
        data={data}
        searchable
        nothingFound="No templates found"
        onChange={handleChange}
      />
    </div>
  );
};

export default TemplateSelector;


// import React from 'react';

// const TemplateSelector = ({ templates, onSelect }) => {
//   const handleChange = (e) => {
//     const templateId = e.target.value;
//     const selectedTemplate = templates.find((t) => t.id === templateId);
//     if (selectedTemplate) {
//       onSelect(selectedTemplate);
//     }
//   };

//   return (
//     <div className="template-selector my-4">
//       <select onChange={handleChange} className="form-select mt-1 block w-full">
//         <option value="">-- Start from a template --</option>
//         {templates.map((template) => (
//           <option key={template.id} value={template.id}>
//             {template.name}
//           </option>
//         ))}
//       </select>
//     </div>
//   );
// };

// export default TemplateSelector;




// // import React from 'react';

// // const TemplateSelector = ({ templates, onSelect }) => {
// //   return (
// //     <div className="border p-2">
// //       {templates.map((template) => (
// //         <div key={template.id} className="p-2 border-b">
// //           <div className="flex justify-between items-center">
// //             <div>
// //               <strong>{template.name}</strong>
// //               <p>{template.description}</p>
// //               <small>{template.service}</small>
// //             </div>
// //             <button onClick={() => onSelect(template)} className="bg-blue-500 text-white p-1">
// //               Use Template
// //             </button>
// //           </div>
// //         </div>
// //       ))}
// //     </div>
// //   );
// // };

// // export default TemplateSelector;
