// DataTriggers.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import PatternBuilder from '../components/webhooks/PatternBuilder';
import MLInsightPanel from '../components/webhooks/MLInsightPanel';
import ValidationPanel from '../components/webhooks/ValidationPanel';
import { getWebhooks } from '../components/webhooks/WebhookAPI';
import TriggerList from '../components/webhooks/TriggerList'; // Our updated list component

const GO_BASE = process.env.REACT_APP_GO_BASE; 
const API_BASE = `${GO_BASE}/api/v1`;

function getDefaultHeaders() {
  const token = localStorage.getItem('authToken');
  const OrgID = localStorage.getItem('ActiveOrgID');
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'OrgID': OrgID,
  };
}

function DataTriggersPage() {
  const [triggers, setTriggers] = useState([]);
  const [currentTrigger, setCurrentTrigger] = useState({
    id: '',
    name: '',
    conditions: [],
    steps: [],
    status: 'draft',
  });
  const [validationFeedback, setValidationFeedback] = useState(null);
  const [webhookOptions, setWebhookOptions] = useState([]);

  useEffect(() => {
    // Fetch triggers here in the container, so we have a single source of truth.
    fetchTriggers();

    // Fetch webhooks (existing code)
    const fetchWebhooksData = async () => {
      try {
        console.log('[DataTriggersPage] Attempting to fetch webhooks using getWebhooks API function.');
        const data = await getWebhooks();
        setWebhookOptions(data);
      } catch (error) {
        console.error('[DataTriggersPage] Failed to fetch webhooks:', error);
      }
    };

    fetchWebhooksData();
  }, []);

  const fetchTriggers = async () => {
    try {
      console.log('[DataTriggersPage] Fetching triggers...');
      const response = await axios.get(`${API_BASE}/triggers`, { headers: getDefaultHeaders() });
      console.log('[DataTriggersPage] Fetched triggers:', response.data);
      setTriggers(response.data);
    } catch (error) {
      console.error('[DataTriggersPage] Failed to fetch triggers:', error);
      toast.error('Failed to fetch triggers');
    }
  };

  // Called by TriggerList when user clicks "Edit"
  const handleEditTrigger = (trigger) => {
    console.log('[DataTriggersPage] Editing trigger:', trigger);
    setCurrentTrigger(trigger);
    // Now PatternBuilder will see the updated currentTrigger
  };

  // Handler for when user types in the trigger Name field.
  const handleNameChange = (e) => {
    setCurrentTrigger({ ...currentTrigger, name: e.target.value });
  };

  const handleSave = async () => {
    try {
      // Flatten conditions only at save time, if needed
      const flattenedConditions = currentTrigger.conditions.map((item) => {
        if (item.condition !== undefined) {
          return {
            id: item.condition.id,
            type: item.condition.type,
            parameters: item.condition.parameters,
            operator: item.operator || 'AND',
          };
        }
        return item;
      });

      const payload = {
        ...currentTrigger,
        conditions: flattenedConditions,
      };

      if (currentTrigger.id) {
        await axios.put(`${API_BASE}/triggers/${currentTrigger.id}`, payload, {
          headers: getDefaultHeaders(),
        });
      } else {
        await axios.post(`${API_BASE}/triggers`, payload, {
          headers: getDefaultHeaders(),
        });
      }
      toast.success('[DataTriggersPage] Trigger saved successfully');
      fetchTriggers(); // Refresh the list
    } catch (error) {
      console.error('[DataTriggersPage] Save failed:', error);
      toast.error('Failed to save trigger');
    }
  };

  const handleDeploy = async () => {
    try {
      const triggerToDeploy = { ...currentTrigger, status: 'active' };
      console.log('[DataTriggersPage] Deploying trigger:', triggerToDeploy);
      await axios.post(`${API_BASE}/triggers/deploy`, triggerToDeploy, {
        headers: getDefaultHeaders(),
      });
      toast.success('[DataTriggersPage] Trigger deployed successfully');
      fetchTriggers(); // Refresh the list
    } catch (error) {
      console.error('[DataTriggersPage] Deploy failed:', error);
      toast.error('Failed to deploy trigger');
    }
  };

  const handleValidate = async () => {
    try {
      console.log('[DataTriggersPage] Validating trigger:', currentTrigger);
      const response = await axios.post(`${API_BASE}/triggers/validate`, currentTrigger, {
        headers: getDefaultHeaders(),
      });
      console.log('[DataTriggersPage] Validation result:', response.data);
      setValidationFeedback(response.data);
      return response.data;
    } catch (error) {
      console.error('[DataTriggersPage] Validation failed:', error);
      setValidationFeedback({ error: 'Validation failed. Please try again.' });
      return null;
    }
  };

  const handleInsightApply = (insight) => {
    console.log('[DataTriggersPage] Applying insight:', insight);
    const mergedConditions = [
      ...currentTrigger.conditions,
      ...insight.suggestedConditions,
    ];
    console.log('[DataTriggersPage] Merged conditions:', mergedConditions);
    setCurrentTrigger({ ...currentTrigger, conditions: mergedConditions });
  };

  return (
    <div className="data-triggers-container">
      <header className="data-triggers-header sticky top-0 bg-white shadow p-4 flex justify-between items-center">
        <div className="header-title">
          <h2 className="text-xl font-semibold">Data Triggers</h2>
        </div>
      </header>

      <main className="data-triggers-main p-4">
        {/* PatternBuilder uses the currentTrigger in state */}
        <PatternBuilder
          webhookOptions={webhookOptions}
          currentTrigger={currentTrigger}
          onChange={(updatedTrigger) => {
            // A simple (but not optimized) deep equality check:
            if (JSON.stringify(updatedTrigger) !== JSON.stringify(currentTrigger)) {
              console.log('[DataTriggersPage] Trigger updated:', updatedTrigger);
              setCurrentTrigger(updatedTrigger);
            }
          }}
        />

        <div className="trigger-header">
          <div className="trigger-name">
            <label htmlFor="triggerName" className="trigger-name-label">
              Trigger Name
            </label>
            <input
              id="triggerName"
              type="text"
              value={currentTrigger.name}
              onChange={handleNameChange}
              placeholder="Enter trigger name..."
              className="trigger-name-input"
            />
          </div>
          <div className="button-group-bottom">
            <button onClick={handleValidate} className="btn-validate">
              Validate
            </button>
            <button onClick={handleSave} className="btn-save">
              Save
            </button>
            <button onClick={handleDeploy} className="btn-deploy">
              Deploy
            </button>
          </div>
        </div>

        {validationFeedback && (
          <ValidationPanel
            validationFeedback={validationFeedback}
            onValidate={handleValidate}
          />
        )}

        <div className="existing-webhooks-card trigger-list-section">
          <h2 className="webhooks-subtitle">Existing Triggers</h2>
          <TriggerList
            triggers={triggers}
            onEditTrigger={handleEditTrigger} 
          />
        </div>

        <MLInsightPanel
          baseUrl="/api/ml/insights"
          currentTrigger={currentTrigger}
          onInsightApply={handleInsightApply}
        />
      </main>
    </div>
  );
}

export default DataTriggersPage;



// import React, { useEffect, useState } from 'react';
// import PatternBuilder from '../components/webhooks/PatternBuilder';
// import MLInsightPanel from '../components/webhooks/MLInsightPanel';
// import ValidationPanel from '../components/webhooks/ValidationPanel';
// import { getWebhooks } from '../components/webhooks/WebhookAPI';
// import TriggerList from '../components/webhooks/TriggerList';
// import axios from 'axios';

// const GO_BASE = process.env.REACT_APP_GO_BASE; 
// const API_BASE = `${GO_BASE}/api/v1`;

// function getDefaultHeaders() {
//   const token = localStorage.getItem('authToken');
//   const OrgID = localStorage.getItem('ActiveOrgID');
//   return {
//     'Content-Type': 'application/json',
//     'Authorization': `Bearer ${token}`,
//     'OrgID': OrgID,
//   };
// }

// function DataTriggersPage() {
//   const [currentTrigger, setCurrentTrigger] = useState({
//     id: '',
//     name: '',
//     conditions: [],
//     steps: [],
//     status: 'draft',
//   });
//   const [validationFeedback, setValidationFeedback] = useState(null);
//   const [webhookOptions, setWebhookOptions] = useState([]);

//   useEffect(() => {
//     // Fetch webhooks using the proper API function.
//     const fetchWebhooks = async () => {
//       try {
//         console.log('[DataTriggersPage] Attempting to fetch webhooks using getWebhooks API function.');
//         const data = await getWebhooks();
//         console.log('[DataTriggersPage] Fetched webhooks data:', data);
//         if (Array.isArray(data)) {
//           console.log(`[DataTriggersPage] Number of webhooks fetched: ${data.length}`);
//           data.forEach((wh, index) => {
//             const name = wh.name || wh.Name || '';
//             console.log(`[DataTriggersPage] Webhook ${index}: Name: ${name}`);
//           });
//         } else {
//           console.warn('[DataTriggersPage] Fetched webhooks data is not an array:', data);
//         }
//         setWebhookOptions(data);
//       } catch (error) {
//         console.error('[DataTriggersPage] Failed to fetch webhooks:', error);
//       }
//     };

//     fetchWebhooks();
//   }, []);

//   // Handler for when user types in the trigger Name field.
//   const handleNameChange = (e) => {
//     setCurrentTrigger({ ...currentTrigger, name: e.target.value });
//   };

//   // Handler for when user selects the model type.
//   const handleModelTypeChange = (e) => {
//     setCurrentTrigger({ ...currentTrigger, modelType: e.target.value });
//   };

//   const handleSave = async () => {
//     try {
//       // 1) Flatten conditions only at save time.
//       // currentTrigger.conditions = [
//       //   { condition: { id, type, parameters }, operator: 'AND' },
//       //   { condition: { id, type, parameters }, operator: 'THEN' },
//       //   ...
//       // ]
//       const flattenedConditions = currentTrigger.conditions.map((item) => {
//         // If 'condition' exists, extract its values; otherwise, assume it's already flattened.
//         if (item.condition !== undefined) {
//           return {
//             id: item.condition.id,
//             type: item.condition.type,
//             parameters: item.condition.parameters,
//             operator: item.operator || 'AND',
//           };
//         }
//         return item;
//       });
      
  
//       // 2) Build final payload with flattened conditions
//       const payload = {
//         ...currentTrigger,
//         conditions: flattenedConditions,
//       };
  
//       // 3) Send to backend
//       if (currentTrigger.id) {
//         await axios.put(`${API_BASE}/triggers/${currentTrigger.id}`, payload, {
//           headers: getDefaultHeaders(),
//         });
//       } else {
//         await axios.post(`${API_BASE}/triggers`, payload, {
//           headers: getDefaultHeaders(),
//         });
//       }
//       console.log('[DataTriggersPage] Trigger saved successfully');
//     } catch (error) {
//       console.error('[DataTriggersPage] Save failed:', error);
//     }
//   };

//   const handleDeploy = async () => {
//     try {
//       const triggerToDeploy = { ...currentTrigger, status: 'active' };
//       console.log('[DataTriggersPage] Deploying trigger:', triggerToDeploy);
//       await axios.post(`${API_BASE}/triggers/deploy`, triggerToDeploy, {
//         headers: getDefaultHeaders(),
//       });
//       console.log('[DataTriggersPage] Trigger deployed successfully');
//     } catch (error) {
//       console.error('[DataTriggersPage] Deploy failed:', error);
//     }
//   };

//   const handleValidate = async () => {
//     try {
//       console.log('[DataTriggersPage] Validating trigger:', currentTrigger);
//       const response = await axios.post(`${API_BASE}/triggers/validate`, currentTrigger, {
//         headers: getDefaultHeaders(),
//       });
//       console.log('[DataTriggersPage] Validation result:', response.data);
//       setValidationFeedback(response.data);
//       return response.data;
//     } catch (error) {
//       console.error('[DataTriggersPage] Validation failed:', error);
//       setValidationFeedback({ error: 'Validation failed. Please try again.' });
//       return null;
//     }
//   };

//   const handleInsightApply = (insight) => {
//     console.log('[DataTriggersPage] Applying insight:', insight);
//     const mergedConditions = [
//       ...currentTrigger.conditions,
//       ...insight.suggestedConditions,
//     ];
//     console.log('[DataTriggersPage] Merged conditions:', mergedConditions);
//     setCurrentTrigger({ ...currentTrigger, conditions: mergedConditions });
//   };

//   return (
//     <div className="data-triggers-container">
//       <header className="data-triggers-header sticky top-0 bg-white shadow p-4 flex justify-between items-center">
//         <div className="header-title">
//           <h2 className="text-xl font-semibold">Data Triggers</h2>
//         </div>
//       </header>

//       <main className="data-triggers-main p-4">

//         <PatternBuilder
//           webhookOptions={webhookOptions}
//           currentTrigger={currentTrigger}
//           onChange={(updatedTrigger) => {
//             // A simple (but not optimized) deep equality check:
//             if (JSON.stringify(updatedTrigger) !== JSON.stringify(currentTrigger)) {
//               console.log('[DataTriggersPage] Trigger updated:', updatedTrigger);
//               setCurrentTrigger(updatedTrigger);
//             }
//           }}
//         />
                  
//           <div className="trigger-header">
//             <div className="trigger-name">
//               <label htmlFor="triggerName" className="trigger-name-label">
//                 Trigger Name
//               </label>
//               <input
//                 id="triggerName"
//                 type="text"
//                 value={currentTrigger.name}
//                 onChange={handleNameChange}
//                 placeholder="Enter trigger name..."
//                 className="trigger-name-input"
//               />
//             </div>
//             <div className="button-group-bottom">
//               <button onClick={handleValidate} className="btn-validate">
//                 Validate
//               </button>
//               <button onClick={handleSave} className="btn-save">
//                 Save
//               </button>
//               <button onClick={handleDeploy} className="btn-deploy">
//                 Deploy
//               </button>
//             </div>
//         </div>


//         {validationFeedback && (
//           <ValidationPanel
//             validationFeedback={validationFeedback}
//             onValidate={handleValidate}
//           />
//         )}

//         <div className="existing-webhooks-card mar-4">
//           <h3 className="webhooks-subtitle">Existing Triggers</h3>
//           <TriggerList />
//         </div>

//         <MLInsightPanel
//           baseUrl="/api/ml/insights"
//           currentTrigger={currentTrigger}
//           onInsightApply={handleInsightApply}
//         />
//       </main>
//     </div>
//   );
// }

// export default DataTriggersPage;








// // import React, { useEffect, useState } from 'react';
// // import PatternBuilder from '../components/webhooks/PatternBuilder';
// // import MLInsightPanel from '../components/webhooks/MLInsightPanel';
// // import ValidationPanel from '../components/webhooks/ValidationPanel';
// // import { getWebhooks } from '../components/webhooks/WebhookAPI';
// // import axios from 'axios';

// // const GO_BASE = process.env.REACT_APP_GO_BASE; 
// // const API_BASE = `${GO_BASE}/api/v1`;

// // function getDefaultHeaders() {
// //   const token = localStorage.getItem('authToken');
// //   const OrgID = localStorage.getItem('ActiveOrgID');
// //   return {
// //     'Content-Type': 'application/json',
// //     'Authorization': `Bearer ${token}`,
// //     'OrgID': OrgID,
// //   };
// // }

// // function DataTriggersPage() {
// //   const [currentTrigger, setCurrentTrigger] = useState({
// //     id: '',
// //     name: '',
// //     conditions: [],
// //     steps: [],
// //     status: 'draft',
// //     modelType: '',
// //   });
// //   const [validationFeedback, setValidationFeedback] = useState(null);
// //   const [webhookOptions, setWebhookOptions] = useState([]);

// //   useEffect(() => {
// //     // Fetch webhooks using the proper API function.
// //     const fetchWebhooks = async () => {
// //       try {
// //         console.log('[DataTriggersPage] Attempting to fetch webhooks using getWebhooks API function.');
// //         const data = await getWebhooks();
// //         console.log('[DataTriggersPage] Fetched webhooks data:', data);
// //         if (Array.isArray(data)) {
// //           console.log(`[DataTriggersPage] Number of webhooks fetched: ${data.length}`);
// //           data.forEach((wh, index) => {
// //             const name = wh.name || wh.Name || '';
// //             console.log(`[DataTriggersPage] Webhook ${index}: Name: ${name}`);
// //           });
// //         } else {
// //           console.warn('[DataTriggersPage] Fetched webhooks data is not an array:', data);
// //         }
// //         setWebhookOptions(data);
// //       } catch (error) {
// //         console.error('[DataTriggersPage] Failed to fetch webhooks:', error);
// //       }
// //     };

// //     fetchWebhooks();
// //   }, []);

// //   const handleSave = async () => {
// //     try {
// //       if (currentTrigger.id) {
// //         console.log('[DataTriggersPage] Saving trigger (update) with ID:', currentTrigger.id);
// //         await axios.put(`${API_BASE}/triggers/${currentTrigger.id}`, currentTrigger, {
// //           headers: getDefaultHeaders(),
// //         });
// //       } else {
// //         console.log('[DataTriggersPage] Saving new trigger:', currentTrigger);
// //         await axios.post(`${API_BASE}/triggers`, currentTrigger, {
// //           headers: getDefaultHeaders(),
// //         });
// //       }
// //       console.log('[DataTriggersPage] Trigger saved successfully');
// //     } catch (error) {
// //       console.error('[DataTriggersPage] Save failed:', error);
// //     }
// //   };

// //   const handleDeploy = async () => {
// //     try {
// //       const triggerToDeploy = { ...currentTrigger, status: 'active' };
// //       console.log('[DataTriggersPage] Deploying trigger:', triggerToDeploy);
// //       await axios.post(`${API_BASE}/triggers/deploy`, triggerToDeploy, {
// //         headers: getDefaultHeaders(),
// //       });
// //       console.log('[DataTriggersPage] Trigger deployed successfully');
// //     } catch (error) {
// //       console.error('[DataTriggersPage] Deploy failed:', error);
// //     }
// //   };

// //   const handleValidate = async () => {
// //     try {
// //       console.log('[DataTriggersPage] Validating trigger:', currentTrigger);
// //       const response = await axios.post(`${API_BASE}/triggers/validate`, currentTrigger, {
// //         headers: getDefaultHeaders(),
// //       });
// //       console.log('[DataTriggersPage] Validation result:', response.data);
// //       setValidationFeedback(response.data);
// //       return response.data;
// //     } catch (error) {
// //       console.error('[DataTriggersPage] Validation failed:', error);
// //       setValidationFeedback({ error: 'Validation failed. Please try again.' });
// //       return null;
// //     }
// //   };

// //   const handleInsightApply = (insight) => {
// //     console.log('[DataTriggersPage] Applying insight:', insight);
// //     const mergedConditions = [
// //       ...currentTrigger.conditions,
// //       ...insight.suggestedConditions,
// //     ];
// //     console.log('[DataTriggersPage] Merged conditions:', mergedConditions);
// //     setCurrentTrigger({ ...currentTrigger, conditions: mergedConditions });
// //   };

// //   return (
// //     <div className="data-triggers-container">
// //       <header className="data-triggers-header sticky top-0 bg-white shadow p-4 flex justify-between items-center">
// //         <div className="header-title">
// //           <h2 className="text-xl font-semibold">Data Triggers</h2>
// //         </div>
// //       </header>

// //       <main className="data-triggers-main p-4">
// //         <PatternBuilder
// //           webhookOptions={webhookOptions}
// //           currentTrigger={currentTrigger}
// //           onChange={(updatedTrigger) => {
// //             console.log('[DataTriggersPage] Trigger updated:', updatedTrigger);
// //             setCurrentTrigger(updatedTrigger);
// //           }}
// //         />

// //         <div className="button-group-bottom flex justify-end space-x-2 mt-4">
// //           <button onClick={handleValidate} className="btn-validate bg-blue-500 text-white px-4 py-2 rounded">
// //             Validate
// //           </button>
// //           <button onClick={handleSave} className="btn-save bg-green-500 text-white px-4 py-2 rounded">
// //             Save
// //           </button>
// //           <button onClick={handleDeploy} className="btn-deploy bg-purple-500 text-white px-4 py-2 rounded">
// //             Deploy
// //           </button>
// //         </div>

// //         {validationFeedback && (
// //           <ValidationPanel
// //             validationFeedback={validationFeedback}
// //             onValidate={handleValidate}
// //           />
// //         )}

// //         <MLInsightPanel
// //           baseUrl="/api/ml/insights"
// //           currentTrigger={currentTrigger}
// //           onInsightApply={handleInsightApply}
// //         />
// //       </main>
// //     </div>
// //   );
// // }

// // export default DataTriggersPage;


// // // import React, { useEffect, useState } from 'react';
// // // import PatternBuilder from '../components/webhooks/PatternBuilder';
// // // import MLInsightPanel from '../components/webhooks/MLInsightPanel';
// // // import ValidationPanel from '../components/webhooks/ValidationPanel';
// // // import { getWebhooks } from '../components/webhooks/WebhookAPI';
// // // import axios from 'axios';

// // // const GO_BASE = process.env.REACT_APP_GO_BASE; 
// // // const API_BASE = `${GO_BASE}/api/v1`;

// // // function DataTriggersPage() {
// // //   const [currentTrigger, setCurrentTrigger] = useState({
// // //     id: '',
// // //     name: '',
// // //     conditions: [],
// // //     steps: [],
// // //     status: 'draft',
// // //     modelType: '',
// // //   });
// // //   const [validationFeedback, setValidationFeedback] = useState(null);
// // //   const [webhookOptions, setWebhookOptions] = useState([]);

// // //   useEffect(() => {
// // //     // Fetch webhooks using the proper API function.
// // //     const fetchWebhooks = async () => {
// // //       try {
// // //         console.log('[DataTriggersPage] Attempting to fetch webhooks using getWebhooks API function.');
// // //         const data = await getWebhooks();
// // //         console.log('[DataTriggersPage] Fetched webhooks data:', data);
// // //         if (Array.isArray(data)) {
// // //           console.log(`[DataTriggersPage] Number of webhooks fetched: ${data.length}`);
// // //           data.forEach((wh, index) => {
// // //             const name = wh.name || wh.Name || '';
// // //             console.log(`[DataTriggersPage] Webhook ${index}: Name: ${name}`);
// // //           });
// // //         } else {
// // //           console.warn('[DataTriggersPage] Fetched webhooks data is not an array:', data);
// // //         }
// // //         setWebhookOptions(data);
// // //       } catch (error) {
// // //         console.error('[DataTriggersPage] Failed to fetch webhooks:', error);
// // //       }
// // //     };

// // //     fetchWebhooks();
// // //   }, []);

// // //   const handleSave = async () => {
// // //     try {
// // //       if (currentTrigger.id) {
// // //         console.log('[DataTriggersPage] Saving trigger (update) with ID:', currentTrigger.id);
// // //         await axios.put(`${API_BASE}/triggers/${currentTrigger.id}`, currentTrigger);
// // //       } else {
// // //         console.log('[DataTriggersPage] Saving new trigger:', currentTrigger);
// // //         await axios.post(`${API_BASE}/triggers`, currentTrigger);
// // //       }
// // //       console.log('[DataTriggersPage] Trigger saved successfully');
// // //     } catch (error) {
// // //       console.error('[DataTriggersPage] Save failed:', error);
// // //     }
// // //   };

// // //   const handleDeploy = async () => {
// // //     try {
// // //       const triggerToDeploy = { ...currentTrigger, status: 'active' };
// // //       console.log('[DataTriggersPage] Deploying trigger:', triggerToDeploy);
// // //       await axios.post('/api/triggers/deploy', triggerToDeploy);
// // //       console.log('[DataTriggersPage] Trigger deployed successfully');
// // //     } catch (error) {
// // //       console.error('[DataTriggersPage] Deploy failed:', error);
// // //     }
// // //   };

// // //   const handleValidate = async () => {
// // //     try {
// // //       console.log('[DataTriggersPage] Validating trigger:', currentTrigger);
// // //       const response = await axios.post('/api/triggers/validate', currentTrigger);
// // //       console.log('[DataTriggersPage] Validation result:', response.data);
// // //       setValidationFeedback(response.data);
// // //       return response.data;
// // //     } catch (error) {
// // //       console.error('[DataTriggersPage] Validation failed:', error);
// // //       setValidationFeedback({ error: 'Validation failed. Please try again.' });
// // //       return null;
// // //     }
// // //   };

// // //   const handleInsightApply = (insight) => {
// // //     console.log('[DataTriggersPage] Applying insight:', insight);
// // //     // Merge suggested conditions into currentTrigger.conditions.
// // //     const mergedConditions = [
// // //       ...currentTrigger.conditions,
// // //       ...insight.suggestedConditions,
// // //     ];
// // //     console.log('[DataTriggersPage] Merged conditions:', mergedConditions);
// // //     setCurrentTrigger({ ...currentTrigger, conditions: mergedConditions });
// // //   };

// // //   return (
// // //     <div className="data-triggers-container">
// // //       <header className="data-triggers-header sticky top-0 bg-white shadow p-4 flex justify-between items-center">
// // //         <div className="header-title">
// // //           <h2 className="text-xl font-semibold">Data Triggers</h2>
// // //         </div>
// // //       </header>

// // //       <main className="data-triggers-main p-4">
// // //         <PatternBuilder
// // //           webhookOptions={webhookOptions}
// // //           currentTrigger={currentTrigger}
// // //           onChange={(updatedTrigger) => {
// // //             console.log('[DataTriggersPage] Trigger updated:', updatedTrigger);
// // //             setCurrentTrigger(updatedTrigger);
// // //           }}
// // //         />

// // //         <div className="button-group-bottom flex justify-end space-x-2 mt-4">
// // //           <button onClick={handleValidate} className="btn-validate bg-blue-500 text-white px-4 py-2 rounded">
// // //             Validate
// // //           </button>
// // //           <button onClick={handleSave} className="btn-save bg-green-500 text-white px-4 py-2 rounded">
// // //             Save
// // //           </button>
// // //           <button onClick={handleDeploy} className="btn-deploy bg-purple-500 text-white px-4 py-2 rounded">
// // //             Deploy
// // //           </button>
// // //         </div>

// // //         {validationFeedback && (
// // //           <ValidationPanel
// // //             validationFeedback={validationFeedback}
// // //             onValidate={handleValidate}
// // //           />
// // //         )}

// // //         <MLInsightPanel
// // //           baseUrl="/api/ml/insights"
// // //           currentTrigger={currentTrigger}
// // //           onInsightApply={handleInsightApply}
// // //         />
// // //       </main>
// // //     </div>
// // //   );
// // // }

// // // export default DataTriggersPage;
