// import React, { useMemo, useEffect } from 'react';
// import { Button } from '@mantine/core'; // You can still use Mantine's Button if you like
// import ReactFlow, {
//   Background,
//   Controls,
//   useReactFlow,
//   ReactFlowProvider,
// } from 'react-flow-renderer';

// const colors = {
//   dkpurple: '#2c294b',
//   mdpurple: '#3b3484',
//   ltpurple: '#7174b0',
//   bgpurple: '#cdcee1',
//   dkorange: '#de6736',
//   mdorange: '#e08a3c',
//   ltorange: '#ebb844',
//   bgorange: '#f7e8c6',
// };

// function parseParameters(raw) {
//   if (!raw) return {};
//   if (typeof raw === 'object') return raw;
//   try {
//     const jsonString = atob(raw);
//     return JSON.parse(jsonString);
//   } catch (err) {
//     try {
//       return JSON.parse(raw);
//     } catch (e) {
//       console.warn('Failed to parse condition parameters:', e);
//     }
//   }
//   return {};
// }

// function buildConditionLabel(cond, idx) {
//   const params = parseParameters(cond.parameters);
//   let mainLine = '';

//   switch (cond.type) {
//     case 'EVENT':
//       mainLine = `Event: ${params.eventDesc || '(no desc)'}`;
//       break;
//     case 'PAGE':
//       mainLine = `Page: ${params.pageUrl || '(no url)'}`;
//       break;
//     case 'ML_PATTERN':
//       mainLine = `ML Pattern: ${params.patternName || '(no name)'}`;
//       break;
//     default:
//       mainLine = cond.type || `Condition ${idx + 1}`;
//       break;
//   }

//   if (typeof params.lookbackDays === 'number') {
//     mainLine += `\nLookback: ${params.lookbackDays} days`;
//   }

//   return mainLine;
// }

// function FlowDiagram({ trigger, isOpen }) {
//   const { fitView } = useReactFlow();

//   const nodeWidth = 250;
//   const conditionHeight = 100;
//   const stepHeight = 100;
//   const operatorHeight = 50;
//   const verticalGap = 40;

//   // Build nodes and edges in a memo
//   const [nodes, edges] = useMemo(() => {
//     const nodesArray = [];
//     const edgesArray = [];

//     const conditions = trigger.conditions || [];
//     const steps = trigger.steps || [];

//     // Place conditions in one column
//     const conditionX = 50;
//     let baseY = 0;

//     // 1) Build Condition nodes
//     const conditionNodes = conditions.map((cond, idx) => {
//       const condNodeId = `cond-${idx}`;
//       const label = buildConditionLabel(cond, idx);

//       return {
//         id: condNodeId,
//         data: { label },
//         position: {
//           x: conditionX,
//           y: baseY + idx * (conditionHeight + operatorHeight + verticalGap),
//         },
//         style: {
//           width: nodeWidth,
//           height: conditionHeight,
//           backgroundColor: colors.bgpurple,
//           border: `2px solid ${colors.ltpurple}`,
//           borderRadius: 8,
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           textAlign: 'center',
//           whiteSpace: 'pre-line',
//           fontSize: '18px',
//           color: colors.dkpurple,
//         },
//       };
//     });
//     nodesArray.push(...conditionNodes);

//     // 2) Insert operator nodes between consecutive conditions
//     for (let i = 0; i < conditionNodes.length - 1; i++) {
//       const c1 = conditionNodes[i];
//       const c2 = conditionNodes[i + 1];

//       const c1Bottom = c1.position.y + conditionHeight;
//       const c2Top = c2.position.y;
//       const midpointY = (c1Bottom + c2Top) / 2 - operatorHeight / 2;

//       const opNodeId = `op-${i}`;
//       const opLabel = conditions[i].operator || 'AND';

//       nodesArray.push({
//         id: opNodeId,
//         data: { label: opLabel },
//         position: { x: conditionX + nodeWidth / 2 - 30, y: midpointY },
//         style: {
//           width: 60,
//           height: operatorHeight,
//           backgroundColor: '#fff',
//           border: `1px solid ${colors.dkpurple}`,
//           borderRadius: 4,
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           fontSize: '14px',
//         },
//       });

//       // condition i -> operator -> condition i+1
//       edgesArray.push(
//         {
//           id: `edge-${c1.id}-${opNodeId}`,
//           source: c1.id,
//           target: opNodeId,
//           animated: true,
//           style: { stroke: colors.dkpurple },
//           type: 'smoothstep',
//         },
//         {
//           id: `edge-${opNodeId}-${c2.id}`,
//           source: opNodeId,
//           target: c2.id,
//           animated: true,
//           style: { stroke: colors.dkpurple },
//           type: 'smoothstep',
//         }
//       );
//     }

//     // 3) Build Step nodes in another column
//     const stepX = 450;
//     const stepNodes = steps.map((step, idx) => {
//       const stepId = `step-${idx}`;
//       const label =
//         step.webhookId && step.webhookId.length
//           ? step.webhookId.join(', ')
//           : `Step ${idx + 1}`;

//       return {
//         id: stepId,
//         data: { label },
//         position: {
//           x: stepX,
//           y: baseY + idx * (stepHeight + verticalGap),
//         },
//         style: {
//           width: nodeWidth,
//           height: stepHeight,
//           backgroundColor: colors.bgorange,
//           border: `2px solid ${colors.ltorange}`,
//           borderRadius: 8,
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           textAlign: 'center',
//           whiteSpace: 'pre-line',
//           fontSize: '18px',
//           color: colors.dkorange,
//         },
//       };
//     });
//     nodesArray.push(...stepNodes);

//     // 4) Connect the LAST condition to the FIRST step only
//     if (conditionNodes.length > 0 && stepNodes.length > 0) {
//       const finalConditionNodeId =
//         conditionNodes[conditionNodes.length - 1].id;
//       const firstStepNodeId = stepNodes[0].id;
//       console.log('finalConditionNodeId:', finalConditionNodeId);
//       console.log('firstStepNodeId:', firstStepNodeId);
//       edgesArray.push({
//         id: `edge-${finalConditionNodeId}-${firstStepNodeId}`,
//         source: finalConditionNodeId,
//         target: firstStepNodeId,
//         animated: true,
//         style: { stroke: colors.dkpurple },
//         type: 'smoothstep',
//       });
//     }

//     // 5) Chain steps: step-0 -> step-1 -> step-2 ...
//     for (let i = 0; i < stepNodes.length - 1; i++) {
//       edgesArray.push({
//         id: `edge-step-${i}-step-${i + 1}`,
//         source: `step-${i}`,
//         target: `step-${i + 1}`,
//         animated: true,
//         style: { stroke: colors.dkpurple },
//         type: 'smoothstep',
//       });
//     }

//     console.log('Built edges:', edgesArray);
//     return [nodesArray, edgesArray];
//   }, [trigger]);

//   // Re-fit once the diagram is visible
//   useEffect(() => {
//     if (isOpen) {
//       setTimeout(() => {
//         fitView({ padding: 0.2, includeHiddenNodes: true });
//       }, 150);
//     }
//   }, [isOpen, fitView]);

//   return (
//     <div style={{ width: '100%', height: '100%' }}>
//       <ReactFlow
//         key={trigger.id || trigger.name} // stable & reliable
//         nodes={nodes}
//         edges={edges}
//         fitView
//         fitViewOptions={{ padding: 0.2 }}
//         style={{ width: '100%', height: '100%' }}
//       >
//         <Background color="#aaa" gap={16} />
//         <Controls />
//       </ReactFlow>
//     </div>
//   );
// }

// export default function TriggerFlowModal({ trigger, opened, onClose }) {
//   if (!trigger) return null;

//   return (
//     <CustomModal isOpen={opened} onClose={onClose}>
//       {/* Minimal top bar with a custom close button */}
//       <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0.5rem' }}>
//         <Button variant="subtle" onClick={onClose} style={{ fontSize: '1.2rem' }}>
//           ✕
//         </Button>
//       </div>

//       <h2 style={{ margin: '-3rem 1rem 1rem', color: colors.dkpurple }}>
//         {trigger.name || 'Trigger Flow'}
//       </h2>

//       <div style={{ width: '100%', height: 'calc(100% - 64px)', position: 'relative' }}>
//         <ReactFlowProvider>
//           <FlowDiagram trigger={trigger} isOpen={opened} />
//         </ReactFlowProvider>
//       </div>
//     </CustomModal>
//   );
// }


import React, { useMemo, useEffect } from 'react';
import { Modal, Button } from '@mantine/core';
import ReactFlow, {
  Background,
  Controls,
  useReactFlow,
  ReactFlowProvider,
} from 'react-flow-renderer';

const colors = {
  dkpurple: '#2c294b',
  mdpurple: '#3b3484',
  ltpurple: '#7174b0',
  bgpurple: '#cdcee1',
  dkorange: '#de6736',
  mdorange: '#e08a3c',
  ltorange: '#ebb844',
  bgorange: '#f7e8c6',
};

function parseParameters(raw) {
  if (!raw) return {};
  if (typeof raw === 'object') return raw;
  try {
    const jsonString = atob(raw);
    return JSON.parse(jsonString);
  } catch (err) {
    try {
      return JSON.parse(raw);
    } catch (e) {
      console.warn('Failed to parse condition parameters:', e);
    }
  }
  return {};
}

function buildConditionLabel(cond, idx) {
  const params = parseParameters(cond.parameters);
  let mainLine = '';

  switch (cond.type) {
    case 'EVENT':
      mainLine = `Event: ${params.eventDesc || '(no desc)'}`;
      break;
    case 'PAGE':
      mainLine = `Page: ${params.pageUrl || '(no url)'}`;
      break;
    case 'ML_PATTERN':
      mainLine = `ML Pattern: ${params.patternName || '(no name)'}`;
      break;
    default:
      mainLine = cond.type || `Condition ${idx + 1}`;
      break;
  }

  if (typeof params.lookbackDays === 'number') {
    mainLine += `\nLookback: ${params.lookbackDays} days`;
  }

  return mainLine;
}

function FlowDiagram({ trigger, isOpen }) {
  const { fitView } = useReactFlow();

  const nodeWidth = 250;
  const conditionHeight = 100;
  const stepHeight = 100;
  const operatorHeight = 50;
  const verticalGap = 40;

  // Build nodes and edges in a memo
  const [nodes, edges] = useMemo(() => {
    const nodesArray = [];
    const edgesArray = [];

    const conditions = trigger.conditions || [];
    const steps = trigger.steps || [];

    // Place conditions in one column
    const conditionX = 50;
    let baseY = 0;

    // 1) Build Condition nodes
    const conditionNodes = conditions.map((cond, idx) => {
      const condNodeId = `cond-${idx}`;
      const label = buildConditionLabel(cond, idx);

      return {
        id: condNodeId,
        data: { label },
        position: {
          x: conditionX,
          y: baseY + idx * (conditionHeight + operatorHeight + verticalGap),
        },
        style: {
          width: nodeWidth,
          height: conditionHeight,
          backgroundColor: colors.bgpurple,
          border: `2px solid ${colors.ltpurple}`,
          borderRadius: 8,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center', // center text
          textAlign: 'center',
          whiteSpace: 'pre-line',
          fontSize: '18px',
          color: colors.dkpurple,
        },
      };
    });
    nodesArray.push(...conditionNodes);

    // 2) Insert operator nodes between consecutive conditions
    for (let i = 0; i < conditionNodes.length - 1; i++) {
      const c1 = conditionNodes[i];
      const c2 = conditionNodes[i + 1];

      const c1Bottom = c1.position.y + conditionHeight;
      const c2Top = c2.position.y;
      const midpointY = (c1Bottom + c2Top) / 2 - operatorHeight / 2;

      const opNodeId = `op-${i}`;
      const opLabel = conditions[i].operator || 'AND';

      nodesArray.push({
        id: opNodeId,
        data: { label: opLabel },
        position: { x: conditionX + nodeWidth / 2 - 30, y: midpointY },
        style: {
          width: 60,
          height: operatorHeight,
          backgroundColor: '#fff',
          border: `1px solid ${colors.dkpurple}`,
          borderRadius: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '14px',
        },
      });

      // condition i -> operator -> condition i+1
      edgesArray.push(
        {
          id: `edge-${c1.id}-${opNodeId}`,
          source: c1.id,
          target: opNodeId,
          animated: true,
          style: { stroke: colors.dkpurple },
          type: 'smoothstep',
        },
        {
          id: `edge-${opNodeId}-${c2.id}`,
          source: opNodeId,
          target: c2.id,
          animated: true,
          style: { stroke: colors.dkpurple },
          type: 'smoothstep',
        }
      );
    }

    // 3) Build Step nodes in another column
    const stepX = 450;
    const stepNodes = steps.map((step, idx) => {
      const stepId = `step-${idx}`;
      const label = step.webhookId && step.webhookId.length
        ? step.webhookId.join(', ')
        : `Step ${idx + 1}`;

      return {
        id: stepId,
        data: { label },
        position: {
          x: stepX,
          y: baseY + idx * (stepHeight + verticalGap),
        },
        style: {
          width: nodeWidth,
          height: stepHeight,
          backgroundColor: colors.bgorange,
          border: `2px solid ${colors.ltorange}`,
          borderRadius: 8,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center', // center text
          textAlign: 'center',
          whiteSpace: 'pre-line',
          fontSize: '18px',
          color: colors.dkorange,
        },
      };
    });
    nodesArray.push(...stepNodes);

    // 4) Connect the LAST condition to the FIRST step only
    if (conditionNodes.length > 0 && stepNodes.length > 0) {
        const finalConditionNodeId = conditionNodes[conditionNodes.length - 1].id;
        const firstStepNodeId = stepNodes[0].id;
        console.log('finalConditionNodeId:', finalConditionNodeId);
        console.log('firstStepNodeId:', firstStepNodeId);
        edgesArray.push({
            id: `edge-${finalConditionNodeId}-${firstStepNodeId}`,
            source: finalConditionNodeId,
            target: firstStepNodeId, // guaranteed correct node ID
            animated: true,
            style: { stroke: colors.dkpurple },
            type: 'smoothstep',
          });
    }

    // 5) Chain steps: step-0 -> step-1 -> step-2 ...
    for (let i = 0; i < stepNodes.length - 1; i++) {
      edgesArray.push({
        id: `edge-step-${i}-step-${i + 1}`,
        source: `step-${i}`,
        target: `step-${i + 1}`,
        animated: true,
        style: { stroke: colors.dkpurple },
        type: 'smoothstep',
      });
    }

    // Debug: log out edges so you can confirm there's no “cond-1 -> step-1”
    console.log('Built edges:', edgesArray);

    return [nodesArray, edgesArray];
  }, [trigger]);

  // Re-fit once the modal is open
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        fitView({ padding: 0.2, includeHiddenNodes: true });
      }, 150);
    }
  }, [isOpen, fitView]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ReactFlow
    key={trigger.id || trigger.name } // stable & reliable
    nodes={nodes}
        edges={edges}
        fitView
        fitViewOptions={{ padding: 0.2 }}
        style={{ width: '100%', height: '100%' }}
      >
        <Background color="#aaa" gap={16} />
        <Controls />
      </ReactFlow>
    </div>
  );
}

export default function TriggerFlowModal({ trigger, opened, onClose }) {
  if (!trigger) return null;

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      padding={0}
      overlayBlur={2}
      // Instead of size="90%", we forcibly set width/height in styles:
      classNames={{ modal: 'trigger-flow-modal' }}
      styles={{
        modal: {
          width: '90vw',
          height: '90vh',
          maxWidth: 'none',
          display: 'block',
          flexDirection: 'column',
        },
        body: {
          flex: '1 1 auto', // crucial: forces modal content to expand
          display: 'block',
          overflow: 'hidden', // prevent internal overflow
        },
      }}
    //   styles={{
    //     modal: {
    //       width: '90vw',
    //       height: '90vh',
    //       maxWidth: 'none',
    //       display: 'flex',
    //       flexDirection: 'column',
    //     },
    //   }}
    >
      {/* Minimal top bar with a custom close button */}
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0.5rem' }}>
        <Button variant="subtle" onClick={onClose} style={{ fontSize: '1.2rem' }}>
          ✕
        </Button>
      </div>

      <h2 style={{ margin: '-3rem 1rem 1rem', color: colors.dkpurple }}>
        {trigger.name || 'Trigger Flow'}
      </h2>

      {/* Wrap FlowDiagram with ReactFlowProvider directly in the same file */}
      <div style={{ width: '100%', height: 'calc(100% - 64px)', position: 'relative' }}>
        <ReactFlowProvider>
          <FlowDiagram trigger={trigger} isOpen={opened} />
        </ReactFlowProvider>
      </div>
    </Modal>
  );
}


// // import React, { useMemo, useEffect } from 'react';
// // import { Modal, Button } from '@mantine/core';
// // import ReactFlow, {
// //   Background,
// //   Controls,
// //   useReactFlow, 
// //   ReactFlowProvider,
// // } from 'react-flow-renderer';


// // const colors = {
// //   dkpurple: '#2c294b',
// //   mdpurple: '#3b3484',
// //   ltpurple: '#7174b0',
// //   bgpurple: '#cdcee1',
// //   dkorange: '#de6736',
// //   mdorange: '#e08a3c',
// //   ltorange: '#ebb844',
// //   bgorange: '#f7e8c6',
// //   cranberry: '#762861',
// //   magenta: '#c5316a',
// //   ltgray: '#ebe5df',
// //   mdgray: '#958e86',
// //   brown: '#5d3c23',
// //   mdgreen: '#80ba55',
// // };

// // function parseParameters(raw) {
// //   if (!raw) return {};
// //   if (typeof raw === 'object') return raw;
// //   try {
// //     const jsonString = atob(raw);
// //     return JSON.parse(jsonString);
// //   } catch (err) {
// //     try {
// //       return JSON.parse(raw);
// //     } catch (e) {
// //       console.warn('Failed to parse condition parameters:', e);
// //     }
// //   }
// //   return {};
// // }

// // function buildConditionLabel(cond, idx) {
// //   const params = parseParameters(cond.parameters);
// //   let mainLine = '';

// //   switch (cond.type) {
// //     case 'EVENT':
// //       mainLine = `Event: ${params.eventDesc || '(no desc)'}`;
// //       break;
// //     case 'PAGE':
// //       mainLine = `Page: ${params.pageUrl || '(no url)'}`;
// //       break;
// //     case 'ML_PATTERN':
// //       mainLine = `ML Pattern: ${params.patternName || '(no name)'}`;
// //       break;
// //     default:
// //       mainLine = cond.type || `Condition ${idx + 1}`;
// //       break;
// //   }

// //   if (typeof params.lookbackDays === 'number') {
// //     mainLine += `\nLookback: ${params.lookbackDays} days`;
// //   }

// //   return mainLine;
// // }

// // function FlowDiagram({ trigger, isOpen }) {
// //   const { fitView } = useReactFlow();
// //   const nodeWidth = 250;
// //   const conditionHeight = 100;
// //   const stepHeight = 100;
// //   const operatorHeight = 50;
// //   const verticalGap = 40;

// //   const [nodes, edges] = useMemo(() => {
// //     const nodesArray = [];
// //     const edgesArray = [];

// //     const conditions = trigger.conditions || [];
// //     const steps = trigger.steps || [];

// //     // Place conditions in one column
// //     const conditionX = 50;
// //     let baseY = 0;

// //     // Build condition nodes
// //     const conditionNodes = conditions.map((cond, idx) => {
// //       const condNodeId = `cond-${idx}`;
// //       const label = buildConditionLabel(cond, idx);

// //       return {
// //         id: condNodeId,
// //         data: { label },
// //         position: {
// //           x: conditionX,
// //           y: baseY + idx * (conditionHeight + operatorHeight + verticalGap),
// //         },
// //         style: {
// //           width: nodeWidth,
// //           height: conditionHeight,
// //           backgroundColor: colors.bgpurple,
// //           border: `2px solid ${colors.ltpurple}`,
// //           borderRadius: 8,
// //           display: 'flex',
// //           alignItems: 'center',
// //           justifyContent: 'center', // center the text
// //           textAlign: 'center',
// //           whiteSpace: 'pre-line',
// //           fontSize: '18px',
// //           color: colors.dkpurple,
// //         },
// //       };
// //     });

// //     nodesArray.push(...conditionNodes);

// //     // Insert operator nodes between consecutive conditions
// //     for (let i = 0; i < conditionNodes.length - 1; i++) {
// //       const c1 = conditionNodes[i];
// //       const c2 = conditionNodes[i + 1];

// //       // Midpoint Y
// //       const c1Bottom = c1.position.y + conditionHeight;
// //       const c2Top = c2.position.y;
// //       const midpointY = (c1Bottom + c2Top) / 2 - operatorHeight / 2;

// //       const opNodeId = `op-${i}`;
// //       const opLabel = conditions[i].operator || 'AND';
// //       nodesArray.push({
// //         id: opNodeId,
// //         data: { label: opLabel },
// //         position: { x: conditionX + nodeWidth / 2 - 30, y: midpointY },
// //         style: {
// //           width: 60,
// //           height: operatorHeight,
// //           backgroundColor: '#fff',
// //           border: `1px solid ${colors.dkpurple}`,
// //           borderRadius: 4,
// //           display: 'flex',
// //           alignItems: 'center',
// //           justifyContent: 'center',
// //           fontSize: '14px',
// //         },
// //       });

// //       // condition i -> operator -> condition i+1
// //       edgesArray.push(
// //         {
// //           id: `edge-${c1.id}-${opNodeId}`,
// //           source: c1.id,
// //           target: opNodeId,
// //           animated: true,
// //           style: { stroke: colors.dkpurple },
// //           type: 'smoothstep',
// //         },
// //         {
// //           id: `edge-${opNodeId}-${c2.id}`,
// //           source: opNodeId,
// //           target: c2.id,
// //           animated: true,
// //           style: { stroke: colors.dkpurple },
// //           type: 'smoothstep',
// //         }
// //       );
// //     }

// //     // Final condition node ID (the last condition in the array)
// //     let finalConditionNodeId = null;
// //     if (conditions.length > 0) {
// //       finalConditionNodeId = `cond-${conditions.length - 1}`;
// //     }

// //     // Build step nodes in another column
// //     const stepX = 450;
// //     const stepNodes = steps.map((step, idx) => {
// //       const stepId = `step-${idx}`;
// //       const label = step.webhookId && step.webhookId.length
// //         ? step.webhookId.join(', ')
// //         : `Step ${idx + 1}`;

// //       return {
// //         id: stepId,
// //         data: { label },
// //         position: {
// //           x: stepX,
// //           y: baseY + idx * (stepHeight + verticalGap),
// //         },
// //         style: {
// //           width: nodeWidth,
// //           height: stepHeight,
// //           backgroundColor: colors.bgorange,
// //           border: `2px solid ${colors.ltorange}`,
// //           borderRadius: 8,
// //           display: 'flex',
// //           alignItems: 'center',
// //           justifyContent: 'center', // center the text
// //           textAlign: 'center',
// //           whiteSpace: 'pre-line',
// //           fontSize: '18px',
// //           color: colors.dkorange,
// //         },
// //       };
// //     });

// //     nodesArray.push(...stepNodes);

// //     // (Key part) Connect the LAST condition node to the FIRST step node
// //     if (finalConditionNodeId && steps.length > 0) {
// //       edgesArray.push({
// //         id: `edge-${finalConditionNodeId}-step-0`,
// //         source: finalConditionNodeId,
// //         target: `step-0`,
// //         animated: true,
// //         style: { stroke: colors.dkpurple },
// //         type: 'smoothstep',
// //       });
// //     }

// //     // Chain steps: step-0 -> step-1 -> ...
// //     for (let i = 0; i < steps.length - 1; i++) {
// //       edgesArray.push({
// //         id: `edge-step-${i}-step-${i + 1}`,
// //         source: `step-${i}`,
// //         target: `step-${i + 1}`,
// //         animated: true,
// //         style: { stroke: colors.dkpurple },
// //         type: 'smoothstep',
// //       });
// //     }

// //     return [nodesArray, edgesArray];
// //   }, [trigger]);

// //   // When the modal opens, wait a beat, then call fitView to ensure the diagram fills
// //   useEffect(() => {
// //     if (isOpen) {
// //       setTimeout(() => {
// //         fitView({ padding: 0.2, includeHiddenNodes: true });
// //       }, 100);
// //     }
// //   }, [isOpen, fitView]);

// //   return (
// //     <div style={{ width: '100%', height: '100%' }}>
// //       <ReactFlow
// //         nodes={nodes}
// //         edges={edges}
// //         // Let it try to fit initially
// //         fitView
// //         fitViewOptions={{ padding: 0.2 }}
// //         style={{ width: '100%', height: '100%' }}
// //       >
// //         <Background color="#aaa" gap={16} />
// //         <Controls />
// //       </ReactFlow>
// //     </div>
// //   );
// // }

// // export default function TriggerFlowModal({ trigger, opened, onClose }) {
// //     if (!trigger) return null;
  
// //     return (
// //       <Modal
// //         opened={opened}
// //         onClose={onClose}
// //         size="90%"
// //         padding={0}
// //         overlayBlur={2}
// //         withCloseButton={false}
// //         styles={{
// //           modal: {
// //             height: '90vh',
// //             maxWidth: '90vw',
// //             display: 'flex',
// //             flexDirection: 'column',
// //           },
// //         }}
// //       >
// //         {/* Minimal custom close */}
// //         <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0.5rem' }}>
// //           <Button variant="subtle" onClick={onClose} style={{ fontSize: '1.2rem' }}>
// //             ✕
// //           </Button>
// //         </div>
  
// //         <h2 style={{ color: colors.dkpurple }}>
// //           {trigger.name || 'Trigger Flow'}
// //         </h2>
  
// //         {/* Wrap the FlowDiagram with ReactFlowProvider directly */}
// //         <div style={{ width: '100%', height: '100%', flex: 1, position: 'relative' }}>
// //           <ReactFlowProvider>
// //             <FlowDiagram trigger={trigger} isOpen={opened} />
// //           </ReactFlowProvider>
// //         </div>
// //       </Modal>
// //     );
// //   }




// // // import React, { useMemo } from 'react';
// // // import { Modal, Button } from '@mantine/core';
// // // import ReactFlow, {
// // //   Background,
// // //   Controls,
// // //   useNodesState,
// // //   useEdgesState,
// // // } from 'react-flow-renderer';

// // // const colors = {
// // //   dkpurple: '#2c294b',
// // //   mdpurple: '#3b3484',
// // //   ltpurple: '#7174b0',
// // //   bgpurple: '#cdcee1',
// // //   dkorange: '#de6736',
// // //   mdorange: '#e08a3c',
// // //   ltorange: '#ebb844',
// // //   bgorange: '#f7e8c6',
// // //   cranberry: '#762861',
// // //   magenta: '#c5316a',
// // //   ltgray: '#ebe5df',
// // //   mdgray: '#958e86',
// // //   brown: '#5d3c23',
// // //   mdgreen: '#80ba55',
// // // };

// // // function parseParameters(raw) {
// // //   if (!raw) return {};
// // //   if (typeof raw === 'object') {
// // //     return raw; // already an object
// // //   }
// // //   try {
// // //     const jsonString = atob(raw);
// // //     return JSON.parse(jsonString);
// // //   } catch (err) {
// // //     try {
// // //       return JSON.parse(raw);
// // //     } catch (e) {
// // //       console.warn('Failed to parse condition parameters:', e);
// // //     }
// // //   }
// // //   return {};
// // // }

// // // function buildConditionLabel(cond, idx) {
// // //   const params = parseParameters(cond.parameters);
// // //   let mainLine = '';

// // //   switch (cond.type) {
// // //     case 'EVENT':
// // //       mainLine = `Event: ${params.eventDesc || '(no desc)'}`;
// // //       break;
// // //     case 'PAGE':
// // //       mainLine = `Page: ${params.pageUrl || '(no url)'}`;
// // //       break;
// // //     case 'ML_PATTERN':
// // //       mainLine = `ML Pattern: ${params.patternName || '(no name)'}`;
// // //       break;
// // //     default:
// // //       mainLine = cond.type || `Condition ${idx + 1}`;
// // //       break;
// // //   }

// // //   if (typeof params.lookbackDays === 'number') {
// // //     mainLine += `\nLookback: ${params.lookbackDays} days`;
// // //   }

// // //   return mainLine;
// // // }

// // // function TriggerFlowModal({ trigger, opened, onClose }) {
// // //   if (!trigger) return null;

// // //   // 1) Remove large top space: remove default title & close button, do a custom close button
// // //   //    and minimal padding to shrink the modal top area.
// // //   return (
// // //     <Modal
// // //       opened={opened}
// // //       onClose={onClose}
// // //       withCloseButton={false} // We'll add our own close button below
// // //       size="80%"             // Give enough width to comfortably display the flow
// // //       padding={0}            // Remove extra Mantine padding
// // //       overlayBlur={2}
// // //       styles={{
// // //         modal: {
// // //           // Optional: Make the modal a bit taller so the flow and controls fit nicely
// // //           height: '90vh',
// // //           display: 'flex',
// // //           flexDirection: 'column',
// // //         },
// // //       }}
// // //     >
// // //       {/* Custom close button in top-right corner */}
// // //       <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0.5rem' }}>
// // //         <Button variant="subtle" onClick={onClose} style={{ fontSize: '1.2rem' }}>
// // //           ✕
// // //         </Button>
// // //       </div>

// // //       {/* Title or name (optional) */}
// // //       <h2 style={{ margin: '0 1rem 1rem', color: colors.dkpurple }}>{trigger.name}</h2>

// // //       {/* 2) Keep ReactFlow container large enough so everything fits, and the controls are visible. */}
// // //       <div style={{ width: '100%', height: '100%', flex: 1, position: 'relative' }}>
// // //         <FlowDiagram trigger={trigger} />
// // //       </div>
// // //     </Modal>
// // //   );
// // // }

// // // // Extract the flow-building logic to a sub-component
// // // function FlowDiagram({ trigger }) {
// // //   const nodeWidth = 250;
// // //   const conditionHeight = 100;
// // //   const stepHeight = 100;
// // //   const operatorHeight = 50;
// // //   const verticalGap = 40;

// // //   // We’ll place conditions in a single column, steps in another column.
// // //   // Then we place operator nodes exactly halfway between consecutive conditions.

// // //   const [nodes, edges] = useMemo(() => {
// // //     const nodesArray = [];
// // //     const edgesArray = [];

// // //     // 3) Condition node positions
// // //     const conditions = trigger.conditions || [];
// // //     const conditionX = 50;
// // //     let baseY = 0; // start from 0 so we don't push everything offscreen

// // //     // Build condition nodes first, store them for a second pass
// // //     const conditionNodes = conditions.map((cond, idx) => {
// // //       const condNodeId = `cond-${idx}`;
// // //       const label = buildConditionLabel(cond, idx);

// // //       return {
// // //         id: condNodeId,
// // //         data: { label },
// // //         position: { x: conditionX, y: baseY + idx * (conditionHeight + operatorHeight + verticalGap) },
// // //         style: {
// // //           width: nodeWidth,
// // //           height: conditionHeight,
// // //           backgroundColor: colors.bgpurple,
// // //           border: `2px solid ${colors.ltpurple}`,
// // //           borderRadius: 8,
// // //           display: 'flex',
// // //           alignItems: 'center',
// // //           justifyContent: 'center', // (5) Center text in node
// // //           textAlign: 'center',
// // //           whiteSpace: 'pre-line',
// // //           fontSize: '18px',
// // //           color: colors.dkpurple,
// // //         },
// // //       };
// // //     });

// // //     nodesArray.push(...conditionNodes);

// // //     // (3) Insert operator nodes between consecutive condition nodes at the midpoint
// // //     for (let i = 0; i < conditionNodes.length - 1; i++) {
// // //       const c1 = conditionNodes[i];
// // //       const c2 = conditionNodes[i + 1];

// // //       const c1Bottom = c1.position.y + conditionHeight;
// // //       const c2Top = c2.position.y;
// // //       const midpointY = (c1Bottom + c2Top) / 2 - operatorHeight / 2;

// // //       const opNodeId = `op-${i}`;
// // //       const opLabel = conditions[i].operator || 'AND'; // or use the next condition's operator if you prefer
// // //       nodesArray.push({
// // //         id: opNodeId,
// // //         data: { label: opLabel },
// // //         position: { x: conditionX + nodeWidth / 2 - 30, y: midpointY },
// // //         style: {
// // //           width: 60,
// // //           height: operatorHeight,
// // //           backgroundColor: '#fff',
// // //           border: `1px solid ${colors.dkpurple}`,
// // //           borderRadius: 4,
// // //           display: 'flex',
// // //           alignItems: 'center',
// // //           justifyContent: 'center',
// // //           fontSize: '14px',
// // //           textAlign: 'center',
// // //         },
// // //       });

// // //       // edges: condition i -> operator -> condition i+1
// // //       edgesArray.push(
// // //         {
// // //           id: `edge-${c1.id}-${opNodeId}`,
// // //           source: c1.id,
// // //           target: opNodeId,
// // //           animated: true,
// // //           style: { stroke: colors.dkpurple },
// // //           type: 'smoothstep',
// // //         },
// // //         {
// // //           id: `edge-${opNodeId}-${c2.id}`,
// // //           source: opNodeId,
// // //           target: c2.id,
// // //           animated: true,
// // //           style: { stroke: colors.dkpurple },
// // //           type: 'smoothstep',
// // //         }
// // //       );
// // //     }

// // //     // The final condition node ID (to connect to steps)
// // //     let finalConditionNodeId = null;
// // //     if (conditions.length > 0) {
// // //       finalConditionNodeId = `cond-${conditions.length - 1}`;
// // //     }

// // //     // 4) Step nodes: place them in a separate column, chain them, connect finalCondition -> first step
// // //     const steps = trigger.steps || [];
// // //     const stepX = 450;
// // //     const stepNodes = steps.map((step, idx) => {
// // //       const stepId = `step-${idx}`;
// // //       const label = step.webhookId && step.webhookId.length
// // //         ? step.webhookId.join(', ')
// // //         : `Step ${idx + 1}`;

// // //       return {
// // //         id: stepId,
// // //         data: { label },
// // //         position: {
// // //           x: stepX,
// // //           y: baseY + idx * (stepHeight + verticalGap),
// // //         },
// // //         style: {
// // //           width: nodeWidth,
// // //           height: stepHeight,
// // //           backgroundColor: colors.bgorange,
// // //           border: `2px solid ${colors.ltorange}`,
// // //           borderRadius: 8,
// // //           display: 'flex',
// // //           alignItems: 'center',
// // //           justifyContent: 'center', // center text
// // //           textAlign: 'center',
// // //           whiteSpace: 'pre-line',
// // //           fontSize: '18px',
// // //           color: colors.dkorange,
// // //         },
// // //       };
// // //     });

// // //     nodesArray.push(...stepNodes);

// // //     // Edge from final condition to first step only (instead of connecting them all)
// // //     if (finalConditionNodeId && steps.length > 0) {
// // //       edgesArray.push({
// // //         id: `edge-${finalConditionNodeId}-step-0`,
// // //         source: finalConditionNodeId,
// // //         target: `step-0`,
// // //         animated: true,
// // //         style: { stroke: colors.dkpurple },
// // //         type: 'smoothstep',
// // //       });
// // //     }

// // //     // Chain step i to step i+1
// // //     for (let i = 0; i < steps.length - 1; i++) {
// // //       edgesArray.push({
// // //         id: `edge-step-${i}-step-${i + 1}`,
// // //         source: `step-${i}`,
// // //         target: `step-${i + 1}`,
// // //         animated: true,
// // //         style: { stroke: colors.dkpurple },
// // //         type: 'smoothstep',
// // //       });
// // //     }

// // //     return [nodesArray, edgesArray];
// // //   }, [trigger]);

// // //   return (
// // //     <div style={{ width: '100%', height: '100%' }}>
// // //       <ReactFlow
// // //         nodes={nodes}
// // //         edges={edges}
// // //         fitView
// // //         // (1) Let ReactFlow handle the auto-fitting; add a bit of padding
// // //         fitViewOptions={{ padding: 0.2 }}
// // //         style={{ width: '100%', height: '100%' }}
// // //       >
// // //         <Background color="#aaa" gap={16} />
// // //         <Controls />
// // //       </ReactFlow>
// // //     </div>
// // //   );
// // // }

// // // export default TriggerFlowModal;


// // // // import React from 'react';
// // // // import { Modal } from '@mantine/core';
// // // // import ReactFlow, { Background, Controls } from 'react-flow-renderer';

// // // // const colors = {
// // // //   dkpurple: '#2c294b',
// // // //   mdpurple: '#3b3484',
// // // //   ltpurple: '#7174b0',
// // // //   bgpurple: '#cdcee1',
// // // //   dkorange: '#de6736',
// // // //   mdorange: '#e08a3c',
// // // //   ltorange: '#ebb844',
// // // //   bgorange: '#f7e8c6',
// // // //   cranberry: '#762861',
// // // //   magenta: '#c5316a',
// // // //   ltgray: '#ebe5df',
// // // //   mdgray: '#958e86',
// // // //   brown: '#5d3c23',
// // // //   mdgreen: '#80ba55',
// // // // };

// // // // // Helper to parse parameters whether they're objects or base64-encoded
// // // // function parseParameters(raw) {
// // // //   if (!raw) return {};
// // // //   if (typeof raw === 'object') {
// // // //     return raw; // already an object
// // // //   }
// // // //   try {
// // // //     // Attempt base64 decoding
// // // //     const jsonString = atob(raw);
// // // //     return JSON.parse(jsonString);
// // // //   } catch (err) {
// // // //     // If base64 fails, try direct JSON parse
// // // //     try {
// // // //       return JSON.parse(raw);
// // // //     } catch (e) {
// // // //       console.warn('Failed to parse condition parameters:', e);
// // // //     }
// // // //   }
// // // //   return {};
// // // // }

// // // // // Build the text label for a condition node
// // // // function buildConditionLabel(cond, idx) {
// // // //   const params = parseParameters(cond.parameters);
// // // //   let mainLine = '';

// // // //   switch (cond.type) {
// // // //     case 'EVENT':
// // // //       mainLine = `Event: ${params.eventDesc || '(no desc)'}`;
// // // //       break;
// // // //     case 'PAGE':
// // // //       mainLine = `Page: ${params.pageUrl || '(no url)'}`;
// // // //       break;
// // // //     case 'ML_PATTERN':
// // // //       mainLine = `ML Pattern: ${params.patternName || '(no name)'}`;
// // // //       break;
// // // //     default:
// // // //       mainLine = cond.type || `Condition ${idx + 1}`;
// // // //       break;
// // // //   }

// // // //   if (typeof params.lookbackDays === 'number') {
// // // //     mainLine += `\nLookback: ${params.lookbackDays} days`;
// // // //   }

// // // //   return mainLine;
// // // // }

// // // // function TriggerFlowModal({ trigger, opened, onClose }) {
// // // //   if (!trigger) return null;

// // // //   // Layout columns for conditions (left) and steps (right)
// // // //   const conditionX = 50;
// // // //   const stepX = 450;
// // // //   const baseY = -325; // start at 0 to remove extra top whitespace

// // // //   // Make each node 100px taller than before
// // // //   const conditionHeight = 125;
// // // //   const operatorHeight = 50;
// // // //   const stepHeight = 125;
// // // //   const verticalGap = 40;
// // // //   const nodeWidth = 250;

// // // //   const nodes = [];
// // // //   const edges = [];

// // // //   // Build condition nodes + operator nodes
// // // //   let currentCondY = baseY;
// // // //   const conditions = trigger.conditions || [];

// // // //   conditions.forEach((cond, idx, arr) => {
// // // //     const condNodeId = `cond-${idx}`;
// // // //     const label = buildConditionLabel(cond, idx);

// // // //     // Condition node
// // // //     nodes.push({
// // // //       id: condNodeId,
// // // //       data: { label },
// // // //       position: { x: conditionX, y: currentCondY },
// // // //       style: {
// // // //         width: nodeWidth,
// // // //         height: conditionHeight, // enforce a fixed height
// // // //         backgroundColor: colors.bgpurple,
// // // //         border: `2px solid ${colors.ltpurple}`,
// // // //         borderRadius: 8,
// // // //         padding: '10px',
// // // //         whiteSpace: 'pre-line',
// // // //         fontSize: '22px',
// // // //         alignItems: 'center',
// // // //       },
// // // //     });

// // // //     currentCondY += conditionHeight;

// // // //     // Insert an operator node if there's another condition after this
// // // //     if (idx < arr.length - 1) {
// // // //       const opNodeId = `op-${idx}`;
// // // //       const opLabel = cond.operator || 'AND';

// // // //       nodes.push({
// // // //         id: opNodeId,
// // // //         data: { label: opLabel },
// // // //         position: {
// // // //           x: conditionX + nodeWidth / 2 - 30,
// // // //           y: currentCondY,
// // // //         },
// // // //         style: {
// // // //           width: 60,
// // // //           height: operatorHeight,
// // // //           backgroundColor: '#fff',
// // // //           border: `1px solid ${colors.dkpurple}`,
// // // //           borderRadius: 4,
// // // //           textAlign: 'center',
// // // //           display: 'flex',
// // // //           alignItems: 'center',
// // // //           justifyContent: 'center',
// // // //           fontSize: '14px',
// // // //         },
// // // //       });

// // // //       // Edges: condition -> operator -> next condition
// // // //       edges.push({
// // // //         id: `edge-${condNodeId}-${opNodeId}`,
// // // //         source: condNodeId,
// // // //         target: opNodeId,
// // // //         animated: true,
// // // //         style: { stroke: colors.dkpurple },
// // // //         type: 'smoothstep',
// // // //       });

// // // //       const nextCondId = `cond-${idx + 1}`;
// // // //       edges.push({
// // // //         id: `edge-${opNodeId}-${nextCondId}`,
// // // //         source: opNodeId,
// // // //         target: nextCondId,
// // // //         animated: true,
// // // //         style: { stroke: colors.dkpurple },
// // // //         type: 'smoothstep',
// // // //       });

// // // //       currentCondY += operatorHeight + verticalGap;
// // // //     }
// // // //   });

// // // //   // The final condition node is used to connect to steps
// // // //   const conditionCount = conditions.length;
// // // //   let finalConditionNodeId = null;
// // // //   if (conditionCount > 0) {
// // // //     finalConditionNodeId = `cond-${conditionCount - 1}`;
// // // //   }

// // // //   // Build step nodes in the right column
// // // //   let currentStepY = baseY;
// // // //   const steps = trigger.steps || [];
// // // //   steps.forEach((step, idx) => {
// // // //     const stepId = `step-${idx}`;
// // // //     const label = step.webhookId && step.webhookId.length
// // // //       ? step.webhookId.join(', ')
// // // //       : `Step ${idx + 1}`;

// // // //     nodes.push({
// // // //       id: stepId,
// // // //       data: { label },
// // // //       position: { x: stepX, y: currentStepY },
// // // //       style: {
// // // //         width: nodeWidth,
// // // //         height: stepHeight,
// // // //         backgroundColor: colors.bgorange,
// // // //         border: `2px solid ${colors.ltorange}`,
// // // //         borderRadius: 8,
// // // //         padding: '10px',
// // // //         whiteSpace: 'pre-line',
// // // //         fontSize: '18px',
// // // //         color: colors.dkorange,
// // // //       },
// // // //     });

// // // //     // Connect the final condition node to each step
// // // //     if (finalConditionNodeId) {
// // // //       edges.push({
// // // //         id: `edge-${finalConditionNodeId}-${stepId}`,
// // // //         source: finalConditionNodeId,
// // // //         target: stepId,
// // // //         animated: true,
// // // //         style: { stroke: colors.dkpurple },
// // // //         type: 'smoothstep',
// // // //       });
// // // //     }

// // // //     currentStepY += stepHeight + verticalGap;
// // // //   });

// // // //   return (
// // // //     <Modal
// // // //       opened={opened}
// // // //       onClose={onClose}
// // // //       withCloseButton
// // // //       size="xl"
// // // //       overlayBlur={2}
// // // //     >
// // // //       <h2 style={{ marginBottom: '1rem', color: colors.dkpurple }}>
// // // //         {trigger.name}
// // // //       </h2>

// // // //       <div style={{ width: '100%', height: '80vh' }}>
// // // //         <ReactFlow
// // // //           nodes={nodes}
// // // //           edges={edges}
// // // //           fitView
// // // //           fitViewOptions={{ padding: 0 }} // remove extra margin
// // // //           style={{ width: '100%', height: '100%' }}
// // // //         >
// // // //           <Background color="#aaa" gap={16} />
// // // //           <Controls />
// // // //         </ReactFlow>
// // // //       </div>
// // // //     </Modal>
// // // //   );
// // // // }

// // // // export default TriggerFlowModal;



// // // // // import React from 'react';
// // // // // import { Modal } from '@mantine/core';
// // // // // import ReactFlow, { Background, Controls } from 'react-flow-renderer';

// // // // // const colors = {
// // // // //   dkpurple: '#2c294b',
// // // // //   mdpurple: '#3b3484',
// // // // //   ltpurple: '#7174b0',
// // // // //   bgpurple: '#cdcee1',
// // // // //   dkorange: '#de6736',
// // // // //   mdorange: '#e08a3c',
// // // // //   ltorange: '#ebb844',
// // // // //   bgorange: '#f7e8c6',
// // // // //   cranberry: '#762861',
// // // // //   magenta: '#c5316a',
// // // // //   ltgray: '#ebe5df',
// // // // //   mdgray: '#958e86',
// // // // //   brown: '#5d3c23',
// // // // //   mdgreen: '#80ba55',
// // // // // };

// // // // // // Helper to handle parameters whether they're objects or base64-encoded JSON
// // // // // function parseParameters(raw) {
// // // // //   if (!raw) return {};
// // // // //   if (typeof raw === 'object') {
// // // // //     return raw; // already an object
// // // // //   }
// // // // //   try {
// // // // //     // Attempt base64 decoding
// // // // //     const jsonString = atob(raw);
// // // // //     return JSON.parse(jsonString);
// // // // //   } catch (err) {
// // // // //     // If base64 fails, try direct JSON parse
// // // // //     try {
// // // // //       return JSON.parse(raw);
// // // // //     } catch (e) {
// // // // //       console.warn('Failed to parse condition parameters:', e);
// // // // //     }
// // // // //   }
// // // // //   return {};
// // // // // }

// // // // // function buildConditionLabel(cond, idx) {
// // // // //   const params = parseParameters(cond.parameters);
// // // // //   let mainLine = '';

// // // // //   switch (cond.type) {
// // // // //     case 'EVENT':
// // // // //       mainLine = `Event: ${params.eventDesc || '(no desc)'}`;
// // // // //       break;
// // // // //     case 'PAGE':
// // // // //       mainLine = `Page: ${params.pageUrl || '(no url)'}`;
// // // // //       break;
// // // // //     case 'ML_PATTERN':
// // // // //       mainLine = `ML Pattern: ${params.patternName || '(no name)'}`;
// // // // //       break;
// // // // //     default:
// // // // //       mainLine = cond.type || `Condition ${idx + 1}`;
// // // // //       break;
// // // // //   }

// // // // //   if (typeof params.lookbackDays === 'number') {
// // // // //     mainLine += `\nLookback: ${params.lookbackDays} days`;
// // // // //   }

// // // // //   return mainLine;
// // // // // }

// // // // // function TriggerFlowModal({ trigger, opened, onClose }) {
// // // // //   if (!trigger) return null;

// // // // //   // Columns for conditions (left) and steps (right)
// // // // //   const conditionX = 50;
// // // // //   const stepX = 500; // separate column for steps
// // // // //   const baseY = 50;

// // // // //   // Reduce the spacing so more nodes fit on screen
// // // // //   const conditionHeight = 90; 
// // // // //   const operatorHeight = 40;
// // // // //   const stepHeight = 90;
// // // // //   const verticalGap = 10;

// // // // //   const nodeWidth = 300;

// // // // //   const nodes = [];
// // // // //   const edges = [];

// // // // //   // Build condition nodes
// // // // //   let currentCondY = baseY;
// // // // //   const conditions = trigger.conditions || [];

// // // // //   conditions.forEach((cond, idx, arr) => {
// // // // //     const condNodeId = `cond-${idx}`;
// // // // //     const label = buildConditionLabel(cond, idx);

// // // // //     // Condition node
// // // // //     nodes.push({
// // // // //       id: condNodeId,
// // // // //       data: { label },
// // // // //       position: { x: conditionX, y: currentCondY },
// // // // //       style: {
// // // // //         width: nodeWidth,
// // // // //         backgroundColor: colors.bgpurple,
// // // // //         border: `2px solid ${colors.ltpurple}`,
// // // // //         borderRadius: 8,
// // // // //         padding: '10px',
// // // // //         whiteSpace: 'pre-line',
// // // // //         fontSize: '16px',
// // // // //       },
// // // // //     });

// // // // //     currentCondY += conditionHeight;

// // // // //     // If there's a next condition, insert an operator node
// // // // //     if (idx < arr.length - 1) {
// // // // //       const opNodeId = `op-${idx}`;
// // // // //       const opLabel = cond.operator || 'AND';

// // // // //       nodes.push({
// // // // //         id: opNodeId,
// // // // //         data: { label: opLabel },
// // // // //         position: {
// // // // //           x: conditionX + nodeWidth / 2 - 30,
// // // // //           y: currentCondY,
// // // // //         },
// // // // //         style: {
// // // // //           width: 60,
// // // // //           height: 30,
// // // // //           backgroundColor: '#fff',
// // // // //           border: `1px solid ${colors.dkpurple}`,
// // // // //           borderRadius: 4,
// // // // //           textAlign: 'center',
// // // // //           display: 'flex',
// // // // //           alignItems: 'center',
// // // // //           justifyContent: 'center',
// // // // //           fontSize: '14px',
// // // // //         },
// // // // //       });

// // // // //       // Edges: condition -> operator -> next condition
// // // // //       edges.push({
// // // // //         id: `edge-${condNodeId}-${opNodeId}`,
// // // // //         source: condNodeId,
// // // // //         target: opNodeId,
// // // // //         animated: true,
// // // // //         style: { stroke: colors.dkpurple },
// // // // //         type: 'smoothstep',
// // // // //       });

// // // // //       const nextCondId = `cond-${idx + 1}`;
// // // // //       edges.push({
// // // // //         id: `edge-${opNodeId}-${nextCondId}`,
// // // // //         source: opNodeId,
// // // // //         target: nextCondId,
// // // // //         animated: true,
// // // // //         style: { stroke: colors.dkpurple },
// // // // //         type: 'smoothstep',
// // // // //       });

// // // // //       currentCondY += operatorHeight + verticalGap;
// // // // //     }
// // // // //   });

// // // // //   // Identify final condition node (the last condition in the array)
// // // // //   const conditionCount = conditions.length;
// // // // //   let finalConditionNodeId = null;
// // // // //   if (conditionCount > 0) {
// // // // //     finalConditionNodeId = `cond-${conditionCount - 1}`;
// // // // //   }

// // // // //   // Build step nodes in right column
// // // // //   let currentStepY = baseY;
// // // // //   const steps = trigger.steps || [];
// // // // //   steps.forEach((step, idx) => {
// // // // //     const stepId = `step-${idx}`;
// // // // //     const label = step.webhookId && step.webhookId.length
// // // // //       ? step.webhookId.join(', ')
// // // // //       : `Step ${idx + 1}`;

// // // // //     nodes.push({
// // // // //       id: stepId,
// // // // //       data: { label },
// // // // //       position: { x: stepX, y: currentStepY },
// // // // //       style: {
// // // // //         width: nodeWidth,
// // // // //         backgroundColor: colors.bgorange,
// // // // //         border: `2px solid ${colors.ltorange}`,
// // // // //         borderRadius: 8,
// // // // //         padding: '10px',
// // // // //         whiteSpace: 'pre-line',
// // // // //         fontSize: '18px',
// // // // //         color: colors.dkorange,
// // // // //       },
// // // // //     });

// // // // //     // Only connect from the final condition node to the steps
// // // // //     if (finalConditionNodeId) {
// // // // //       edges.push({
// // // // //         id: `edge-${finalConditionNodeId}-${stepId}`,
// // // // //         source: finalConditionNodeId,
// // // // //         target: stepId,
// // // // //         animated: true,
// // // // //         style: { stroke: colors.dkpurple },
// // // // //         type: 'smoothstep',
// // // // //       });
// // // // //     }

// // // // //     currentStepY += stepHeight;
// // // // //   });

// // // // //   return (
// // // // //     <Modal
// // // // //       opened={opened}
// // // // //       onClose={onClose}
// // // // //       withCloseButton
// // // // //       size="xl"
// // // // //       overlayBlur={2}
// // // // //     >
// // // // //       {/* Title with Trigger Name */}
// // // // //       <h2 style={{ marginBottom: '1rem', color: colors.dkpurple }}>
// // // // //         {trigger.name}
// // // // //       </h2>

// // // // //       <div style={{ width: '100%', height: '80vh' }}>
// // // // //         <ReactFlow nodes={nodes} edges={edges} fitView style={{ width: '100%', height: '100%' }}>
// // // // //           <Background color="#aaa" gap={16} />
// // // // //           <Controls />
// // // // //         </ReactFlow>
// // // // //       </div>
// // // // //     </Modal>
// // // // //   );
// // // // // }

// // // // // export default TriggerFlowModal;



// // // // // // import React from 'react';
// // // // // // import { Modal } from '@mantine/core';
// // // // // // import ReactFlow, { Background, Controls } from 'react-flow-renderer';

// // // // // // const colors = {
// // // // // //   dkpurple: '#2c294b',
// // // // // //   mdpurple: '#3b3484',
// // // // // //   ltpurple: '#7174b0',
// // // // // //   bgpurple: '#cdcee1',
// // // // // //   dkorange: '#de6736',
// // // // // //   mdorange: '#e08a3c',
// // // // // //   ltorange: '#ebb844',
// // // // // //   bgorange: '#f7e8c6',
// // // // // //   cranberry: '#762861',
// // // // // //   magenta: '#c5316a',
// // // // // //   ltgray: '#ebe5df',
// // // // // //   mdgray: '#958e86',
// // // // // //   brown: '#5d3c23',
// // // // // //   mdgreen: '#80ba55',
// // // // // // };

// // // // // // // 1) A helper to handle parameters whether they're an object or a base64 string
// // // // // // function parseParameters(raw) {
// // // // // //   if (!raw) return {};
// // // // // //   // If it's already an object, just return it
// // // // // //   if (typeof raw === 'object') {
// // // // // //     return raw;
// // // // // //   }
// // // // // //   // Otherwise, try base64 decoding, then JSON
// // // // // //   try {
// // // // // //     const jsonString = atob(raw);
// // // // // //     return JSON.parse(jsonString);
// // // // // //   } catch (err) {
// // // // // //     // If base64 fails, try direct JSON parse
// // // // // //     try {
// // // // // //       return JSON.parse(raw);
// // // // // //     } catch (e) {
// // // // // //       console.warn('Failed to parse condition parameters:', e);
// // // // // //     }
// // // // // //   }
// // // // // //   return {};
// // // // // // }

// // // // // // function buildConditionLabel(cond, idx) {
// // // // // //   const params = parseParameters(cond.parameters);
// // // // // //   let mainLine = '';

// // // // // //   switch (cond.type) {
// // // // // //     case 'EVENT':
// // // // // //       mainLine = `Event: ${params.eventDesc || '(no desc)'}`;
// // // // // //       break;
// // // // // //     case 'PAGE':
// // // // // //       mainLine = `Page: ${params.pageUrl || '(no url)'}`;
// // // // // //       break;
// // // // // //     case 'ML_PATTERN':
// // // // // //       mainLine = `ML Pattern: ${params.patternName || '(no name)'}`;
// // // // // //       break;
// // // // // //     default:
// // // // // //       mainLine = cond.type || `Condition ${idx + 1}`;
// // // // // //       break;
// // // // // //   }

// // // // // //   if (typeof params.lookbackDays === 'number') {
// // // // // //     mainLine += `\nLookback: ${params.lookbackDays} days`;
// // // // // //   }

// // // // // //   return mainLine;
// // // // // // }

// // // // // // function TriggerFlowModal({ trigger, opened, onClose }) {
// // // // // //   if (!trigger) return null;

// // // // // //   // We will place conditions in the left column, steps in the right column
// // // // // //   // Operators go between conditions

// // // // // //   // Layout constants
// // // // // //   const conditionX = 50;
// // // // // //   const stepX = 500; // steps in a separate column
// // // // // //   const baseY = 50;

// // // // // //   // Vertical spacing
// // // // // //   const conditionHeight = 120; // how far to move down for each condition
// // // // // //   const operatorHeight = 50;
// // // // // //   const stepHeight = 120;
// // // // // //   const verticalGap = 20;

// // // // // //   const nodeWidth = 300;

// // // // // //   const nodes = [];
// // // // // //   const edges = [];

// // // // // //   // Track Y for conditions
// // // // // //   let currentCondY = baseY;
// // // // // //   const conditions = trigger.conditions || [];

// // // // // //   conditions.forEach((cond, idx, arr) => {
// // // // // //     const condNodeId = `cond-${idx}`;
// // // // // //     const label = buildConditionLabel(cond, idx);

// // // // // //     // Condition node
// // // // // //     nodes.push({
// // // // // //       id: condNodeId,
// // // // // //       data: { label },
// // // // // //       position: { x: conditionX, y: currentCondY },
// // // // // //       style: {
// // // // // //         width: nodeWidth,
// // // // // //         backgroundColor: colors.bgpurple,
// // // // // //         border: `2px solid ${colors.ltpurple}`,
// // // // // //         borderRadius: 8,
// // // // // //         padding: '10px',
// // // // // //         whiteSpace: 'pre-line',
// // // // // //         fontSize: '16px',
// // // // // //       },
// // // // // //     });

// // // // // //     currentCondY += conditionHeight;

// // // // // //     // If there's a next condition, insert an operator node
// // // // // //     if (idx < arr.length - 1) {
// // // // // //       const opNodeId = `op-${idx}`;
// // // // // //       const opLabel = cond.operator || 'AND';

// // // // // //       // Operator node: smaller, centered horizontally between condition columns
// // // // // //       // We'll put it near the middle, but for simplicity, let's put it in line with the conditions
// // // // // //       // so it looks directly under the current condition node
// // // // // //       nodes.push({
// // // // // //         id: opNodeId,
// // // // // //         data: { label: opLabel },
// // // // // //         position: {
// // // // // //           x: conditionX + (nodeWidth / 2) - 30, 
// // // // // //           y: currentCondY,
// // // // // //         },
// // // // // //         style: {
// // // // // //           width: 60,
// // // // // //           height: 40,
// // // // // //           backgroundColor: '#fff',
// // // // // //           border: `1px solid ${colors.dkpurple}`,
// // // // // //           borderRadius: 4,
// // // // // //           textAlign: 'center',
// // // // // //           display: 'flex',
// // // // // //           alignItems: 'center',
// // // // // //           justifyContent: 'center',
// // // // // //           fontSize: '14px',
// // // // // //         },
// // // // // //       });

// // // // // //       // Condition -> Operator
// // // // // //       edges.push({
// // // // // //         id: `edge-${condNodeId}-${opNodeId}`,
// // // // // //         source: condNodeId,
// // // // // //         target: opNodeId,
// // // // // //         animated: true,
// // // // // //         style: { stroke: colors.dkpurple },
// // // // // //         type: 'smoothstep',
// // // // // //       });

// // // // // //       // Operator -> next condition
// // // // // //       const nextCondId = `cond-${idx + 1}`;
// // // // // //       edges.push({
// // // // // //         id: `edge-${opNodeId}-${nextCondId}`,
// // // // // //         source: opNodeId,
// // // // // //         target: nextCondId,
// // // // // //         animated: true,
// // // // // //         style: { stroke: colors.dkpurple },
// // // // // //         type: 'smoothstep',
// // // // // //       });

// // // // // //       currentCondY += operatorHeight + verticalGap;
// // // // // //     }
// // // // // //   });

// // // // // //   const conditionCount = conditions.length;
// // // // // //   let finalConditionNodeId = null;
// // // // // //   if (conditionCount > 0) {
// // // // // //     finalConditionNodeId = `cond-${conditionCount - 1}`;
// // // // // //   }

// // // // // //   // Steps in right column
// // // // // //   let currentStepY = baseY;
// // // // // //   const steps = trigger.steps || [];
// // // // // //   steps.forEach((step, idx) => {
// // // // // //     const stepId = `step-${idx}`;
// // // // // //     const label = step.webhookId && step.webhookId.length
// // // // // //       ? step.webhookId.join(', ')
// // // // // //       : `Step ${idx + 1}`;

// // // // // //     // Step node
// // // // // //     nodes.push({
// // // // // //       id: stepId,
// // // // // //       data: { label },
// // // // // //       position: { x: stepX, y: currentStepY },
// // // // // //       style: {
// // // // // //         width: nodeWidth,
// // // // // //         backgroundColor: colors.bgorange,
// // // // // //         border: `2px solid ${colors.ltorange}`,
// // // // // //         borderRadius: 8,
// // // // // //         padding: '10px',
// // // // // //         whiteSpace: 'pre-line',
// // // // // //         fontSize: '18px',
// // // // // //         color: colors.dkorange,
// // // // // //       },
// // // // // //     });

// // // // // //     // Edge from final condition to this step
// // // // // //     if (finalConditionNodeId) {
// // // // // //       edges.push({
// // // // // //         id: `edge-${finalConditionNodeId}-${stepId}`,
// // // // // //         source: finalConditionNodeId,
// // // // // //         target: stepId,
// // // // // //         animated: true,
// // // // // //         style: { stroke: colors.dkpurple },
// // // // // //         type: 'smoothstep',
// // // // // //       });
// // // // // //     }

// // // // // //     currentStepY += stepHeight;
// // // // // //   });

// // // // // //   return (
// // // // // //     <Modal
// // // // // //       opened={opened}
// // // // // //       onClose={onClose}
// // // // // //       withCloseButton={false}
// // // // // //       size="90%"
// // // // // //       styles={{ modal: { padding: 0 } }}
// // // // // //     >
// // // // // //       {/* A simple container for the close button + the flow */}
// // // // // //       <div style={{ position: 'relative', width: '100%', height: '80vh' }}>
// // // // // //         {/* Close button above the flow (top-right, outside the ReactFlow) */}
// // // // // //         <div style={{ padding: '10px', textAlign: 'right' }}>
// // // // // //           <button
// // // // // //             onClick={onClose}
// // // // // //             style={{
// // // // // //               background: 'transparent',
// // // // // //               border: `1px solid ${colors.dkpurple}`,
// // // // // //               borderRadius: '4px',
// // // // // //               padding: '4px 8px',
// // // // // //               cursor: 'pointer',
// // // // // //               color: colors.dkpurple,
// // // // // //               fontSize: '16px',
// // // // // //             }}
// // // // // //           >
// // // // // //             X
// // // // // //           </button>
// // // // // //         </div>

// // // // // //         {/* Trigger name in top-left above the flow */}
// // // // // //         <div
// // // // // //           style={{
// // // // // //             position: 'absolute',
// // // // // //             top: 60,
// // // // // //             left: 10,
// // // // // //             zIndex: 10,
// // // // // //             fontSize: '20px',
// // // // // //             fontWeight: 'bold',
// // // // // //             color: colors.dkpurple,
// // // // // //           }}
// // // // // //         >
// // // // // //           {trigger.name}
// // // // // //         </div>

// // // // // //         <ReactFlow
// // // // // //           nodes={nodes}
// // // // // //           edges={edges}
// // // // // //           fitView
// // // // // //           style={{ width: '100%', height: '100%' }}
// // // // // //         >
// // // // // //           <Background color="#aaa" gap={16} />
// // // // // //           <Controls style={{ top: 'auto', bottom: 10, left: 10 }} />
// // // // // //         </ReactFlow>
// // // // // //       </div>
// // // // // //     </Modal>
// // // // // //   );
// // // // // // }

// // // // // // export default TriggerFlowModal;

