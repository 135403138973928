import React from 'react';

function MLInsightPanel({ baseUrl, currentTrigger, onInsightApply }) {
  // Placeholder data; in a real app, fetch from `baseUrl`
  const insights = [
    { id: 1, title: 'Insight 1', confidence: 0.8, suggestedConditions: [] },
    { id: 2, title: 'Insight 2', confidence: 0.6, suggestedConditions: [] },
  ];

  const modelOptions = [
    'Events data',
    'Pages data',
    'FP Growth model',
    'Frequent Patterns model',
    'Markov Chain model',
    'Random Forest model',
  ];

  return (
    <div className="ml-insight-panel-container">
      <label className="ml-insight-label" htmlFor="modelSelect">
        Select Model
      </label>
      <select id="modelSelect" className="ml-insight-select">
        <option value="">-- Select a model --</option>
        {modelOptions.map((model) => (
          <option key={model} value={model}>
            {model}
          </option>
        ))}
      </select>

      <div className="ml-insight-list">
        {insights.map((insight) => (
          <div key={insight.id} className="ml-insight-item">
            <div className="ml-insight-title">{insight.title}</div>
            <div className="ml-insight-confidence">
              Confidence: {(insight.confidence * 100).toFixed(0)}%
            </div>
            <button
              onClick={() => onInsightApply(insight)}
              className="ml-insight-apply-button"
            >
              Apply
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MLInsightPanel;




// //MLInsightsPanel.jsx
// import React from 'react';

// function MLInsightPanel({ baseUrl, currentPattern, onInsightApply }) {
//   // Placeholder data; in a real app, fetch from `baseUrl`
//   const insights = [
//     { id: 1, title: 'Insight 1', confidence: 0.8 },
//     { id: 2, title: 'Insight 2', confidence: 0.6 },
//   ];

//   // Single place for "Select Model"
//   const modelOptions = [
//     'Events data',
//     'Pages data',
//     'FP Growth model',
//     'Frequent Patterns model',
//     'Markov Chain model',
//     'Random Forest model',
//   ];

//   return (
//     <div className="ml-insight-panel-container">
//       <label className="ml-insight-label" htmlFor="modelSelect">
//         Select Model
//       </label>
//       <select id="modelSelect" className="ml-insight-select">
//         <option value="">-- Select a model --</option>
//         {modelOptions.map((model) => (
//           <option key={model} value={model}>
//             {model}
//           </option>
//         ))}
//       </select>

//       <div className="ml-insight-list">
//         {insights.map((insight) => (
//           <div key={insight.id} className="ml-insight-item">
//             <div className="ml-insight-title">{insight.title}</div>
//             <div className="ml-insight-confidence">
//               Confidence: {(insight.confidence * 100).toFixed(0)}%
//             </div>
//             <button
//               onClick={() => onInsightApply(insight)}
//               className="ml-insight-apply-button"
//             >
//               Apply
//             </button>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default MLInsightPanel;


// // import React from 'react';
// // import { Select, Card, Text, Button } from '@mantine/core';

// // function MLInsightPanel({ baseUrl, currentPattern, onInsightApply }) {
// //   // Placeholder data; in a real implementation, fetch ML insights from `baseUrl`
// //   const insights = [
// //     { id: 1, title: 'Insight 1', confidence: 0.8 },
// //     { id: 2, title: 'Insight 2', confidence: 0.6 },
// //   ];

// //   return (
// //     <div>
// //       <h2 className="text-xl font-bold mb-4">ML Insights</h2>
// //       <Select
// //         data={[
// //           { value: 'model1', label: 'Model 1' },
// //           { value: 'model2', label: 'Model 2' },
// //         ]}
// //         placeholder="Select Model"
// //         mb="md"
// //       />
// //       <div className="space-y-2">
// //         {insights.map((insight) => (
// //           <Card key={insight.id} shadow="sm" p="lg">
// //             <Text weight={500}>{insight.title}</Text>
// //             <Text>Confidence: {(insight.confidence * 100).toFixed(0)}%</Text>
// //             <Button
// //               onClick={() => onInsightApply(insight)}
// //               mt="md"
// //               variant="light"
// //               color="blue"
// //             >
// //               Apply
// //             </Button>
// //           </Card>
// //         ))}
// //       </div>
// //     </div>
// //   );
// // }

// // export default MLInsightPanel;
